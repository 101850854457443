import { styled } from '@mui/material';
import { CtrlAccordion, CtrlAccordionDetails, CtrlAccordionSummary } from '../../components/controls/CtrlAccordion';
import { ReactNode } from 'react';
import { DefaultProps, SectionSystemCode } from '../../common/types';
import React from 'react';

export interface ExpandableSectionProps extends DefaultProps {
    name: SectionSystemCode;
    title: string | ReactNode;
    details?: string | ReactNode;
    defaultExpanded?: boolean;
    expanded: boolean;
    onExpandSection: (section: SectionSystemCode) => void;
}

function ExpandableSectionComponent(props: ExpandableSectionProps) {
    const isControlled = props.expanded !== undefined;

    const onSectionExpanded = React.useCallback(() => {props.onExpandSection(props.name)}, [props]);

    return (
        <div className={props.className}>
            <CtrlAccordion
                className="section"
                defaultExpanded={props.defaultExpanded || false}
                expanded={isControlled ? props.expanded : undefined}
                onChange={onSectionExpanded}
            >
                <CtrlAccordionSummary className="section-summary">{props.title}</CtrlAccordionSummary>
                <CtrlAccordionDetails className="section-content">{props.details}</CtrlAccordionDetails>
            </CtrlAccordion>
        </div>
    );
}

export const ExpandableSection = styled(ExpandableSectionComponent, { name: 'ExpandableSection' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '.section': {
        borderBottom: `1px solid ${theme.app.neutralColor.borderMain}`,
        '& .MuiAccordion-root': {
            margin: 0,
        },
    },
    '.section-content': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
    },
    '.section-summary': {
        minHeight: 60,
    },
}));
