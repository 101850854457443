import { ChangeEvent, ReactElement } from "react";
import { IState } from "./state";
import { ValidatedState, ValidationError, FieldValidator, SectionSystemCode } from "../common/types";
import _ from "lodash";
import { ReferralModel, referralModel } from "../pages/ReferralModel";

export interface IModel<T = any> {
    state: IState<T>;
}

export class TModel<T = any> implements IModel<T> {
    state;

    constructor(modelState: IState<T>) {
        this.state = modelState;
    }

    formatAdditionalNotes = (fieldsList: {fieldName: string, label: string, value?: string}[], sectionSystemCode: SectionSystemCode, withSpecialInstructions: boolean = true) => {
        const state = this.state.get();
        let notes: string[] = [];

        fieldsList.forEach(field => {
            const value = field.value || _.get(state, field.fieldName);

            if(value){
                notes.push(field.label ? `${field.label}: ${value}`: value);
            }
        });

        if(withSpecialInstructions){
            const specialInstructionsState = referralModel.specialInstructionsModel.state.get();
            const specialInstructions = specialInstructionsState.specialInstructions;

            if(specialInstructionsState.serviceCodes.includes(sectionSystemCode)){
                notes.push(specialInstructions ? `\n${referralModel.specialInstructionsModel.state.get().specialInstructions}` : '');
            }
        }

        return notes.filter(n => n).join('; ');
    }
}

export class ChangeableModel<T extends ValidatedState> extends TModel<T> {
    private handlersMap = new Map<string, (value: any) => void>();
    protected validators: FieldValidator[];

    constructor({ modelState, validators }: { modelState: IState<T>; validators: FieldValidator[] }) {
        super(modelState);
        this.validators = validators;
    }

    handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        this.state.update((state) => {
            _.set(state, name, value);

            const errors = state.errors.find((err) => err.fieldName === name);
            if (errors) {
                errors.errors = [];
            }
        });
    };

    handleControlChange = (fieldName: string) => {
        let handler = this.handlersMap.get(fieldName);
        if (!handler) {
            handler = (value: any) => {
                this.clearErrorMessages(fieldName);
                this.state.update((state) => {
                    _.set(state, fieldName, value);
                });
            };
            this.handlersMap.set(fieldName, handler);
        }
        return handler;
    };

    validate(referralModel: ReferralModel) {
        let errors: ValidationError[] = [];
        let warnings: ValidationError[] = [];

        this.validators?.forEach((validator) => {
            let error: ValidationError = errors.find((e) => e.fieldName === validator.fieldName) ?? {
                fieldLabel: validator.fieldLabel,
                fieldName: validator.fieldName,
                errors: [],
            };
            let warning: ValidationError = warnings.find((e) => e.fieldName === validator.fieldName) ?? {
                fieldLabel: validator.fieldLabel,
                fieldName: validator.fieldName,
                errors: [],
            };

            validator.validateFunctions.forEach((validate) => {
                let errorMessage = validate(_.get(this.state.get(), validator.fieldName), referralModel);
                if (errorMessage) {
                    validator.isWarning ? warning.errors?.push(errorMessage) : error.errors?.push(errorMessage);
                }
            });

            validator.isWarning ? warnings.push(warning) : errors.push(error);
        });

        this.state.update((state) => {
            state.validationCanBeSkipped = _.isEqual(state.errors, errors) && _.isEqual(state.warnings, warnings);

            state.errors = errors;
            state.warnings = warnings;
        });
    }

    clearValidationMessages() {
        this.state.update((state) => {
            state.errors = [];
        });
    }

    clearErrorMessages(fieldName: string) {
        this.state.update((state) => {
            const fieldErrors = state.errors?.find(
                (error) => error.fieldName === fieldName || error.fieldName === fieldName + '.value',
            );
            if (fieldErrors) {
                fieldErrors.errors = [];
            }
        });
    }

    getErrorMessage = (fieldName: string): string | undefined => {
        const { errors } = this.state.get();
        const foundError = errors?.find((error) => error.fieldName === fieldName);

        return foundError?.errors?.[0];
    };

    isValid = (): boolean => {
        const { errors } = this.state.get();
        return !errors || errors.length === 0 || errors.every((err) => err.errors?.length === 0);
    };

    getSummaryFields() {
        return new Map<string, string | ReactElement | undefined>([]);
    }

    getAdditionalNotes(withSpecialInstructions: boolean = true) {
        return new Map<string, string>([]);
    }

    getWarnings = (): string[] => {
        const { warnings } = this.state.get();

        return (
            warnings
                ?.filter((w) => w.errors?.length && w.errors.length > 0)
                ?.map((w) => [w.fieldLabel, w.errors?.join('; ')].join(': ')) ?? []
        );
    };

    populateFrom = (source: any) => {
        if (!_.isEmpty(source)) {;
            this.state.set((oldState) => ({ ...oldState, ...source }));
        }
    };
}