import { Section, SectionSystemCode } from '../common/types';
import { TGlobalState } from '../model/state';
import { TPortalCustomization, TPortalMode } from './sections/utils';

export interface IReferralState {
    sections: Section[];
    sectionsError?: string;
    selectedServices: SectionSystemCode[];
    mode: TPortalMode;
    customization: TPortalCustomization;
    queryParams: Record<string, string>;
}

export const defaultReferralState: IReferralState = {
    sections: [
        { title: "Submitter", systemCode: "Submitter", visible: true, expanded: true, service: false },
        { title: "Claim/Patient", systemCode: "ClaimPatient", visible: true, expanded: false, service: false },
        { title: "Employer", systemCode: "Employer", visible: true, expanded: false, service: false },
        { title: "Adjuster", systemCode: "Adjuster", visible: true, expanded: false, service: false },
        { title: "Insurance", systemCode: "Insurance", visible: true, expanded: false, service: false },
        { title: "Other Parties", systemCode: "OtherParties", visible: true, expanded: false, service: false },
        { title: "Referring Physician", systemCode: "ReferringPhysician", visible: false, expanded: false, service: false },
        { title: "Case Manager", systemCode: "NurseCaseManager", visible: false, expanded: false, service: false },
        { title: "Attorney", systemCode: "Attorney", visible: false, expanded: false, service: false },
        { title: "Physical Therapy", systemCode: 'PhysicalMedicine', visible: false, expanded: false, service: true },
        { title: "Diagnostics", systemCode: 'DiagnosticServices', visible: false, expanded: false, service: true },
        { title: "Durable Medical Equipment", systemCode: 'DurableMedicalEquipment', visible: false, expanded: false, service: true },
        { title: "Home Health + Complex Care", systemCode: 'HomeHealthCare', visible: false, expanded: false, service: true },
        { title: "Transportation", systemCode: 'TransportationServices', visible: false, expanded: false, service: true },
        { title: "Language", systemCode: 'LanguageTranslation', visible: false, expanded: false, service: true },
        { title: "Dental", systemCode: 'dsDentalServices', visible: false, expanded: false, service: true },
        { title: "Doctor", systemCode: 'dsDoctorServices', visible: false, expanded: false, service: true },
        { title: "MRI Prescreening Questions", systemCode: "PrescreeningQuestions", visible: false, expanded: false, service: false },
        { title: "Special Instructions", systemCode: "SpecialInstructions", visible: true, expanded: false, service: false },
        { title: "Attachments", systemCode: "Attachments", visible: true, expanded: false, service: false },
    ],
    selectedServices: [],
    mode: 'EZReferral',
    customization: 'Default',
    queryParams: {},
};

export const referralState = new TGlobalState<IReferralState>(defaultReferralState);