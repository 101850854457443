import { ViewProps } from '../../../../common/types';
import { styled } from '@mui/material';
import { CtrlComboSelect } from '../../../../components/controls/CtrlComboSelect';
import { CtrlTextField } from '../../../../components/controls/CtrlTextField';
import { CtrlTextArea } from '../../../../components/controls/CtrlTextArea';
import { CtrlCheckbox } from '../../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../../ReferralModel';
import { valuesManager } from '../../../../services/valuesManager/valuesManager';
import { CtrlButton } from '../../../../components/controls/CtrlButton';
import { ArrowRightIcon, InfoIcon } from '../../../../theme/icons';
import { useEffect } from 'react';

export interface DoctorProps extends ViewProps<ReferralModel> {}

const NoteMessage = `Note: If you have any medical records, include them in the attachments section or fax to 888-539-0579`;

function DoctorComponent(props: DoctorProps) {
    const { model } = props;
    const doctorModel = model.doctorModel;
    const state = doctorModel.state.use();
    const doctorReferralForValues = valuesManager.use(s => s.doctorReferralForValues);

    useEffect(() => { valuesManager.loadReferralForValues() }, []);
    useEffect(() => { 
        doctorModel.state.update(s => {
            if (!s.referralFor) {
                s.referralFor = doctorReferralForValues?.find(rf => rf.value === 'EvalTX') || doctorReferralForValues?.[0];
            }
        })
     }, [doctorModel.state, doctorReferralForValues]);
   
    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <div className="span-6">
                        <CtrlComboSelect
                            label="Referral Reason"
                            selectFrom={valuesManager.resourceValues.referralReasonValues}
                            value={state.referralReason}
                            onChange={(selectedValue) => {
                                const newValue = selectedValue || valuesManager.resourceValues.referralReasonValues[0];
                                doctorModel.handleControlChange('referralReason')(newValue);
                                doctorModel.clearErrorMessages('referralReasonDescription');
                            }}
                            hideClear
                        />
                    </div>
                </div>
                <div className="span-6">
                    <CtrlTextField
                            label="Description"
                            value={state.referralReasonDescription}
                            onChange={doctorModel.handleControlChange('referralReasonDescription')}
                            error={doctorModel.getErrorMessage('referralReasonDescription')}
                            required={state.referralReason?.value === "Other"}
                            maxCharacters={200}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect
                            label="Referral For"
                            selectFrom={doctorReferralForValues}
                            value={state.referralFor}
                            onChange={(selectedValue) => {
                                const newValue = selectedValue || doctorReferralForValues?.[0];
                                doctorModel.handleControlChange('referralFor')(newValue);
                                doctorModel.clearErrorMessages('referralForDescription');
                            }}
                            hideClear
                        />
                </div>
                <div className="span-6">
                <CtrlTextField
                            label="Description"
                            value={state.referralForDescription}
                            onChange={doctorModel.handleControlChange('referralForDescription')}
                            error={doctorModel.getErrorMessage('referralForDescription')}
                            required={state.referralFor?.value === "Other"}
                            maxCharacters={200}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect
                            label="Visit Purpose"
                            selectFrom={valuesManager.resourceValues.visitPurposeValues}
                            value={state.visitPurpose}
                            onChange={(selectedValue) => {
                                const newValue = selectedValue || valuesManager.resourceValues.visitPurposeValues[0];
                                doctorModel.handleControlChange('visitPurpose')(newValue);
                                doctorModel.clearErrorMessages('visitPurposeDescription');
                            }}
                            hideClear
                        />
                </div>
                <div className="span-6">
                        <CtrlTextField
                            label="Description"
                            value={state.visitPurposeDescription}
                            onChange={doctorModel.handleControlChange('visitPurposeDescription')}
                            error={doctorModel.getErrorMessage('visitPurposeDescription')}
                            required={state.visitPurpose?.value === "Other"}
                            maxCharacters={200}
                        />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-12">
                        <CtrlTextField
                            label="Provider Type Required"
                            value={state.providerTypeRequired}
                            onChange={doctorModel.handleControlChange('providerTypeRequired')}
                            maxCharacters={200}
                        />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-12">
                        <CtrlTextField
                            label="How did the Injury Occur?"
                            value={state.howDidTheInjuryOccur}
                            onChange={doctorModel.handleControlChange('howDidTheInjuryOccur')}
                            maxCharacters={255}
                        />
                </div>        
            </div>
            <div className="fields-container">
                <div className="span-12">
                        <CtrlTextArea
                            label="Physicians Previously Seen Regarding the Injury"
                            maxCharacters={1000}
                            style={{ minHeight: 84 }}
                            onChange={doctorModel.handleControlChange('previousPhysician')}
                            value={state.previousPhysician}
                        />
                </div>
            </div>
            <div className="hh-is-rush-row">
                <div className="span-12">
                    <CtrlCheckbox
                        label="Rush"
                        value={state.isRush}
                        onChange={doctorModel.handleControlChange('isRush')}
                    />
                </div>
            </div>
            <div className="notes-row">
                <InfoIcon />
                    <div className="span-12">
                        {NoteMessage}
                    </div>
            </div>
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection('dsDoctorServices', doctorModel);
                        model.validateSection('Attachments', model.attachmentsModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const Doctor = styled(DoctorComponent, { name: 'Doctor' })(({ theme }) => ({
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.hh-is-rush-row': {
        marginTop: 7,
        marginBottom: 20,
        paddingTop: 7,
    },
    '.notes-row': {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        columnGap: theme.spacing(1),
    },
}));