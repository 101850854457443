import { styled } from "@mui/material";
import { MessageWarningIcon } from "../../../theme/icons";

const HelperErrorSpan = styled("span", { name: "HelperErrorSpan" })(
    ({ theme }) => ({
        color: theme.app.statusColor.errorMain,
        display: "flex",
        alignItems: "center",
        "& svg.error-text-icon": {
            color: theme.app.statusColor.errorMain,
            marginRight: theme.spacing(1),
            width: 12,
            height: 12,
        },
    })
);

export const helperError = (error?: boolean | string) =>
    error && typeof error === "string" ? (
        <HelperErrorSpan>
            <MessageWarningIcon className="error-text-icon" />
            {error}
        </HelperErrorSpan>
    ) : undefined;
