import { SectionSystemCode } from "../../../../common/types";
import { ChangeableModel } from "../../../../model/model";
import { IHomeHealthComplexCareState, THHServicesResourceValue } from "./HomeHealthComplexCareState";
import { validateDateField, validateRequiredField, validatePhoneNumber, validateZipCode, requiredIfAddressPopulated } from "../../../../common/validationHelpers";
import { IClaimPatientState } from "../../claimPatient/ClaimPatientState";
import { FieldValidator, TRange } from '../../../../common/types';
import { ReferralModel, referralModel } from "../../../ReferralModel";
import { valuesManager } from "../../../../services/valuesManager/valuesManager";
import dayjs from "dayjs";
import { ReactElement } from "react";
import _ from "lodash";

export const sectionSystemCode: SectionSystemCode = 'HomeHealthCare'

const requiredForHHService = (value: any, referralModel: ReferralModel) => {
    return !referralModel.homeHealthComplexCareModel.state.get().homeHealthServices?.some(x => x.checked)
        ? validateRequiredField(value)
        : null;
};

export const requestedStartOfCareDateRanges = ():TRange<Date> => ({
    from: dayjs(new Date()).add(1, 'day').startOf('day').toDate(),
    to: dayjs(new Date()).add(1, 'year').endOf('year').toDate(),
});

export const hospitalDischargeDateRange = (): TRange<Date> => {
    return ({
        from: dayjs(new Date()).startOf('day').toDate(),
        to: dayjs(new Date()).add(1, 'year').endOf('year').toDate(),
    });
};

const getAddress = () => referralModel.homeHealthComplexCareModel.state.get().careAddress === 'Home'
    ? referralModel.claimPatientModel.state.get().address
    : referralModel.homeHealthComplexCareModel.state.get().address


const validateHomeHealthAddress = (fieldName: string) => {
    return (value: string, referralModel: ReferralModel) => {
        return requiredIfAddressPopulated(getAddress)(referralModel.homeHealthComplexCareModel.state.get().careAddress === "Home" ? _.get(referralModel.claimPatientModel.state.get().address, fieldName) : value, referralModel)
    };
}

export const homeHealthComplexCareValidators: FieldValidator[] = [
    { fieldName: 'commentsAdditionalDetails', fieldLabel: 'Comments/Additional details', validateFunctions: [requiredForHHService] },
    { 
        fieldName: "requestedStartOfCare",
        fieldLabel: "Requested Start of Care",
        validateFunctions: [validateDateField(() => referralModel.homeHealthComplexCareModel.state.get().requestedStartOfCareString, requestedStartOfCareDateRanges)]
    },
    { 
        fieldName: "hospitalDischargeDate", 
        fieldLabel: "Hospital Discharge Date", 
        validateFunctions: [validateDateField(() => referralModel.homeHealthComplexCareModel.state.get().hospitalDischargeDateString, hospitalDischargeDateRange)]
    },
    { fieldName: 'hospitalPhone', fieldLabel: 'Hospital Phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'address.address1', fieldLabel: 'Address 1', validateFunctions: [validateHomeHealthAddress('address1')] },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [validateHomeHealthAddress('city')] },
    { fieldName: 'address.state.value', fieldLabel: 'State', validateFunctions: [validateHomeHealthAddress('state.value')] },
    { 
        fieldName: 'address.zip',
        fieldLabel: 'Zip code',
        validateFunctions: [
            (value, referralModel) => validateZipCode(referralModel.homeHealthComplexCareModel.state.get().careAddress === 'Home' ? referralModel.claimPatientModel.state.get().address?.zip : value),
            validateHomeHealthAddress('zip')
        ] 
    },
];

export interface IClaimPatientEntity {
    copyClaimPatientFields: (submitter: IClaimPatientState) => void;
}

export class HomeHealthComplexCareModel extends ChangeableModel<IHomeHealthComplexCareState> {
    clearHospitalFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.update((state) => {
            if (event.target.value === 'No') {
                state.hospitalFacilityName = '';
                state.hospitalDischargeDate = undefined;
                state.hospitalPhone = '';
                state.contactPerson = '';
            }           
        });

        return this.handleChange(event);
    }

    handleHhServiceChange(service: THHServicesResourceValue){
        this.state.update((state) => {
            const targetHHService = state.homeHealthServices?.find(s => s.value === service.value);
            if(targetHHService){
                targetHHService.checked = !service.checked;
                const errors = state.errors.find((err) => err.fieldName === 'commentsAdditionalDetails');
                if (errors) {
                    errors.errors = [];
                }
            }
        })
        
        this.handleHhfrequencyDurationTypeChange(service, undefined);
    }

    handleHhfrequencyDurationTypeChange(service: THHServicesResourceValue, value?: string){
        this.state.update((state) => {
            const targetHHService = state.homeHealthServices?.find(s => s.value === service.value);
            if(targetHHService){
                targetHHService.frequencyDurationType = value;
            }
            const errors = state.errors.find(
                (err) =>
                    err.fieldName === service.value
            );
            if (errors) {
                errors.errors = [];
            }
        })
    }

    validate(referralModel: ReferralModel): void {
        const hhServices = this.state.get().homeHealthServices || [];
        this.validators = [
            ...homeHealthComplexCareValidators,
            ...hhServices.map((service) => this.getHHServiceValidators(service)).flat(),
        ];

        super.validate(referralModel);
    }

    private readonly getHHServiceValidators = (service: THHServicesResourceValue): FieldValidator[] => {
        return [
            {
                fieldName: service.value,
                fieldLabel: `Frequency / Duration / Type - ${service.label}`,
                validateFunctions: [() => service.checked ? validateRequiredField(service.frequencyDurationType) : undefined],
            }
        ];
    };

    getSummaryFields = () => {
        const state = this.state.get();
        const selectedServices = state.homeHealthServices?.filter(service => service.checked) || [];
        
        return new Map<string, string | ReactElement | undefined>([
            ["", 
            <div>{selectedServices.map(service => <span> {service.label.toUpperCase()} <br/> 
            <div style={{ marginLeft: 25}}> {service.frequencyDurationType ? service.frequencyDurationType : ''} </div> </span>)}</div> 
            ],
        ]);
    }

    override getAdditionalNotes(withSpecialInstructions: boolean = true){
        const state = this.state.get();

        let notes: [string, string][] = [];

        const hhServices = state.homeHealthServices?.filter(s => s.checked);

        (hhServices?.length ? hhServices : [{label: '', frequencyDurationType: '', value: ''}]).forEach(service => {
            const note = [
                referralModel.getGeneralInstructions(),
                this.formatAdditionalNotes([
                    {fieldName: 'commentsAdditionalDetails', label: 'Comments / Additional Details'},
                    {fieldName: 'frequencyDurationType', label: 'Frequency / Duration / Type', value: service.frequencyDurationType},
                    {fieldName: 'selectedMedicalSuppliesValue', label: 'Medical Supplies', value: state.selectedMedicalSuppliesValue?.map(s => s.label).join('; ')},
                    {fieldName: 'selectedRespiratorySuppliesValue', label: 'Respiratory Supplies', value: state.selectedRespiratorySuppliesValue?.map(s => s.label).join('; ')},
                    {fieldName: 'isPrescriptionPhysicianOrders', label: 'Prescription/Physician Orders', value: state.isPrescriptionPhysicianOrders ? "Yes" : undefined},
                    {fieldName: 'patientInHospital', label: 'Patient in the Hospital', value: state.patientInHospital},
                    {fieldName: 'hospitalFacilityName', label: 'Hospital / Facility Name'},
                    {fieldName: 'hospitalPhone', label: 'Hospital Phone'},
                    {fieldName: 'contactPerson', label: 'Contact Person'},
                ],
                sectionSystemCode,
                withSpecialInstructions),
            ];

            notes.push([
                service.value,
                note.filter(n => n).join(";")
            ]);
        });

        return new Map<string, string>(notes);
    }
}