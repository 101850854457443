import { Box, Typography, styled } from '@mui/material';
import { CtrlRadio } from '../../../components/controls/CtrlRadio';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon, InfoIcon } from '../../../theme/icons';
import { CtrlCheckbox } from '../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../ReferralModel';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { OtherSubmitterType } from './SubmitterState';
import { CtrlComboSelect } from '../../../components/controls/CtrlComboSelect';
import { setStateToStorage } from '../../../common/localStorageUtils';

export interface SubmitterProps extends ViewProps<ReferralModel> {}
const sectionSystemCode: SectionSystemCode = 'Submitter';

export const otherSubmitterOptions: { id: OtherSubmitterType; label: string }[] = [
    { id: 'Attorney', label: 'Attorney' },
    { id: 'AttorneyOffice', label: 'Attorney Office' },
    { id: 'Employer', label: 'Employer' },
    { id: 'UtilizationReview', label: 'Utilization Reviewer' },
    { id: 'UtilizationReviewOffice', label: 'Utilization Reviewer Office' },
    { id: 'IndependentAdjuster', label: 'Independent Adjuster' },
    { id: 'IMECompany', label: 'IME Company' },
];

const sectionsToSubmitterMap = new Map<string, SectionSystemCode>([
    ['NurseCaseManager', 'NurseCaseManager'],
    ['NurseCaseManagerOffice', 'NurseCaseManager'],
    ['ReferringPhysician', 'ReferringPhysician'],
    ['ReferringPhysicianOffice', 'ReferringPhysician'],
    ['Attorney', 'Attorney'],
    ['AttorneyOffice', 'Attorney'],
]);

function SubmitterComponent(props: SubmitterProps) {
    const { model } = props;
    const submitterModel = model.submitterModel;
    const state = submitterModel.state.use();
    const globalState = model.state.use();
    const storageKey = globalState.customization === 'GM' ? 'Submitter-gm' : 'Submitter';

    return (
        <div className={props.className}>
            {globalState.customization !== 'GM' ? (
                <Box>
                    <Typography variant="h2">I am</Typography>
                    <div className="submitter-section">
                        <CtrlRadio
                            className="radio-section"
                            options={state.submitters}
                            name="submitterType"
                            value={state.submitterType}
                            onChange={(e) => {
                                submitterModel.handleChange(e);
                                if (e.target.value !== 'Other') {
                                    submitterModel.handleControlChange('otherSubmitterType')(undefined);
                                }
                                model.setSectionVisible(sectionsToSubmitterMap.get(e.target.value));
                            }}
                        >
                            <div className="other-submitter-select" style={{ width: 220, height: 48 }}>
                                {state.submitterType === 'Other' ? (
                                    <CtrlComboSelect
                                        selectFrom={otherSubmitterOptions}
                                        value={otherSubmitterOptions.find((s) => s.id === state.otherSubmitterType)}
                                        onChange={(value) => {
                                            submitterModel.handleControlChange('otherSubmitterType')(value?.id);
                                            model.setSectionVisible(sectionsToSubmitterMap.get(value?.id ?? ''));

                                            submitterModel.state.update((s) => {
                                                const errors = s.errors.find((e) => e.fieldName === 'companyName');
                                                if (errors) {
                                                    errors.errors = [];
                                                }
                                            });
                                        }}
                                        error={submitterModel.getErrorMessage('otherSubmitterType')}
                                        fullWidth
                                    />
                                ) : undefined}
                            </div>
                        </CtrlRadio>
                    </div>
                </Box>
            ) : null}
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="First Name"
                        value={state.firstName}
                        onChange={submitterModel.handleControlChange('firstName')}
                        error={submitterModel.getErrorMessage('firstName')}
                        maxCharacters={50}
                        required
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Last Name"
                        value={state.lastName}
                        onChange={submitterModel.handleControlChange('lastName')}
                        error={submitterModel.getErrorMessage('lastName')}
                        maxCharacters={50}
                        required
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-3">
                    <CtrlTextField
                        label="Main Phone"
                        onChange={submitterModel.handleControlChange('mainPhone')}
                        error={submitterModel.getErrorMessage('mainPhone')}
                        value={state.mainPhone}
                        required
                        phoneFormat
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Ext."
                        value={state.ext}
                        onChange={submitterModel.handleControlChange('ext')}
                        error={submitterModel.getErrorMessage('ext')}
                        maskProps={{
                            mask: '99999999',
                        }}
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Fax"
                        value={state.fax}
                        onChange={submitterModel.handleControlChange('fax')}
                        error={submitterModel.getErrorMessage('fax')}
                        phoneFormat
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Cell Phone"
                        value={state.cellPhone}
                        onChange={submitterModel.handleControlChange('cellPhone')}
                        error={submitterModel.getErrorMessage('cellPhone')}
                        phoneFormat
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className={globalState.customization === 'GM' ? 'span-12' : 'span-6'}>
                    <CtrlTextField
                        label="Email"
                        value={state.email}
                        onChange={submitterModel.handleControlChange('email')}
                        error={submitterModel.getErrorMessage('email')}
                        required
                    />
                </div>
                {globalState.customization !== 'GM' ? (
                    <div className="span-6">
                        <CtrlTextField
                            label="Company Name"
                            value={state.companyName}
                            onChange={submitterModel.handleControlChange('companyName')}
                            error={submitterModel.getErrorMessage('companyName')}
                            required={state.otherSubmitterType === 'UtilizationReview'}
                        />
                    </div>
                ) : null}
            </div>
            <div className="alert-row">
                <InfoIcon />
                <Typography>
                    If you are the only one submitting referrals from this device, remember this section data to save
                    time in the future
                </Typography>
            </div>
            <div className="continue-button-row">
                <CtrlCheckbox
                    label="Remember This Section Data"
                    value={state.rememberSectionData}
                    onChange={(value) => {
                        submitterModel.handleControlChange('rememberSectionData')(value);
                        setStateToStorage(storageKey, {...submitterModel.state.get(), submitters: undefined}, value);
                    }}
                />
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        const state = submitterModel.state.get();
                        model.validateSection(sectionSystemCode, submitterModel);
                        setStateToStorage(storageKey, {...state, submitters: undefined}, state.rememberSectionData);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const Submitter = styled(SubmitterComponent, { name: 'Submitter' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.submitter-section': {
        width: '65%',
        display: 'flex',
    },
    '.radio-section': {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
    },
    '.radio-section label ': {
        height: 34,
    },
    '.other-submitter-select': {
        alignSelf: 'flex-end',
    },
}));
