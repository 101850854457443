import { Typography, styled } from '@mui/material';
import { Section, ViewProps } from '../common/types';
import { ReferralModel, referralModel } from './ReferralModel';
import React from 'react';
import { ExpandableSection, Services } from './sections/index';
import { ContentSectionsMap } from './ContentSectionsMap';

function sectionHeader(title: string, summary?: React.ReactNode, endIcon?: React.ReactNode) {
    return (
        <div className="section-header" id={title}>
            <Typography variant="h2" className="section-title">
                {title}
            </Typography>
            {summary}
            {endIcon}
        </div>
    );
}

function ContentComponent(props: ViewProps<ReferralModel>) {
    const { model } = props;
    const { sections, sectionsError, customization } = model.state.use();

    const sectionComponents = ContentSectionsMap(model);

    const renderSections = React.useCallback(
        ({ title, systemCode, visible, expanded }: Section) => {
            const SectionComponent = sectionComponents.get(systemCode);

            if (!SectionComponent) {
                return;
            }
            const isSectionComponentType = 'details' in SectionComponent;

            return visible ? (
                <ExpandableSection
                    title={sectionHeader(
                        title,
                        !expanded && isSectionComponentType && SectionComponent.summary,
                        isSectionComponentType && SectionComponent.endIcon,
                    )}
                    details={isSectionComponentType ? SectionComponent.details : SectionComponent}
                    expanded={expanded}
                    onExpandSection={model.setSectionExpanded}
                    name={systemCode}
                    key={systemCode}
                />
            ) : null;
        },
        [model.setSectionExpanded, sectionComponents],
    );

    return (
        <div className={props.className}>
            {
                referralModel.needToRenderServices()
                ? <Services
                    sections={sections}
                    onToggleService={model.setServiceSelected}
                    error={sectionsError}
                    customization={customization}
                />
                : null
            }
            {sections.map(renderSections)}
        </div>
    );
}

export const Content = styled(ContentComponent, { name: 'Content' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1 1 auto',
    padding: theme.spacing(6),
    '.section-header': {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(4),
        width: '65%',
        minHeight: 60,
    },
    '.section-title': {
        color: theme.app.accentColor.textMain,
        width: '270px',
    },
    '.fields-container': {
        width: '65%',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        columnGap: theme.spacing(4),
        paddingTop: theme.spacing(4),
        '&:not(:nth-of-type(1))': {
            marginTop: theme.spacing(5),
        },
    },
    '.selected-services': {
        alignItems: 'flex-start',
        flexDirection: 'column',
        paddingTop: 0,
        marginTop: `${theme.spacing(3)} !important`,
        '.services-chips': {
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            columnGap: theme.spacing(3),
        },
    },
    '.continue-button-row': {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    '.alert-row': {
        display: 'flex',
        paddingTop: theme.spacing(3),
        gap: 4,
        marginTop: '0 !important',
        alignItems: 'center',
    },
}));
