import { styled } from '@mui/system';
import { CtrlTextArea } from '../../../components/controls/CtrlTextArea';
import { Checkbox, Typography } from '@mui/material';
import { theme } from '../../../theme';
import { CtrlChip } from '../../../components/controls/CtrlChip';
import { Section, ViewProps } from '../../../common/types';
import { ReferralModel, referralModel } from '../../ReferralModel';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../theme/icons';
import { useEffect } from 'react';
import { helperError } from '../../../components/controls/elements/helperError';

export interface SpecialInstructionsProps extends ViewProps<ReferralModel> {}

function SpecialInstructionsComponent(props: SpecialInstructionsProps) {
    const { model } = props;
    const specialInstructionsModel = model.specialInstructionsModel;
    const state = specialInstructionsModel.state.use();
    const globalState = model.state.use();
    const sections = globalState.sections.filter((s) => s.service && s.visible);
    const charactersLimit = 3000 - referralModel.getMaxSectionInstructionLimit();

    useEffect(() => {
        specialInstructionsModel.sectionChanged(globalState.sections);
    }, [specialInstructionsModel, globalState.sections]);

    return (
        <div className={props.className}>
            <div className="fields-container no-padding-top">
                <div className="span-12">
                    <CtrlTextArea
                        className="text-area"
                        maxCharacters={charactersLimit > 2000 ? 2000 : charactersLimit}
                        value={state.specialInstructions}
                        onChange={specialInstructionsModel.handleControlChange('specialInstructions')}
                        error={specialInstructionsModel.getErrorMessage('specialInstructions')}
                        doNotTruncateText
                    />
                </div>
            </div>
            {referralModel.needToRenderServices() ? (
                <div className="fields-container selected-services">
                    <div className="span-12">
                        <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                            Selected Services
                        </Typography>
                        <div className={'services-chips'}>
                            {sections.map((i: Section) => (
                                <CtrlChip
                                    label={i.title}
                                    key={i.systemCode}
                                    onDelete={() => {}}
                                    onClick={() =>
                                        specialInstructionsModel.serviceClick(
                                            i.systemCode,
                                            !state.serviceCodes.includes(i.systemCode),
                                        )
                                    }
                                    deleteIcon={
                                        <Checkbox
                                            className="chip-checkbox"
                                            checked={state.serviceCodes.includes(i.systemCode)}
                                        />
                                    }
                                />
                            ))}
                        </div>
                        {Boolean(specialInstructionsModel.getErrorMessage('serviceCodes')) &&
                            helperError(specialInstructionsModel.getErrorMessage('serviceCodes'))}
                    </div>
                </div>
            ) : null}
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection('SpecialInstructions', specialInstructionsModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const SpecialInstructions = styled(SpecialInstructionsComponent, { name: 'SpecialInstructions' })(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 6),
        padding: theme.spacing(0, 4, 4),
        '.text-area': {
            minHeight: 84,
        },
        '.services-chips': {
            display: 'flex',
            columnGap: theme.spacing(3),
            rowGap: theme.spacing(1),
            flexWrap: 'wrap',
            marginTop: theme.spacing(1),
        },
        '.no-padding-top': {
            paddingTop: 0,
        },
        '.chip-checkbox': {
            pointerEvents: 'none',
            margin: theme.spacing(1, 0, 1, 1),
            order: -1,
        },
    }),
);
