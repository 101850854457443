import { Typography, styled } from '@mui/material';
import { DefaultProps } from '../../common/types';
import { WarningIcon } from '../../theme/icons';
import { theme } from '../../theme';

export interface WarningsPanelProps extends DefaultProps {
    messages: string[];
}

function WarningsPanelComponent(props: WarningsPanelProps) {
    const { messages } = props;

    if (!messages.length) {
        return null;
    }

    return (
        <div className={props.className}>
            <div className="header">
                <WarningIcon className={'icon'} />
                <div className="content">
                    <Typography className="title" variant="body1">
                        Please review the following suggestions
                    </Typography>
                    <ul className="messages-list">
                        {messages.map((m, idx) => (
                            <li>
                                <Typography variant="body2" color={theme.app.neutralColor.textMain} key={idx}>
                                    <div dangerouslySetInnerHTML={{ __html: m }} />
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export const WarningsPanel = styled(WarningsPanelComponent, { name: 'WarningsPanel' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    margin: theme.spacing(6, 0, 0),
    padding: theme.spacing(4),
    width: '65%',
    border: `1px solid ${theme.app.statusColor.warningLight}`,
    background: theme.app.statusColor.warningSuperLight,
    '.header': {
        display: 'flex',
        alignItems: 'flex-start',
        columnGap: theme.spacing(3),
    },
    '.icon': {
        color: theme.app.statusColor.warningMain,
        marginTop: 3,
    },
    '.title': {
        color: theme.app.statusColor.warningMain,
        fontSize: 14,
    },
    '.content': {
        width: '100%',
    },
    '.messages-list': {
        paddingLeft: 20,
        margin: theme.spacing(3, 0, 0),
        color: theme.app.neutralColor.textMain,
    },
}));
