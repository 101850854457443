import { DefaultProps, SectionSystemCode } from '../../../common/types';
import { CtrlComboSelect, CtrlComboSelectProps } from '../../../components/controls/CtrlComboSelect';
import React from 'react';
import _ from 'lodash';
import { Typography, styled } from '@mui/material';
import { ICompany, defaultColumns } from './utils';
import clsx from 'clsx';
import { TResourceValue } from '../../../services/valuesManager/valuesManager';

export const CompanySelectRow = styled(
    function (props: DefaultProps & { values: string[]; sectionSystemCode: SectionSystemCode; isHeader?: boolean; isSelected?: boolean }) {
        const { values, sectionSystemCode, isHeader, isSelected } = props;

        return (
            <div className={props.className}>
                <div className={clsx('flex-container', { selected: isSelected })}>
                    {values.map((value, idx) => (
                        <div className={idx < 2 ? 'flex-item-wide' : 'flex-item-short'} key={`${sectionSystemCode}-${value}`}>
                            <Typography variant={isHeader ? 'h2' : 'body2'} className="text-ellipsis">
                                {value}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        );
    },
    { name: 'CompanySelectRow' },
)(({ theme }) => ({
    '.flex-container': {
        display: 'flex',
    },
    '.flex-item-wide': {
        flex: '3 1 70%',
        minWidth: 0,
    },
    '.flex-item-short': {
        flex: '1 1 30%',
        minWidth: 0,
    },
    '.text-ellipsis': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '.selected': {
        p: {
            color: theme.app.accentColor.textMain,
        },
    },
}));

export interface CompanyProps extends Omit<CtrlComboSelectProps, 'onChange' | 'selectFrom'> {
    sectionSystemCode: SectionSystemCode;
    onChange: (value?: string | TResourceValue, company?: ICompany) => void;
    selectFrom: ICompany[];
    columns?: [
        {
            label: string;
            valuePath: string;
        },
    ];
}

export function Company(props: CompanyProps) {
    const { sectionSystemCode, onChange, selectFrom, columns = defaultColumns, ...other } = props;
    const getSelected = React.useCallback((o: any, selected?: any) => o.value === selected?.value && !selected.freeText, []);
    const getHovered = React.useCallback(
        (o: any, hovered?: any) => o.value === hovered?.value && !hovered.freeText,
        [],
    );
    
    const companyRows = React.useCallback(() => {
        return selectFrom.map((company): any => ({
            label: company.company?.label ?? '',
            value: company.company?.value ?? '',
            element: (
                <CompanySelectRow
                    sectionSystemCode={sectionSystemCode}
                    values={columns.map((c) => _.get(company, c.valuePath))}
                    isSelected={props.value?.value === company.company?.value && !props.value?.freeText}
                />
            ),
        }));
    }, [columns, props.value?.freeText, props.value?.value, sectionSystemCode, selectFrom]);

    const handleChange = React.useCallback(
        (value?: TResourceValue) => {
            onChange(
                value,
                selectFrom.find((c) => c.company?.value === value?.value),
            );
        },
        [selectFrom, onChange],
    );

    return (
        <CtrlComboSelect
            {...other}
            onChange={handleChange}
            header={selectFrom.length ? <CompanySelectRow values={columns.map((c) => c.label)} sectionSystemCode={sectionSystemCode} isHeader /> : undefined}
            selectFrom={companyRows}
            getSelected={getSelected}
            getHovered={getHovered}
            getLabel={(c: TResourceValue) => c.value}
            freeText
            customRows
        />
    );
}
