import { IconButton, IconButtonProps, styled } from "@mui/material";
import { WithTooltip } from "./WithTooltip";

export interface CtrlIconButtonProps extends IconButtonProps {
    title?: string;
    tooltipFollowCursor?: boolean;
    redMark?: boolean;
    invisible?: boolean;
}

function CtrlIconButtonComponent(props: CtrlIconButtonProps) {
    const { title, tooltipFollowCursor, redMark, invisible, ...other } = props;
    if (invisible) {
        return null;
    }
    return (
        <WithTooltip
            title={title}
            disabled={props.disabled}
            followCursor={tooltipFollowCursor}
        >
            <IconButton {...other}>{props.children}</IconButton>
        </WithTooltip>
    );
}

export const CtrlIconButton = styled(CtrlIconButtonComponent, {
    name: "CtrlIconButton",
})(({ theme, redMark }) => ({
    "::after": redMark
        ? {
              content: "''",
              width: 8,
              height: 8,
              backgroundColor: theme.app.statusColor.errorMain,
              borderRadius: 4,
              border: `solid 1px ${theme.app.neutralColor.backgroundSuperLight}`,
              position: "absolute",
              right: 5,
              top: 5,
          }
        : {},
}));
