import { styled } from '@mui/system';
import { CtrlFileUploadArea } from '../../../components/controls/CtrlFileUploadArea';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { ReferralModel } from '../../ReferralModel';
import { Attachment } from './Attachment';
import { useEffect } from 'react';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../theme/icons';
import { WarningsPanel } from '../WarningsPanel';

export interface AttachmentsProps extends ViewProps<ReferralModel> {}
const sectionSystemCode: SectionSystemCode = 'Attachments';

function AttachmentsComponent(props: AttachmentsProps) {
    const { model } = props;
    const globalState = model.state.use();
    const attachmentsModel = model.attachmentsModel;
    const state = attachmentsModel.state.use();

    useEffect(() => attachmentsModel.sectionChanged(globalState.sections),
        [attachmentsModel, globalState.sections])

    return (
        <div className={props.className}>
            <div className="fields-container file-area-field">
                <div className="span-12">
                    <CtrlFileUploadArea onAddFiles={attachmentsModel.onAddFiles} />
                </div>
            </div>
            <div className="fields-container file-list">
                <div className="span-12 files-container">
                    {state.attachments.map((attachment, idx) => 
                        <Attachment
                            key={idx}
                            attachment={attachment}
                            onChange={attachmentsModel.onChangeAttachment}
                            onRemove={attachmentsModel.onRemoveAttachment}
                            sections={globalState.sections}
                            customization={globalState.customization}
                        />)}
                </div>
            </div>
            <WarningsPanel messages={attachmentsModel.getWarnings()} />
            <CtrlButton
                endIcon={<ArrowRightIcon />}
                onClick={() => {
                    model.validateSection(sectionSystemCode, attachmentsModel);
                }}
                className='continue-button'
            >
                Continue
            </CtrlButton>
        </div>
    );
}

export const Attachments = styled(AttachmentsComponent, { name: 'Attachments' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.file-area-field': {
        paddingTop: theme.spacing(1),
    },
    '.fields-container.file-list': {
        marginTop: 0,
        paddingTop: 8,
    },
    ".files-container": {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8
    },
    ".continue-button": {
        width: 120,
        marginTop: 16
    }
}));
