import { appStyles } from "./appStyles";

export const overrides = {
    MuiCheckbox: {
        styleOverrides: {
            root: {
                padding: 0,
                color: appStyles.neutralColor.borderMain,
                '&:hover': {
                    color: appStyles.neutralColor.borderHover,
                },
                '&.Mui-checked': {
                    color: appStyles.accentColor.iconMain,
                    '&:hover': {
                        color: appStyles.accentColor.iconHover,
                    },
                },
                '&.Mui-disabled': {
                    color: appStyles.neutralColor.borderDisabled,
                    backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                    '&.Mui-checked': {
                        color: appStyles.neutralColor.backgroundDisabled,
                    },
                },
                '&.MuiCheckbox-indeterminate': {
                    color: appStyles.accentColor.iconMain,
                    '&:hover': {
                        color: appStyles.accentColor.iconHover,
                    },
                },
            },
        },
    },
    MuiSvgIcon: {
        styleOverrides: {
            root: {
                width: 16,
                height: 16,
            },
        },
    },
    MuiToolbar: {
        styleOverrides: {
            root: {
                '@media (min-width: 600px)': {
                    minHeight: 48,
                },
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: 16,
            },
        },
    },
    MuiIconButton: {
        styleOverrides: {
            root: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            sizeSmall: {
                padding: 4,
                backgroundColor: 'inherit',
                '.MuiSvgIcon-root': {
                    color: appStyles.neutralColor.iconMain,
                },
                '&.MuiIconButton-colorPrimary': {
                    '.MuiSvgIcon-root': {
                        color: appStyles.accentColor.iconMain,
                    },
                },
                ':hover': {
                    backgroundColor: 'inherit',
                    '.MuiSvgIcon-root': {
                        color: appStyles.neutralColor.iconHover,
                    },
                    '&.MuiIconButton-colorPrimary': {
                        '.MuiSvgIcon-root': {
                            color: appStyles.accentColor.iconHover,
                        },
                    },
                },
                '&.Mui-disabled': {
                    backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                    '.MuiSvgIcon-root': {
                        color: appStyles.neutralColor.iconDisabled,
                    },
                },
            },
            sizeMedium: {
                border: `solid 1px ${appStyles.neutralColor.borderMain}`,
                backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                padding: 3,
                borderRadius: 6,
                color: appStyles.neutralColor.iconMain,
                ':hover': {
                    borderColor: appStyles.neutralColor.borderHover,
                    backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                    color: appStyles.neutralColor.iconHover,
                },
                '&.Mui-disabled': {
                    backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                    borderColor: appStyles.neutralColor.borderDisabled,
                    color: appStyles.neutralColor.iconDisabled,
                },
            },
            sizeLarge: {
                border: `solid 1px ${appStyles.neutralColor.borderMain}`,
                backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                padding: 7,
                borderRadius: 6,
                color: appStyles.neutralColor.iconMain,
                ':hover': {
                    borderColor: appStyles.neutralColor.borderHover,
                    backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                    color: appStyles.neutralColor.iconHover,
                },
                '&.Mui-disabled': {
                    backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                    borderColor: appStyles.neutralColor.borderDisabled,
                    color: appStyles.neutralColor.iconDisabled,
                },
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            outlined: {
                zIndex: 0,
                color: appStyles.neutralColor.textMain,
                fontSize: 12,
                fontWeight: 400,
                "&[data-shrink='false']": {
                    transform: 'translate(0px, -18px)',
                    transition: 'none',
                },
                "&[data-shrink='true']": {
                    color: appStyles.neutralColor.textMain,
                    transform: 'translate(0px, -18px)',
                    transition: 'none',
                },
                '&.Mui-error': {
                    color: appStyles.neutralColor.textMain,
                },
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            adornedEnd: {
                '&.MuiOutlinedInput-root': {
                    paddingRight: 2,
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                borderRadius: 6,
                height: 32,
                '&.Mui-disabled': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: appStyles.neutralColor.borderDisabled,
                    },
                },
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: appStyles.accentColor.borderMain,
                        borderWidth: 1,
                        outline: `solid 3px ${appStyles.accentColor.backgroundLight}`,
                    },
                },
                '&.Mui-error': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: appStyles.statusColor.errorMain,
                    },
                },
                '&.Mui-error.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: appStyles.statusColor.errorMain,
                    },
                },
                '&.MuiInputBase-sizeSmall': {
                    height: 24,
                    '& input': {
                        height: 24,
                    },
                },
            },
            input: {
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 8,
                paddingRight: 8,
                color: appStyles.neutralColor.textDark,
            },
            notchedOutline: {
                border: `solid 1px ${appStyles.neutralColor.borderMain}`,
                borderRadius: 6,
                top: 0,
                ':hover': {
                    borderColor: appStyles.neutralColor.borderHover,
                    borderWidth: 1,
                },
                '& legend': {
                    display: 'none',
                },
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                fontSize: 12,
                margin: 0,
                height: 16,
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none' as any,
                borderRadius: 6,
                fontSize: 12,
                fontWeight: 600,
                '&.Mui-disabled': {
                    backgroundColor: appStyles.neutralColor.backgroundDisabled,
                    color: appStyles.neutralColor.textDisabled,
                    '&.MuiButtonBase-root': {
                        '& .MuiButton-startIcon': {
                            color: appStyles.neutralColor.iconDisabled,
                        },
                        '& .MuiButton-endIcon': {
                            color: appStyles.neutralColor.iconDisabled,
                        },
                    },
                },
            },
            sizeLarge: {
                height: 32,
                padding: '8px 16px',
            },
            sizeMedium: {
                height: 32,
                padding: '8px 16px',
            },
            sizeSmall: {
                height: 24,
                padding: '4px 8px',
                '& .MuiButton-startIcon': {
                    marginRight: 4,
                    marginLeft: 0,
                },
                '& .MuiButton-endIcon': {
                    marginRight: 0,
                    marginLeft: 4,
                },
            },
            contained: {
                backgroundColor: appStyles.accentColor.backgroundMain,
                ':hover': {
                    backgroundColor: appStyles.accentColor.backgroundHover,
                },
            },
            containedError: {
                backgroundColor: appStyles.statusColor.errorBackgroundMain,
                ':hover': {
                    backgroundColor: appStyles.statusColor.errorBackgroundHover,
                },
            },
            containedSecondary: {
                backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                color: appStyles.neutralColor.textMain,
                ':hover': {
                    backgroundColor: appStyles.neutralColor.backgroundLight,
                    color: appStyles.neutralColor.textDark,
                },
            },
            outlined: {
                backgroundColor: appStyles.neutralColor.backgroundSuperLight,
                border: `solid 1px ${appStyles.neutralColor.borderMain}`,
                color: appStyles.neutralColor.textMain,
                '& .MuiButton-startIcon': {
                    color: appStyles.neutralColor.iconMain,
                },
                '& .MuiButton-endIcon': {
                    color: appStyles.neutralColor.iconMain,
                },
                ':hover': {
                    borderColor: appStyles.neutralColor.borderHover,
                    color: appStyles.neutralColor.textDark,
                    '& .MuiButton-startIcon': {
                        color: appStyles.neutralColor.iconHover,
                    },
                    '& .MuiButton-endIcon': {
                        color: appStyles.neutralColor.iconHover,
                    },
                },
            },
            text: {
                color: appStyles.accentColor.textMain,
                '& .MuiButton-startIcon': {
                    color: appStyles.accentColor.iconMain,
                },
                '& .MuiButton-endIcon': {
                    color: appStyles.accentColor.iconMain,
                },
                ':hover': {
                    color: appStyles.accentColor.textHover,
                    '& .MuiButton-startIcon': {
                        color: appStyles.accentColor.iconHover,
                    },
                    '& .MuiButton-endIcon': {
                        color: appStyles.accentColor.iconHover,
                    },
                },
                '&.Mui-disabled': {
                    backgroundColor: 'inherit',
                },
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: 4,
                '&.Mui-disabled': {
                    '& .MuiChip-deleteIcon': {
                        color: appStyles.neutralColor.iconDisabled,
                    },
                },
                '&:active': {
                    boxShadow: 'none',
                },
            },
            filled: {
                backgroundColor: appStyles.neutralColor.backgroundDefault,
                ':hover': {
                    backgroundColor: appStyles.accentColor.backgroundLight,
                },
                '&.Mui-disabled': {
                    backgroundColor: appStyles.neutralColor.backgroundDisabled,
                    opacity: 1,
                    '& .MuiChip-label': {
                        color: appStyles.neutralColor.textDisabled,
                    },
                },
            },
            sizeMedium: {
                height: 24,
            },
            label: {
                fontSize: 12,
                fontWeight: 400,
                color: appStyles.neutralColor.textMain,
                margin: 4,
                padding: 0,
            },
            deleteIcon: {
                margin: '4px 4px 4px 0',
                color: appStyles.neutralColor.iconMain,
                ':hover': {
                    color: appStyles.neutralColor.iconHover,
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                backgroundColor: appStyles.neutralColor.backgroundSuperDark,
                padding: '6px 8px',
                fontSize: 12,
                fontWeight: 400,
                color: appStyles.neutralColor.textLight,
                borderRadius: 8,
            },
        },
    },
    MuiPickerStaticWrapper: {
        styleOverrides: {
            content: {
                minWidth: 292,
            },
        },
    },
    MuiCalendarOrClockPicker: {
        styleOverrides: {
            root: {
                width: 292,
                '& > div': {
                    width: 292,
                },
            },
        },
    },
    MuiCalendarPicker: {
        styleOverrides: {
            root: {
                width: 292,
                maxHeight: 254,
                overflow: 'hidden',
            },
            viewTransitionContainer: {
                flex: '1 1 auto',
                overflow: 'hidden',
                height: 200,
            },
        },
    },
    PrivatePickersSlideTransition: {
        styleOverrides: {
            root: {
                '&.MuiDayPicker-slideTransition': {
                    minHeight: 188,
                },
            },
        },
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            root: {
                marginTop: 8,
            },
            label: {
                fontSize: 12,
                fontWeight: 600,
                color: appStyles.neutralColor.textDark,
            },
        },
    },
    MuiDayPicker: {
        styleOverrides: {
            weekDayLabel: {
                fontSize: 12,
                fontWeight: 400,
                height: 20,
            },
        },
    },
    MuiYearPicker: {
        styleOverrides: {
            root: {
                margin: '8px 0',
                maxHeight: 192,
            },
        },
    },
    MuiPickersDay: {
        styleOverrides: {
            root: {
                fontSize: 12,
                fontWeight: 400,
                borderRadius: 4,
                height: 28,
                '&:hover': {
                    backgroundColor: appStyles.neutralColor.backgroundHover,
                },
                '&.Mui-selected': {
                    backgroundColor: appStyles.accentColor.backgroundMain,
                    '&:hover': {
                        backgroundColor: appStyles.accentColor.backgroundMain,
                    },
                    '&:focus': {
                        backgroundColor: appStyles.accentColor.backgroundMain,
                    },
                },
                '&.MuiPickersDay-dayOutsideMonth': {
                    color: appStyles.neutralColor.textDisabled,
                },
            },
            today: {
                '&.MuiPickersDay-root:not(.Mui-selected)': {
                    border: 'none',
                    color: appStyles.accentColor.textMain,
                },
            },
            dayOutsideMonth: {},
        },
    },
    PrivatePickersYear: {
        styleOverrides: {
            button: {
                borderRadius: 4,
                height: 28,
                width: 58,
                margin: 0,
                fontSize: 12,
                fontWeight: 400,
                color: appStyles.neutralColor.textDark,
                '&:hover': {
                    backgroundColor: appStyles.neutralColor.backgroundHover,
                },
                '&.Mui-selected': {
                    color: appStyles.neutralColor.textLight,
                    backgroundColor: appStyles.accentColor.backgroundMain,
                    '&:focus': {
                        backgroundColor: appStyles.accentColor.backgroundMain,
                    },
                    '&:hover': {
                        backgroundColor: appStyles.accentColor.backgroundMain,
                    },
                },
            },
        },
    },
    MuiPickersPopper: {
        styleOverrides: {
            paper: {
                borderRadius: 12,
                transform: 'translate(0, 9px) !important',
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            root: {
                minHeight: 'auto',
            },
            indicator: {
                display: 'none',
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                margin: 4,
                padding: '4px 12px',
                minHeight: 'auto',
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 4,
                height: 24,
                '&.MuiButtonBase-root': {
                    textTransform: 'none',
                },
                '&.Mui-selected': {
                    backgroundColor: appStyles.accentColor.backgroundLight,
                    color: appStyles.accentColor.textMain,
                    '&.Mui-disabled': {
                        backgroundColor: appStyles.neutralColor.backgroundDisabled,
                        color: appStyles.neutralColor.textDisabled,
                    },
                },
                '&.Mui-disabled': {
                    color: appStyles.neutralColor.textDisabled,
                },
                '&.variant-small': {
                    margin: 2,
                    padding: '2px 8px',
                    height: 20,
                },
            },
        },
    },
    MuiSnackbar: {
        styleOverrides: {
            root: {
                minHeight: 40,
                transition: 'top 100ms',
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            root: {
                padding: '8px 12px',
                display: 'flex',
                alignItems: 'center',
                fontSize: 14,
                fontWeight: 400,
                borderRadius: 6,
            },
            standardInfo: {
                backgroundColor: appStyles.neutralColor.backgroundSuperDark,
                color: appStyles.neutralColor.textLight,
            },
            standardSuccess: {
                backgroundColor: appStyles.neutralColor.backgroundSuperDark,
                color: appStyles.neutralColor.textLight,
            },
            standardWarning: {
                backgroundColor: appStyles.neutralColor.backgroundSuperDark,
                color: appStyles.neutralColor.textLight,
            },
            standardError: {
                backgroundColor: appStyles.neutralColor.backgroundSuperDark,
                color: appStyles.neutralColor.textLight,
            },
            message: {
                padding: 0,
                display: 'flex',
                alignItems: 'center',
            },
            icon: {
                padding: 0,
                marginRight: 8,
                '& svg': {
                    width: 20,
                    height: 20,
                },
            },
        },
    },
    MuiDialog: {
        styleOverrides: {
            root: {
                '& div.MuiBackdrop-root.MuiModal-backdrop': {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                },
                '& .MuiDialog-container': {
                    width: '100%',
                },
                '& .MuiDialog-paper': {
                    maxWidth: 'unset',
                },
            },
            backdrop: {},
            paper: {
                borderRadius: 12,
            },
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                fontSize: 18,
                fontWeight: 600,
                padding: '16px',
                display: 'flex',
                alignItems: 'center',
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                padding: '16px',
            },
        },
    },
    MuiDialogContentText: {
        styleOverrides: {
            root: {
                fontSize: 14,
                fontWeight: 400,
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                padding: 16,
                borderTop: `solid 1px ${appStyles.neutralColor.borderMain}`,
                backgroundColor: appStyles.neutralColor.backgroundLight,
            },
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                outline: 'none',
            },
        },
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                border: 'none',
                boxShadow: 'none',
                '&::before': {
                    display: 'none',
                },
            },
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                minHeight: 'auto',
                '&.Mui-expanded': {
                    minHeight: 'auto',
                },
            },
            content: {
                margin: '0 0',
                alignItems: 'center',
                columnGap: 12,
                '&.Mui-expanded': {
                    margin: '0 0',
                },
            },
            expandIconWrapper: {
                color: appStyles.neutralColor.textDark,
                transform: 'none',
                '&.Mui-expanded': {
                    transform: 'rotate(180deg)',
                },
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                paddingLeft: 12,
                paddingRight: 12,
                '& .MuiListItemIcon-root': {
                    minWidth: 16,
                    marginRight: 8,
                },
            },
        },
    },
    MuiList: {
        styleOverrides: {
            root: {
                paddingTop: 4,
                paddingBottom: 4,
            },
        },
    },
    MuiClockPicker: {
        styleOverrides: {
            root: {
                width: '100%',
                '& .MuiClock-root .MuiIconButton-root': {
                    top: '70%',
                    height: '32px',
                    width: '32px',
                    border: 'none',
                    borderRadius: '50%',
                    margin: '0 1',
                    ':hover': {
                        backgroundColor: appStyles.neutralColor.backgroundHover,
                    },
                },
                '& .MuiTypography-root': {
                    color: 'unset',
                    marginTop: '2px',
                },
            },
        },
    },
};
