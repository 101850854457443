import { styled } from "@mui/material";
import { ReactNode } from "react";

const AsteriskSpan = styled("span", { name: "requiredLabel" })(({ theme }) => ({
    color: theme.app.statusColor.errorMain,
    marginLeft: 2,
}));

export const requiredLabel = (required?: boolean, label?: string | ReactNode) =>
    required && label ? (
        <span>
            {label}
            <AsteriskSpan>*</AsteriskSpan>
        </span>
    ) : (
        label
    );
