import { FieldValidator, SectionSystemCode } from '../../../../common/types';
import { requiredIfCondition } from '../../../../common/validationHelpers';
import { ChangeableModel } from '../../../../model/model';
import { referralModel } from '../../../ReferralModel';
import { IDoctorState } from './DoctorState';

export const sectionSystemCode: SectionSystemCode = 'dsDoctorServices';

export const doctorValidators: FieldValidator[] = [
    {
        fieldName: 'referralReasonDescription',
        fieldLabel: 'Referral Reason Description',
        validateFunctions: [
            requiredIfCondition(
                ({ referralModel }) => referralModel.doctorModel.state.get().referralReason?.value === 'Other',
            ),
        ],
    },
    {
        fieldName: 'referralForDescription',
        fieldLabel: 'Referral For Description',
        validateFunctions: [
            requiredIfCondition(
                ({ referralModel }) => referralModel.doctorModel.state.get().referralFor?.value === 'Other',
            ),
        ],
    },
    {
        fieldName: 'visitPurposeDescription',
        fieldLabel: 'Visit Purpose Description',
        validateFunctions: [
            requiredIfCondition(
                ({ referralModel }) => referralModel.doctorModel.state.get().visitPurpose?.value === 'Other',
            ),
        ],
    },
];

export class DoctorModel extends ChangeableModel<IDoctorState> {
    getSummaryFields = () => {
        const state = this.state.get();
        return new Map([
            ['Referral For', state.referralFor?.label],
            ['Visit Purpose', state.visitPurpose?.label],
        ]);
    };

    override getAdditionalNotes(withSpecialInstructions: boolean = true) {
        const state = this.state.get();

        let notes: [string, string][] = [];

        const note = [
            referralModel.getGeneralInstructions(),
            this.formatAdditionalNotes(
                [
                    { fieldName: 'referralReason.value', label: 'Referral Reason'},
                    { fieldName: 'referralReasonDescription', label: 'Referral Reason Description' },
                    { fieldName: 'referralForDescription', label: 'Referral For Description' },
                    { fieldName: 'visitPurpose.value', label: 'Visit Purpose'},
                    { fieldName: 'visitPurposeDescription', label: 'Visit Purpose Description' },
                    { fieldName: 'providerTypeRequired', label: 'Provider Type Required' },
                    { fieldName: 'previousPhysician', label: 'Physicians Previously Seen Regarding the Injury' },
                ],
                sectionSystemCode,
                withSpecialInstructions,
            ),
        ];

        notes.push([state.referralFor?.value ?? '', note.filter(n => n).join(';')]);

        return new Map<string, string>(notes);
    }
}
