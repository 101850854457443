import { FieldValidator } from '../../../common/types';
import {
    requiredIfAddressPopulated,
    validateEmail,
    validateFaxNumber,
    validateOptionExists,
    validatePhoneNumber,
    validateRequiredField,
    validateZipCode,
} from '../../../common/validationHelpers';
import { ChangeableModel } from '../../../model/model';
import { TResourceValue, valuesManager } from '../../../services/valuesManager/valuesManager';
import { ReferralModel, referralModel } from '../../ReferralModel';
import { ISubmitterEntity } from '../claimPatient/ClaimPatientModel';
import { ICompany } from '../company/utils';
import { ISubmitterState, SubmitterType } from '../submitter/SubmitterState';
import { ICaseManagerState } from './CaseManagerState';
import { setSubmitterFields } from '../utils';

export const caseManagerSubmitterTypes: SubmitterType[] = ['NurseCaseManager', 'NurseCaseManagerOffice'];

const requiredForSubmitter = (value: any, referralModel: ReferralModel) => {
    return caseManagerSubmitterTypes.includes(referralModel.submitterModel.state.get().submitterType)
        ? validateRequiredField(value)
        : null;
};

const requiredIfSectionHasContent = (value: any, referralModel: ReferralModel) => {
    return referralModel.caseManagerModel.isAnyFieldPopulated()
        ? validateRequiredField(value)
        : null;
}
const getAddress = () => referralModel.caseManagerModel.state.get().address

export const caseManagerValidators: FieldValidator[] = [
    { fieldName: 'firstName', fieldLabel: 'First Name', validateFunctions: [requiredForSubmitter, requiredIfSectionHasContent] },
    { fieldName: 'lastName', fieldLabel: 'Last Name', validateFunctions: [requiredForSubmitter, requiredIfSectionHasContent] },
    { fieldName: 'company.value', fieldLabel: 'Company', validateFunctions: [requiredForSubmitter] },
    { fieldName: 'phone', fieldLabel: 'Phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'cellPhone', fieldLabel: 'Cell phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'fax', fieldLabel: 'Fax', validateFunctions: [validateFaxNumber] },
    { fieldName: 'email', fieldLabel: 'Email', validateFunctions: [requiredForSubmitter, validateEmail] },
    { fieldName: 'address.address1', fieldLabel: 'Address 1', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.state.value', fieldLabel: 'State', validateFunctions: [requiredIfAddressPopulated(getAddress), validateOptionExists(valuesManager.resourceValues.states)] },
    { fieldName: 'address.zip', fieldLabel: 'Zip code', validateFunctions: [validateZipCode, requiredIfAddressPopulated(getAddress)] },
];

export class CaseManagerModel extends ChangeableModel<ICaseManagerState> implements ISubmitterEntity {
    copySubmitterFields = (submitter: ISubmitterState) => {
        const fields =
            submitter.submitterType === 'NurseCaseManagerOffice'
                ? {
                      'company.value': submitter.companyName,
                      'company.label': submitter.companyName,
                  }
                : {
                      firstName: submitter.firstName,
                      lastName: submitter.lastName,
                      phone: submitter.mainPhone,
                      ext: submitter.ext,
                      cellPhone: submitter.cellPhone,
                      email: submitter.email,
                      fax: submitter.fax,
                      'company.value': submitter.companyName,
                      'company.label': submitter.companyName,
                  };

        this.state.update((state) => {
            setSubmitterFields(fields, state);
        });
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map([
            ['Case Manager', [state.firstName, state.lastName].filter((v) => v).join(' ')],
            ['Phone', [state.phone, state.ext ? `ext.: ${state.ext}` : null].filter((v) => v).join(' ')],
            ['Email', state.email],
            ['Company', state.company?.value],
        ]);
    };

    handleCompanyChange = (company?: TResourceValue | string, savedCompany?: ICompany) => {
        this.state.update((state) => {
            if (typeof company === 'string') {
                state.company = { label: company, value: company, freeText: true };
            } else {
                if (savedCompany) {
                    state.address = savedCompany.address;
                }
                state.company = {label: company?.label || '', value: company?.value || ''};
            }

            const errors = state.errors.find((err) => err.fieldName === 'company.value');
            if (errors) {
                errors.errors = [];
            }
        });
    };

    isAnyFieldPopulated = () => {
        const state = this.state.get();

        return Boolean(state.firstName
            || state.lastName
            || state.phone
            || state.ext
            || state.cellPhone
            || state.email
            || state.fax
            || state.company?.value
            || state.address?.address1
            || state.address?.address2
            || state.address?.city
            || state.address?.state
            || state.address?.zip);
    }
}
