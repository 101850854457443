import { Box, Checkbox, Typography, styled } from '@mui/material';
import { DefaultProps, Section, SectionSystemCode } from '../../../common/types';
import { CloseIcon, ImageIcon } from '../../../theme/icons';
import { CtrlIconButton } from '../../../components/controls/CtrlIconButton';
import clsx from 'clsx';
import { IAttachment } from './AttachmentsState';
import { CtrlComboSelect } from '../../../components/controls/CtrlComboSelect';
import { TResourceValue, valuesManager } from '../../../services/valuesManager/valuesManager';
import { CtrlTextArea } from '../../../components/controls/CtrlTextArea';
import { CtrlChip } from '../../../components/controls/CtrlChip';
import { theme } from '../../../theme';
import { useCallback } from 'react';
import { helperError } from '../../../components/controls/elements/helperError';
import { TPortalCustomization } from '../utils';
import { referralModel } from '../../ReferralModel';

export interface AttachmentProps extends DefaultProps {
    attachment: IAttachment;
    onChange: (attachment: IAttachment) => void;
    onRemove: (attachment: IAttachment) => void;
    sections: Section[];
    customization: TPortalCustomization;
}

function formatSize(size: number) {
    if (size / 1024 / 1024 > 1) {
        return `${(size / 1024 / 1024).toFixed(2)} MB`;
    }
    return `${(size / 1024).toFixed(2)} KB`;
}

function AttachmentComponent(props: AttachmentProps) {
    const { attachment, onChange, onRemove, className, sections, customization } = props;
    const attachmentTypes = valuesManager.resourceValues.attachmentTypes;

    const attachmentType = attachmentTypes.find((t) => t.value === attachment.attachmentType);
    const changeType = useCallback(
        (t?: TResourceValue) => {
            t && onChange({ ...attachment, attachmentType: t.value });
        },
        [attachment, onChange],
    );

    const changeDescription = useCallback(
        (d: string) => onChange({ ...attachment, description: d }),
        [attachment, onChange],
    );

    const serviceClick = (code: SectionSystemCode, checked: boolean) => {
        onChange({
            ...attachment,
            serviceCodes: checked
                ? attachment.serviceCodes.includes(code)
                    ? attachment.serviceCodes
                    : [...attachment.serviceCodes, code]
                : attachment.serviceCodes.filter((c) => c !== code),
        });
    };

    return (
        <Box className={className}>
            <div className="image-content">
                <div className={clsx('icon', 'image-icon')}>
                    <ImageIcon />
                </div>
                <div className="image-info">
                    <Typography variant="h2">{attachment.name}</Typography>
                    <Typography variant="body1" className="extensions">
                        {formatSize(attachment.size)}
                    </Typography>
                    {Boolean(attachment.sizeError) && helperError(attachment.sizeError)}
                </div>
                <CtrlComboSelect
                    width={200}
                    label="Type"
                    value={attachmentType}
                    onChange={changeType}
                    selectFrom={valuesManager.resourceValues.attachmentTypes}
                    className="type-select"
                    hideClear
                />
            </div>
            <div>
                <CtrlTextArea
                    label="Description"
                    value={attachment.description}
                    onChange={changeDescription}
                    maxCharacters={1000}
                    className="description-field"
                    required={attachment.attachmentType === 'Other'}
                    error={attachment.descriptionError}
                />
            </div>
            {referralModel.needToRenderServices() ? (
                <div>
                    <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        Selected Services
                    </Typography>
                    <div className="services-chips">
                        {sections.map((i: Section) =>
                            i.service && i.visible ? (
                                <CtrlChip
                                    label={i.title}
                                    key={i.systemCode}
                                    onDelete={() => {}}
                                    onClick={() => {
                                        serviceClick(i.systemCode, !attachment.serviceCodes.includes(i.systemCode));
                                    }}
                                    deleteIcon={
                                        <Checkbox
                                            className="chip-checkbox"
                                            checked={attachment.serviceCodes.includes(i.systemCode)}
                                        />
                                    }
                                />
                            ) : null,
                        )}
                    </div>
                    {Boolean(attachment.serviceCodesError) && helperError(attachment.serviceCodesError)}
                </div>
            ) : null}
            <CtrlIconButton
                size="small"
                className="image-remove-button"
                onClick={() => {
                    onRemove(attachment);
                }}
                title="Remove"
            >
                <CloseIcon />
            </CtrlIconButton>
        </Box>
    );
}

export const Attachment = styled(AttachmentComponent, { name: 'Attachment' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    flexShrink: 0,
    borderRadius: 8,
    border: `1px solid ${theme.app.neutralColor.borderMain}`,
    background: theme.app.neutralColor.backgroundSuperLight,
    marginTop: theme.spacing(2),
    position: 'relative',
    '&:hover': {
        borderColor: theme.app.neutralColor.borderHover,
        '.image-remove-button': {
            display: 'flex',
        },
    },
    '.image-content': {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        flex: '1 0 0',
    },
    '.image-icon': {
        color: theme.app.accentColor.iconMain,
    },
    '.image-info': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 4,
        flex: '1 0 0',
    },
    '.image-remove-button': {
        position: 'absolute',
        top: -8,
        right: -8,
        border: 'solid 1px',
        borderColor: theme.app.neutralColor.borderHover,
        borderRadius: 16,
        display: 'none',
    },
    '.type-select': {
        alignSelf: 'flex-end',
    },
    '.services-chips': {
        display: 'flex',
        columnGap: theme.spacing(3),
        rowGap: theme.spacing(1),
        marginTop: theme.spacing(1),
        flexWrap: 'wrap',
    },
    '.description-field': {
        marginTop: 24,
    },
    '.chip-checkbox': {
        pointerEvents: 'none',
        margin: theme.spacing(1, 0, 1, 1),
        order: -1,
    },
}));
