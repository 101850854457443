import { SubmittedOrderRequestResponse, printSummaryPage } from '../../api/order';
import { Order } from '../../api/types/claim';
import { DefaultProps, TAddress } from '../../common/types';
import { printPdfByteArray } from '../../common/utils';
import { otherSubmitterOptions } from '../../pages/sections/submitter/Submitter';
import { submitters } from '../../pages/sections/submitter/SubmitterState';
import { PrintIcon } from '../../theme/icons';
import { showConfirmationDialog } from './ConfirmationDialog';
import { Typography, styled } from '@mui/material';

interface ConfirmationSummaryViewProps extends DefaultProps {
    order: Order;
    submittedOrderRequest: SubmittedOrderRequestResponse;
    submissionDT?: string;
    requestKey?: string;
};

const join = (array: any[], separator: string) => {
    return array.filter(Boolean).join(separator);
};

const formatCompany = (company?: string, address?: TAddress) => {
    const formattedAddress = join([address?.city, address?.state?.value], ', ');
    return join([company, formattedAddress && `(${formattedAddress})`], ' ');
};

const getServicesRequested = (order: any) =>{
    const services = new Map<string, boolean>([
        ['Diagnostics', !!order.diagnosticServices],
        ['Transportation', !!order.transportationServices],
        ['Language', !!order.languageTranslation],
        ['Dental', !!order.dsDentalServices],
        ['Doctor', !!order.dsDoctorServices],
        ['Home Health + Complex Care', !!order.homeHealthCare],
        ['Durable Medical Equipment', !!order.durableMedicalEquipment],
        ['Physical Therapy', !!order.physicalMedicine],
    ]);

    return Array.from(services)
        .filter(([key, value]) => value)
        .map(([key]) => key);
};

const generateDataBlocks = (order: Order, submittedOrderRequest: SubmittedOrderRequestResponse) => {
    const submitterType = order.submitter?.otherSubmitterType
        ? otherSubmitterOptions.find(opt => opt.id === order.submitter?.otherSubmitterType)
        : submitters.find(opt => opt.value === order.submitter?.submitterType);

    const dataBlocks = [
        { label: 'Request Key', value: submittedOrderRequest.orderRequestId },
        {
            label: 'Submitter',
            value: join(
                [
                    order.submitter?.firstName,
                    order.submitter?.lastName,
                    `(${submitterType?.label})`,
                ],
                ' ',
            ),
        },
        { label: 'Services Requested', value: getServicesRequested(order).join('; ') },
        {
            label: 'Patient',
            value: join([order.claimPatient?.firstName, order.claimPatient?.lastName], ' '),
        },
        { label: 'Claim Number', value: order.claimPatient?.claimNumber },
        { label: 'Employer', value: formatCompany(order.employer?.company, order.employer?.address) },
        { label: 'Insurance', value: formatCompany(order.insurance?.company?.value, order.insurance?.address) },
        { label: 'Adjuster', value: join([order.adjuster?.firstName, order.adjuster?.lastName], ' ') },
        {
            label: 'Referring Physician',
            value: join([order.referringPhysician?.firstName, order.referringPhysician?.lastName], ' '),
        },
    ];

    return dataBlocks.filter(block => !!block.value);
};

const printSummaryPageAction = (order: Order, submittedOrderRequest: SubmittedOrderRequestResponse) => {
    return {
        icon: <PrintIcon />,
        buttonLabel: 'Print This Page',
        callback: async () => {
            const response = await printSummaryPage(order, submittedOrderRequest);
            printPdfByteArray(response?.data as ArrayBuffer);
        },
    };
};

function ConfirmationSummaryViewComponent(props: ConfirmationSummaryViewProps) {
    const { order, submittedOrderRequest } = props;

    return (
        <div className={props.className}>
            <Typography className="span-12">
                Your request was submitted successfully and you can print this summary page for your records. <br />
                You will also receive a confirmation email shortly.
            </Typography>

            <div className="span-12">
                <Typography variant="body2">{`Submitted: ${submittedOrderRequest.createdDateStringET}`}</Typography>
            </div>

            {generateDataBlocks(order, submittedOrderRequest).map((field, idx) => {
                return (
                    <div className="span-6" key={idx}>
                        <Typography variant="h2">{field.label}</Typography>
                        <Typography variant="body2">{field.value}</Typography>
                    </div>
                );
            })}
        </div>
    );
};

const ConfirmationSummaryView = styled(ConfirmationSummaryViewComponent, {
    name: 'ConfirmationSummaryView',
})(({ theme }) => ({
    width: 600,
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(4),
}));

export const showConfirmationSummaryDialog = async (
    order: Order,
    submittedOrderRequest: SubmittedOrderRequestResponse,
) => {
    return showConfirmationDialog({
        title: 'Confirmation Summary',
        message: <ConfirmationSummaryView order={order} submittedOrderRequest={submittedOrderRequest} />,
        cancelButton: null,
        confirmButton: 'Done',
        additionalActions: [printSummaryPageAction(order, submittedOrderRequest)],
    });
};
