import { Checkbox, CheckboxProps, FormControlLabel, styled } from "@mui/material";
import { useCallback } from "react";
import {
    CheckedIcon,
    IndeterminateIcon,
    UncheckedIcon,
} from "../../theme/icons";

export interface CtrlCheckboxProps
    extends Omit<CheckboxProps, "checked" | "onChange" | "value"> {
    value: boolean;
    label?: string;
    onChange?: (value: boolean) => void;
}

function CtrlCheckboxComponent(props: CtrlCheckboxProps) {
    const { value, onChange: propsOnChange, label, ...other } = props;

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            propsOnChange && propsOnChange(checked);
        },
        [propsOnChange]
    );

    return (
        <FormControlLabel
            className={props.className}
            label={label}
            control={
                <Checkbox
                    checked={value}
                    onChange={onChange}
                    checkedIcon={<CheckedIcon />}
                    icon={<UncheckedIcon />}
                    indeterminateIcon={<IndeterminateIcon />}
                    {...other}
                />
            }
        />
    );
}

export const CtrlCheckbox = styled(CtrlCheckboxComponent, {
    name: "CtrlCheckbox",
})(({ theme }) => ({
    margin: 0,
    "&.MuiCheckbox-root": {
        marginRight: theme.spacing(2),
    },
}));
