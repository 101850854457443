import { FieldValidator, Section, SectionSystemCode } from "../../../common/types";
import { ERROR_MESSAGE, validateRequiredField } from "../../../common/validationHelpers";
import { ChangeableModel } from "../../../model/model";
import { ReferralModel } from "../../ReferralModel";
import { TPortalCustomization, TPortalMode } from "../utils";
import { IAttachment, IAttachmentsState } from "./AttachmentsState";

const SERVICE_REQUIRED = "Specifying service(s) is required";

const RX_PRESCRIPTION_FILE_TYPE = "RX_Prescription";
const MD_NOTE_FILE_TYPE = "MdNote";
const PRECERT_AUTH_LETTER_FILE_TYPE = 'Authorization';
const OTHER_FILE_TYPE = 'Other';

const attachmentToFileTypeMap = new Map([
    ['RX', RX_PRESCRIPTION_FILE_TYPE],
    ['Treating Doctor Note (First)', MD_NOTE_FILE_TYPE],
    ['Treating Doctor Note (Latest)', MD_NOTE_FILE_TYPE],
    ['Precert or Auth Letter', PRECERT_AUTH_LETTER_FILE_TYPE],
]);

const validateAttachments = (value: IAttachment[], referralModel: ReferralModel) => {
    if (value.some(att => referralModel.attachmentsModel.attachmentHasError(att))) {
        referralModel.attachmentsModel.state.update(s => {
            s.attachments = s.attachments.map(a => referralModel.attachmentsModel.validateAttachment(a))
        })
        return ERROR_MESSAGE.COMPLETE_THIS_FIELD;
    }
    return null;
};
const rxAttachments = ['RX', 'Treating Doctor Note (First)', 'Treating Doctor Note (Latest)']

const validateDxAttachmentsWarning = (value: string, referralModel: ReferralModel) => {
    const { customization, mode} = referralModel.state.get();
    const messages = new Map<TPortalCustomization | TPortalMode, string>([
        ['Default', 'Attaching RX for Diagnostics service can expedite scheduling. Hard copies can be faxed to 855-813-1724'],
        ['GM',  "Attaching RX can expedite scheduling. It can also be emailed to <a href='mailto:GM_Diagnostic@onecallcm.com'>GM_Diagnostic@onecallcm.com</a>"],
        ['Navigere', 'Attaching RX for Diagnostics service can expedite scheduling. Hard copies can be faxed to 855-813-1724'],
    ]);

    return mode === 'EZReferral'
        && !referralModel.attachmentsModel.state.get().attachments.some(a => rxAttachments.includes(a.attachmentType) && a.serviceCodes.includes('DiagnosticServices'))
        && referralModel.isServiceSelected(['DiagnosticServices'])
        ? messages.get(customization)
        : undefined;
};

const validateDentalDoctorAttachmentsWarning = (value: string, referralModel: ReferralModel) => {
    return referralModel.state.get().mode === 'EZReferral'
        && !referralModel.attachmentsModel.state.get().attachments?.some(a => a.serviceCodes.includes('dsDentalServices') || a.serviceCodes.includes('dsDoctorServices'))
        && referralModel.isServiceSelected(['dsDentalServices', 'dsDoctorServices'])
        ? "Attaching any Dental + Doctor medical records can expedite scheduling. Hard copies can be faxed to 888-539-0579"
        : undefined;
};

const validateEzAuthAttachmentsWarning = (value: string, referralModel: ReferralModel) => {
    return referralModel.state.get().mode === 'EZAuth'
        && !referralModel.attachmentsModel.state.get().attachments.some(a => rxAttachments.includes(a.attachmentType))
        ? "Attaching RX can speed up processing time"
        : undefined;
};


export const attachmentsValidators: FieldValidator[] = [
    { fieldName: 'attachments', fieldLabel: 'Attachments', validateFunctions: [validateAttachments] },
    {
        fieldName: 'attachments',
        fieldLabel: 'Attachments',
        validateFunctions: [
            validateDxAttachmentsWarning, validateDentalDoctorAttachmentsWarning, validateEzAuthAttachmentsWarning
        ],
        isWarning: true,
    },
]

let idGen = 1;

export class AttachmentsModel extends ChangeableModel<IAttachmentsState> {
    visibleSystemSectionCodes: SectionSystemCode[] = [];

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map([['Files', state.attachments.length.toString()]]);
    };

    onAddFiles = (files: File[]) => {
        this.state.update((s) => {
            s.attachments.push(
                ...files.map((file) => ({
                    id: idGen++,
                    file: file,
                    name: file.name,
                    size: file.size,
                    attachmentType: 'RX',
                    description: '',
                    serviceCodes: [...this.visibleSystemSectionCodes],
                })),
            );
        });
    };

    onChangeAttachment = (attachment: IAttachment) => {
        this.state.update((s) => {
            s.attachments = s.attachments.map((a) => {
                return a.id === attachment.id
                    ? {
                          ...attachment,
                          serviceCodesError: attachment.serviceCodes.length ? undefined : attachment.serviceCodesError,
                          descriptionError:
                              attachment.attachmentType === 'Other' && !attachment.description?.trim()
                                  ? attachment.descriptionError
                                  : undefined,
                      }
                    : a;
            });
        });
    };

    onRemoveAttachment = (attachment: IAttachment) => {
        this.state.update((s) => {
            s.attachments = s.attachments.filter((a) => a.id !== attachment.id);
        });
    };

    sectionChanged = (sections: Section[]) => {
        const codes = sections.filter((s) => s.service && s.visible).map((s) => s.systemCode);
        const removed = this.visibleSystemSectionCodes.filter((c) => !codes.includes(c));
        this.visibleSystemSectionCodes = codes;

        if (removed.length) {
            this.state.update((s) => {
                s.attachments = s.attachments.map((a) => ({
                    ...a,
                    serviceCodes: a.serviceCodes.filter((c) => !removed.includes(c)),
                    serviceCodesError: undefined,
                }));
            });
        }
    };

    validateAttachment(attachment: IAttachment): IAttachment {
        return {
            ...attachment,
            serviceCodesError: attachment.serviceCodes.length ? undefined : SERVICE_REQUIRED,
            descriptionError:
                attachment.attachmentType === 'Other' ? validateRequiredField(attachment.description) : undefined,
            sizeError: attachment.file.size > 10 * 1024 * 1024 ? 'Maximum allowed file size is 10 Mb' : undefined,
        };
    }

    attachmentHasError(attachment: IAttachment): boolean {
        return Boolean(
            attachment.serviceCodes.length === 0 ||
                (attachment.attachmentType === 'Other' && validateRequiredField(attachment.description)) ||
                attachment.file.size > 10 * 1024 * 1024,
        );
    }

    getAttachmentsUploadPayload(attachments: IAttachment[]) {
        const payload = new FormData();
        attachments.forEach((attachment, idx) => {
            const keyPrefix = `attachments[${idx}]`;
            const { fileType, description } = this.getAttachmentTypeAndDescription(attachment.id) || {};

            if (!fileType) {
                throw new Error('File type could not be empty!');
            }

            payload.append(`${keyPrefix}.Id`, attachment.id.toString());
            payload.append(`${keyPrefix}.FileName`, attachment.name);
            payload.append(`${keyPrefix}.FileContent`, attachment.file);
            payload.append(`${keyPrefix}.FileType`, fileType);
            payload.append(`${keyPrefix}.Description`, description ?? '');
        });
        return payload;
    }

    private getAttachmentTypeAndDescription(
        attachmentId: number,
    ): { fileType: string; description?: string } | undefined {
        const attachment = this.state.get().attachments.find((attachment) => attachment.id === attachmentId);

        if (!attachment) {
            return undefined;
        }

        const fileType = this.mapFileType(attachment.attachmentType);
        return {
            fileType: fileType,
            description: attachment.attachmentType === OTHER_FILE_TYPE
                ? attachment.description
                : fileType === RX_PRESCRIPTION_FILE_TYPE || fileType === PRECERT_AUTH_LETTER_FILE_TYPE
                    ? attachment.description
                    : [attachment.attachmentType, attachment.description].join('; '),
        };
    }

    private mapFileType(attachmentType: string): string {
        return attachmentToFileTypeMap.get(attachmentType) ?? OTHER_FILE_TYPE;
    }
}