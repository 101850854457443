import { TAddress, ValidatedState } from "../../../../common/types"
import { TResourceValue } from "../../../../services/valuesManager/valuesManager"

export interface ILanguageState extends ValidatedState {
    appoinmentType?: TResourceValue,
    appointmentDate?: Date,
    appointmentDateString?: string,
    appointmentTime?: Date;
    appointmentTimeString?: string;
    languageCertification?: TResourceValue,
    language?: TResourceValue,
    phone: string,
    doctorName: string,
    address?: TAddress,
    additionalInstructions: string,
    isRush: boolean,
}

export const defaultLanguageState: ILanguageState = {
    phone: "",
    doctorName: "",
    additionalInstructions: "",
    isRush: false,
    errors: [],
}