import { appFetch } from './appFetch';
import { TClientApiSettings } from '../../common/types';
import APIJSON from '../../config/api.json';

interface ApiResponse<T> {
    success: boolean;
    data?: T;
    error?: string;
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export class ApiClient {
    private readonly baseUrl: string;

    constructor(baseUrl: string, clientSettings: TClientApiSettings) {
        this.baseUrl = baseUrl;
    }

    async callApi<T>(endpoint: string, method: HttpMethod, data?: any, headers?: any): Promise<ApiResponse<T>> {
        const response = await appFetch(this.baseUrl + endpoint, {
            method,
            headers: {
                'subscription-key': APIJSON.api.subscriptionKey,
                ...headers,
            },
            body: data,
        });

        const contentType = response.headers.get('content-type');
        const responseData = contentType?.includes('application/pdf')
            ? await response.arrayBuffer()
            : await response.json();

        if (response.ok) {
            return {
                success: true,
                data: responseData as T,
            };
        } else {
            return {
                success: false,
                error: responseData.error || 'Unknown error',
            };
        }
    }
}
