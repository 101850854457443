import { Chip, ChipProps, styled } from "@mui/material";
import { forwardRef } from "react";
import { CloseIcon } from "../../theme/icons";

export interface CtrlChipProps extends ChipProps {}

const CtrlChipRef = forwardRef(function CtrlChipComponent(
    props: CtrlChipProps,
    ref: ChipProps["ref"]
) {
    const { deleteIcon, ...other } = props;
    return (
        <Chip ref={ref} {...other} deleteIcon={deleteIcon ?? <CloseIcon />} />
    );
});

export const CtrlChip = styled(CtrlChipRef, { name: "CtrlChip" })(
    ({ theme }) => ({})
) as typeof Chip;
