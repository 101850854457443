import { styled, Typography } from "@mui/material";
import { DefaultProps, TOptionHint } from "../../common/types";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import {
    ForwardedRef,
    forwardRef,
    ReactElement,
    useCallback,
    useMemo,
} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { CheckIcon } from "../../theme/icons";
import clsx from "clsx";
import { OverflowTooltipText } from "../misc/OverflowTooltipText";
import {
    defaultGetOptionDisabled,
    defaultGetOptionHint,
    defaultOptionGetLabel,
} from "./utils";
import { CtrlCircularProgress } from "./CtrlCircularProgress";
import { WithTooltip } from "./WithTooltip";

export const ctrlListItemHeight = 24;

interface ItemDataProps<T = any> {
    options: readonly T[];
    getLabel?: (value: T, index?: number) => string;
    getIcon?: (value: T, index?: number) => ReactElement;
    getSelected?: (value: T) => boolean;
    onClick?: (value: T, index?: number) => void;
    getOptionClass?: (value: T, index?: number) => string;
    getOptionDisabled?: (value: T, index?: number) => boolean;
    getOptionHint?: (value: T, index?: number) => TOptionHint;
    getHovered?: (value: T) => boolean;
    onMouseHover?: (value: T, index?: number) => void;
}

interface RowComponentProps<T = any> extends ListChildComponentProps<T> {
    className?: string;
    customHovering?: boolean;
    customRows?: boolean;
}

function RowComponent<T = any>(props: RowComponentProps<T>): ReactElement {
    const { data, index, style, customRows } = props;
    const {
        options,
        getLabel: getLabelProps,
        getIcon,
        getSelected,
        onClick,
        getOptionClass,
        getOptionDisabled: propsGetOptionDisabled,
        getOptionHint: propsGetOptionHint,
        getHovered,
        onMouseHover,
    } = data as ItemDataProps<T>;
    const selected = Boolean(getSelected?.(options[index]));
    const hovered = Boolean(getHovered?.(options[index]));
    const icon = getIcon?.(options[index], index);

    const getLabel = useCallback(
        (option: T, index: number) => {
            return getLabelProps
                ? getLabelProps(option, index)
                : defaultOptionGetLabel(option);
        },
        [getLabelProps]
    );

    const getOptionDisabled = useCallback(
        (option: T, index?: number) => {
            return propsGetOptionDisabled
                ? propsGetOptionDisabled(option, index)
                : defaultGetOptionDisabled(option);
        },
        [propsGetOptionDisabled]
    );

    const getOptionHint = useCallback(
        (option: T, index?: number): TOptionHint => {
            return propsGetOptionHint
                ? propsGetOptionHint(option, index)
                : defaultGetOptionHint(option);
        },
        [propsGetOptionHint]
    );

    const handleClick = useCallback(() => {
        if (onClick && !getOptionDisabled(options[index], index)) {
            onClick(options[index], index);
        }
    }, [onClick, getOptionDisabled, options, index]);

    const label = getLabel(options[index], index);
    const customRowElement = customRows ? (options[index] as any)['element'] : null;
    const optionClass = getOptionClass?.(options[index], index);
    const disabled = getOptionDisabled(options[index], index);

    const onMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLLIElement>) => {
            onMouseHover?.(options[index]);
        },
        [index, onMouseHover, options]
    );

    const optionHint: TOptionHint = getOptionHint(options[index], index);

    return (
        <WithTooltip title={optionHint.title} titleWidth={optionHint.titleWidth}>
            <Typography
                variant="body1"
                component="li"
                noWrap
                style={style}
                className={clsx(
                    props.className,
                    { selected, hovered, disabled },
                    optionClass
                )}
                onClick={handleClick}
                onMouseEnter={onMouseEnter}
            >
                {Boolean(icon) && <span className="list-row-icon">{icon}</span>}
                <OverflowTooltipText className={"list-row-text"}>
                    {customRows ? customRowElement : label}
                </OverflowTooltipText>
                {selected && <CheckIcon className="list-row-icon-selected" />}
            </Typography>
        </WithTooltip>
    );
}

const Row = styled(RowComponent, { name: 'CtrlListRow' })(({ theme, customHovering }) => ({
    height: 20,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.app.neutralColor.textDark,
    padding: theme.spacing(1, 2),
    overflow: 'hidden',
    ':hover': {
        backgroundColor: !customHovering ? theme.app.neutralColor.backgroundHover : undefined,
    },
    '&.selected': {
        '.list-row-text': {
            color: theme.app.accentColor.textMain,
        },
        '.list-row-icon-selected': {
            position: 'absolute',
            left: 'calc(100% - 22px)',
            color: theme.app.accentColor.iconMain,
        },
    },
    '&.hovered:not(.disabled)': {
        backgroundColor: customHovering ? theme.app.neutralColor.backgroundHover : undefined,
    },
    '&.disabled': {
        '.list-row-text': {
            color: theme.app.neutralColor.textDisabled,
        },
    },
    '.list-row-icon': {
        marginRight: theme.spacing(1),
    },
    '.list-row-text': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: '1 1 auto',
    },
}));

export interface CtrlListProps<T = any> extends DefaultProps {
    options: readonly T[];
    getLabel?: (value: T, index?: number) => string;
    getIcon?: (value: T, index?: number) => ReactElement;
    getSelected?: (value: T) => boolean;
    onClick?: (value: T, index?: number) => void;
    getOptionClass?: (value: T, index?: number) => string;
    getOptionDisabled?: (value: T, index?: number) => boolean;
    getOptionHint?: (value: T, index?: number) => TOptionHint;
    emptyMessage?: string | ReactElement;
    getHovered?: (value: T) => boolean;
    onMouseHover?: (value: T, index?: number) => void;
    loading?: boolean;
    header?: boolean;
    customRows?: boolean;
}

const CtrlListRef = forwardRef(function CtrlListComponent<T = any>(
    props: CtrlListProps<T>,
    ref: ForwardedRef<FixedSizeList | null>
) {
    const {
        options,
        getLabel,
        getIcon,
        getSelected,
        onClick,
        getOptionClass,
        getOptionDisabled,
        getOptionHint,
        emptyMessage,
        getHovered,
        onMouseHover,
        loading,
        header,
        customRows,
    } = props;

    const itemData = useMemo(
        () => ({
            options,
            getLabel,
            getIcon,
            getSelected,
            onClick,
            getOptionClass,
            getOptionDisabled,
            getOptionHint,
            getHovered,
            onMouseHover,
        }),
        [
            getHovered,
            getIcon,
            getLabel,
            getOptionClass,
            getOptionDisabled,
            getOptionHint,
            getSelected,
            onClick,
            options,
            onMouseHover,
        ]
    );

    if (loading) {
        return (
            <div className="flex-item flex-center-content">
                <CtrlCircularProgress size={16} />
            </div>
        );
    }

    if (!options.length && emptyMessage) {
        return <div className="ctrl-list-empty-message">{emptyMessage}</div>;
    }

    return (
        <AutoSizer>
            {({ height, width }) => (
                <FixedSizeList
                    ref={ref}
                    height={height - (header ? 25 : 0)}
                    width={width}
                    itemData={itemData}
                    itemCount={options.length}
                    itemSize={ctrlListItemHeight}
                    outerElementType="div"
                    innerElementType="ul"
                >
                    {(itemProps) => (
                        <Row
                            {...itemProps}
                            customHovering={Boolean(getHovered)}
                            customRows={customRows}
                        />
                    )}
                </FixedSizeList>
            )}
        </AutoSizer>
    );
});

export const CtrlList = styled(CtrlListRef, { name: "CtrlList" })(
    ({ theme }) => ({})
) as <T = any>(
    props: CtrlListProps<T> & { ref?: ForwardedRef<FixedSizeList | null> }
) => ReactElement;
