import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { DefaultProps } from "../../common/types";
import { ProgressPurpleIcon, ProgressWhiteIcon } from "../../theme/icons";

const Rotated = styled("span")<{ angle: number; size?: number }>(
    ({ angle, size }) => ({
        width: size ?? 32,
        height: size ?? 32,
        "& svg": {
            transform: `rotate(${angle}deg)`,
        },
    })
);

interface CtrlCircularProgressProps extends DefaultProps {
    size?: number;
    variant?: "white" | "purple";
}

function CtrlCircularProgressComponent(props: CtrlCircularProgressProps) {
    const { variant } = props;
    const steps = 10;
    const stepInterval = 150;
    const [currStep, setCurrStep] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrStep((s) => s + 1);
        }, stepInterval);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const step = currStep % steps;
    const angle = (360 / steps) * step;

    return (
        <Rotated angle={angle} className={props.className} size={props.size}>
            {variant === "white" ? (
                <ProgressWhiteIcon />
            ) : (
                <ProgressPurpleIcon />
            )}
        </Rotated>
    );
}

export const CtrlCircularProgress = styled(CtrlCircularProgressComponent, {
    name: "CtrlCircularProgress",
})(({ theme, size }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
        width: size ?? 32,
        height: size ?? 32,
    },
}));
