import { TAddress, ValidatedState } from "../../../common/types";

export interface IReferringPhysicianState extends ValidatedState {
    firstName?: string;
    lastName?: string;
    practiceName?: string;
    phone?: string;
    email?: string;
    fax?: string;
    address?: TAddress;
    rememberSectionData: boolean;
}

export const defaultReferringPhysicianState: IReferringPhysicianState = {
    errors: [],
    rememberSectionData: false,
}