import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, styled } from '@mui/material';
import { DefaultProps, ViewProps } from '../../common/types';
import { CloseLargeIcon } from '../../theme/icons';
import { TComponentState } from '../../model/state';
import { TDialogModel, Views } from './model';
import { CtrlIconButton } from '../controls/CtrlIconButton';
import { showDialog } from './Dialogs';

const confirmationId = Symbol('confirmation');

interface AdditionalAction {
    icon?: React.JSX.Element,
    buttonLabel: string,
    callback: () => void,
}

interface ConfirmationState {
    title?: string;
    message?: string | React.JSX.Element;
    buttons: (string | null)[];
    primaryButton?: string;
    confirmButton?: string;
    cancelButton?: string | null;
    additionalActions?: AdditionalAction[];
}

class ConfirmationModel extends TDialogModel<ConfirmationState, string> {
    constructor(inputState: ConfirmationState) {
        const state: ConfirmationState = {
            ...inputState,
        };
        super(new TComponentState(state));
    }
}

function ConfirmationDialogComponent(props: ViewProps<ConfirmationModel> & DefaultProps) {
    const { className, model } = props;
    const state = model.state.use();

    return (
        <Dialog open={true} className={className}>
            <DialogTitle className="title">
                <span>{state.title}</span>
                <div className="icon">
                    <CtrlIconButton size="small" onClick={() => model.close(state.cancelButton)}>
                        <CloseLargeIcon />
                    </CtrlIconButton>
                </div>
            </DialogTitle>
            <DialogContent className="content">
                {typeof state.message === 'string' ? (
                    <Typography variant="body1">{state.message}</Typography>
                ) : (
                    state.message
                )}
            </DialogContent>
            <DialogActions>
                {state.additionalActions &&
                    state.additionalActions.map((action) => (
                        <Button
                            startIcon={action.icon}
                            key={action.buttonLabel}
                            onClick={action.callback}
                        >
                            {action.buttonLabel}
                        </Button>
                    ))}
                {state.buttons
                    .filter((b) => !!b)
                    .map((button) => (
                        <Button
                            key={button}
                            variant={button === state.primaryButton ? 'contained' : 'outlined'}
                            onClick={() => model.close(button)}
                            autoFocus={button === state.primaryButton || state.buttons.length === 1}
                        >
                            {button}
                        </Button>
                    ))}
            </DialogActions>
        </Dialog>
    );
}

const ConfirmationDialog = styled(ConfirmationDialogComponent, {
    name: 'ConfirmationDialog',
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(3),
    zIndex: 9999,
    '.title': {
        display: 'flex',
    },
    '.icon': {
        marginLeft: 'auto',
    },
    '.content': {
        minWidth: 320,
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
    },
})) as typeof ConfirmationDialogComponent;

Views.registerView(confirmationId, ConfirmationDialog);

export const showConfirmationDialog = async ({
    message,
    title = 'Confirmation' as string,
    confirmButton,
    cancelButton,
    additionalActions,
}: Omit<ConfirmationState, 'buttons'>) => {
    confirmButton = confirmButton === undefined ? 'Yes' : confirmButton;
    cancelButton = cancelButton === undefined ? 'Cancel' : cancelButton;

    return showDialog({
        viewId: confirmationId,
        model: new ConfirmationModel({
            message,
            title,
            confirmButton,
            cancelButton,
            buttons: [confirmButton, cancelButton],
            primaryButton: confirmButton,
            additionalActions,
        }),
    });
};
