import _ from 'lodash';
import { ChangeableModel } from '../../../../model/model';
import {
    TResourceValue,
    TResourceValueProductCategory,
    valuesManager,
} from '../../../../services/valuesManager/valuesManager';
import { DurableMedicalEquipmentProduct, IDurableMedicalEquipmentState } from './DurableMedicalEquipmentState';
import { FieldValidator, SectionSystemCode, TRange } from '../../../../common/types';
import {
    ERROR_MESSAGE,
    requiredIfAddressPopulated,
    validateDateField,
    validatePhoneNumber,
    validateRequiredField,
    validateZipCode,
} from '../../../../common/validationHelpers';
import dayjs from 'dayjs';
import { ReferralModel, referralModel } from '../../../ReferralModel';

const sectionSystemCode: SectionSystemCode = 'DurableMedicalEquipment';

const productsToRemove = ['Home Modification Evaluation'];
const productCategoriesRenamingMap = new Map([
    ['Home & Vehicle Modifications', 'Vehicle Modifications (Free Evaluation)'],
    ['Wheelchairs, Custom', 'Wheelchairs, Custom (Free Evaluation)'],
    ['Ramps', 'Ramps (Free On-Site Evaluation)'],
]);
const productsRenamingMap = new Map([
    ['Other', 'Other (Specify in Comments)'],
    ['Adjustable Orthopedic Bed', 'Adjustable Orthopedic Bed (Specify size in Comments)'],
    ['Eggcrate Mattress Overlay', 'Eggcrate Mattress Overlay (Specify size in Comments)'],
    ['Orthopedic Bed', 'Orthopedic Bed (Specify size in Comments)'],
    ['Health/Hygiene Supplies', 'Health/Hygiene Supplies (Specify in Comments)'],
    ['Incision/Wound Care Supplies', 'Incision/Wound Care Supplies (Specify in Comments)'],
    ['Incontinence Supplies', 'Incontinence Supplies (Specify in Comments)'],
    ['Ostomy Supplies', 'Ostomy Supplies (Specify in Comments)'],
    ['Urology Supplies', 'Urology Supplies (Specify in Comments)'],
    ['Lower Extremity Brace', 'Lower Extremity Brace (Specify in Comments)'],
    ['Upper Extremity Brace', 'Upper Extremity Brace (Specify in Comments)'],
    ['Upper Extremity Sling', 'Upper Extremity Sling (Specify in Comments)'],

    ['Lower Extremity', 'Lower Extremity (Specify in Comments)'],
    ['Upper Extremity', 'Upper Extremity (Specify in Comments)'],
    ['Respiratory Supplies', 'Respiratory Supplies (Specify in Comments)'],
]);

export const requestedDeliveryDateRange = (): TRange<Date> => ({
    from: dayjs(new Date()).add(1, 'day').startOf('day').toDate(),
    to: dayjs(new Date()).add(1, 'year').endOf('year').toDate(),
});

const validateComment = (value: string, referralModel: ReferralModel) => {
    const products = referralModel.durableMedicalEquipmentModel.state.get().products;
    if (!products.some((p) => p.productCategory?.label)) {
        return validateRequiredField(value);
    }

    return '';
};

const getAddress = () => referralModel.durableMedicalEquipmentModel.state.get().deliveryAddressType === 'Home'
    ? referralModel.claimPatientModel.state.get().address
    : referralModel.durableMedicalEquipmentModel.state.get().address

const validateDmeAddress = (fieldName: string) => {
    return (value: string, referralModel: ReferralModel) => {
        return requiredIfAddressPopulated(getAddress)(referralModel.durableMedicalEquipmentModel.state.get().deliveryAddressType === "Home" ? _.get(referralModel.claimPatientModel.state.get().address, fieldName) : value, referralModel)
    };
}

export const durableMedicalEquipmentModelValidators: FieldValidator[] = [
    { fieldName: 'contactPhone', fieldLabel: 'Contact Phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'vendorPhone', fieldLabel: 'Vendor Phone', validateFunctions: [validatePhoneNumber] },
    {
        fieldName: 'requestedDeliveryDate',
        fieldLabel: 'Requested Delivery Date',
        validateFunctions: [
            validateDateField(
                () => referralModel.durableMedicalEquipmentModel.state.get().requestedDeliveryDateString,
                requestedDeliveryDateRange,
                'Date should be in the range of tomorrow and 2024',
            ),
        ],
    },
    { fieldName: 'comments', fieldLabel: 'Comments', validateFunctions: [validateComment] },

    { fieldName: 'address.address1', fieldLabel: 'Address 1', validateFunctions: [validateDmeAddress("address1")] },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [validateDmeAddress("city")] },
    { fieldName: 'address.state.value', fieldLabel: 'State', validateFunctions: [validateDmeAddress("state.value")] },
    { 
        fieldName: 'address.zip',
        fieldLabel: 'Zip code',
        validateFunctions:
        [
            (value, referralModel) => validateZipCode(referralModel.durableMedicalEquipmentModel.state.get().deliveryAddressType === 'Home' ? referralModel.claimPatientModel.state.get().address?.zip : value),
            validateDmeAddress("zip")
        ]
    },

];

export class DurableMedicalEquipmentModel extends ChangeableModel<IDurableMedicalEquipmentState> {
    loadProductCategoriesDropdownValues = async () => {
        await valuesManager.getProductCategories();
        this.state.update((state) => {
        state.productCategories =
            valuesManager.resourceValues.productCategories
                ?.filter((pc) => pc.productLineCode === 'DurableMedicalEquipment')
                ?.map((pc) =>
                    productCategoriesRenamingMap.has(pc.label)
                        ? { ...pc, label: productCategoriesRenamingMap.get(pc.label) ?? '' }
                        : pc,
                ) ?? [];
        });
    };

    getProductCategoriesForSection = (product: DurableMedicalEquipmentProduct) => {
        const state = this.state.get();
        return state.productCategories?.filter(
            (pc) =>
                product.productCategory?.id === pc.id || !state.products.some((p) => p.productCategory?.id === pc.id),
        );
    };

    handleProductCategoryChange =
        (product: DurableMedicalEquipmentProduct) => async (value?: TResourceValueProductCategory) => {
            const categoryChanged =
                this.state.get().products?.find((pc) => pc.id === product.id)?.productCategory?.value !== value?.value;

            this.state.update((state) => {
                const changedProduct = state.products.find((p) => p.id === product.id);
                if (changedProduct) {
                    changedProduct.productCategory = value;

                    const errors = state.errors.find((err) => err.fieldName === `product-${product.id}`);
                    if (errors) {
                        errors.errors = [];
                    }
                }
            });

            if (categoryChanged) {
                const loadedProducts = await this.loadProducts(value?.id);

                this.state.update((state) => {
                    const changedProduct = state.products.find((p) => p.id === product.id);
                    if (changedProduct) {
                        changedProduct.products = loadedProducts;
                        changedProduct.selectedProducts = [];
                    }

                    const errors = state.errors.find((err) => err.fieldName === 'comments');
                    if (errors) {
                        errors.errors = [];
                    }
                });
            }
        };

    handleProductChange = (product: DurableMedicalEquipmentProduct) => async (value?: TResourceValue[]) => {
        this.state.update((state) => {
            const changedProduct = state.products.find((p) => p.id === product.id);
            if (changedProduct) {
                changedProduct.selectedProducts = value;
            }

            const errors = state.errors.find((err) => err.fieldName === `product-${product.id}`);
            if (errors) {
                errors.errors = [];
            }
        });
    };

    loadProducts = async (categoryId: string | number | undefined) => {
        return categoryId
            ? (await valuesManager.getProducts(categoryId))
                  .map((p) =>
                      productsRenamingMap.has(p.label) ? { ...p, label: productsRenamingMap.get(p.label) ?? '' } : p,
                  )
                  .filter((p) => !productsToRemove.some((r) => p.label === r))
            : [];
    };

    addProduct = () => {
        this.state.update((state) => {
            state.products = [...state.products, { id: _.uniqueId() }];
        });
    };

    removeProduct = (product: DurableMedicalEquipmentProduct) => () => {
        this.state.update((state) => {
            state.products = state.products.filter((p) => p.id !== product.id);
        });
    };

    removeEmptyProducts = () => {
        this.state.update((state) => {
            state.products = state.products.filter((p) => p.productCategory?.value);

            if (state.products.length === 0) {
                state.products = [{ id: _.uniqueId() }];
            }
        });
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([
            ['', [...state.products.map((p) => p.productCategory?.label), state.comments].filter((v) => v).join('\n')],
        ]);
    };

    validate(referralModel: ReferralModel) {
        super.validate(referralModel);

        this.state.update((s) => {
            s.products.forEach((p) => {
                if (p.productCategory?.value && p.selectedProducts?.length === 0) {
                    s.errors = [
                        ...s.errors,
                        {
                            fieldLabel: 'Product',
                            fieldName: `product-${p.id}`,
                            errors: [ERROR_MESSAGE.COMPLETE_THIS_FIELD],
                        },
                    ];
                }
            });
        });
    }

    override getAdditionalNotes(withSpecialInstructions: boolean = true) {
        const state = this.state.get();

        let notes: [string, string][] = [];

        const products = state.products
            .map((c) => c.selectedProducts?.map((p) => p.value) ?? [])
            .reduce(function (acc, curr) {
                return acc.concat(curr);
            }, []);

        const note = [
            referralModel.getGeneralInstructions(),
            this.formatAdditionalNotes(
                [
                    { fieldName: 'comments', label: 'Comments / Other Products / Product Details' },
                    { fieldName: 'prescription', label: 'Prescription', value: state.prescription ? 'Yes' : undefined },
                    { fieldName: 'vendorContactName', label: 'Vendor Contact Name' },
                    { fieldName: 'vendorName', label: 'Vendor Name (if Already Involved)' },
                    { fieldName: 'vendorPhone', label: 'Vendor Phone' },
                ],
                sectionSystemCode,
                withSpecialInstructions,
            ),
        ];

        (products.length ? products : ['']).forEach((product) => {
            notes.push([product, note.filter(n => n).join(';')]);
        });

        return new Map<string, string>(notes);
    }
}
