import _ from 'lodash';
import { FieldValidator, SectionSystemCode, TRange } from '../../../common/types';
import {
    ERROR_MESSAGE,
    validateWarning,
    requiredForService,
    validateEmail,
    validatePhoneNumber,
    validateRequiredField,
    validateZipCode,
    requiredForCustomization,
    validateOptionExists,
    requiredIfCondition,
} from '../../../common/validationHelpers';
import { ChangeableModel } from '../../../model/model';
import { TResourceValue, valuesManager } from '../../../services/valuesManager/valuesManager';
import { ReferralModel } from '../../ReferralModel';
import { ISubmitterState } from '../submitter/SubmitterState';
import { setSubmitterFields } from '../utils';
import { IClaimPatientState, genders } from './ClaimPatientState';
import { ClaimPatient } from '../../../api/types/claim';

export const requiredClaimNumberServices: SectionSystemCode[] = ['DiagnosticServices', 'DurableMedicalEquipment', 'HomeHealthCare'];
export const requiredDateOfBirthServices: SectionSystemCode[] = ['DiagnosticServices'];
export const requiredInjuryServices: SectionSystemCode[] = ['DiagnosticServices'];

const validateDateOfInjury = (dateOfInjury: Date | undefined, model: ReferralModel) => {
    const state = model.claimPatientModel.state.get();
    return dateOfInjury && state.dateOfBirth && dateOfInjury <= state.dateOfBirth
        ? 'Must be greater than Date of Birth'
        : undefined;
};

const validateLanguagePreference = (language: TResourceValue | undefined, model: ReferralModel) => {
    const state = model.claimPatientModel.state.get();
    return state.speaksEnglish === 'limited'
        ? validateRequiredField(language?.value || '')
        : undefined;
}

const validateDateOfBirth = (dateOfBirth: Date | undefined, model: ReferralModel) => {
    if (model.claimPatientModel.state.get().dateOfBirthMasked) {
        return '';
    }

    if(!model.isServiceSelected(requiredDateOfBirthServices)){
        return '';
    }

    return dateOfBirth ? '' : ERROR_MESSAGE.COMPLETE_THIS_FIELD;
}

const validateNumberInRange = (range: TRange<number>) => (value: string) => {
    range.from ??= Number.NEGATIVE_INFINITY;
    range.to ??= Number.POSITIVE_INFINITY;
    
    const val = Number(value);
    return value && !isNaN(val) && !(val >= range.from && val <= range.to) ? `Valid range ${range.from} - ${range.to}` : undefined;
}

const validateHomePhone = (homePhone: string | undefined, model: ReferralModel) => {
    const state = model.claimPatientModel.state.get();
    return !state.homePhoneMasked
        ? validateRequiredField(homePhone) || validatePhoneNumber(homePhone || '')
        : undefined;
}

export const claimPatientWarnings: FieldValidator[] = [
    {
        fieldName: 'dateOfInjury',
        fieldLabel: 'Date of Injury',
        validateFunctions: [
            validateWarning(
                ({ value, referralModel }) =>
                    !value &&
                    referralModel.isServiceSelected([
                        'DiagnosticServices',
                        'HomeHealthCare',
                        'DurableMedicalEquipment',
                        'PhysicalMedicine',
                    ]),
            ),
        ],
        isWarning: true,
    },
    {
        fieldName: 'injuryJurisdictionState.value',
        fieldLabel: 'Injury/Jurisdiction State',
        validateFunctions: [
            validateWarning(
                ({ value, referralModel }) =>
                    !value &&
                    referralModel.isServiceSelected(['HomeHealthCare', 'DurableMedicalEquipment']) &&
                    !referralModel.isServiceSelected(['DiagnosticServices']),
            ),
        ],
        isWarning: true,
    },
    {
        fieldName: 'dateOfBirth',
        fieldLabel: 'Date of Birth',
        validateFunctions: [
            validateWarning(
                ({ value, referralModel }) =>
                    !value &&
                    !referralModel.claimPatientModel.state.get().dateOfBirthMasked &&
                    referralModel.isServiceSelected(['PhysicalMedicine']) &&
                    !referralModel.isServiceSelected(['DiagnosticServices']),
            ),
        ],
        isWarning: true,
    },
    {
        fieldName: 'claimNumber',
        fieldLabel: 'Claim Number',
        validateFunctions: [
            validateWarning(
                ({ value, referralModel }) =>
                    !value &&
                    referralModel.isServiceSelected([
                        'TransportationServices',
                        'LanguageTranslation',
                        'PhysicalMedicine',
                    ]) &&
                    !referralModel.isServiceSelected([
                        'DiagnosticServices',
                        'HomeHealthCare',
                        'DurableMedicalEquipment',
                    ]),
            ),
        ],
        isWarning: true,
    },
    {
        fieldName: 'medgateNumber',
        fieldLabel: 'Medgate #',
        validateFunctions: [
            validateWarning(
                ({ value, referralModel }) =>
                    !value &&
                    referralModel.state.get().customization === 'GM'
            ),
        ],
        isWarning: true,
    },
];

export const claimPatientValidators: FieldValidator[] = [
    { fieldName: 'dateOfBirth', fieldLabel: 'Date of Birth', validateFunctions: [validateDateOfBirth] },
    { fieldName: 'firstName', fieldLabel: 'First Name', validateFunctions: [validateRequiredField] },
    { fieldName: 'lastName', fieldLabel: 'Last Name', validateFunctions: [validateRequiredField] },
    {
        fieldName: 'claimNumber',
        fieldLabel: 'Claim Number',
        validateFunctions: [requiredForService(requiredClaimNumberServices)],
    },
    {
        fieldName: 'injuryJurisdictionState.value',
        fieldLabel: 'Injury / Jurisdiction State',
        validateFunctions: [
            requiredForService(requiredInjuryServices),
            validateOptionExists(valuesManager.resourceValues.states),
        ],
    },
    { fieldName: 'dateOfInjury', fieldLabel: 'Date of Injury', validateFunctions: [validateDateOfInjury] },
    {
        fieldName: 'homePhone',
        fieldLabel: 'Home phone',
        validateFunctions: [validateHomePhone],
    },
    { fieldName: 'cellPhone', fieldLabel: 'Cell phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'workPhone', fieldLabel: 'Work phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'alternativePhone', fieldLabel: 'Alternative phone', validateFunctions: [validatePhoneNumber] },
    {
        fieldName: 'address.address1',
        fieldLabel: 'Address 1',
        validateFunctions: [
            requiredIfCondition(
                ({ referralModel }) => !referralModel.claimPatientModel.state.get().address?.address1Masked,
            ),
        ],
    },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [validateRequiredField] },
    {
        fieldName: 'address.state.value',
        fieldLabel: 'State',
        validateFunctions: [validateRequiredField, validateOptionExists(valuesManager.resourceValues.states)],
    },
    {
        fieldName: 'address.zip',
        fieldLabel: 'Zip code',
        validateFunctions: [validateRequiredField, validateZipCode],
    },
    { fieldName: 'email', fieldLabel: 'Email', validateFunctions: [requiredForCustomization('GM'), validateEmail] },
    {
        fieldName: 'languagePreference',
        fieldLabel: 'Language preference',
        validateFunctions: [validateLanguagePreference, validateOptionExists(valuesManager.resourceValues.languages)],
    },
    {
        fieldName: 'employeeId',
        fieldLabel: 'Employee Id',
        validateFunctions: [
            (value, referralModel) => {
                return referralModel.state.get().customization === 'GM' ? validateRequiredField(value) : null;
            },
        ],
    },
    {
        fieldName: 'heightFeet',
        fieldLabel: 'Height feet',
        validateFunctions: [validateNumberInRange({ from: 1, to: 10 })],
    },
    {
        fieldName: 'heightInches',
        fieldLabel: 'Height inches',
        validateFunctions: [validateNumberInRange({ from: 0, to: 11 })],
    },
    { fieldName: 'weight', fieldLabel: 'Weight', validateFunctions: [validateNumberInRange({ from: 1, to: 999 })] },
    ...claimPatientWarnings,
];

export interface ISubmitterEntity {
    copySubmitterFields: (submitter: ISubmitterState) => void;
}

export class ClaimPatientModel extends ChangeableModel<IClaimPatientState> implements ISubmitterEntity {
    changeSpeaksEnglish = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let preferredLanguage: TResourceValue | undefined = undefined;
        if (event.target.value === 'limited') {
            const languages = valuesManager.resourceValues.languages;
            preferredLanguage = languages[0];
        }

        this.handleControlChange('languagePreference')(preferredLanguage);

        return this.handleChange(event);
    };

    copySubmitterFields = (submitter: ISubmitterState) => {
        const fields = {
            firstName: submitter.firstName,
            lastName: submitter.lastName,
            homePhone: submitter.mainPhone,
            ext: submitter.ext,
            cellPhone: submitter.cellPhone,
            email: submitter.email,
        };

        this.state.update((state) => {
            setSubmitterFields(fields, state);
        });
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([
            ['Claim', state.claimNumber],
            ['Patient', [state.firstName, state.lastName].filter((v) => v).join(' ')],
            ['Phone', state.homePhone || state.homePhoneMasked],
            ['Email', state.email],
        ]);
    };

    getIcdCodes = (e: React.ChangeEvent<HTMLInputElement>) => {
        const icdCode = e.target.value;
        this.debouncedGetRuleOuts(icdCode);
    };

    handleIcdCodeChange = (value?: TResourceValue) => {
        this.handleControlChange('icdCode')(value);

        this.state.update((s) => {
            if (!s.describeInjury) {
                s.describeInjury = value?.label;
            }
        });
    };

    populateFrom = (source?: ClaimPatient) => {
        if (!source) {
            return;
        }

        this.state.set((oldState) => {
            return {
                ...oldState,
                ...source,
                heightFeet: (source.heightFeet as any) > 0 ? source.heightFeet : oldState.heightFeet,
                heightInches: (source.heightInches as any) > 0 ? source.heightInches : oldState.heightInches,
                weight: (source.weight as any) > 0 ? source.weight : oldState.weight,
                speaksEnglish: source.speaksEnglish === 'limited' ? 'limited' : 'speaks',
                languagePreference:
                    source.speaksEnglish === 'limited' ? source.languagePreference : oldState.languagePreference,
                gender: genders.find((g) => g.label === source.gender)?.value ?? oldState.gender,

                // Seems like icdCode is not present in Context Data payload.
                icdCode: source.icdCode?.value ? source.icdCode : oldState.icdCode,

                // Masked fields
                dateOfBirth: undefined,
                dateOfBirthMasked: source.dateOfBirth,
                cellPhone: undefined,
                cellPhoneMasked: source.cellPhone,
                homePhone: undefined,
                homePhoneMasked: source.homePhone,
                address: {
                    ...source.address,
                    address1: undefined,
                    address1Masked: source.address?.address1,
                    address2: undefined,
                    address2Masked: source.address?.address2,
                }
            };
        });
    }

    private debouncedGetRuleOuts = _.debounce(async (icdCode) => {
        if (!icdCode || icdCode.length < 2) {
            return;
        }

        const icdCodes = await valuesManager.getIcdCodes(icdCode);
        this.state.update((s) => {
            s.icdCodes = icdCodes;
        });
    }, 500);
}
