import { styled } from '@mui/system';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { Address } from '../Address';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { ReferralModel } from '../../ReferralModel';
import { CtrlCheckbox } from '../../../components/controls/CtrlCheckbox';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../theme/icons';
import { setStateToStorage } from '../../../common/localStorageUtils';
import { Company } from '../company/Company';
import { useCompanies } from '../company/utils';
import { useEffect } from 'react';
import { requiredCityServices, requiredCompanyServices, requiredStateServices } from './InsuranceModel';
import { WarningsPanel } from '../WarningsPanel';
import { isAnyAddressFieldPopulated } from '../../../common/validationHelpers';
import { rememberDataVisible } from '../utils';

export interface InsuranceProps extends ViewProps<ReferralModel> {}
const sectionSystemCode: SectionSystemCode = 'Insurance';

function InsuranceComponent(props: InsuranceProps) {
    const { model } = props;
    const insuranceModel = model.insuranceModel;
    const state = insuranceModel.state.use();
    const { submitterType } = model.submitterModel.state.use();
    const { selectedServices, mode, queryParams } = model.state.use();

    const { getErrorMessage } = insuranceModel;
    const isSubmitter = submitterType === 'AdjusterOffice';

    const { savedCompanies, saveCompany } = useCompanies(sectionSystemCode);
    const anyFieldPopulated = insuranceModel.isAnyFieldPopulated();

    const addressPopulated = isAnyAddressFieldPopulated(state.address);
    
    useEffect(() => {
        insuranceModel.clearValidationMessages();
    }, [insuranceModel, isSubmitter, selectedServices]);

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <Company
                        label="Company"
                        value={state.company}
                        onChange={insuranceModel.handleCompanyChange}
                        error={getErrorMessage('company.value')}
                        selectFrom={savedCompanies}
                        sectionSystemCode={sectionSystemCode}
                        required={
                            isSubmitter ||
                            (model.isServiceSelected(requiredCompanyServices) && mode === 'EZReferral') ||
                            anyFieldPopulated
                        }
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Phone"
                        value={state.phone}
                        onChange={insuranceModel.handleControlChange('phone')}
                        error={insuranceModel.getErrorMessage('phone')}
                        phoneFormat
                    />
                </div>
            </div>
            <Address
                values={state.address}
                handleChange={insuranceModel.handleControlChange}
                getErrorMessage={insuranceModel.getErrorMessage}
                requiredFields={[
                    model.isServiceSelected(requiredCityServices) && mode === 'EZReferral' ? 'city' : '',
                    model.isServiceSelected(requiredStateServices) && mode === 'EZReferral' ? 'state' : '',
                    ...(addressPopulated ? ['address1', 'city', 'state', 'zip'] : []),
                ]}
            />
            <WarningsPanel messages={insuranceModel.getWarnings()} />
            <div className="continue-button-row">
                {rememberDataVisible(queryParams) && (
                    <CtrlCheckbox
                        label="Remember This Section Data"
                        value={state.rememberSectionData}
                        onChange={(value) => {
                            insuranceModel.handleControlChange('rememberSectionData')(value);
                            setStateToStorage(sectionSystemCode, insuranceModel.state.get(), value);
                        }}
                    />
                )}
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        const state = insuranceModel.state.get();
                        model.validateSection(sectionSystemCode, insuranceModel);
                        if (rememberDataVisible(queryParams)) {
                            setStateToStorage(sectionSystemCode, state, state.rememberSectionData);
                            saveCompany(state);
                        }
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const Insurance = styled(InsuranceComponent, { name: 'Insurance' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.no-margin-row': {
        marginTop: '0 !important',
        alignItems: 'center',
    },
    '.flex-container': {
        display: 'flex',
    },
}));
