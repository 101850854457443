import React from 'react';
import { CtrlComboSelect } from './CtrlComboSelect';
import { TResourceValue } from '../../services/valuesManager/valuesManager';
import { Typography, styled } from '@mui/material';
import { DefaultProps } from '../../common/types';
import { clsx } from 'clsx';
import _ from 'lodash';
import { WithTooltip } from './WithTooltip';

export type TCustomRowsColum = {
    label: string;
    key: string;
    span: number;
    withTooltip?: string;
};
const Row = styled(
    function (props: DefaultProps) {
        return <div className={props.className}>{props.children}</div>;
    },
    { name: 'Row' },
)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    width: '100%',
    columnGap: theme.spacing(1),
    '.selected': {
        color: theme.app.accentColor.textMain,
    },
    '.text-ellipsis': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const Column = styled(
    function (props: DefaultProps & { span: number }) {
        return <div className={clsx(props.className, `span-${props.span}`)}>{props.children}</div>;
    },
    { name: 'Column' },
)(() => ({}));

interface CustomComboSelectProps {
    label: string;
    value?: TResourceValue;
    selectFrom?: TResourceValue[];
    disabled?: boolean;
    columns: TCustomRowsColum[];
    listWidth?: number | string;
    required?: boolean;
    error?: string | boolean;
    onChange: (value?: TResourceValue) => void;
    onChangeNative?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClose?: () => void;
    onGetLabel?: (value?: TResourceValue) => string;
}

function CustomComboSelect({
    label,
    value,
    selectFrom,
    disabled,
    columns,
    listWidth,
    required,
    error,
    onChange,
    onChangeNative,
    onClose,
    onGetLabel,
}: CustomComboSelectProps) {
    const getSelected = React.useCallback((option: TResourceValue) => option.value === value?.value, [value]);
    const getHovered = React.useCallback(
        (option: TResourceValue, hovered?: TResourceValue) => option.value === hovered?.value,
        [],
    );

    const renderRow = React.useCallback(
        (option: TResourceValue) => {
            return {
                value: option.value,
                label: option.label,
                element: (
                    <Row key={option.label}>
                        {columns.map((column) => (
                            <Column span={column.span} key={column.label}>
                                <WithTooltip
                                    title={
                                        column.withTooltip ? _.get(option, column.withTooltip, option.label) : undefined
                                    }
                                >
                                    <Typography
                                        variant="body2"
                                        className={clsx('text-ellipsis', { selected: option.value === value?.value })}
                                    >
                                        {_.get(option, column.key)}
                                    </Typography>
                                </WithTooltip>
                            </Column>
                        ))}
                    </Row>
                ),
            };
        },
        [columns, value?.value],
    );

    const handleChange = React.useCallback(
        (rowValue?: TResourceValue) => {
            onChange(selectFrom?.find((v) => v.value === rowValue?.value));
        },
        [onChange, selectFrom],
    );

    return (
        <CtrlComboSelect
            label={label}
            value={value}
            onChange={handleChange}
            onChangeNative={onChangeNative}
            selectFrom={selectFrom?.map(renderRow)}
            disabled={disabled}
            onClose={onClose}
            getSelected={getSelected}
            getHovered={getHovered}
            getLabel={(option) => (onGetLabel ? onGetLabel(option) : option.label)}
            header={
                selectFrom?.length ? (
                    <Row>
                        {columns.map((column) => (
                            <Column span={column.span} key={column.label}>
                                <Typography variant="h2">{column.label}</Typography>
                            </Column>
                        ))}
                    </Row>
                ) : undefined
            }
            listWidth={listWidth}
            required={required}
            searchable={false}
            error={error}
            customRows
        />
    );
}

export default CustomComboSelect;
