import { TAddress, ValidatedState } from "../../../../common/types";
import { TResourceValue, TResourceValueProductCategory } from "../../../../services/valuesManager/valuesManager";
import _ from "lodash";

export type DurableMedicalEquipmentProduct = {
    id: string,
    productCategory?: TResourceValueProductCategory,
    selectedProducts?: TResourceValue[],
    products?: TResourceValue[],
}

export interface IDurableMedicalEquipmentState extends ValidatedState {
    products: DurableMedicalEquipmentProduct[],
    comments: string,
    availableForRental?: string,
    prescription: boolean,
    requestedDeliveryDate?: Date,
    requestedDeliveryDateString: string,
    deliveryAddressType: 'Home' | 'Hospital' | 'Other',
    address?: TAddress,
    contactPerson: string,
    contactPhone: string,
    vendorName: string,
    vendorPhone: string,
    vendorContactName: string,
    isRush: boolean,

    productCategories?: TResourceValueProductCategory[],
}

export const defaultDurableMedicalEquipmentState: IDurableMedicalEquipmentState = {
    products: [{ id: _.uniqueId() }],
    comments: '',
    prescription: false,
    requestedDeliveryDateString: '',
    deliveryAddressType: 'Home',
    availableForRental: '',
    contactPerson: '',
    contactPhone: '',
    vendorName: '',
    vendorPhone: '',
    vendorContactName: '',
    isRush: false,
    errors: [],
};