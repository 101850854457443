import { styled } from '@mui/system';
import { CtrlTextField } from '../../components/controls/CtrlTextField';
import { CtrlComboSelect } from '../../components/controls/CtrlComboSelect';
import { TAddress } from '../../common/types';
import { valuesManager } from '../../services/valuesManager/valuesManager';
import { zipMask } from '../../components/controls/utils';

export interface AddressProps {
    values?: TAddress,
    fieldTemplate?: string,
    handleChange: (fieldName: string) => (value?: any) => void,
    getErrorMessage: (fieldName: string) => string | undefined,
    disabled?: boolean,
    requiredFields?: string[]

}

function AddressComponent(props: AddressProps) {
    const { values, fieldTemplate = 'address', getErrorMessage, handleChange, disabled, requiredFields } = props;

    return (
        <>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Address 1"
                        value={values?.address1}
                        maskedValue={values?.address1Masked}
                        onChangeNative={(e) => {
                            handleChange(`${fieldTemplate}.address1`)(e.target.value);
                            handleChange(`${fieldTemplate}.address1Masked`)(undefined);
                        }}
                        error={getErrorMessage(`${fieldTemplate}.address1`)}
                        disabled={disabled}
                        required={requiredFields?.includes('address1')}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Address 2"
                        value={values?.address2}
                        maskedValue={values?.address2Masked}
                        onChangeNative={(e) => {
                            handleChange(`${fieldTemplate}.address2`)(e.target.value);
                            handleChange(`${fieldTemplate}.address2Masked`)(undefined);
                        }}
                        error={getErrorMessage(`${fieldTemplate}.address2`)}
                        disabled={disabled}
                        required={requiredFields?.includes('address2')}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="City"
                        value={values?.city}
                        onChange={handleChange(`${fieldTemplate}.city`)}
                        error={getErrorMessage(`${fieldTemplate}.city`)}
                        disabled={disabled}
                        required={requiredFields?.includes('city')}
                    />
                </div>
                <div className="span-4">
                    <CtrlComboSelect
                        label="State"
                        value={values?.state}
                        onChange={handleChange(`${fieldTemplate}.state`)}
                        error={getErrorMessage(`${fieldTemplate}.state.value`)}
                        selectFrom={valuesManager.resourceValues.states}
                        getLabel={(v) => v.value}
                        disabled={disabled}
                        required={requiredFields?.includes('state')}
                    />
                </div>
                <div className="span-2">
                    <CtrlTextField
                        label="Zip"
                        value={values?.zip}
                        onChange={handleChange(`${fieldTemplate}.zip`)}
                        error={getErrorMessage(`${fieldTemplate}.zip`)}
                        maskProps={{
                            mask: zipMask,
                        }}
                        disabled={disabled}
                        required={requiredFields?.includes('zip')}
                    />
                </div>
            </div>
        </>
    );
}

export const Address = styled(AddressComponent, { name: 'Address' })(({ theme }) => ({}));
