import { styled } from '@mui/system';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { ReferralModel } from '../../ReferralModel';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { caseManagerSubmitterTypes } from './CaseManagerModel';
import { Address } from '../Address';
import { CtrlCheckbox } from '../../../components/controls/CtrlCheckbox';
import { ArrowRightIcon } from '../../../theme/icons';
import { setStateToStorage } from '../../../common/localStorageUtils';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { Company } from '../company/Company';
import { useCompanies } from '../company/utils';
import { useEffect } from 'react';
import { isAnyAddressFieldPopulated } from '../../../common/validationHelpers';
import { rememberDataVisible } from '../utils';

export interface CaseManagerProps extends ViewProps<ReferralModel> {}
const sectionSystemCode: SectionSystemCode = 'NurseCaseManager';

function CaseManagerComponent(props: CaseManagerProps) {
    const { model } = props;
    const caseManagerModel = model.caseManagerModel;
    const state = caseManagerModel.state.use();
    const { submitterType } = model.submitterModel.state.use();

    const { handleControlChange, getErrorMessage, handleCompanyChange } = caseManagerModel;
    const isSubmitter = caseManagerSubmitterTypes.includes(submitterType);

    const { savedCompanies, saveCompany } = useCompanies(sectionSystemCode);
    const { queryParams, selectedServices } = model.state.use();
    
    useEffect(() => {
        caseManagerModel.clearValidationMessages();
    }, [caseManagerModel, isSubmitter, selectedServices]);

    const anyFieldPopulated = caseManagerModel.isAnyFieldPopulated();
    const addressPopulated = isAnyAddressFieldPopulated(state.address);

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="First Name"
                        value={state.firstName}
                        onChange={handleControlChange('firstName')}
                        error={getErrorMessage('firstName')}
                        maxCharacters={50}
                        required={isSubmitter || anyFieldPopulated}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Last Name"
                        value={state.lastName}
                        onChange={handleControlChange('lastName')}
                        error={getErrorMessage('lastName')}
                        maxCharacters={50}
                        required={isSubmitter || anyFieldPopulated}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Phone"
                        value={state.phone}
                        onChange={handleControlChange('phone')}
                        error={getErrorMessage('phone')}
                        phoneFormat
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Ext."
                        value={state.ext}
                        onChange={handleControlChange('ext')}
                        maskProps={{
                            mask: '99999999',
                        }}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Cell Phone"
                        value={state.cellPhone}
                        onChange={handleControlChange('cellPhone')}
                        error={getErrorMessage('cellPhone')}
                        phoneFormat
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Email"
                        value={state.email}
                        onChange={handleControlChange('email')}
                        error={getErrorMessage('email')}
                        required={isSubmitter}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Fax"
                        value={state.fax}
                        onChange={handleControlChange('fax')}
                        error={getErrorMessage('fax')}
                        phoneFormat
                    />
                </div>
                <div className="span-6">
                    <Company
                        label="Company"
                        value={state.company}
                        onChange={handleCompanyChange}
                        selectFrom={savedCompanies}
                        error={getErrorMessage('company.value')}
                        required={isSubmitter}
                        sectionSystemCode={sectionSystemCode}
                    />
                </div>
            </div>
            <Address
                values={state.address}
                handleChange={handleControlChange}
                getErrorMessage={getErrorMessage}
                requiredFields={addressPopulated ? ['address1', 'city', 'state', 'zip'] : []}
            />
            <div className="continue-button-row">
                {rememberDataVisible(queryParams) && (
                    <CtrlCheckbox
                        label="Remember This Section Data"
                        value={state.rememberSectionData}
                        onChange={(value) => {
                            handleControlChange('rememberSectionData')(value);
                            setStateToStorage(sectionSystemCode, caseManagerModel.state.get(), value);
                        }}
                    />
                )}
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        const state = caseManagerModel.state.get();
                        model.validateSection(sectionSystemCode, caseManagerModel);
                        if (rememberDataVisible(queryParams)) {
                            setStateToStorage(sectionSystemCode, state, state.rememberSectionData);
                            saveCompany(state);
                        }
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const CaseManager = styled(CaseManagerComponent, { name: 'CaseManager' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
}));
