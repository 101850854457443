import { FieldValidator, Section, SectionSystemCode } from '../../../common/types';
import { ChangeableModel } from '../../../model/model';
import { ReferralModel, referralModel } from '../../ReferralModel';
import { preselectedProductsMap } from '../serviceLines/transportation/constants';
import { ISpecialInstructionsState } from './SpecialInstructionsState';

const validateServiceCodes = (serviceCodes: SectionSystemCode[], referralModel: ReferralModel) => {
    const specialInstructions = referralModel.specialInstructionsModel.state.get().specialInstructions;
    if (specialInstructions.trim() && !serviceCodes.length) {
        return 'Specifying service(s) is required';
    }

    return '';
};

export const specialInstructionsValidators: FieldValidator[] = [
    { fieldName: 'serviceCodes', fieldLabel: 'Service Codes', validateFunctions: [validateServiceCodes] },
];

export class SpecialInstructionsModel extends ChangeableModel<ISpecialInstructionsState> {
    visibleSystemSectionCodes: SectionSystemCode[] = [];

    serviceClick = (code: SectionSystemCode, checked: boolean) => {
        this.state.update((state) => {
            state.serviceCodes = checked
                ? state.serviceCodes.includes(code)
                    ? state.serviceCodes
                    : [...state.serviceCodes, code]
                : state.serviceCodes.filter((c) => c !== code);

            const errors = state.errors.find((err) => err.fieldName === 'serviceCodes');
            if (errors) {
                errors.errors = [];
            }
        });
    };

    sectionChanged = (sections: Section[]) => {
        const codes = sections.filter((s) => s.service && s.visible).map((s) => s.systemCode);
        const added = codes.filter((c) => !this.visibleSystemSectionCodes.includes(c));
        const removed = this.visibleSystemSectionCodes.filter((c) => !codes.includes(c));
        this.visibleSystemSectionCodes = codes;

        if (added.length) {
            this.state.update((s) => {
                s.serviceCodes = [...s.serviceCodes, ...added];

                const errors = s.errors.find((err) => err.fieldName === 'serviceCodes');
                if (errors) {
                    errors.errors = [];
                }
            });
        }

        if (removed.length) {
            this.state.update((s) => {
                s.serviceCodes = s.serviceCodes.filter((c) => !removed.includes(c));
            });
        }
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([['', state.specialInstructions]]);
    };

    validate(referralModel: ReferralModel): void {
        super.validate(referralModel);

        this.setServiceForCustomization();
        referralModel.validateNotesLimit();
    };

    private setServiceForCustomization(){
        const customization = referralModel.state.get().customization;
        if(preselectedProductsMap.has(customization)){
            const preselectedProduct = preselectedProductsMap.get(customization);
            this.state.update(s => s.serviceCodes = preselectedProduct ? [preselectedProduct] : s.serviceCodes)
        }
    }
}
