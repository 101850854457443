import { ChangeableModel } from "../../../model/model";
import { referralModel } from "../../ReferralModel";
import { IOtherPartiesState } from "./OtherPartiesState";

export class OtherPartiesModel extends ChangeableModel<IOtherPartiesState> {

    physicianSelected = () => {
        const submitterState = referralModel.submitterModel.state.get();
        const referralState = referralModel.state.get();

        return (submitterState.submitterType === 'ReferringPhysician'
        || submitterState.submitterType === 'ReferringPhysicianOffice'
        || referralState.sections.find(s => s.systemCode === 'PhysicalMedicine')?.serviceSelected
        || referralState.sections.find(s => s.systemCode === 'DiagnosticServices')?.serviceSelected) ?? false;
    }

    attorneySelected = () => {
        const submitterState = referralModel.submitterModel.state.get();
        return submitterState.otherSubmitterType === 'Attorney' || submitterState.otherSubmitterType === 'AttorneyOffice';
    }

    caseManagerSelected = () => {
        const submitterState = referralModel.submitterModel.state.get();
        return submitterState.submitterType === 'NurseCaseManager' || submitterState.submitterType === 'NurseCaseManagerOffice';
    }

}