import { styled } from "@mui/material";
import { ReactNode, useState } from "react";
import { DefaultProps } from "../../common/types";

interface OverflowTooltipTextProps extends DefaultProps {
    children: ReactNode;
    fullWidth?: boolean;
}

function OverflowTooltipTextComponent(props: OverflowTooltipTextProps) {
    const [overflow, setOverflow] = useState(false);

    return (
        <span
            className={props.className}
            onMouseOver={(e) => {
                if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
                    setOverflow(true);
                }
            }}
            onMouseOut={() => setOverflow(false)}
            title={overflow ? props.children?.toString() : undefined}
        >
            {props.children}
        </span>
    );
}

export const OverflowTooltipText = styled(OverflowTooltipTextComponent, {
    name: "OverflowTooltipText",
})(({ fullWidth }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: fullWidth ? "100%" : undefined,
    display: "inline-block",
}));
