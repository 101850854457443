import { FieldValidator, SectionSystemCode } from "../../../common/types";
import {
    validateWarning,
    requiredForService,
    validateEmail,
    validateFaxNumber,
    validatePhoneNumber,
    validateRequiredField,
    validateZipCode,
    requiredIfAddressPopulated,
    requiredForCustomization,
    validateOptionExists,
    requiredForModeAndServices,
} from '../../../common/validationHelpers';
import { ChangeableModel } from "../../../model/model";
import { valuesManager } from "../../../services/valuesManager/valuesManager";
import { ReferralModel, referralModel } from "../../ReferralModel";
import { ISubmitterEntity } from "../claimPatient/ClaimPatientModel";
import { ISubmitterState, SubmitterType } from "../submitter/SubmitterState";
import { setSubmitterFields } from "../utils";
import { IReferringPhysicianState } from "./ReferringPhysicianState";

export const physicianSubmitterTypes: SubmitterType[] = ['ReferringPhysician', 'ReferringPhysicianOffice'];

export const requiredNameServices: SectionSystemCode[] = ['PhysicalMedicine', 'DiagnosticServices'];
export const requiredPhoneFaxServices: SectionSystemCode[] = ['DiagnosticServices'];

const requiredForSubmitter = (value: any, referralModel: ReferralModel) => {
    return physicianSubmitterTypes.includes(referralModel.submitterModel.state.get().submitterType)
        ? validateRequiredField(value)
        : null;
};

const requiredIfSectionHasContent = (value: any, referralModel: ReferralModel) => {
    return referralModel.referringPhysicianModel.isAnyFieldPopulated()
        ? validateRequiredField(value)
        : null;
}

const getAddress = () => referralModel.referringPhysicianModel.state.get().address

export const referringPhysicianValidators: FieldValidator[] = [
    {
        fieldName: 'firstName',
        fieldLabel: 'First Name',
        validateFunctions: [requiredForSubmitter, requiredForService(requiredNameServices), requiredIfSectionHasContent],
    },
    {
        fieldName: 'lastName',
        fieldLabel: 'Last Name',
        validateFunctions: [requiredForSubmitter, requiredForService(requiredNameServices), requiredIfSectionHasContent],
    },
    {
        fieldName: 'phone',
        fieldLabel: 'Phone',
        validateFunctions: [requiredForSubmitter, validatePhoneNumber, requiredForService(requiredPhoneFaxServices)],
    },
    {
        fieldName: 'fax',
        fieldLabel: 'Fax',
        validateFunctions:
        [
            validateFaxNumber,
            requiredForCustomization('Navigere'), requiredForCustomization('GM')
        ],
    },
    { fieldName: 'address.address1', fieldLabel: 'Address 1', validateFunctions: [requiredIfAddressPopulated(getAddress), requiredForCustomization('GM')] },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [requiredIfAddressPopulated(getAddress), requiredForCustomization('GM')] },
    { fieldName: 'address.state.value', fieldLabel: 'State', validateFunctions: [requiredIfAddressPopulated(getAddress), requiredForCustomization('GM'), validateOptionExists(valuesManager.resourceValues.states)] },
    { fieldName: 'address.zip', fieldLabel: 'Zip code', validateFunctions: [validateZipCode, requiredIfAddressPopulated(getAddress), requiredForCustomization('GM')] },
    {
        fieldName: 'email',
        fieldLabel: 'Email',
        validateFunctions: [
            requiredForModeAndServices(requiredPhoneFaxServices, 'EZAuth'),
            validateEmail,
            (value, referralModel) => {
                return referralModel.state.get().mode === 'EZAuth'
                    ? requiredForSubmitter(value, referralModel)
                    : null;
            },
        ]
    },

    {
        fieldName: 'practiceName',
        fieldLabel: 'Practice Name',
        validateFunctions: [
            validateWarning(
                ({ value, referralModel }) =>
                    !value &&
                    (referralModel.isServiceSelected(['DiagnosticServices']) ||
                        physicianSubmitterTypes.includes(referralModel.submitterModel.state.get().submitterType)),
            ),
        ],
        isWarning: true,
    },
    {
        fieldName: 'phone',
        fieldLabel: 'Phone',
        validateFunctions: [
            validateWarning(
                ({ value, referralModel }) =>
                    !value &&
                    referralModel.isServiceSelected(['PhysicalMedicine']) &&
                    !referralModel.isServiceSelected(requiredPhoneFaxServices),
            ),
        ],
        isWarning: true,
    },
];

export class ReferringPhysicianModel extends ChangeableModel<IReferringPhysicianState> implements ISubmitterEntity {
    copySubmitterFields = (submitter: ISubmitterState) => {
        const fields =
            submitter.submitterType === 'ReferringPhysicianOffice'
                ? {
                      practiceName: submitter.companyName,
                  }
                : {
                      firstName: submitter.firstName,
                      lastName: submitter.lastName,
                      phone: submitter.mainPhone,
                      email: submitter.email,
                      fax: submitter.fax,
                      practiceName: submitter.companyName,
                  };

        this.state.update((state) => {
            setSubmitterFields(fields, state);
        });
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map([
            ['Name', [state.firstName, state.lastName].filter((v) => v).join(' ')],
            ['Practice', state.practiceName],
            ['Phone', state.phone],
            ['Email', state.email],
        ]);
    };

    isAnyFieldPopulated = () => {
        const state = this.state.get();

        return Boolean(state.firstName
            || state.lastName
            || state.practiceName
            || state.phone
            || state.fax
            || state.email
            || state.address?.address1
            || state.address?.address2
            || state.address?.city
            || state.address?.state
            || state.address?.zip);
    }
}