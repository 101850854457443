import dayjs from 'dayjs';
import { SectionSystemCode, TRange } from '../../../../common/types';
import { requiredIfCondition, validateDateField, validateRequiredField, validateWarning } from '../../../../common/validationHelpers';
import { ChangeableModel } from '../../../../model/model';
import { TResourceValue, valuesManager } from '../../../../services/valuesManager/valuesManager';
import { IPhysicalTherapyState } from './PhysicalTherapyState';
import { getShortenText } from '../../../../common/utils';
import { referralModel } from '../../../ReferralModel';

export const sectionSystemCode: SectionSystemCode = 'PhysicalMedicine';

export const rxCertificationExpirationRange = (): TRange<Date> => ({
    to: dayjs(new Date()).add(1, 'year').endOf('year').toDate(),
});
export const surgeryDateRange = (): TRange<Date> => ({
    to: dayjs(new Date()).add(1, 'year').endOf('year').toDate(),
});

export const physicalTherapyValidators = [
    { fieldName: 'therapyType.value', fieldLabel: 'Therapy Type', validateFunctions: [validateRequiredField] },
    {
        fieldName: 'rxCertificationExpirationDate',
        fieldLabel: 'Rx Certification Expiration Date',
        validateFunctions: [
            validateDateField(
                () => referralModel.physicalTherapyModel.state.get().rxCertificationExpirationDateString,
                rxCertificationExpirationRange,
            ),
        ],
    },
    {
        fieldName: 'surgeryDate',
        fieldLabel: 'Surgery Date',
        validateFunctions: [
            validateDateField(() => referralModel.physicalTherapyModel.state.get().surgeryDateString, surgeryDateRange),
        ],
    },
    {
        fieldName: 'otherDescription',
        fieldLabel: 'Other Description',
        validateFunctions: [
            requiredIfCondition(
                ({ referralModel }) => referralModel.physicalTherapyModel.state.get().therapyType?.value === 'PhysMedOther',
            ),
        ],
    },
    {
        fieldName: 'bodyPart.value',
        fieldLabel: 'Body Part',
        validateFunctions: [
            validateWarning(({ value, referralModel }) => !value && referralModel.isServiceSelected(['PhysicalMedicine'])),
        ],
        isWarning: true,
    },
    {
        fieldName: 'therapyPrescription',
        fieldLabel: 'Therapy Prescription',
        validateFunctions: [
            validateWarning(({ value, referralModel }) => !value && referralModel.isServiceSelected(['PhysicalMedicine'])),
        ],
        isWarning: true,
    },
];

export class PhysicalTherapyModel extends ChangeableModel<IPhysicalTherapyState> {
    loadProducts = async () => {
        const productCategory = valuesManager.resourceValues.productCategories?.find(
            (p) => p.value === sectionSystemCode,
        );

        const products = await valuesManager.getProducts(productCategory?.id ?? 0);

        this.state.update((state) => {
            state.dropdownValues = {};
            state.dropdownValues.productCategory = productCategory;
            state.dropdownValues.products = products;

            state.therapyType = undefined;
            state.bodyPart = undefined;
            state.bodySide = undefined;
            state.injuryType = undefined;
        });
    };

    loadBodyParts = async () => {
        const productId = this.state.get().therapyType?.id ?? 0;
        const bodyParts = productId !== 0 ? await valuesManager.getBodyParts(productId) : [];

        this.state.update((state) => {
            state.dropdownValues.bodyParts = bodyParts;
            state.bodyPart = undefined;
            state.bodySide = undefined;
            state.injuryType = undefined;
            state.otherDescription = '';
        });
    };

    loadBodySides = async () => {
        const bodyPartId = this.state.get().bodyPart?.id ?? 0;
        const bodySides = bodyPartId !== 0 ? await valuesManager.getBodyPartModifiers(bodyPartId) : [];
        const injuryTypes = bodyPartId !== 0 ? await valuesManager.getInjuryTypes(bodyPartId) : [];

        this.state.update((state) => {
            state.dropdownValues.bodySides = bodySides;
            state.dropdownValues.injuryTypes = injuryTypes;
            state.bodySide = undefined;
            state.injuryType = undefined;
        });
    };

    handleProcedureChange = async (value: TResourceValue | undefined) => {
        const procedureUpdated = !value?.value || value.value !== this.state.get().therapyType?.value;

        this.state.update((state) => {
            state.therapyType = value;

            const errors = state.errors.find((err) => err.fieldName === 'therapyType.value');
            if (errors) {
                errors.errors = [];
            }

            if (!value?.value) {
                state.dropdownValues.bodyParts = undefined;
            }
        });
        if (procedureUpdated) {
            await this.loadBodyParts();
        }
    };

    handleBodyPartChange = async (value: TResourceValue | undefined) => {
        const bodySideUpdated = !value?.value || value.value !== this.state.get().bodyPart?.value;
        this.state.update((state) => {
            state.bodyPart = value;

            if (!value?.value) {
                state.dropdownValues.bodySides = undefined;
                state.dropdownValues.injuryTypes = undefined;
            }
        });

        if (bodySideUpdated) {
            await this.loadBodySides();
        }
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | React.JSX.Element | undefined>([
            [
                'Therapy Type',
                state.therapyType?.value === 'PhysMedOther' && state.otherDescription
                    ? state.otherDescription
                    : state.therapyType?.label,
            ],
            ['Body Part', state.bodyPart?.label],
            ['Therapy Prescription', getShortenText(state.therapyPrescription)],
        ]);
    };

    override getAdditionalNotes(withSpecialInstructions: boolean = true) {
        const state = this.state.get();

        let notes: [string, string][] = [];

        const note = [
            referralModel.getGeneralInstructions(),
            this.formatAdditionalNotes(
                [
                    {
                        fieldName: 'therapyPrescription',
                        label: 'Therapy Prescription (Frequency / Duration / Visits Authorized)',
                    },
                    { fieldName: 'otherDescription', label: 'Other Description' },
                ],
                sectionSystemCode,
                withSpecialInstructions,
            ),
        ];

        notes.push([state.therapyType?.value ?? '', note.filter(n => n).join(';')]);

        return new Map<string, string>(notes);
    }
}
