import { styled } from '@mui/system';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { Address } from '../Address';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { ReferralModel } from '../../ReferralModel';
import { CtrlCheckbox } from '../../../components/controls/CtrlCheckbox';
import { setStateToStorage } from '../../../common/localStorageUtils';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../theme/icons';
import { physicianSubmitterTypes, requiredNameServices, requiredPhoneFaxServices } from './ReferringPhysicianModel';
import { useEffect } from 'react';
import { WarningsPanel } from '../WarningsPanel';
import { isAnyAddressFieldPopulated } from '../../../common/validationHelpers';
import { rememberDataVisible } from '../utils';
export interface ReferringPhysicianProps extends ViewProps<ReferralModel> {}

const sectionSystemCode: SectionSystemCode = 'ReferringPhysician';

function ReferringPhysicianComponent(props: ReferringPhysicianProps) {
    const { model } = props;
    const referringPhysicianModel = model.referringPhysicianModel;
    const state = referringPhysicianModel.state.use();
    const { submitterType } = model.submitterModel.state.use();
    const { selectedServices, customization, queryParams, mode } = model.state.use();
    const { handleControlChange, getErrorMessage } = referringPhysicianModel;
    const isSubmitter = physicianSubmitterTypes.includes(submitterType);

    useEffect(() => {
        referringPhysicianModel.clearValidationMessages();
    }, [referringPhysicianModel, isSubmitter, selectedServices]);

    const anyFieldPopulated = referringPhysicianModel.isAnyFieldPopulated();
    const addressPopulated = isAnyAddressFieldPopulated(state.address);

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="First Name"
                        value={state.firstName}
                        onChange={handleControlChange('firstName')}
                        error={getErrorMessage('firstName')}
                        maxCharacters={50}
                        required={isSubmitter || model.isServiceSelected(requiredNameServices) || anyFieldPopulated}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Last Name"
                        value={state.lastName}
                        onChange={handleControlChange('lastName')}
                        error={getErrorMessage('lastName')}
                        maxCharacters={50}
                        required={isSubmitter || model.isServiceSelected(requiredNameServices) || anyFieldPopulated}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Practice Name"
                        value={state.practiceName}
                        onChange={handleControlChange('practiceName')}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Phone"
                        value={state.phone}
                        onChange={handleControlChange('phone')}
                        error={getErrorMessage('phone')}
                        phoneFormat
                        required={isSubmitter || model.isServiceSelected(requiredPhoneFaxServices)}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Email"
                        value={state.email}
                        onChange={handleControlChange('email')}
                        error={getErrorMessage('email')}
                        required={mode === 'EZAuth' && (isSubmitter || model.isServiceSelected(requiredPhoneFaxServices))}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Fax"
                        value={state.fax}
                        onChange={handleControlChange('fax')}
                        error={getErrorMessage('fax')}
                        required={customization === 'Navigere' || customization === 'GM'}
                        phoneFormat
                    />
                </div>
            </div>
            <Address
                values={state.address}
                handleChange={handleControlChange}
                getErrorMessage={getErrorMessage}
                requiredFields={addressPopulated || customization === 'GM' ? ['address1', 'city', 'state', 'zip'] : []}
            />
            <WarningsPanel messages={referringPhysicianModel.getWarnings()} />
            <div className="continue-button-row">
                {rememberDataVisible(queryParams) && (
                    <CtrlCheckbox
                        label="Remember This Section Data"
                        value={state.rememberSectionData}
                        onChange={(value) => {
                            handleControlChange('rememberSectionData')(value);
                            setStateToStorage(sectionSystemCode, referringPhysicianModel.state.get(), value);
                        }}
                    />
                )}
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        const state = referringPhysicianModel.state.get();
                        model.validateSection(sectionSystemCode, referringPhysicianModel);
                        rememberDataVisible(queryParams) && setStateToStorage(sectionSystemCode, state, state.rememberSectionData);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const ReferringPhysician = styled(ReferringPhysicianComponent, { name: 'ReferringPhysician' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
}));
