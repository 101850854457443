import { Box, Typography, styled } from '@mui/material';
import { ViewProps } from '../common/types';
import { ReferralModel } from './ReferralModel';

function FooterComponent(props: ViewProps<ReferralModel>) {
    const { model } = props;
    const { customization } = model.state.use();

    const companyName = customization === 'Navigere' ? 'Navigere' :  'One Call';

    return (
        <div className={props.className}>
            <Box className="box">
                <Typography variant="body1" className="text">
                    The online services provided by {companyName} are for exclusive use of {companyName} customers and partners.
                </Typography>
                <Typography variant="body1" className="text">
                    ©2023 {companyName}. All rights reserved. Unauthorized access is strictly prohibited. Usage will be
                    monitored.
                </Typography>
            </Box>
        </div>
    );
}

export const Footer = styled(FooterComponent, { name: 'Footer' })(({ theme }) => ({
    marginTop: 'auto',
    background: theme.app.neutralColor.white,
    '.box': {
        height: 80,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.text': {
        color: theme.app.neutralColor.textMain,
    },
}));
