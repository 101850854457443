import { TModel } from "../../model/model";
import { TGlobalState } from "../../model/state";
import React from "react";
import { IDialogViewData, Views } from "./model";

export const dialogsState = new TGlobalState<IDialogViewData[]>([]);
export const dialogsModel = new TModel<IDialogViewData[]>(dialogsState);

export function Dialogs() {
    const dialogs = dialogsModel.state.use();

    if (!dialogs.length) {
        return null;
    }

    return (
        <>
            {dialogs.map((dialogView, idx) => (
                <React.Fragment key={idx}>
                    {Views.renderView(dialogView.viewId, {
                        model: dialogView.model,
                        className: "dialog",
                    })}
                </React.Fragment>
            ))}
        </>
    );
}

export async function showDialog<R>(data: IDialogViewData): Promise<R> {
    data.model.result = new Promise<R>((resolve) => {
        data.model.onClose = (res) => {
            dialogsModel.state.set((oldState) =>
                oldState.filter((item) => item !== data)
            );
            resolve(res);
        };
        dialogsModel.state.set((oldState) => [data, ...oldState]);
    });
    return data.model.result;
}
