import { TAddress, ValidatedState } from "../../../../common/types"
import { TResourceValue, valuesManager } from "../../../../services/valuesManager/valuesManager";

export const patientRadioButtonValues = [{ label: 'No', value: 'No' }, { label: 'Yes', value: 'Yes' } ];
export const careAddressValues = [{ label: 'Home', value: 'Home' }, { label: 'Other', value: 'Other' }];

export type THHServicesResourceValue = TResourceValue & {
    checked: boolean;
    frequencyDurationType?: string;
};

export interface IHomeHealthComplexCareState extends ValidatedState {
    homeHealthServices?: THHServicesResourceValue[],
    selectedMedicalSuppliesValue?: TResourceValue[], 
    selectedRespiratorySuppliesValue?: TResourceValue[],   
    commentsAdditionalDetails?: string,
    isPrescriptionPhysicianOrders: boolean,
    requestedStartOfCare?: Date;
    requestedStartOfCareString?: string;
    careAddress?: string;
    patientInHospital?: string;
    hospitalFacilityName?: string;
    hospitalDischargeDate?: Date;
    hospitalDischargeDateString?: string,
    hospitalPhone?: string;
    contactPerson?: string;
    isRush: boolean;
    address?: TAddress;
}

export const defaultHomeHealthComplexCareState: IHomeHealthComplexCareState = {
    homeHealthServices: valuesManager.resourceValues.homeHealthServices?.map((service) =>({...service, checked: false, frequencyDurationType: ""})),
    isPrescriptionPhysicianOrders: false,
    patientInHospital: "No",
    careAddress: "Home",
    isRush: false,
    errors: []
}