import { SetStateAction } from "react";

export const isNullOrUndefined = (v: any) => v === null || v === undefined;

export function resolveState<S>(
    newState: SetStateAction<S>,
    getPrevState: () => S
): S {
    return typeof newState === "function"
        ? (newState as (prev: S) => S)(getPrevState())
        : newState;
}

export const range = (n: number) => Array.from({ length: n }, (_, i) => i);

export const getShortenText = (text: string) => {
    if (text.length < 105) {
        return text;
    }

    const spaceIndex = text.substring(0, 100).lastIndexOf(' ');

    if (spaceIndex !== -1) {
        return text.substring(0, spaceIndex) + '...';
    } else {
        return '...';
    }
};

export const printPdfByteArray = (byteArray: ArrayBuffer) => {
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;

    iframe.onload = () => {
        iframe.contentWindow?.print();
    };

    document.body.appendChild(iframe);
};
