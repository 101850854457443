import { SectionSystemCode, ValidatedState } from "../../../common/types";

export interface ISpecialInstructionsState extends ValidatedState {
    specialInstructions: string,
    serviceCodes: SectionSystemCode[];
}

export const defaultSpecialInstructionsState: ISpecialInstructionsState = {
    specialInstructions: '',
    serviceCodes: [],
    errors: [],
}