import { Typography, styled } from '@mui/material';
import { ViewProps } from '../../../common/types';
import { CtrlRadio } from '../../../components/controls/CtrlRadio';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { CtrlDateField } from '../../../components/controls/CtrlDateField';
import { CtrlComboSelect } from '../../../components/controls/CtrlComboSelect';
import { theme } from '../../../theme';
import { ReferralModel } from '../../ReferralModel';
import { genders } from './ClaimPatientState';
import { Address } from '../Address';
import { valuesManager } from '../../../services/valuesManager/valuesManager';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../theme/icons';
import dayjs from 'dayjs';
import CustomRowsComboSelect, { TCustomRowsColum } from '../../../components/controls/CustomRowsComboSelect';
import { requiredClaimNumberServices, requiredDateOfBirthServices, requiredInjuryServices } from './ClaimPatientModel';
import { useEffect } from 'react';
import { WarningsPanel } from '../WarningsPanel';

export interface ClaimPatientProps extends ViewProps<ReferralModel> {}

const SSNInformation = `
    One Call no longer requires SSN information to be provided during the referral submission process. 
    However, we recognize certain customer business practices require the use of the injured worker’s SSN. 
    One Call will not disclose an injured worker’s SSN without consent of the injured worker to anyone outside of One Call except as mandated by law.`;

const NavigereSSNInformation = `
    Navigere no longer requires SSN information to be provided during the referral submission process. 
    However, we recognize certain customer business practices require the use of the injured worker’s SSN. 
    Navigere will not disclose an injured worker’s SSN without consent of the injured worker to anyone outside of Navigere except as mandated by law.`;

const icdCodeColumns: TCustomRowsColum[] = [
    { label: 'Code', key: 'value', span: 2 },
    { label: 'Type', key: 'type', span: 2 },
    { label: 'Description', key: 'label', span: 8, withTooltip: 'label' },
];

function ClaimPatientComponent(props: ClaimPatientProps) {
    const { model } = props;
    const claimPatientModel = model.claimPatientModel;
    const state = claimPatientModel.state.use();
    const { selectedServices, customization } = model.state.use();
    const { handleControlChange, getErrorMessage, getIcdCodes } = claimPatientModel;

    useEffect(() => {
        claimPatientModel.clearValidationMessages();
    }, [claimPatientModel, selectedServices]);

    return (
        <div className={props.className}>
            <div className="ssn-field">
                <Typography variant="body2" className="ssn-info">
                    {customization === 'Navigere' ? NavigereSSNInformation : SSNInformation}
                </Typography>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="First Name"
                        value={state.firstName}
                        onChange={handleControlChange('firstName')}
                        maxCharacters={50}
                        required
                        error={claimPatientModel.getErrorMessage('firstName')}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Last Name"
                        value={state.lastName}
                        onChange={handleControlChange('lastName')}
                        maxCharacters={50}
                        required
                        error={claimPatientModel.getErrorMessage('lastName')}
                    />
                </div>
            </div>
            <div className="fields-container">
                {customization === 'GM' ? (
                    <>
                        <div className="span-3">
                            <CtrlTextField
                                label="Employee ID"
                                value={state.employeeId}
                                onChange={(val) => {
                                    handleControlChange('employeeId')(val);
                                    handleControlChange('claimNumber')(
                                        state.medgateNumber
                                            ? `${state.employeeId}-${state.medgateNumber}`
                                            : state.employeeId,
                                    );
                                }}
                                required={true}
                                error={claimPatientModel.getErrorMessage('employeeId')}
                            />
                        </div>
                        <div className="span-3">
                            <CtrlTextField
                                label="Medgate #"
                                value={state.medgateNumber}
                                onChange={(val) => {
                                    handleControlChange('medgateNumber')(val);
                                    handleControlChange('claimNumber')(
                                        state.medgateNumber
                                            ? `${state.employeeId}-${state.medgateNumber}`
                                            : state.employeeId,
                                    );
                                }}
                                error={claimPatientModel.getErrorMessage('medgateNumber')}
                            />
                        </div>
                    </>
                ) : (
                    <div className="span-6">
                        <CtrlTextField
                            label="Claim Number"
                            value={state.claimNumber}
                            onChange={handleControlChange('claimNumber')}
                            required={model.isServiceSelected(requiredClaimNumberServices)}
                            error={claimPatientModel.getErrorMessage('claimNumber')}
                        />
                    </div>
                )}
                <div className="span-2">
                    <CtrlDateField
                        label="Date of Injury"
                        disableFuture
                        minDate={state.dateOfBirth && dayjs(state.dateOfBirth).add(1, 'day').toDate()}
                        value={state.dateOfInjury}
                        onChange={handleControlChange('dateOfInjury')}
                        error={getErrorMessage('dateOfInjury')}
                    />
                </div>
                <div className="span-4">
                    <CtrlComboSelect
                        label="Injury / Jurisdiction State"
                        selectFrom={valuesManager.resourceValues.states}
                        value={state.injuryJurisdictionState}
                        onChange={handleControlChange('injuryJurisdictionState')}
                        getLabel={(v) => v.value}
                        required={model.isServiceSelected(requiredInjuryServices)}
                        error={claimPatientModel.getErrorMessage('injuryJurisdictionState.value')}
                    />
                </div>
            </div>
            {customization !== 'GM' ? (
                <div className="fields-container">
                    <div className="span-2">
                        <CustomRowsComboSelect
                            label="ICD Code"
                            value={state.icdCode}
                            onChange={claimPatientModel.handleIcdCodeChange}
                            onChangeNative={getIcdCodes}
                            selectFrom={state.icdCodes}
                            onClose={() => handleControlChange('icdCodes')(undefined)}
                            columns={icdCodeColumns}
                            onGetLabel={(code) => code?.value ?? 'unknown'}
                            listWidth={800}
                        />
                    </div>
                    <div className="span-10">
                        <CtrlTextField
                            label="Describe Injury"
                            value={state.describeInjury}
                            onChange={handleControlChange('describeInjury')}
                        />
                    </div>
                </div>
            ) : null}
            <div className="fields-container">
                <div className="span-2">
                    <CtrlDateField
                        label="Date of Birth"
                        value={state.dateOfBirth}
                        maskedValue={state.dateOfBirthMasked}
                        onChange={(val) => {
                            handleControlChange('dateOfBirth')(val);
                            handleControlChange('dateOfBirthMasked')(undefined);
                        }}
                        disableFuture
                        required={model.isServiceSelected(requiredDateOfBirthServices)}
                        error={claimPatientModel.getErrorMessage('dateOfBirth')}
                    />
                </div>
            </div>
            <div className="gender-field">
                <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                    Gender
                </Typography>
                <CtrlRadio
                    options={genders}
                    value={state.gender}
                    onChange={claimPatientModel.handleChange}
                    name="gender"
                />
            </div>
            <div className="fields-container">
                <div className="height-weight-field">
                    <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        Height (Feet, Inches)
                    </Typography>
                    <div className="height-weight-field-input">
                        <CtrlTextField
                            value={state.heightFeet}
                            onChange={handleControlChange('heightFeet')}
                            maskProps={{
                                mask: '99',
                            }}
                            error={claimPatientModel.getErrorMessage('heightFeet')}
                        />
                        <CtrlTextField
                            value={state.heightInches}
                            onChange={handleControlChange('heightInches')}
                            maskProps={{
                                mask: '99',
                            }}
                            error={claimPatientModel.getErrorMessage('heightInches')}
                        />
                    </div>
                </div>
                <div className="height-weight-field">
                    <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        Weight (Lbs)
                    </Typography>
                    <div className="height-weight-field-input">
                        <CtrlTextField
                            width="50%"
                            value={state.weight}
                            onChange={handleControlChange('weight')}
                            maskProps={{
                                mask: '999',
                            }}
                            error={claimPatientModel.getErrorMessage('weight')}
                        />
                    </div>
                </div>
            </div>
            <div className="fields-container">
                <div className="span-3">
                    <CtrlTextField
                        label="Home Phone"
                        value={state.homePhone}
                        maskedValue={state.homePhoneMasked}
                        onChangeNative={(e) => {
                            handleControlChange('homePhone')(e.target.value);
                            handleControlChange('homePhoneMasked')(undefined);
                        }}
                        error={getErrorMessage('homePhone')}
                        phoneFormat
                        required
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Cell Phone"
                        value={state.cellPhone}
                        maskedValue={state.cellPhoneMasked}
                        onChangeNative={(e) => {
                            handleControlChange('cellPhone')(e.target.value);
                            handleControlChange('cellPhoneMasked')(undefined);
                        }}
                        error={getErrorMessage('cellPhone')}
                        phoneFormat
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Work Phone"
                        value={state.workPhone}
                        onChange={handleControlChange('workPhone')}
                        error={getErrorMessage('workPhone')}
                        phoneFormat
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Ext."
                        value={state.ext}
                        onChange={handleControlChange('ext')}
                        maskProps={{
                            mask: '99999999',
                        }}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-4">
                    <CtrlTextField
                        label="Alternative Phone"
                        value={state.alternativePhone}
                        onChange={handleControlChange('alternativePhone')}
                        error={getErrorMessage('alternativePhone')}
                        phoneFormat
                    />
                </div>
                <div className="span-4">
                    <CtrlTextField
                        label="Alt. Phone Description"
                        value={state.altPhoneDescription}
                        onChange={handleControlChange('altPhoneDescription')}
                    />
                </div>
                <div className="span-4">
                    <CtrlTextField
                        label="Email"
                        value={state.email}
                        onChange={handleControlChange('email')}
                        error={getErrorMessage('email')}
                        required={customization === 'GM'}
                    />
                </div>
            </div>
            <Address
                values={state.address}
                handleChange={handleControlChange}
                getErrorMessage={claimPatientModel.getErrorMessage}
                requiredFields={['address1', 'city', 'state', 'zip']}
            />
            <div className="language-field">
                <div className="english-field">
                    <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        Language
                    </Typography>
                    <CtrlRadio
                        options={[
                            { label: 'Speaks English', value: 'speaks' },
                            { label: 'Limited English, prefers', value: 'limited' },
                        ]}
                        value={state.speaksEnglish}
                        onChange={claimPatientModel.changeSpeaksEnglish}
                        name="speaksEnglish"
                    />
                </div>
                <CtrlComboSelect
                    width="50%"
                    value={state.languagePreference}
                    onChange={handleControlChange('languagePreference')}
                    error={getErrorMessage('languagePreference')}
                    selectFrom={valuesManager.resourceValues.languages}
                    disabled={state.speaksEnglish !== 'limited'}
                    className="language-preference"
                />
            </div>
            <WarningsPanel messages={claimPatientModel.getWarnings()} />
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection('ClaimPatient', claimPatientModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const ClaimPatient = styled(ClaimPatientComponent, { name: 'ClaimPatient' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.ssn-field': {
        width: '65%',
    },
    '.ssn-info': {
        color: theme.app.neutralColor.textMain,
    },
    '.gender-field': {
        marginTop: theme.spacing(5),
    },
    '.language-field': {
        marginTop: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        width: '65%',
        '.english-field': {
            display: 'flex',
            flexDirection: 'column',
        },
        '.language-preference': {
            marginTop: 20,
            width: '20%',
        },
    },
    '.height-weight-field': {
        marginTop: -25,
    },
    '.height-weight-field-input': {
        display: 'flex',
        width: 250,
        columnGap: theme.spacing(2),
    },
}));
