import { createContext, useCallback, useContext, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import APIJSON from '../config/api.json';

interface RecaptchaContextType {
  getToken: () => Promise<string>;
}

const RecaptchaContext = createContext<RecaptchaContextType | null>(null);

export function RecaptchaProvider(props:any) {
  const captchaRef:any = useRef(null);

  const getToken = useCallback(async () => {
    const token = await captchaRef.current.executeAsync();
    captchaRef.current.reset();
    return token;
  }, []);

  return (
    <RecaptchaContext.Provider value={{ getToken }}>
      <ReCAPTCHA
        className="recaptcha"
        sitekey={APIJSON.api.recaptchaSiteKey}
        ref={captchaRef}
        size="invisible"
      />
      {props.children}
    </RecaptchaContext.Provider>
  );
}

export function useRecaptcha() {
  return useContext(RecaptchaContext);
}