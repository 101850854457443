import { ValidatedState } from "../../../common/types";
import { Option } from "../../../components/controls/CtrlRadio";

export type SubmitterType = 
| 'Patient'
| 'Adjuster'
| 'AdjusterOffice'
| 'NurseCaseManager'
| 'NurseCaseManagerOffice'
| 'ReferringPhysician'
| 'ReferringPhysicianOffice'
| 'Provider'
| 'Other'

export type OtherSubmitterType = 
| 'Attorney'
| 'AttorneyOffice'
| 'Employer'
| 'UtilizationReview'
| 'UtilizationReviewOffice'
| 'IndependentAdjuster'
| 'IMECompany';

export const submitters: Option[] = [
    { label: 'Patient', value: 'Patient' },
    { label: 'Adjuster', value: 'Adjuster' },
    { label: 'Adjuster Office', value: 'AdjusterOffice' },
    { label: 'Case Manager', value: 'NurseCaseManager' },
    { label: 'Case Manager Office', value: 'NurseCaseManagerOffice' },
    { label: 'Referring Physician', value: 'ReferringPhysician' },
    { label: 'Referring Physician Office', value: 'ReferringPhysicianOffice' },
    { label: 'Provider of Service', value: 'Provider' },
    { label: 'Other', value: 'Other' },
];

export interface ISubmitterState extends ValidatedState {
    submitters: Option[];
    submitterType: SubmitterType;
    otherSubmitterType?: OtherSubmitterType;
    firstName?: string;
    lastName?: string;
    mainPhone?: string;
    ext?: string;
    fax?: string;
    cellPhone?: string;
    email?: string;
    companyName?: string;
    rememberSectionData: boolean
}

export const defaultSubmitterState: ISubmitterState = {
    submitters: submitters,
    submitterType: 'Adjuster',
    rememberSectionData: false,
    errors: [],
}