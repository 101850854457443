export interface IRequestData {
    input: RequestInfo | URL;
    init?: RequestInit | undefined;
    resolve: (value: Response | PromiseLike<Response>) => void;
    reject: (reason?: any) => void;
    response?: Response;
    error?: any;
}

export async function processFetch(data: IRequestData) {
    let processingData = data;
    try {
        const response = await fetch(processingData.input, processingData.init);

        processingData.resolve(response);
        return true;
    } catch (error) {
        processingData.reject(error);
    }
    return false;
}

export const appFetch: (input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<Response> = (
    input,
    init,
) => {
    return new Promise((resolve, reject) => processFetch({ input, init, resolve, reject }));
};
