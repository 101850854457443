import { InputBaseComponentProps } from "@mui/material";
import React from "react";
import InputMask from "react-input-mask";
import MaskedInput from "react-text-mask";

export interface CtrlMaskInputProps {
    mask: string | Array<string | RegExp>;
    maskChar?: string | null;
}

export const CtrlMaskInput = React.forwardRef<
    HTMLElement,
    CtrlMaskInputProps & InputBaseComponentProps
>(function CtrlMaskInputComponent(props, ref) {
    const { mask, maskChar = null, maskPlaceholder, ...other } = props;

    const cleanMaskEndings = (str: string) => str.replace(/[^\d|A-Z|%]$/i, "");

    return Array.isArray(mask) ? (
        <MaskedInput
            {...other}
            mask={mask}
            guide={false}
            pipe={cleanMaskEndings}
        />
    ) : (
        <InputMask {...other} mask={mask} maskChar={maskChar} />
    );
});
