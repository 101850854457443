import { TAddress, ValidatedState } from "../../../common/types"
import { TResourceValue } from "../../../services/valuesManager/valuesManager"

export interface IInsuranceState extends ValidatedState {
    company?: TResourceValue,
    phone?: string,
    address: TAddress,
    rememberSectionData: boolean
}

export const defaultInsuranceState: IInsuranceState = {
    company: undefined,
    phone: undefined,
    address: {
        address1: undefined,
        address2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
    },
    rememberSectionData: false,
    errors: []
}