import {
    validateRequiredField,
    validateDateField,
    validateTimeFieldFromString,
    validatePhoneNumber,
    requiredIfAddressPopulated,
    validateZipCode,
} from '../../../../common/validationHelpers';
import { ChangeableModel } from '../../../../model/model';
import { TResourceValue } from '../../../../services/valuesManager/valuesManager';
import { ILanguageState } from './LanguageState';
import { FieldValidator, SectionSystemCode, TRange } from '../../../../common/types';
import dayjs from 'dayjs';
import { referralModel } from '../../../ReferralModel';

export const sectionSystemCode: SectionSystemCode = 'LanguageTranslation';

export const appointmentDateRange = (): TRange<Date> => {
    return {
        from: dayjs(new Date()).add(1, 'day').startOf('day').toDate(),
        to: dayjs(new Date()).add(1, 'year').endOf('year').toDate(),
    };
};

const getAddress = () => referralModel.languageModel.state.get().address

export const languageValidators: FieldValidator[] = [
    {
        fieldName: 'appoinmentType.value',
        fieldLabel: 'Type of appointment',
        validateFunctions: [validateRequiredField],
    },
    {
        fieldName: 'languageCertification.value',
        fieldLabel: 'Language certification',
        validateFunctions: [validateRequiredField],
    },
    {
        fieldName: 'language.value',
        fieldLabel: 'Language',
        validateFunctions: [validateRequiredField],
    },
    {
        fieldName: 'appointmentDate',
        fieldLabel: 'Appointment Date',
        validateFunctions: [
            validateDateField(
                () => referralModel.languageModel.state.get().appointmentDateString,
                appointmentDateRange,
            ),
            validateRequiredField,
        ],
    },
    {
        fieldName: 'appointmentTimeString',
        fieldLabel: 'Appointment Time',
        validateFunctions: [validateTimeFieldFromString],
    },
    { fieldName: 'phone', fieldLabel: 'Facility Phone Number', validateFunctions: [validatePhoneNumber] },

    { fieldName: 'address.address1', fieldLabel: 'Address 1', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.state.value', fieldLabel: 'State', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.zip', fieldLabel: 'Zip code', validateFunctions: [validateZipCode, requiredIfAddressPopulated(getAddress)] },
];

export class LanguageModel extends ChangeableModel<ILanguageState> {
    handleAppoinmentTypeChange = async (value: TResourceValue | undefined) => {
        this.state.update((state) => {
            state.appoinmentType = value;

            const errors = state.errors.find((err) => err.fieldName === 'appoinmentType.value');
            if (errors) {
                errors.errors = [];
            }
        });
    };

    handleLanguageCertificationChange = async (value: TResourceValue | undefined) => {
        this.state.update((state) => {
            state.languageCertification = value;

            const errors = state.errors.find((err) => err.fieldName === 'languageCertification.value');
            if (errors) {
                errors.errors = [];
            }
        });
    };

    handleLanguageChange = async (value: TResourceValue | undefined) => {
        this.state.update((state) => {
            state.language = value;

            const errors = state.errors.find((err) => err.fieldName === 'language.value');
            if (errors) {
                errors.errors = [];
            }
        });
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([
            ['Language', state.language?.label],
            ['Type of Appoinment', state.appoinmentType?.label],
            ['Language Certification', state.languageCertification?.label],
        ]);
    };

    override getAdditionalNotes(withSpecialInstructions: boolean = true) {
        const state = this.state.get();

        let notes: [string, string][] = [];

        const note = [
            referralModel.getGeneralInstructions(),
            this.formatAdditionalNotes(
                [{ fieldName: 'additionalInstructions', label: 'Additional instructions' }],
                sectionSystemCode,
                withSpecialInstructions,
            ),
        ];

        notes.push([state.languageCertification?.value ?? '', note.filter(n => n).join(';')]);

        return new Map<string, string>(notes);
    }
}
