import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import { appStyles } from "./appStyles";
import { overrides } from "./overrides";
import globalStyles from "./global-styles";

export const theme = createTheme({
    spacing: 4,
    palette: {
        primary: {
            main: "#722F8D",
        },
        secondary: {
            main: "#19857b",
        },
        error: {
            main: red.A400,
        },
        background: {
            default: "#fff",
        },
    },
    typography: {
        fontFamily: "Open Sans",
        h1: {
            fontSize: 18,
            fontWeight: 600,
            color: appStyles.neutralColor.textDark,
        },
        h2: {
            fontSize: 14,
            fontWeight: 600,
            color: appStyles.neutralColor.textDark,
        },
        h3: {
            fontSize: 12,
            fontWeight: 600,
            color: appStyles.neutralColor.textDark,
        },
        h4: {
            fontSize: 10,
            fontWeight: 600,
            color: appStyles.neutralColor.textDark,
        },
        h5: {
            // h4
            fontSize: 10,
            fontWeight: 600,
            color: appStyles.neutralColor.textDark,
        },
        h6: {
            // h4
            fontSize: 10,
            fontWeight: 600,
            color: appStyles.neutralColor.textDark,
        },
        caption: {
            fontSize: 10,
            fontWeight: 400,
            color: appStyles.neutralColor.textDark,
        },
        body1: {
            fontSize: 12,
            fontWeight: 400,
            color: appStyles.neutralColor.textDark,
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
            color: appStyles.neutralColor.textDark,
        },
        button: {
            fontSize: 12,
            fontWeight: 600,
            color: appStyles.neutralColor.textDark,
        },
    },
    app: appStyles,
    components: {
        ...overrides,
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    ...globalStyles,
                },
            },
        },
    },
});
