import { FieldValidator, SectionSystemCode } from '../../../common/types';
import {
    validateEmail,
    validateFaxNumber,
    validatePhoneNumber,
    validateRequiredField,
    requiredForModeAndServices,
} from '../../../common/validationHelpers';
import { ChangeableModel } from '../../../model/model';
import { ReferralModel } from '../../ReferralModel';
import { ISubmitterEntity } from '../claimPatient/ClaimPatientModel';
import { ISubmitterState, SubmitterType } from '../submitter/SubmitterState';
import { setSubmitterFields } from '../utils';
import { IAdjusterState } from './AdjusterState';

export const adjusterSubmitterTypes: SubmitterType[] = ['Adjuster', 'AdjusterOffice'];
export const requiredNameServices: SectionSystemCode[] = ['PhysicalMedicine', 'LanguageTranslation', 'TransportationServices', 'DiagnosticServices', 'DurableMedicalEquipment', 'HomeHealthCare'];
export const requiredPhoneServices: SectionSystemCode[] = ['DiagnosticServices', 'DurableMedicalEquipment', 'HomeHealthCare'];

const requiredForSubmitter = (value: any, referralModel: ReferralModel) => {
    return adjusterSubmitterTypes.includes(referralModel.submitterModel.state.get().submitterType)
        ? validateRequiredField(value)
        : null;
};

const requiredIfSectionHasContent = (value: any, referralModel: ReferralModel) => {
    return referralModel.adjusterModel.isAnyFieldPopulated()
        ? validateRequiredField(value)
        : null;
}

export const adjusterValidators: FieldValidator[] = [
    { fieldName: 'firstName', fieldLabel: 'First Name', validateFunctions: [requiredForSubmitter, requiredForModeAndServices(requiredNameServices, 'EZReferral'), requiredIfSectionHasContent] },
    { fieldName: 'lastName', fieldLabel: 'Last Name', validateFunctions: [requiredForSubmitter, requiredForModeAndServices(requiredNameServices, 'EZReferral'), requiredIfSectionHasContent] },
    { fieldName: 'phone', fieldLabel: 'Phone', validateFunctions: [requiredForSubmitter, validatePhoneNumber, requiredForModeAndServices(requiredPhoneServices, 'EZReferral')] },
    { fieldName: 'cellPhone', fieldLabel: 'Cell phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'fax', fieldLabel: 'Fax', validateFunctions: [validateFaxNumber] },
    { fieldName: 'email', fieldLabel: 'Email', validateFunctions: [requiredForSubmitter, validateEmail] },
];

export class AdjusterModel extends ChangeableModel<IAdjusterState> implements ISubmitterEntity {
    copySubmitterFields = (submitter: ISubmitterState) => {
        const fields = {
            firstName: submitter.firstName,
            lastName: submitter.lastName,
            phone: submitter.mainPhone,
            ext: submitter.ext,
            cellPhone: submitter.cellPhone,
            email: submitter.email,
            fax: submitter.fax,
        };

        this.state.update((state) => {
            setSubmitterFields(fields, state);
        });
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map([
            ['Adjuster', [state.firstName, state.lastName].filter((v) => v).join(' ')],
            ['Fax', state.fax],
            ['Phone', [state.phone, state.ext ? `ext.: ${state.ext}` : null].filter((v) => v).join(' ')],
            ['Email', state.email]
        ]);
    };

    isAnyFieldPopulated = () => {
        const state = this.state.get();

        return Boolean(state.firstName
            || state.lastName
            || state.phone
            || state.ext
            || state.cellPhone
            || state.fax
            || state.email);
    }
}
