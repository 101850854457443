import { ValidatedState } from "../../../../common/types"
import { TResourceValue } from "../../../../services/valuesManager/valuesManager"


export type TPhysicalTherapyDropdownValues = {
    productCategory?: TResourceValue,
    products?: TResourceValue[],
    bodyParts?: TResourceValue[],
    bodySides?: TResourceValue[],
    injuryTypes?: TResourceValue[],
};

export interface IPhysicalTherapyState extends ValidatedState {
    therapyType?: TResourceValue,
    otherDescription: string,
    bodyPart?: TResourceValue,
    bodySide?: TResourceValue,
    injuryType?: TResourceValue,
    surgeryDate?: Date,
    surgeryDateString?: string,
    rxCertificationNumber: string,
    rxCertificationExpirationDate?: Date,
    rxCertificationExpirationDateString?: string,
    therapyPrescription: string,
    isRush: boolean,
    dropdownValues: TPhysicalTherapyDropdownValues
}

export const defaultPhysicalTherapyState: IPhysicalTherapyState = {
    otherDescription: "",
    rxCertificationNumber: "",
    therapyPrescription: "",
    isRush: false,
    errors: [],
    dropdownValues: {}
}