import { CtrlComboSelect } from '../../../../components/controls/CtrlComboSelect';
import { CtrlTextField } from '../../../../components/controls/CtrlTextField';
import { valuesManager } from '../../../../services/valuesManager/valuesManager';
import { Address } from '../../Address';
import { ChangeableModel } from '../../../../model/model';
import { memo } from 'react';
import { TransportationAddress } from './TransportationState';
import { ValidationError } from '../../../../common/types';

export const TransportationAddressMemo = memo(TransportationAddressComponent);
function TransportationAddressComponent({
    fieldTemplate,
    address,
    model,
    disabled,
    errors,
}: {
    fieldTemplate: string;
    address?: TransportationAddress;
    model: ChangeableModel<any>;
    disabled?: boolean;
    errors?: ValidationError[];
}) {
    return (
        <div className="fields-container">
            <div className="address-type span-4">
                <CtrlComboSelect
                    label={`Address Type`}
                    selectFrom={valuesManager.resourceValues.addressTypes}
                    value={address?.type}
                    onChange={model.handleControlChange(`${fieldTemplate}.type`)}
                    error={model.getErrorMessage(`${fieldTemplate}.type.value`)}
                    required
                />
            </div>
            <div className="address-type span-4">
                <CtrlTextField
                    label="Practice or Business Name"
                    value={address?.practiceName}
                    onChange={model.handleControlChange(`${fieldTemplate}.practiceName`)}
                    disabled={disabled}
                    maxCharacters={50}
                />
            </div>
            <div className="address-type span-4">
                <CtrlTextField
                    label="Location Phone"
                    value={address?.locationPhone}
                    maskedValue={address?.locationPhoneMasked}
                    onChange={model.handleControlChange(`${fieldTemplate}.locationPhone`)}
                    error={model.getErrorMessage(`${fieldTemplate}.locationPhone`)}
                    disabled={disabled}
                    phoneFormat
                />
            </div>
            <div className="span-12">
                <Address
                    values={address}
                    fieldTemplate={fieldTemplate}
                    handleChange={model.handleControlChange}
                    getErrorMessage={model.getErrorMessage}
                    disabled={disabled}
                    requiredFields={['address1', 'city', 'state', 'zip']}
                />
            </div>
        </div>
    );
}
