import { styled } from '@mui/system';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { Address } from '../Address';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../theme/icons';
import { ReferralModel } from '../../ReferralModel';
import { requiredCompanyServices } from './EmployerModel';
import { useEffect } from 'react';
import { WarningsPanel } from '../WarningsPanel';
import { isAnyAddressFieldPopulated } from '../../../common/validationHelpers';

export interface EmployerProps extends ViewProps<ReferralModel> {}
const sectionSystemCode: SectionSystemCode = 'Employer';

function EmployerComponent(props: EmployerProps) {
    const { model } = props;
    const employerModel = model.employerModel;
    const state = employerModel.state.use();
    const { selectedServices } = model.state.use();

    useEffect(() => {
        employerModel.clearValidationMessages();
    }, [employerModel, selectedServices]);

    const addressPopulated = isAnyAddressFieldPopulated(state.address);

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Company"
                        value={state.company}
                        onChange={employerModel.handleControlChange('company')}
                        error={employerModel.getErrorMessage('company')}
                        required={model.isServiceSelected(requiredCompanyServices)}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Phone"
                        phoneFormat
                        value={state.phone}
                        onChange={employerModel.handleControlChange('phone')}
                        error={employerModel.getErrorMessage('phone')}
                    />
                </div>
            </div>
            <Address
                values={state.address}
                handleChange={employerModel.handleControlChange}
                getErrorMessage={employerModel.getErrorMessage}
                requiredFields={addressPopulated ? ['address1', 'city', 'state', 'zip'] : []}
            />
            <WarningsPanel messages={employerModel.getWarnings()} />
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection(sectionSystemCode, employerModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const Employer = styled(EmployerComponent, { name: 'AddressSection' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
}));
