import { TAddress, ValidatedState } from "../../../common/types"
import { TResourceValue } from "../../../services/valuesManager/valuesManager";

export const genders = [{ label: 'Male', value: 'GenderMale' }, { label: 'Female', value: 'GenderFemale' }];

export interface IClaimPatientState extends ValidatedState {
    firstName?: string;
    lastName?: string;
    claimNumber?: string;
    employeeId?: string;
    medgateNumber?: string;
    dateOfInjury?: Date;
    injuryJurisdictionState?: TResourceValue;
    icdCode?: TResourceValue;
    describeInjury?: string;
    dateOfBirth?: Date;
    dateOfBirthMasked?: Date;
    gender?: string;
    heightFeet?: string;
    heightInches?: string;
    weight?: string;
    homePhone?: string;
    homePhoneMasked?: string;
    cellPhone?: string;
    cellPhoneMasked?: string;
    workPhone?: string;
    ext?: string;
    alternativePhone?: string;
    altPhoneDescription?: string;
    email?: string;
    address?: TAddress;
    speaksEnglish: string;
    languagePreference?: TResourceValue;

    icdCodes?: TResourceValue[];
}

export const defaultClaimPatientState: IClaimPatientState = {
    speaksEnglish: 'speaks',
    errors: [],
}