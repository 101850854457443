import { ViewProps } from '../../../../common/types';
import { styled } from '@mui/material';
import { CtrlComboSelect } from '../../../../components/controls/CtrlComboSelect';
import { CtrlTextField } from '../../../../components/controls/CtrlTextField';
import { CtrlDateField } from '../../../../components/controls/CtrlDateField';
import { CtrlTextArea } from '../../../../components/controls/CtrlTextArea';
import { CtrlCheckbox } from '../../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../../ReferralModel';
import { useEffect } from 'react';
import { CtrlButton } from '../../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../../theme/icons';
import { WarningsPanel } from '../../WarningsPanel';

export interface PhysicalTherapyProps extends ViewProps<ReferralModel> {}

function PhysicalTherapyComponent(props: PhysicalTherapyProps) {
    const { model } = props;
    const physicalTherapyModel = model.physicalTherapyModel;
    const state = physicalTherapyModel.state.use();

    useEffect(() => {
        if(!state.dropdownValues?.products || state.dropdownValues.products.length === 0){
            physicalTherapyModel.loadProducts();
        }
    }, [physicalTherapyModel, state]);

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect
                        label="Therapy Type"
                        required={true}
                        value={state.therapyType}
                        onChange={(selectedValue) => {
                            physicalTherapyModel.handleProcedureChange(selectedValue);
                            physicalTherapyModel.clearErrorMessages("otherDescription");
                        }}
                        selectFrom={state.dropdownValues.products}
                        error={physicalTherapyModel.getErrorMessage("therapyType.value")}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Other Description"
                        value={state.otherDescription}
                        onChange={physicalTherapyModel.handleControlChange('otherDescription')}
                        name="otherDescription"
                        disabled={state.therapyType?.value !== 'PhysMedOther'}
                        error={physicalTherapyModel.getErrorMessage('otherDescription')}
                        required={state.therapyType?.value === "PhysMedOther"}
                        maxCharacters={50}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect
                        label="Body Part"
                        onChange={physicalTherapyModel.handleBodyPartChange}
                        value={state.bodyPart}
                        selectFrom={state.dropdownValues.bodyParts}
                        disabled={!state.therapyType || !state.dropdownValues.bodyParts || !state.dropdownValues.bodyParts.length}
                    />
                </div>
                <div className="span-6">
                    <CtrlComboSelect
                        label="Body Side"
                        onChange={physicalTherapyModel.handleControlChange('bodySide')}
                        value={state.bodySide}
                        selectFrom={state.dropdownValues.bodySides}
                        disabled={!state.bodyPart || !state.dropdownValues.bodySides || !state.dropdownValues.bodySides.length}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect
                        label="Injury Type"
                        onChange={physicalTherapyModel.handleControlChange('injuryType')}
                        value={state.injuryType}
                        selectFrom={state.dropdownValues.injuryTypes}
                        disabled={!state.bodyPart || !state.dropdownValues.injuryTypes || !state.dropdownValues.injuryTypes.length}
                    />
                </div>
                <div className="span-6">
                    <CtrlDateField
                        label="Surgery Date"
                        onChange={physicalTherapyModel.handleControlChange('surgeryDate')}
                        value={state.surgeryDate}
                        onTextChange={physicalTherapyModel.handleControlChange('surgeryDateString')}
                        error={physicalTherapyModel.getErrorMessage('surgeryDate')}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="RX Certification Number"
                        placeholder="e.g. 394922950"
                        value={state.rxCertificationNumber}
                        onChange={physicalTherapyModel.handleControlChange('rxCertificationNumber')}
                        name="rxCertificationNumber"
                        maxCharacters={50}
                    />
                </div>
                <div className="span-6">
                    <CtrlDateField
                        label="RX Certification Expiration Date"
                        value={state.rxCertificationExpirationDate}
                        onChange={physicalTherapyModel.handleControlChange('rxCertificationExpirationDate')}
                        onTextChange={physicalTherapyModel.handleControlChange('rxCertificationExpirationDateString')}
                        error={physicalTherapyModel.getErrorMessage("rxCertificationExpirationDate")}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-12">
                    <CtrlTextArea
                        label="Therapy Prescription (Frequency / Duration / Visits Authorized)"
                        maxCharacters={500}
                        style={{ minHeight: 84 }}
                        onChange={physicalTherapyModel.handleControlChange('therapyPrescription')}
                        value={state.therapyPrescription}
                        name="therapyPrescription"
                    />
                </div>
            </div>
            <div className="is-rush-row">
                <div className="span-12">
                    <CtrlCheckbox
                        label="Rush"
                        value={state.isRush}
                        onChange={physicalTherapyModel.handleControlChange('isRush')}
                    />
                </div>
            </div>
            <WarningsPanel messages={physicalTherapyModel.getWarnings()} />
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection('PhysicalMedicine', physicalTherapyModel);
                        model.validateSection('Attachments', model.attachmentsModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const PhysicalTherapy = styled(PhysicalTherapyComponent, { name: 'PhysicalTherapy' })(({ theme }) => ({
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.is-rush-row': {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
    }
}));
