import { styled, TextField, TextFieldProps } from "@mui/material";
import clsx from "clsx";
import React, { ForwardedRef, forwardRef } from "react";
import { ReactElement, useCallback } from "react";
import { CloseIcon } from "../../theme/icons";
import { CtrlIconButton } from "./CtrlIconButton";
import { CtrlMaskInput, CtrlMaskInputProps } from "./CtrlMaskInput";
import { helperError } from "./elements/helperError";
import { requiredLabel } from "./elements/requiredLabel";
import { elementHasExectParent, phoneMask } from "./utils";

export interface CtrlTextFieldProps
    extends Omit<
        TextFieldProps,
        "value" | "onChange" | "endAdorment" | "width" | "error"
    > {
    value?: string;
    maskedValue?: string;
    onChange?: (value: string) => void;
    onChangeNative?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    endIcons?: ReactElement;
    width?: number | string;
    maskProps?: CtrlMaskInputProps;
    required?: boolean;
    error?: string | boolean;
    onClear?: () => boolean;
    hideClear?: boolean;
    autocomplete?: boolean;
    maxCharacters?: number;
    phoneFormat?: boolean;
    showClear?: boolean;
}

const CtrlTextFieldRef = forwardRef(function CtrlTextFieldComponent(
    props: CtrlTextFieldProps,
    ref?: ForwardedRef<HTMLDivElement>
) {
    const {
        value,
        maskedValue,
        onChange,
        onChangeNative,
        variant = "outlined",
        endIcons,
        maskProps,
        label,
        required,
        error,
        helperText,
        InputProps: propsInputProps,
        onClear,
        hideClear,
        autocomplete,
        maxCharacters,
        onBlur,
        phoneFormat,
        showClear,
        ...other
    } = props;

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (maxCharacters) {
                e.target.value = e.target.value.slice(0, maxCharacters);
            }

            onChange?.(e.target.value);
            onChangeNative?.(e);
        },
        [maxCharacters, onChange, onChangeNative]
    );

    const handleClear = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (!onClear?.()) {
                onChange?.("");
                onChangeNative?.({
                    target: {
                        value: "",
                        name: props.name,
                    },
                } as React.ChangeEvent<HTMLInputElement>);
            }
        },
        [onChange, onChangeNative, onClear, props.name]
    );

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            if (!maskedValue) {
                onChange?.(e.target.value?.trim());
                onChangeNative?.({
                    target: {
                        value: e.target.value?.trim(),
                        name: props.name,
                    },
                } as React.ChangeEvent<HTMLInputElement>);
            }

            if (!elementHasExectParent(e.relatedTarget, e.target.parentElement)) {
                onBlur?.(e);
            }
        },
        [maskedValue, onBlur, onChange, onChangeNative, props.name],
    );

    const handleKeyDown = useCallback(
        () => (event?: any) => (event?.key === 'Backspace' && maskedValue ? handleClear(event) : undefined),
        [handleClear, maskedValue],
    );

    let mask = maskProps;
    if(!mask && phoneFormat) {
        mask={
            mask: phoneMask
        }
    }

    return (
        <TextField
            ref={ref}
            className={props.className}
            value={value ?? ''}
            onChange={handleChange}
            variant={variant}
            placeholder={maskedValue}
            InputProps={{
                endAdornment: (
                    <React.Fragment>
                        <CtrlIconButton
                            title="Clear"
                            size="small"
                            className={clsx('ctrl-text-field-clear-button', {
                                'ctrl-text-field-has-value': value || maskedValue || showClear,
                            })}
                            onClick={handleClear}
                            tooltipFollowCursor
                            onFocus={(e: any) => e.target?.previousElementSibling?.focus?.()} // focuses input area
                            invisible={hideClear}
                            tabIndex={-1}
                        >
                            <CloseIcon />
                        </CtrlIconButton>
                        {Boolean(endIcons) && endIcons}
                    </React.Fragment>
                ),
                inputComponent: mask ? CtrlMaskInput : undefined,
                ...propsInputProps,
            }}
            inputProps={{
                autoComplete: autocomplete ?? 'off',
                ...(mask ?? {}),
                onKeyDown: handleKeyDown(),
            }}
            label={requiredLabel(required, label)}
            error={Boolean(error)}
            helperText={helperError(error) ?? helperText}
            onBlur={handleBlur}
            {...other}
        />
    );
});

export const CtrlTextField = styled(CtrlTextFieldRef, {
    name: 'CtrlTextField',
})(({ theme, width = '100%', endIcons, fullWidth, maskedValue }) => ({
    width: fullWidth ? '100%' : width,
    '.MuiSvgIcon-root': {
        color: theme.app.neutralColor.iconMain,
    },
    '& .MuiInputBase-adornedEnd.MuiOutlinedInput-root': {
        paddingRight: Boolean(endIcons) ? 6 : 0,
    },
    '.ctrl-text-field-clear-button': {
        display: 'none',
    },
    ':hover': {
        '& :not(.Mui-disabled) .ctrl-text-field-has-value': {
            display: 'flex',
            marginLeft: -8,
        },
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input::placeholder': {
        color: maskedValue ? theme.app.neutralColor.textDark : 'inherit',
        opacity: maskedValue ? '1 !important' : 'unset',
    },
}));
