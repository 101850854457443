import { styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { DefaultProps } from '../../common/types';
import { CtrlCircularProgress } from '../../components/controls/CtrlCircularProgress';
import { TGlobalState } from '../../model/state';
import clsx from 'clsx';

interface IProgressData<T = any> {
    promise: Promise<T>;
    label: string;
    id: number;
    icon?: ReactElement | null;
}

type IScreenLock = {};

const progressState = new TGlobalState<IProgressData[]>([]);
const notificationsState = new TGlobalState<IProgressData[]>([]);
const screenLocksState = new TGlobalState<IScreenLock[]>([]);

function closeProgress(item: IProgressData) {
    progressState.set((s) => s.filter((i) => i !== item));
}

function closeNotification(item: IProgressData) {
    notificationsState.set((s) => s.filter((i) => i !== item));
}

interface ProgressItemProps extends DefaultProps {
    item: IProgressData;
    isNotification: boolean;
}

function ProgressItemComponent(props: ProgressItemProps) {
    const { className, item, isNotification } = props;

    item.promise.finally(() => (isNotification ? closeNotification(item) : closeProgress(item)));

    return (
        <div className={className}>
            {item.icon !== null ? item.icon ?? <CtrlCircularProgress size={16} variant="white" /> : null}
            <Typography variant="body2">{item.label}</Typography>
        </div>
    );
}

const ProgressItem = styled(ProgressItemComponent, { name: 'ProgressItem' })(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    backgroundColor: theme.app.neutralColor.backgroundSuperDark,
    color: theme.app.neutralColor.textLight,
    borderRadius: 6,
    padding: theme.spacing(2, 3),
    position: 'fixed',
    top: 16,
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 9999,
    '& p': {
        color: theme.app.neutralColor.textLight,
    },
    '& > *': {
        marginRight: theme.spacing(2),
    },
}));

interface ProgressProps extends DefaultProps {}

function ProgressComponent(props: ProgressProps) {
    const { className } = props;
    const progress = progressState.use();
    const locks = screenLocksState.use();
    const notifications = notificationsState.use();

    return (
        <>
            {notifications.length ? (
                notifications
                    .slice(0, 1)
                    .map((item) => <ProgressItem item={item} key={item.id} isNotification={true} />)
            ) : progress.length || locks.length ? (
                <div className={clsx(className, { 'disable-interaction': locks.length })}>
                    {progress.slice(0, 1).map((item) => (
                        <ProgressItem item={item} key={item.id} isNotification={false} />
                    ))}
                </div>
            ) : null}
        </>
    );
}

export const Progress = styled(ProgressComponent, { name: 'Progress' })(({ theme }) => ({
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    '&.disable-interaction': {
        position: 'fixed',
        pointerEvents: 'unset',
        zIndex: 9998,
    },
}));

let genId: number = 0;
const getId = () => {
    if (genId > 100000) genId = 0;
    return ++genId;
};

export function showProgress<T = any>(promise: Promise<T>, label: string, icon?: ReactElement) {
    setTimeout(() => {
        progressState.set((s) => [...s, { promise, label, id: getId(), icon }]);
    }, 0);
}

export function notifyProgress(label: string, icon?: ReactElement | null, timeout: number = 2000) {
    notificationsState.set((s) => [
        ...s,
        {
            promise: new Promise((resolve) => setTimeout(resolve, timeout)),
            label,
            id: getId(),
            icon,
        },
    ]);
}

export function addScreenLock() {
    const newLock: IScreenLock = {};
    screenLocksState.set((s) => [...s, newLock]);
    return newLock;
}

export function removeScreenLock(lock: IScreenLock) {
    screenLocksState.set((s) => s.filter((i) => i !== lock));
}
