import {
    Accordion,
    AccordionDetails,
    AccordionDetailsProps,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    styled,
} from "@mui/material";
import { ChevronDownIcon } from "../../theme/icons";

export interface CtrlAccordionProps extends AccordionProps {}

export function CtrlAccordion(props: CtrlAccordionProps) {
    const { defaultExpanded, ...other } = props;

    return <Accordion {...other} defaultExpanded={defaultExpanded ?? true} />;
}

export interface CtrlAccordionSummaryProps extends AccordionSummaryProps {}

function CtrlAccordionSummaryComponent(props: CtrlAccordionSummaryProps) {
    const { expandIcon, ...other } = props;

    return (
        <AccordionSummary
            {...other}
            expandIcon={expandIcon ?? <ChevronDownIcon />}
        />
    );
}

export const CtrlAccordionSummary = styled(CtrlAccordionSummaryComponent, {
    name: "CtrlAccordionSummary",
})(({ theme }) => ({
    flexDirection: "row-reverse",
    columnGap: theme.spacing(2),
    "& .MuiAccordionSummary-content": {
        height: '100%'
    }
}));

export interface CtrlAccordionDetailsProps extends AccordionDetailsProps {}

export function CtrlAccordionDetails(props: CtrlAccordionDetailsProps) {
    return <AccordionDetails {...props} />;
}
