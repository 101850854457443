import { styled } from "@mui/material";
import { useCallback, useMemo } from "react";
import { DefaultProps, TOptionHint } from "../../common/types";
import { CtrlCheckbox } from "./CtrlCheckbox";
import { CtrlList } from "./CtrlList";
import { defaultOptionGetLabel, useFilteredOptions } from "./utils";

export interface CtrlListMultiselectProps<T = any> extends DefaultProps {
    options: readonly T[];
    selected?: readonly T[];
    setSelected?: (selected: T[]) => void;
    getLabel?: (value: T, index?: number) => string;
    withSelectAll?: boolean;
    searchString?: string;
    getOptionClass?: (value: T, index?: number) => string;
    getOptionDisabled?: (value: T, index?: number) => boolean;
    getOptionHint?: (value: T, index?: number) => TOptionHint;
    loading?: boolean;
}

function CtrlListMultiselectComponent<T = any>(
    props: CtrlListMultiselectProps<T>
) {
    const {
        options: propsOptions,
        selected,
        setSelected,
        getLabel: propsGetLabel,
        withSelectAll,
        searchString,
        getOptionClass,
        getOptionDisabled,
        getOptionHint,
        loading,
    } = props;

    const getLabel = useCallback(
        (o: T, idx?: number) => {
            return withSelectAll && idx === 0
                ? (o as string)
                : propsGetLabel
                ? propsGetLabel(o, idx)
                : defaultOptionGetLabel(o);
        },
        [propsGetLabel, withSelectAll]
    );

    const filteredOptions = useFilteredOptions(
        propsOptions,
        searchString,
        getLabel
    );

    const { options, selectedAll } = useMemo(() => {
        const selectedAll: boolean =
            Boolean(propsOptions.length) &&
            (propsOptions.length === selected?.length ?? 0);
        const options = withSelectAll
            ? (["Select all", ...filteredOptions] as T[])
            : filteredOptions;
        return { options, selectedAll };
    }, [filteredOptions, propsOptions.length, selected?.length, withSelectAll]);

    const getIcon = useCallback(
        (o: T, idx?: number) => {
            const checked =
                (idx === 0 && withSelectAll && selectedAll) ||
                (selected ? selected.includes(o) : false);
            return (
                <CtrlCheckbox
                    value={checked}
                    disabled={getOptionDisabled?.(o, idx)}
                />
            );
        },
        [getOptionDisabled, selected, selectedAll, withSelectAll]
    );

    const onClick = useCallback(
        (o: T, idx?: number) => {
            if (withSelectAll && idx === 0) {
                setSelected?.(selectedAll ? [] : [...propsOptions]);
            } else {
                if (selected?.includes(o)) {
                    setSelected?.(selected.filter((i) => i !== o));
                } else {
                    setSelected?.([...(selected ?? []), o]);
                }
            }
        },
        [propsOptions, selected, selectedAll, setSelected, withSelectAll]
    );

    return (
        <CtrlList
            options={options}
            getLabel={getLabel}
            getIcon={getIcon}
            onClick={onClick}
            getOptionClass={getOptionClass}
            getOptionDisabled={getOptionDisabled}
            getOptionHint={getOptionHint}
            loading={loading}
        />
    );
}

export const CtrlListMultiselect = styled(CtrlListMultiselectComponent, {
    name: "CtrlListMultiselect",
})(({ theme }) => ({})) as typeof CtrlListMultiselectComponent;
