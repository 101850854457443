import { ValidatedState } from "./types";

export function setStateToStorage<T extends ValidatedState>(key: string, state: T, rememberSectionData: boolean) {
    if(rememberSectionData){

        localStorage.setItem(key, JSON.stringify({ ...state, errors: [], warnings: [] }));
        return;
    }
    localStorage.removeItem(key);
}

export function getStateFromStorage<T extends ValidatedState>(key: string): T | null{
    const stateData = localStorage.getItem(key);

    if(!stateData){
        return null;
    }

    return JSON.parse(stateData);
}