import { ValidatedState } from "../../../../common/types"
import { TResourceValue, valuesManager } from "../../../../services/valuesManager/valuesManager";

export interface IDentalState extends ValidatedState {
    referralFor?: TResourceValue;
    referralForDescription?: string;
    visitPurpose?: TResourceValue;
    visitPurposeDescription?: string;
    howDidTheInjuryOccur?: string;
    dentistFirstName? : string;
    dentistLastName? : string;
    practiceName? : string;
    phone?: string;
    fax?: string;
    isOCwithProvider: boolean;
    previousTreatment?: string;
    isRush: boolean;
}

export const defaultDentalState: IDentalState = {
    referralFor: undefined,
    visitPurpose: valuesManager.resourceValues.dentalVisitPurposeValues[0],
    isOCwithProvider: false,
    isRush: false,
    errors: []
}