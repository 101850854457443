import React from 'react';
import { MainLayout } from './pages/MainLayout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecaptchaProvider } from './recaptcha/RecaptchaProvider';
import { InitializeApp } from './InitializeApp';
import { Dialogs } from './components/dialogs/Dialogs';
import { Progress } from './components/dialogs/Progress';

function App() {
    return (
        <RecaptchaProvider>
            <BrowserRouter>
                <InitializeApp>
                    <Routes>
                        <Route path="*" element={<MainLayout />} />
                    </Routes>
                </InitializeApp>
            </BrowserRouter>
            <Progress />
            <Dialogs />
        </RecaptchaProvider>
    );
}

export default App;
