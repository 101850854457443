import { useEffect } from 'react';
import { apiClient, easyReferralApiClient } from './api/order';
import { ApiClient } from './api/apiClient';
import APIJSON from './config/api.json';
import { valuesManager } from './services/valuesManager/valuesManager';
import { useBeforeUnload, useUrlCheck } from './pages/sections/utils';
import { referralModel } from './pages/ReferralModel';

export function InitializeApp({ children }: { children: any }) {
    const { mode, customization, queryParams } = useUrlCheck();
    useEffect(() => {
        apiClient.set(new ApiClient(APIJSON.api.path, easyReferralApiClient));
        referralModel.init({mode, customization, queryParams});
    }, [mode, customization, queryParams]);

    useEffect(() => {
        valuesManager.loadProductCategories();
    }, []);

    useBeforeUnload();

    return <>{children}</>;
}
