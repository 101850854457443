import { ViewProps } from '../../../../common/types';
import { Typography, styled } from '@mui/material';
import { theme } from '../../../../theme';
import { CtrlComboSelect } from '../../../../components/controls/CtrlComboSelect';
import { CtrlDateField } from '../../../../components/controls/CtrlDateField';
import { CtrlTextField } from '../../../../components/controls/CtrlTextField';
import { CtrlTextArea } from '../../../../components/controls/CtrlTextArea';
import { CtrlCheckbox } from '../../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../../ReferralModel';
import { valuesManager } from '../../../../services/valuesManager/valuesManager';
import { Address } from '../../Address';
import { CtrlButton } from '../../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../../theme/icons';
import { appointmentDateRange } from './LanguageModel';
import CustomRowsComboSelect, { TCustomRowsColum } from '../../../../components/controls/CustomRowsComboSelect';
import { isAnyAddressFieldPopulated } from '../../../../common/validationHelpers';

export interface LanguageProps extends ViewProps<ReferralModel> {}

const languageCertificationColumns: TCustomRowsColum[] = [
    { label: 'Certification', key: 'label', span: 4 },
    { label: 'Description', key: 'description', span: 8, withTooltip: 'description'},
];

function LanguageComponent(props: LanguageProps) {
    const { model } = props;
    const languageModel = model.languageModel;
    const state = languageModel.state.use();

    const addressPopulated = isAnyAddressFieldPopulated(state.address);

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect 
                        label="Type of Appointment" 
                        required
                        value={state.appoinmentType} 
                        selectFrom={valuesManager.resourceValues.appointmentTypes}
                        onChange={languageModel.handleAppoinmentTypeChange}
                        error={languageModel.getErrorMessage('appoinmentType.value')}
                    />
                </div>
                <div className="span-3">
                    <CtrlDateField
                        label="Appointment Date"
                        value={state.appointmentDate}
                        onChange={(value) => {
                            languageModel.handleControlChange('appointmentDate')(value);
                            if (!value) {
                                languageModel.handleControlChange('appointmentTime')(null);
                            }
                        }}
                        error={languageModel.getErrorMessage('appointmentDate')}
                        onTextChange={languageModel.handleControlChange('appointmentDateString')}
                        minDate={appointmentDateRange().from}
                        maxDate={appointmentDateRange().to}
                        mode="date"
                        required
                    />
                </div>
                <div className="span-3">
                    <CtrlDateField
                        label="Appointment Time"
                        value={state.appointmentTime}
                        onChange={languageModel.handleControlChange('appointmentTime')}
                        onTextChange={languageModel.handleControlChange('appointmentTimeString')}
                        error={languageModel.getErrorMessage('appointmentTimeString')}
                        mode="time"
                        disabled={state.appointmentDate == null}
                    />
                </div>                
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CustomRowsComboSelect
                        label="Language Certification"
                        value={state.languageCertification}
                        onChange={languageModel.handleLanguageCertificationChange}
                        selectFrom={valuesManager.resourceValues.languageCertifications}
                        onClose={() => languageModel.handleControlChange('languageCertification')}
                        columns={languageCertificationColumns}
                        error={languageModel.getErrorMessage("languageCertification.value")}
                        required
                    />
                </div>
                <div className="span-6">
                    <CtrlComboSelect 
                        label="Language"
                        value={state.language}
                        onChange={languageModel.handleLanguageChange}
                        error={languageModel.getErrorMessage('language.value')}
                        selectFrom={valuesManager.resourceValues.languages} 
                        searchable={true}
                        required
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Facility Phone Number"
                        value={state.phone}
                        onChange={languageModel.handleControlChange('phone')}
                        error={languageModel.getErrorMessage('phone')}
                        phoneFormat
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField 
                        label="Doctor Name (Applied to Medical Appointments)"
                        value={state.doctorName}
                        onChange={languageModel.handleControlChange('doctorName')}
                        name="doctorName"
                        maxCharacters={100} 
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        Facility Address
                    </Typography>
                </div>
            </div>
            <Address
                    values={state.address}
                    handleChange={languageModel.handleControlChange}
                    getErrorMessage={languageModel.getErrorMessage}
                    requiredFields={addressPopulated ? ['address1', 'city', 'state', 'zip'] : []}
            />
            <div className="fields-container">
                <div className="span-12">
                    <CtrlTextArea
                        label="Additional Instructions"
                        maxCharacters={1000}
                        style={{ minHeight: 84 }}
                        onChange={languageModel.handleControlChange('additionalInstructions')}
                        value={state.additionalInstructions}
                        name="additionalInstructions"
                    />
                </div>
            </div>
            <div className="is-rush-row">
                <div className="span-12">
                    <CtrlCheckbox
                        label="Rush"
                        value={state.isRush}
                        onChange={languageModel.handleControlChange('isRush')}
                    />
                </div>
            </div>
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection('LanguageTranslation', languageModel);
                        model.validateSection('Attachments', model.attachmentsModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const Language = styled(LanguageComponent, { name: 'Language' })(({ theme }) => ({
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
}));
