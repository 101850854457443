import { TAddress, ValidatedState } from "../../../common/types"

export interface IEmployerState extends ValidatedState {
    company?: string,
    phone?: string,
    address: TAddress,
}

export const defaultEmployerState: IEmployerState = {
    company: undefined,
    phone: undefined,
    address: {
        address1: undefined,
        address2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
    },
    errors: []
}