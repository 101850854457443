import { styled } from '@mui/material';
import { ViewProps } from '../../../common/types';
import { CtrlCheckbox } from '../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../ReferralModel';

export interface OtherPartiesSummaryProps extends ViewProps<ReferralModel> {}

function OtherPartiesSummaryComponent(props: OtherPartiesSummaryProps) {
    const { model } = props;
    const otherPartiesModel = model.otherPartiesModel;
    const state = otherPartiesModel.state.use();
    model.state.use();
    model.submitterModel.state.use();

    const removeCellAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
    };

    return (
        <div className={props.className}>
            <div className="cell" onClick={removeCellAction}>
                <div>
                    <CtrlCheckbox
                        label="Referring Physician"
                        value={state.referringPhysicianSelected || otherPartiesModel.physicianSelected()}
                        disabled={otherPartiesModel.physicianSelected()}
                        onChange={(value) => {
                            otherPartiesModel.handleControlChange('referringPhysicianSelected')(value);
                            model.setSectionVisible('ReferringPhysician');
                        }}
                    />
                </div>
            </div>
            <div className="cell" onClick={removeCellAction}>
                <div>
                    <CtrlCheckbox
                        label="Case Manager"
                        value={state.caseManagerSelected || otherPartiesModel.caseManagerSelected()}
                        disabled={otherPartiesModel.caseManagerSelected()}
                        onChange={(value) => {
                            otherPartiesModel.handleControlChange('caseManagerSelected')(value);
                            model.setSectionVisible('NurseCaseManager');
                        }}
                    />
                </div>
            </div>
            <div className="cell" onClick={removeCellAction}>
                <div>
                    <CtrlCheckbox
                        label="Attorney"
                        value={state.attorneySelected || otherPartiesModel.attorneySelected()}
                        disabled={otherPartiesModel.attorneySelected()}
                        onChange={(value) => {
                            otherPartiesModel.handleControlChange('attorneySelected')(value);
                            model.setSectionVisible('Attorney');
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export const OtherPartiesSummary = styled(OtherPartiesSummaryComponent, { name: 'OtherPartiesSummary' })(({ theme }) => ({
    minHeight: 60,
    justifyContent: 'space-evenly',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    width: '100%',
    padding: theme.spacing(3),
    '.cell': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        justifyContent: 'center',
        overflow: 'hidden',
        padding: theme.spacing(0, 1),
    }
}));
