import { Typography, styled } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { DefaultProps } from '../../common/types';
import { CtrlButton } from './CtrlButton';
import { UploadIcon } from '../../theme/icons';
import clsx from 'clsx';
import { helperError } from './elements/helperError';

export interface CtrlFileUploadAreaProps extends DefaultProps {
    onAddFiles: (files: File[]) => void;
}

const supportedExtensions = [
    "bmp", "doc", "docx", "gif", "jpeg", "jpg", "pdf", "png", "tif", "tiff", "xls", "xlsx"
]

const unsupportedExtension = (ext: string) => `File type ".${ext}" is not supported.`;

function CtrlFileUploadAreaComponent(props: CtrlFileUploadAreaProps) {
    const { onAddFiles } = props;
    const [dragActive, setDragActive] = useState(false);
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [extensionError, setExtensionError] = useState("");

    const handleDrag = useCallback((e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    }, []);

    const doAddFiles = useCallback((files: File[]) => {
        const invalidFile = files.find(file => !supportedExtensions.includes(file.name.split('.').pop()?.toLowerCase() ?? ""))
        if (invalidFile) {
            setExtensionError(unsupportedExtension(invalidFile.name.split('.').pop()?.toLowerCase() ?? ""))
        } else {
            setExtensionError("");
            onAddFiles(files);
        }
    }, [onAddFiles])

    const handleDrop = useCallback(
        (e: React.DragEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files?.[0]) {
                doAddFiles([...e.dataTransfer.files]);
            }
        },
        [doAddFiles],
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event?.target?.files) {
                doAddFiles([...event.target.files]);
                event.target.value = "";
            }
        },
        [doAddFiles],
    );

    const onButtonClick = useCallback(() => {
        inputFileRef?.current && inputFileRef.current.click();
    }, []);

    return (
        <div className={props.className}>
            <form
                className={clsx('form', { 'drag-active': dragActive })}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                onSubmit={(e) => e.preventDefault()}
            >
                <input 
                    ref={inputFileRef} 
                    type="file" 
                    accept={`${supportedExtensions.map(ext => `.${ext}`).join(",")}`}
                    multiple
                    onChange={handleChange} 
                    hidden 
                />
                <div className={'container'}>
                    <div className="icon">
                        <UploadIcon />
                    </div>
                    <Typography variant="body2" className="label">
                        <CtrlButton className="button" size="small" onClick={onButtonClick}>
                            Click to upload
                        </CtrlButton>
                        or drag and drop
                    </Typography>
                    <Typography variant="body1" className="extensions">
                        {supportedExtensions.join(", ")}
                    </Typography>
                    {Boolean(extensionError) && helperError(extensionError)}
                </div>
            </form>
        </div>
    );
}

export const CtrlFileUploadArea = styled(CtrlFileUploadAreaComponent, {
    name: 'CtrlFileUploadArea',
})(({ theme }) => ({
    margin: 0,
    width: "100%",
    '.form': {
        display: 'flex',
        justifyContent: 'center',
        height: 120,
        borderRadius: 8,
        padding: theme.spacing(4),
        border: `1px solid ${theme.app.neutralColor.borderMain}`,
        background: theme.app.neutralColor.backgroundSuperLight,
    },
    '.container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    '.drag-active': {
        border: `2px dashed ${theme.app.neutralColor.borderMain}`,
    },
    '.icon': {
        display: 'flex',
        width: 40,
        height: 40,
        padding: 10,
        borderRadius: '50%',
        border: `6px solid ${theme.app.neutralColor.backgroundSuperLight}`,
        background: theme.app.neutralColor.backgroundLight,
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.label': {
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        marginTop: 'auto',
        color: theme.app.neutralColor.textMain,
    },
    '.button': {
        fontSize: 14,
    },
    '.extensions': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.app.neutralColor.textMain,
    },
}));

