import { Typography, styled } from "@mui/material";
import clsx from "clsx";
import { ReactElement, useCallback } from "react";
import { helperError } from "./elements/helperError";
import { requiredLabel } from "./elements/requiredLabel";

export interface CtrlTextAreaProps
    extends Omit<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        "value" | "onChange"
    > {
    value?: string;
    onChange?: (value: string) => void;
    onChangeNative?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    label?: string | ReactElement;
    error?: string | boolean;
    width?: number | string;
    height?: number | string;
    resize?: "both" | "vertical" | "horizontal" | "none";
    maxCharacters?: number;
    doNotTruncateText?: boolean;
}

function CtrlTextAreaComponent(props: CtrlTextAreaProps) {
    const {
        value,
        onChange,
        onChangeNative,
        spellCheck = false,
        label,
        className,
        required,
        error,
        width,
        height,
        resize,
        maxCharacters,
        doNotTruncateText,
        ...other
    } = props;

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            let value = e.target.value;
            
            if(maxCharacters){
                if(doNotTruncateText && (props.value?.length ?? -1)> maxCharacters){
                    value = e.target.value.length <= (props.value?.length ?? -1) 
                        ? e.target.value
                        : props.value ?? '';
                }
                else{
                    value = e.target.value.slice(0, maxCharacters);
                }
            }

            onChange?.(value ?? '');
            onChangeNative?.(e);
        },
        [doNotTruncateText, maxCharacters, props.value, onChange, onChangeNative]
    );

    const errorText = helperError(error);

    return (
        <div className={clsx(className, { "Mui-error": error })}>
            {label && (
                <div className="text-area-label">
                    {requiredLabel(required, label)}
                </div>
            )}
            <textarea
                className="text-area"
                value={value ?? ""}
                onChange={handleChange}
                {...other}
                spellCheck={spellCheck}
            />
            {errorText && <div className="error-wraper">{errorText}</div>}
            {maxCharacters && (
                <Typography variant="body1" className="text-area-chars-counter">
                    {maxCharacters - (value ?? "").length}/{maxCharacters}{" "}
                    characters remaining
                </Typography>
            )}
        </div>
    );
}

export const CtrlTextArea = styled(CtrlTextAreaComponent, {
    name: "CtrlTextArea",
})(({ theme, width = "100%", height, resize, disabled }) => ({
    position: "relative",
    display: "inline-flex",
    flexDirection: "column",
    width,
    ".text-area-label": {
        position: "absolute",
        left: 0,
        top: -18,
        color: disabled
            ? theme.app.neutralColor.textDisabled
            : theme.app.neutralColor.textMain,
        fontSize: 12,
        fontWeight: 400,
    },
    ".text-area": {
        color: theme.app.neutralColor.textDark,
        backgroundColor: theme.app.neutralColor.backgroundSuperLight,
        borderRadius: 6,
        border: `solid 1px ${theme.app.neutralColor.borderMain}`,
        fontSize: 12,
        fontWeight: 400,
        fontFamily: "Open Sans",
        resize: resize ?? (width ? "vertical" : undefined),
        height,
        padding: theme.spacing(2),
        ":active": {
            borderColor: theme.app.accentColor.borderMain,
            outline: `solid 2px ${theme.app.accentColor.backgroundLight}`,
        },
        ":focus": {
            borderColor: theme.app.accentColor.borderMain,
            outline: `solid 2px ${theme.app.accentColor.backgroundLight}`,
        },
        ":disabled": {
            color: theme.app.neutralColor.textDefault,
            borderColor: theme.app.neutralColor.borderDisabled,
            ":active": {
                outline: "none",
            },
        },
    },
    ".text-area-chars-counter": {
        color: theme.app.neutralColor.textDefault,
        alignSelf: "flex-end",
    },
    ".error-wraper": {
        width: "100%",
    },
    "&.Mui-error": {
        "& .text-area": {
            borderColor: theme.app.statusColor.errorMain,
        },
    },
}));
