import { styled } from '@mui/material';
import { ValidationError, ViewProps } from '../../common/types';
import { ChangeableModel } from '../../model/model';
import { CtrlIconButton } from '../../components/controls/CtrlIconButton';
import clsx from 'clsx';
import { WarningIcon } from '../../theme/icons';

export interface SectionIconProps<T extends ChangeableModel<any>> extends ViewProps<T> {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const hasMessages = (value: ValidationError) => value.errors?.length && value.errors.length > 0;

function SectionIconComponent<T extends ChangeableModel<any>>(props: SectionIconProps<T>) {
    const { model, onClick } = props;
    const { errors, warnings } = model.state.use();

    const hasErrors = errors.some(hasMessages);
    const hasWarnings = warnings?.some(hasMessages);

    return (
        <div className={props.className}>
            {(hasErrors || hasWarnings) && (
                <CtrlIconButton size="small" onClick={onClick}>
                    <WarningIcon className={clsx('icon', `${hasErrors ? 'isError' : 'isWarning'}`)} />
                </CtrlIconButton>
            )}
        </div>
    );
}

export const SectionIcon = styled(SectionIconComponent, { name: 'SectionIcon' })(({ theme }) => ({
    position: 'absolute',
    left: '65%',
    '.icon': {
        '&.isWarning': {
            color: theme.app.statusColor.warningMain,
        },
        '&.isError': {
            color: theme.app.statusColor.errorMain,
        },
    },
}));
