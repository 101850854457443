import _ from "lodash";
import { ValidatedState } from "../../../../common/types"
import { TResourceValue } from "../../../../services/valuesManager/valuesManager";

export interface IProcedure {
    id: string;
    product?: TResourceValue;
    bodyPart?: TResourceValue;
    bodySide?: TResourceValue;
    procedureOption?: TResourceValue;
    otherProcedure?: TResourceValue;
    ruleOut1?: TResourceValue;
    ruleOut2?: TResourceValue;

    bodyParts?: TResourceValue[];
    bodySides?: TResourceValue[];
    procedureOptions?: TResourceValue[];
    ruleOuts1?: TResourceValue[];
    ruleOuts2?: TResourceValue[];
    diagnosticProcedures?: TResourceValue[];
}

export interface IDiagnosticState extends ValidatedState {
    procedures: IProcedure[];
    products?: TResourceValue[];
    isRush: boolean;
    physicianFollowUpDate?: Date;
    physicianFollowUpDateString?: string;
    filmsRequested: boolean;
    cdsRequested: boolean;
    productsLoaded: boolean;
}

export const defaultDiagnosticState: IDiagnosticState = {
    procedures: [{ id: _.uniqueId() }],
    isRush: false,
    filmsRequested: false,
    cdsRequested: false,
    productsLoaded: false,
    errors: [],
};