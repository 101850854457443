import { SectionSystemCode, ValidatedState } from "../../../common/types";

export interface IAttachment {
    id: number;
    name: string;
    size: number;
    sizeError?: string;
    file: File;
    attachmentType: string;
    description: string;
    descriptionError?: string;
    serviceCodes: SectionSystemCode[];
    serviceCodesError?: string;
    orderRequestDocumentId?: string;
}

export interface IAttachmentsState extends ValidatedState {
    attachments: IAttachment[];
}

export const defaultAttachmentsState: IAttachmentsState = {
    errors: [],
    attachments: [],
}