import {
    requiredIfAddressPopulated,
    validateEmail,
    validateOptionExists,
    validatePhoneNumber,
    validateRequiredField,
    validateZipCode,
} from '../../../common/validationHelpers';
import { ChangeableModel } from '../../../model/model';
import { valuesManager } from '../../../services/valuesManager/valuesManager';
import { ReferralModel, referralModel } from '../../ReferralModel';
import { ISubmitterState, OtherSubmitterType } from '../submitter/SubmitterState';
import { setSubmitterFields } from '../utils';
import { IAttorneyState } from './AttorneyState';

export const attorneySubmitterTypes: (OtherSubmitterType | undefined)[] = ['Attorney', 'AttorneyOffice'];

const requiredForSubmitter = (value: any, referralModel: ReferralModel) => {
    return attorneySubmitterTypes.includes(referralModel.submitterModel.state.get().otherSubmitterType)
        ? validateRequiredField(value)
        : null;
};

const requiredIfSectionHasContent = (value: any, referralModel: ReferralModel) => {
    return referralModel.attorneyModel.isAnyFieldPopulated()
        ? validateRequiredField(value)
        : null;
}

const getAddress = () => referralModel.attorneyModel.state.get().address

export const attorneyValidators = [
    { fieldName: 'firstName', fieldLabel: 'First Name', validateFunctions: [requiredForSubmitter, requiredIfSectionHasContent] },
    { fieldName: 'lastName', fieldLabel: 'Last Name', validateFunctions: [requiredForSubmitter, requiredIfSectionHasContent] },
    { fieldName: 'phone', fieldLabel: 'Phone', validateFunctions: [requiredForSubmitter, validatePhoneNumber] },
    { fieldName: 'cellPhone', fieldLabel: 'Cell Phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'email', fieldLabel: 'Email', validateFunctions: [validateEmail] },
    { fieldName: 'address.address1', fieldLabel: 'Address 1', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.state.value', fieldLabel: 'State', validateFunctions: [requiredIfAddressPopulated(getAddress), validateOptionExists(valuesManager.resourceValues.states)] },
    { fieldName: 'address.zip', fieldLabel: 'Zip code', validateFunctions: [validateZipCode, requiredIfAddressPopulated(getAddress)] },
];

export class AttorneyModel extends ChangeableModel<IAttorneyState> {
    copySubmitterFields = (submitter: ISubmitterState) => {
        if (submitter.otherSubmitterType === 'Attorney') {
            const fields = {
                firstName: submitter.firstName,
                lastName: submitter.lastName,
                phone: submitter.mainPhone,
                ext: submitter.ext,
                cellPhone: submitter.cellPhone,
                email: submitter.email,
            };

            this.state.update((state) => {
                setSubmitterFields(fields, state);
            });
        }
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([
            ['Name', [state.firstName, state.lastName].filter((v) => v).join(' ')],
            ['Phone', [state.phone, state.ext ? `ext.: ${state.ext}` : null].filter((v) => v).join(' ')],
            ['Email', state.email],
        ]);
    };

    isAnyFieldPopulated = () => {
        const state = this.state.get();

        return Boolean(state.firstName
            || state.lastName
            || state.phone
            || state.ext
            || state.cellPhone
            || state.email
            || state.address?.address1
            || state.address?.address2
            || state.address?.city
            || state.address?.state
            || state.address?.zip);
    }
}
