import { ValidatedState } from "../../../common/types";

export interface IAdjusterState extends ValidatedState {
    firstName?: string;
    lastName?: string;
    phone?: string;
    ext?: string;
    cellPhone?: string;
    fax?: string;
    email?: string;
    rememberSectionData: boolean;
}

export const defaultAdjusterState: IAdjusterState = {
    errors: [],
    rememberSectionData: false,
}