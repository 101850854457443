import { TAddress, ValidatedState } from "../../../common/types"

export interface IAttorneyState extends ValidatedState {
    firstName?: string;
    lastName?: string;
    phone?: string,
    ext?: string;
    cellPhone?: string;
    email?: string;
    address: TAddress,
    rememberSectionData: boolean
}

export const defaultAttorneyState: IAttorneyState = {
    address: {},
    errors: [],
    rememberSectionData: false
}