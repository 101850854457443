import { getStateFromStorage, setStateToStorage } from '../common/localStorageUtils';
import { SectionSystemCode, ValidatedState } from '../common/types';
import { ChangeableModel, TModel } from '../model/model';
import { IState, TGlobalState } from '../model/state';
import { IReferralState, referralState } from './ReferralState';
import { CaseManagerModel, caseManagerValidators } from './sections/caseManager/CaseManagerModel';
import { defaultCaseManagerState } from './sections/caseManager/CaseManagerState';
import { AttorneyModel, attorneyValidators } from './sections/attorney/AttorneyModel';
import { defaultAttorneyState } from './sections/attorney/AttorneyState';
import { ClaimPatientModel, ISubmitterEntity, claimPatientValidators } from './sections/claimPatient/ClaimPatientModel';
import { defaultClaimPatientState } from './sections/claimPatient/ClaimPatientState';
import { EmployerModel, employerValidators } from './sections/employer/EmployerModel';
import { defaultEmployerState } from './sections/employer/EmployerState';
import { InsuranceModel, insuranceValidators } from './sections/insurance/InsuranceModel';
import { defaultInsuranceState } from './sections/insurance/InsuranceState';
import { OtherPartiesModel } from './sections/otherParties/OtherPartiesModel';
import { defaultOtherPartiesState } from './sections/otherParties/OtherPartiesState';
import {
    ReferringPhysicianModel,
    referringPhysicianValidators,
} from './sections/referringPhysician/ReferringPhysicianModel';
import { defaultReferringPhysicianState } from './sections/referringPhysician/ReferringPhysicianState';
import {
    PhysicalTherapyModel,
    physicalTherapyValidators,
} from './sections/serviceLines/physicalTherapy/PhysicalTherapyModel';
import { defaultPhysicalTherapyState } from './sections/serviceLines/physicalTherapy/PhysicalTherapyState';
import { SubmitterModel, submitterValidators } from './sections/submitter/SubmitterModel';
import { OtherSubmitterType, SubmitterType, defaultSubmitterState } from './sections/submitter/SubmitterState';
import { DiagnosticModel } from './sections/serviceLines/diagnostic/DiagnosticModel';
import { defaultDiagnosticState } from './sections/serviceLines/diagnostic/DiagnosticState';
import { AdjusterModel, adjusterValidators } from './sections/adjuster/AdjusterModel';
import { defaultAdjusterState } from './sections/adjuster/AdjusterState';
import { TransportationModel } from './sections/serviceLines/transportation/TransportationModel';
import { defaultTransportationState } from './sections/serviceLines/transportation/TransportationState';
import {
    HomeHealthComplexCareModel,
    homeHealthComplexCareValidators,
} from './sections/serviceLines/homeHealthComplexCare/HomeHealthComplexCareModel';
import { defaultHomeHealthComplexCareState } from './sections/serviceLines/homeHealthComplexCare/HomeHealthComplexCareState';
import {
    DurableMedicalEquipmentModel,
    durableMedicalEquipmentModelValidators,
} from './sections/serviceLines/durableMedicalEquipment/DurableMedicalEquipmentModel';
import { defaultDurableMedicalEquipmentState } from './sections/serviceLines/durableMedicalEquipment/DurableMedicalEquipmentState';
import { AttachmentsModel, attachmentsValidators } from './sections/attachments/AttachmentsModel';
import { defaultAttachmentsState } from './sections/attachments/AttachmentsState';
import {
    SpecialInstructionsModel,
    specialInstructionsValidators,
} from './sections/specialInstructions/SpecialInstructionsModel';
import { defaultSpecialInstructionsState } from './sections/specialInstructions/SpecialInstructionsState';
import { LanguageModel, languageValidators } from './sections/serviceLines/language/LanguageModel';
import { defaultLanguageState } from './sections/serviceLines/language/LanguageState';
import { DoctorModel, doctorValidators } from './sections/serviceLines/doctor/DoctorModel';
import { defaultDoctorState } from './sections/serviceLines/doctor/DoctorState';
import { DentalModel, dentalValidators } from './sections/serviceLines/dental/DentalModel';
import { defaultDentalState } from './sections/serviceLines/dental/DentalState';
import { TPortalCustomization, TPortalMode, removeBeforeUnload } from './sections/utils';
import { ERROR_MESSAGE } from '../common/validationHelpers';
import { showConfirmationDialog } from '../components/dialogs/ConfirmationDialog';
import { addScreenLock, removeScreenLock, showProgress } from '../components/dialogs/Progress';
import { SubmittedOrderRequestResponse, getContextData, submitReferral, uploadAttachments } from '../api/order';
import { showConfirmationSummaryDialog } from '../components/dialogs/ConfirmationSummaryDialog';
import { buildOrderPayload } from '../api/payloadBuilder';
import { MriPrescreeningModel } from './sections/mriPrescreening/MriPrescreeningModel';
import { defaultMriPrescreeningState } from './sections/mriPrescreening/MriPrescreeningState';
import { preselectedProductsMap } from './sections/serviceLines/transportation/constants';
import { Order } from '../api/types/claim';
import _ from 'lodash';

type InitParams = {
    mode: TPortalMode,
    customization: TPortalCustomization,
    queryParams: Record<string, string>,
}

export class ReferralModel extends TModel<IReferralState> {
    sectionModels = new Map<SectionSystemCode, ChangeableModel<any>>();

    employerModel: EmployerModel;
    submitterModel: SubmitterModel;
    claimPatientModel: ClaimPatientModel;
    otherPartiesModel: OtherPartiesModel;
    referringPhysicianModel: ReferringPhysicianModel;
    insuranceModel: InsuranceModel;
    attorneyModel: AttorneyModel;
    caseManagerModel: CaseManagerModel;
    prescreeningQuestionsModel: MriPrescreeningModel;

    languageModel: LanguageModel;
    physicalTherapyModel: PhysicalTherapyModel;
    diagnosticModel: DiagnosticModel;
    transportationModel: TransportationModel;
    adjusterModel: AdjusterModel;
    homeHealthComplexCareModel: HomeHealthComplexCareModel;
    durableMedicalEquipmentModel: DurableMedicalEquipmentModel;
    attachmentsModel: AttachmentsModel;
    specialInstructionsModel: SpecialInstructionsModel;
    doctorModel: DoctorModel;
    dentalModel: DentalModel;

    constructor(modelState: IState<IReferralState>) {
        super(modelState);

        this.employerModel = new EmployerModel({
            modelState: new TGlobalState(defaultEmployerState),
            validators: employerValidators,
        });
        this.sectionModels.set('Employer', this.employerModel);

        this.submitterModel = new SubmitterModel({
            modelState: new TGlobalState(defaultSubmitterState),
            validators: submitterValidators,
        });
        this.sectionModels.set('Submitter', this.submitterModel);

        this.physicalTherapyModel = new PhysicalTherapyModel({
            modelState: new TGlobalState(defaultPhysicalTherapyState),
            validators: physicalTherapyValidators,
        });
        this.sectionModels.set('PhysicalMedicine', this.physicalTherapyModel);

        this.claimPatientModel = new ClaimPatientModel({
            modelState: new TGlobalState(defaultClaimPatientState),
            validators: claimPatientValidators,
        });
        this.sectionModels.set('ClaimPatient', this.claimPatientModel);

        this.otherPartiesModel = new OtherPartiesModel({
            modelState: new TGlobalState(defaultOtherPartiesState),
            validators: [],
        });
        this.sectionModels.set('OtherParties', this.otherPartiesModel);

        this.referringPhysicianModel = new ReferringPhysicianModel({
            modelState: new TGlobalState(defaultReferringPhysicianState),
            validators: referringPhysicianValidators,
        });
        this.sectionModels.set('ReferringPhysician', this.referringPhysicianModel);

        this.insuranceModel = new InsuranceModel({
            modelState: new TGlobalState(defaultInsuranceState),
            validators: insuranceValidators,
        });
        this.sectionModels.set('Insurance', this.insuranceModel);

        this.caseManagerModel = new CaseManagerModel({
            modelState: new TGlobalState(defaultCaseManagerState),
            validators: caseManagerValidators,
        });
        this.sectionModels.set('NurseCaseManager', this.caseManagerModel);

        this.attorneyModel = new AttorneyModel({
            modelState: new TGlobalState(defaultAttorneyState),
            validators: attorneyValidators,
        });
        this.sectionModels.set('Attorney', this.attorneyModel);

        this.diagnosticModel = new DiagnosticModel({
            modelState: new TGlobalState(defaultDiagnosticState),
            validators: [],
        });
        this.sectionModels.set('DiagnosticServices', this.diagnosticModel);

        this.transportationModel = new TransportationModel({
            modelState: new TGlobalState(defaultTransportationState),
            validators: [],
        });
        this.sectionModels.set('TransportationServices', this.transportationModel);

        this.languageModel = new LanguageModel({
            modelState: new TGlobalState(defaultLanguageState),
            validators: languageValidators,
        });
        this.sectionModels.set('LanguageTranslation', this.languageModel);

        this.adjusterModel = new AdjusterModel({
            modelState: new TGlobalState(defaultAdjusterState),
            validators: adjusterValidators,
        });
        this.sectionModels.set('Adjuster', this.adjusterModel);

        this.attachmentsModel = new AttachmentsModel({
            modelState: new TGlobalState(defaultAttachmentsState),
            validators: attachmentsValidators,
        });
        this.sectionModels.set('Attachments', this.attachmentsModel);

        this.prescreeningQuestionsModel = new MriPrescreeningModel({
            modelState: new TGlobalState(defaultMriPrescreeningState),
            validators: [],
        });
        this.sectionModels.set('PrescreeningQuestions', this.prescreeningQuestionsModel);

        this.homeHealthComplexCareModel = new HomeHealthComplexCareModel({
            modelState: new TGlobalState(defaultHomeHealthComplexCareState),
            validators: homeHealthComplexCareValidators,
        });
        this.sectionModels.set('HomeHealthCare', this.homeHealthComplexCareModel);

        this.durableMedicalEquipmentModel = new DurableMedicalEquipmentModel({
            modelState: new TGlobalState(defaultDurableMedicalEquipmentState),
            validators: durableMedicalEquipmentModelValidators,
        });
        this.sectionModels.set('DurableMedicalEquipment', this.durableMedicalEquipmentModel);

        this.specialInstructionsModel = new SpecialInstructionsModel({
            modelState: new TGlobalState(defaultSpecialInstructionsState),
            validators: specialInstructionsValidators,
        });
        this.sectionModels.set('SpecialInstructions', this.specialInstructionsModel);

        this.doctorModel = new DoctorModel({
            modelState: new TGlobalState(defaultDoctorState),
            validators: doctorValidators,
        });
        this.sectionModels.set('dsDoctorServices', this.doctorModel);

        this.dentalModel = new DentalModel({
            modelState: new TGlobalState(defaultDentalState),
            validators: dentalValidators,
        });
        this.sectionModels.set('dsDentalServices', this.dentalModel);

        this.setSectionVisible('Submitter', true);
    }

    init = async ({ mode, customization, queryParams }: InitParams) => {
        this.state.update((s) => {
            s.mode = mode;
            s.customization = customization;
            s.queryParams = queryParams;
        });

        queryParams['token']
            ? await this.initModelsStateFromContextData(queryParams['token'], queryParams['userIP'])
            : this.initModelsStateFromStorage();

        if (mode === 'EZAuth') {
            this.submitterModel.state.update((s) => {
                s.submitters = s.submitters.filter(
                    (s) => s.value === 'ReferringPhysician' || s.value === 'ReferringPhysicianOffice',
                );
                if (s.submitterType !== 'ReferringPhysician' && s.submitterType !== 'ReferringPhysicianOffice') {
                    s.submitterType = s.submitters[0].value as SubmitterType;
                }
                s.otherSubmitterType = undefined;
            });
            this.setSectionVisible('ReferringPhysician');
            document.title = 'One Call: Physician Services';
        }

        this.initGmCustomization();
        this.initNavigereCustomization();
        this.initPreselectedProducts();
    };

    initGmCustomization = () => {
        if (this.state.get().customization !== 'GM') {
            return;
        }

        const gmSections = new Map<SectionSystemCode, boolean>([
            ['PrescreeningQuestions', true],
            ['Employer', false],
            ['Insurance', false],
            ['Adjuster', false],
            ['NurseCaseManager', false],
            ['Attorney', false],
            ['ReferringPhysician', true],
            ['OtherParties', false],
            ['DiagnosticServices', true],
        ]);
        gmSections.forEach((s, v) => this.setSectionVisible(v, s));

        this.setServiceSelected('DiagnosticServices', true);

        this.specialInstructionsModel.state.update((s) => {
            s.serviceCodes = ['DiagnosticServices'];
        });

        this.submitterModel.state.update((s) => {
            s.submitterType = 'ReferringPhysician';
            s.companyName = '';
        });
    };

    initPreselectedProducts = () => {
        preselectedProductsMap.forEach((s, c) => {
            if (this.state.get().customization === c) {
                this.setServiceSelected(s, true);
                this.setSectionVisible(s, true);
                this.specialInstructionsModel.state.update((state) => (state.serviceCodes = [s]));
            }
        });
    };

    initNavigereCustomization = () => {
        if (this.state.get().customization !== 'Navigere') {
            return;
        }

        this.setSectionVisible('DiagnosticServices', true);
        this.setServiceSelected('DiagnosticServices', true);

        this.specialInstructionsModel.state.update((s) => {
            s.serviceCodes = ['DiagnosticServices'];
        });
    };

    setSectionVisible = (sectionSystemCode?: SectionSystemCode, visible?: boolean) => {
        this.state.update((state) => {
            const submitterState = this.submitterModel.state.get();
            const otherPartiesState = this.otherPartiesModel.state.get();

            const section = state.sections.find((s) => s.systemCode === sectionSystemCode);
            if (section) {
                section.visible = visible === undefined ? !section.visible : visible;
            }

            const referringPhysicianSection = state.sections.find((s) => s.systemCode === 'ReferringPhysician');
            if (referringPhysicianSection) {
                referringPhysicianSection.visible =
                    state.sections.find((s) => s.systemCode === 'PhysicalMedicine')?.visible ||
                    state.sections.find((s) => s.systemCode === 'DiagnosticServices')?.visible ||
                    submitterState.submitterType === 'ReferringPhysician' ||
                    submitterState.submitterType === 'ReferringPhysicianOffice' ||
                    otherPartiesState.referringPhysicianSelected;
            }

            const caseManagerSection = state.sections.find((s) => s.systemCode === 'NurseCaseManager');
            if (caseManagerSection) {
                caseManagerSection.visible =
                    submitterState.submitterType === 'NurseCaseManager' ||
                    submitterState.submitterType === 'NurseCaseManagerOffice' ||
                    otherPartiesState.caseManagerSelected;
            }

            const attorneySection = state.sections.find((s) => s.systemCode === 'Attorney');
            if (attorneySection) {
                attorneySection.visible =
                    submitterState.otherSubmitterType === 'Attorney' ||
                    submitterState.otherSubmitterType === 'AttorneyOffice' ||
                    otherPartiesState.attorneySelected;
            }
        });
    };

    setServiceSelected = (sectionSystemCode?: SectionSystemCode, selected?: boolean) => {
        this.state.update((state) => {
            const section = state.sections.find((s) => s.systemCode === sectionSystemCode);
            if (section) {
                section.serviceSelected = selected === undefined ? !section.serviceSelected : selected;
                state.selectedServices = state.sections.filter((s) => s.serviceSelected).map((s) => s.systemCode);
            }
            state.sectionsError = undefined;
        });

        this.setSectionVisible(sectionSystemCode, selected);
    };

    setSectionExpanded = (sectionSystemCode: SectionSystemCode, expanded?: boolean) => {
        this.state.update((state) => {
            const section = state.sections.find((s) => s.systemCode === sectionSystemCode);
            if (section) {
                section.expanded = expanded === undefined ? !section.expanded : expanded;
            }
        });

        //TODO Refactor for another sections with 'Remember Section Data' checkbox
        if (sectionSystemCode === 'Submitter') {
            const submitterState = this.submitterModel.state.get();
            setStateToStorage(
                this.state.get().customization === 'GM' ? 'Submitter-gm' : sectionSystemCode,
                submitterState,
                submitterState.rememberSectionData,
            );
        }

        if (sectionSystemCode === 'Insurance') {
            const insuranceState = this.insuranceModel.state.get();
            setStateToStorage(sectionSystemCode, insuranceState, insuranceState.rememberSectionData);
        }
    };

    validateSection<T extends ValidatedState>(sectionSystemCode: SectionSystemCode, model: ChangeableModel<T>) {
        model.validate(this);

        const modelState = model.state.get();

        if ((model.isValid() && model.getWarnings().length === 0) || modelState.validationCanBeSkipped === true) {
            this.openNextSection(sectionSystemCode);

            if (sectionSystemCode === 'Submitter') {
                this.copySubmitterFields();
            }
        }
    }

    getMaxSectionInstructionLimit() {
        const specialInstructionsSeparatorLength = 3;
        const instructionsSelectedServices = this.specialInstructionsModel.state.get().serviceCodes;
        const servicesModels = this.state
            .get()
            .sections.filter((s) => s.serviceSelected && instructionsSelectedServices.includes(s.systemCode))
            .map((s) => this.sectionModels.get(s.systemCode))
            .filter((model) => model) as ChangeableModel<any>[];

        const notes = servicesModels.map((m) => Array.from(m.getAdditionalNotes(false).values()));
        return notes.length
            ? Math.max(...notes.map((s) => Math.max(...s.map((n) => n.length)))) + specialInstructionsSeparatorLength
            : 0;
    }

    validateAll() {
        if (this.needToRenderServices()) {
            this.validateServicesSelected();
        }

        const state = this.state.get();
        const visibleModels = this.getVisibleModels(state);

        visibleModels.forEach((model) => model.validate(this));
        return visibleModels.every((model) => model.isValid()) && !state.sectionsError;
    }

    validateServicesSelected() {
        this.state.update(
            (s) =>
                (s.sectionsError =
                    s.sections.filter((section) => section.serviceSelected).length > 0
                        ? undefined
                        : ERROR_MESSAGE.MISSING_SERVICE),
        );
    }

    validateNotesLimit() {
        const notesLimitExtended =
            this.getMaxSectionInstructionLimit() +
                this.specialInstructionsModel.state.get().specialInstructions.length >
            3000;
        if (notesLimitExtended) {
            this.specialInstructionsModel.state.update((s) => {
                s.errors = [
                    ...s.errors,
                    {
                        fieldName: 'specialInstructions',
                        fieldLabel: 'Special Instructions',
                        errors: ['Notes limit exceeded'],
                    },
                ];
            });
        }
    }

    isServiceSelected(sections: SectionSystemCode[]) {
        return this.state
            .get()
            .sections.filter((s) => sections.includes(s.systemCode))
            .some((s) => s.serviceSelected);
    }

    needToRenderServices() {
        const customizationsWithServices = ['Default', 'DD', 'TL'];
        return customizationsWithServices.includes(this.state.get().customization);
    }

    getGeneralInstructions() {
        const patientState = this.claimPatientModel.state.get();
        const gmTheme = this.state.get().customization === 'GM';
        const mriQuestionsState = this.prescreeningQuestionsModel.state.get();

        return this.formatAdditionalNotes(
            [
                { fieldName: 'alternativePhone', label: 'Alternative Phone', value: patientState.alternativePhone },
                {
                    fieldName: 'alternativePhoneDescription',
                    label: 'Alternative Phone Description',
                    value: patientState.altPhoneDescription,
                },
                ...(gmTheme
                    ? [
                          {
                              fieldName: 'label',
                              label: '',
                              value:
                                  mriQuestionsState.isClaustrophobic ||
                                  mriQuestionsState.hasPriorBodyAreaSurgeries ||
                                  mriQuestionsState.bodyAreaAndType ||
                                  mriQuestionsState.worksWithMetal ||
                                  mriQuestionsState.hadMetalInEyes ||
                                  mriQuestionsState.hasPacemaker ||
                                  mriQuestionsState.isPregnant
                                      ? 'type=SPECIALTY ACCOUNT NOTES, subtype=GENERAL MOTORS'
                                      : undefined,
                          },
                          {
                              fieldName: 'isClaustrophobic',
                              label: 'Are you claustrophobic?',
                              value: mriQuestionsState.isClaustrophobic,
                          },
                          {
                              fieldName: 'hasPriorBodyAreaSurgeries',
                              label: 'Have you had any prior surgeries to the body area in which this test is needed?',
                              value: mriQuestionsState.hasPriorBodyAreaSurgeries,
                          },
                          {
                              fieldName: 'bodyAreaAndType',
                              label: 'Indicate body area and type',
                              value: mriQuestionsState.bodyAreaAndType,
                          },
                          {
                              fieldName: 'worksWithMetal',
                              label: 'Do you work with or around metal, such as welding/grinding?',
                              value: mriQuestionsState.worksWithMetal,
                          },
                          {
                              fieldName: 'hadMetalInEyes',
                              label: 'Have you ever had a metal in your eyes?',
                              value: mriQuestionsState.hadMetalInEyes,
                          },
                          {
                              fieldName: 'hasPacemaker',
                              label: 'Do you have a pacemaker?',
                              value: mriQuestionsState.hasPacemaker,
                          },
                          {
                              fieldName: 'isPregnant',
                              label: 'Any chance of pregnancy?',
                              value: mriQuestionsState.isPregnant,
                          },
                      ]
                    : []),
            ],
            'ClaimPatient',
            false,
        );
    }

    errorIconClick = (section: SectionSystemCode) => (event: React.MouseEvent<any, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        const targetSection = this.state.get().sections.find((s) => s.systemCode === section);
        if (targetSection && !targetSection.expanded) {
            this.setSectionExpanded(targetSection.systemCode, true);
        }
    };

    scrollToInvalidSection = () => {
        const invalidSection = this.state.get().sections.filter((section) => {
            const sectionModel = this.sectionModels.get(section.systemCode);
            return section.visible && !sectionModel?.isValid();
        })[0];

        if (invalidSection) {
            this.setSectionExpanded(invalidSection.systemCode, true);
            const elementToScroll = document.getElementById(invalidSection.title);

            if (elementToScroll) {
                const stickyComponentsOffset = 170;
                const offset = elementToScroll?.getBoundingClientRect().top + window.scrollY - stickyComponentsOffset;
                window.scrollTo({ top: offset, behavior: 'smooth' });
            }
        }
    };

    handleSubmit = async (e: any, getToken: () => Promise<string>) => {
        e.preventDefault();

        if (!referralModel.validateAll()) {
            await this.handleValidationFailure();
        } else {
            await this.handleValidationSuccess(this.submitReferral(getToken));
        }
    };

    changeVisible = (sectionSystemCode?: SectionSystemCode, visible?: boolean) => {
        this.state.update((state) => {
            const section = state.sections.find((s) => s.systemCode === sectionSystemCode);
            if (section) {
                section.visible = visible === undefined ? !section.visible : visible;
            }
        });
    };

    // Added userIP query param for testing purposes. Delete when no longer needed.
    initModelsStateFromContextData = async (token: string, userIP?: string) => {
        if (!token) {
            return;
        }

        const getContextDataPromise = async () => {
            const submitLock = addScreenLock();
            try {
                return await getContextData(token, userIP);
            } catch (error) {
                console.error(error);
                await showConfirmationDialog({
                    title: 'Context Data retrieve failed!',
                    message:
                        'Unable to load context data for prefill. Please try again. If the issue persists, please contact the Support team.',
                    confirmButton: 'Ok',
                    cancelButton: null,
                });
            } finally {
                removeScreenLock(submitLock);
            }
        };
        let getContextDataProgress = getContextDataPromise();
        showProgress(getContextDataProgress, 'Retrieving context data...');
        let contextDataOrder = await getContextDataProgress;

        if (!contextDataOrder) {
            removeBeforeUnload();
            this.reloadWithoutQueryParam('token');
            return;
        }

        this.claimPatientModel.populateFrom(contextDataOrder?.claimPatient);
        this.employerModel.populateFrom(contextDataOrder?.employer);
        this.adjusterModel.populateFrom(contextDataOrder?.adjuster);
        this.insuranceModel.populateFrom(contextDataOrder?.insurance);

        if (!_.isEmpty(contextDataOrder?.attorney)) {
            this.attorneyModel.populateFrom(contextDataOrder?.attorney);
            this.otherPartiesModel.state.update((s) => (s.attorneySelected = true));
            this.setSectionVisible('Attorney');
        }

        if (!_.isEmpty(contextDataOrder?.nurseCaseManager)) {
            this.caseManagerModel.populateFrom(contextDataOrder?.nurseCaseManager);
            this.otherPartiesModel.state.update((s) => (s.caseManagerSelected = true));
            this.setSectionVisible('NurseCaseManager');
        }

        if (!_.isEmpty(contextDataOrder?.referringPhysician)) {
            this.referringPhysicianModel.populateFrom(contextDataOrder?.referringPhysician);
            this.otherPartiesModel.state.update((s) => (s.referringPhysicianSelected = true));
            this.setSectionVisible('ReferringPhysician');
        }

        this.submitterModel.state.set((old: any) => {
            const savedState = getStateFromStorage(
                this.state.get().customization === 'GM' ? 'Submitter-gm' : 'Submitter',
            );
            return savedState ? { ...old, ...savedState } : old;
        });
    };

    private reloadWithoutQueryParam(...params: string[]) {
        const url = new URL(window.location.href);
        params.map((param) => url.searchParams.delete(param));
        window.location.href = url.toString();
    }

    private async handleValidationFailure() {
        await showConfirmationDialog({
            title: 'Missing or incorrect data',
            message: 'Please fill in the fields highlighted red.',
            confirmButton: 'Ok',
            cancelButton: null,
        });
        return referralModel.scrollToInvalidSection();
    }

    private async handleValidationSuccess(
        submitReferral: () => Promise<{
            response: SubmittedOrderRequestResponse | undefined;
            orderPayload: Order | null;
        }>,
    ) {
        this.clearWarnings();
        const { response, orderPayload } = await submitReferral();

        if (!response) {
            await showConfirmationDialog({
                title: 'Submission failed!',
                message:
                    'Unable to submit the referral due to technical issue. Please retry. If the issue persists, please contact the Support team.',
                confirmButton: 'Ok',
                cancelButton: null,
            });
        } else {
            const summary = await showConfirmationSummaryDialog(orderPayload || {}, response);
            if (!summary || summary === 'Done') {
                await this.reset();
            }
        }
    }

    private submitReferral = (getToken: () => Promise<string>) => async () => {
        // Temporary disabled Google ReCaptcha usage for easier testing.
        // const token = (await getToken()) || '';
        const token = '';

        let orderPayload: Order | null = null;
        const submitReferralPromise = async () => {
            const submitLock = addScreenLock();
            try {
                await uploadAttachments();
                orderPayload = await buildOrderPayload(referralModel);
                const contextDataToken = this.state.get().queryParams['token'];
                
                return await submitReferral(token, orderPayload, contextDataToken);
            } catch (error) {
                console.error(error);
            } finally {
                removeScreenLock(submitLock);
            }
        };
        let submitReferralProgress = submitReferralPromise();
        showProgress(submitReferralProgress, 'Submitting referral...');
        let response = await submitReferralProgress;
        return { response, orderPayload };
    };

    private getVisibleModels(state: IReferralState) {
        return state.sections
            .filter((s) => s.visible)
            .map((s) => this.sectionModels.get(s.systemCode))
            .filter((model) => model) as ChangeableModel<ValidatedState>[];
    }

    private openNextSection = (activeSection: SectionSystemCode) => {
        const sections = this.state.get().sections;
        const currentSection = sections.find((s) => s.systemCode === activeSection);

        if (currentSection) {
            const nextSection = sections.find((s, idx) => s.visible && idx > sections.indexOf(currentSection));
            if (nextSection && !nextSection?.expanded) {
                this.setSectionExpanded(nextSection.systemCode);
            }
            this.setSectionExpanded(currentSection.systemCode, false);
        }
    };

    private async reset() {
        const state = this.state.get();
        const models = state.sections
            .map((s) => this.sectionModels.get(s.systemCode))
            .filter((model) => model) as ChangeableModel<any>[];

        models.forEach((model) => {
            model.state.clear();
        });
        referralModel.state.clear();

        if (state.queryParams['token']) {
            removeBeforeUnload();
            this.reloadWithoutQueryParam('token');
        }
        
        await this.init({ mode: state.mode, customization: state.customization, queryParams: {} });
    }

    private clearWarnings() {
        const state = this.state.get();
        const visibleModels = this.getVisibleModels(state);

        visibleModels.forEach((model: ChangeableModel<ValidatedState>) => {
            model.state.update((s: ValidatedState) => {
                s.warnings = [];
            });
        });
    }

    private initModelsStateFromStorage() {
        const setModelState = (key: string, model: ChangeableModel<any>) => {
            model.state.set((old: any) => {
                const savedState = getStateFromStorage(key);
                return savedState ? { ...old, ...savedState } : old;
            });
        };

        setModelState(this.state.get().customization === 'GM' ? 'Submitter-gm' : 'Submitter', this.submitterModel);
        setModelState('Adjuster', this.adjusterModel);
        setModelState('ReferringPhysician', this.referringPhysicianModel);
        setModelState('Insurance', this.insuranceModel);
        setModelState('NurseCaseManager', this.caseManagerModel);
        setModelState('Attorney', this.attorneyModel);
    }

    private copySubmitterFields() {
        const submitterEntityMap = new Map<SubmitterType | OtherSubmitterType | undefined, ISubmitterEntity>([
            ['Patient', this.claimPatientModel],
            ['ReferringPhysician', this.referringPhysicianModel],
            ['ReferringPhysicianOffice', this.referringPhysicianModel],
            ['NurseCaseManager', this.caseManagerModel],
            ['NurseCaseManagerOffice', this.caseManagerModel],
            ['Attorney', this.attorneyModel],
            ['AttorneyOffice', this.attorneyModel],
            ['Adjuster', this.adjusterModel],
            ['AdjusterOffice', this.insuranceModel],
        ]);
        const submitter = this.submitterModel.state.get();

        const entityModel = submitterEntityMap.get(
            submitter.submitterType === 'Other' ? submitter.otherSubmitterType : submitter.submitterType,
        );
        entityModel?.copySubmitterFields(submitter);
    }
}

export const referralModel = new ReferralModel(referralState);
