/* eslint-disable max-classes-per-file */
import React, { SetStateAction } from "react";
import { create } from 'zustand'
import produce from "immer";
import { resolveState } from "../common/utils";

interface IUse<T> {
    (): T;
    <R>(selector: (state: T) => R): R;
}

export type IState<T> = {
    get: () => T;
    set: React.Dispatch<SetStateAction<T>>;
    use: IUse<T>;
    update: (updateDraft: (state: T) => void) => void;
    clear: () => void;
}

export class TOneState<T> implements IState<T> {
    private store;
    defaultState;

    constructor(defaultState: T) {
        this.defaultState = defaultState;
        this.store = create<T>(() => defaultState);
    }

    get = () => this.store.getState();
    set = (setter: SetStateAction<T>) => {
        const newState = resolveState(setter, () => this.store.getState());
        this.store.setState(newState, true);
    };

    use: IUse<T> = <R>(selector?: (state: T) => R) => {
        return selector
            ? this.store(state => selector(state))
            : this.store(state => state)
    };

    update = (updateDraft: (state: T) => void) => {
        this.set(
            produce(this.store.getState(), (draft) => {
                updateDraft(draft as T);
            })
        );
    };

    clear = () => { this.set(this.defaultState); }
}

export class TGlobalState<T> extends TOneState<T> {}

export class TComponentState<T> extends TOneState<T> {}