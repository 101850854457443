import React, { useEffect, useMemo } from "react";
import { isNullOrUndefined, range } from "../../common/utils";
import { TOptionHint } from "../../common/types";

async function resolveSelectFrom<T>(
    selectFrom: T[] | (() => T[] | Promise<T[]>)
): Promise<T[]> {
    let res = selectFrom && (selectFrom as Function)();
    if (res && res.then) {
        res = await res;
    }
    return res;
}

export function useFilteredOptions<T = any>(
    options: readonly T[],
    searchString?: string,
    getLabel?: (o: T, index: number) => string
) {
    return useMemo(() => {
        if (!searchString) {
            return options;
        }
        const searchStringLower = searchString.toLocaleLowerCase();
        return options.filter((o, idx) => {
            const label: string = getLabel
                ? getLabel(o, idx)
                : defaultOptionGetLabel(o);
            return (
                label &&
                label.toLowerCase &&
                label.toLowerCase().includes(searchStringLower)
            );
        });
    }, [options, searchString, getLabel]);
}

export const defaultCompare =
    (propertyKey?: string) =>
    (left: any, right: any): number => {
        const leftV = propertyKey ? (left as any)[propertyKey] : left;
        const rightV = propertyKey ? (right as any)[propertyKey] : right;

        if (isNullOrUndefined(leftV) !== isNullOrUndefined(rightV)) {
            return isNullOrUndefined(leftV) ? -1 : 1;
        }

        if (typeof leftV === "number" && typeof rightV === "number") {
            return leftV - rightV;
        }

        if (typeof leftV === "string" && typeof rightV === "string") {
            return leftV.localeCompare(rightV);
        }

        if (leftV instanceof Date && rightV instanceof Date) {
            return leftV.getTime() - rightV.getTime();
        }

        return 0;
    };

export const emptyLabel = "(empty)";

export const defaultOptionGetLabel = (o: any): string => {
    let value = "";
    if (!isNullOrUndefined(o)) {
        switch (typeof o) {
            case "boolean":
                value = o.toString();
                break;
            case "object":
                value = o.label ?? o.toString?.() ?? "";
                break;
            default:
                value = o.toString?.() ?? "";
        }
    }
    
    return isNullOrUndefined(value) || value === "" ? emptyLabel : value;
};

export const defaultGetOptionDisabled = (o: any): boolean => {
    return typeof o === "object" && o.disabled;
};

export const defaultGetOptionHint = (o: any): TOptionHint => {
    return {
        title: typeof o === "object" && typeof o.hint?.title === "string" ? o.hint.title : "",
        titleWidth: undefined
    };
};

export type SelectOptionsResult<T> = {
    options: T[];
    loading: boolean;
};

export function useSelectOptions<T>(
    selectFrom?: readonly T[] | (() => T[] | Promise<T[]>),
    open?: boolean
): SelectOptionsResult<T> {
    const [options, setOptions] = React.useState<T[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        setLoaded(false);
        setOptions([]);
    }, [selectFrom]);

    useEffect(() => {
        let live = true;

        if (!open || loaded) {
            return;
        }

        if (!selectFrom) {
            setOptions([]);
        } else if (Array.isArray(selectFrom)) {
            setOptions(selectFrom);
        } else if (typeof selectFrom === "function") {
            setLoading(true);
            resolveSelectFrom(selectFrom).then((res) => {
                if (live) {
                    setLoading(false);
                    setLoaded(true);
                    setOptions(res);
                }
            });
        }

        return () => {
            live = false;
        };
    }, [open, selectFrom, loaded]);

    return { options, loading };
}

export function elementHasParent(
    element: Element | null,
    matchCallback: (parent: Element) => boolean,
    skipCurrent?: boolean
) {
    let currElement: Element | null =
        skipCurrent && element ? element.parentElement : element;
    while (currElement) {
        if (matchCallback(currElement)) return true;
        currElement = currElement.parentElement;
    }
    return false;
}

export function elementHasExectParent(
    element: Element | null,
    parent: Element | null,
    skipCurrent?: boolean
) {
    if (!parent) return false;
    let currElement: Element | null =
        skipCurrent && element ? element.parentElement : element;
    while (currElement) {
        if (currElement === parent) return true;
        currElement = currElement.parentElement;
    }
    return false;
}

export const phoneMask = [
    "(",
    ...range(3).map(() => /[\d]/),
    ")",
    " ",
    ...range(3).map(() => /[\d]/),
    "-",
    ...range(4).map(() => /[\d]/)
];

export const zipMask = [...range(5).map(() => /[\d]/), '-', ...range(4).map(() => /[\d]/)];

export function applyMask(input: string, mask: (string | RegExp)[]) {
    const inputArray = input.split('');
    return mask.map((char) => (char instanceof RegExp ? inputArray.shift() : char)).join('');
}