import { Box, Typography, styled } from '@mui/material';
import { CheckIcon, EasyReferralIcon } from '../theme/icons';
import { CtrlButton } from '../components/controls/CtrlButton';
import { useRecaptcha } from '../recaptcha/RecaptchaProvider';
import { referralModel } from './ReferralModel';
import { TPortalCustomization } from './sections/utils';

const helpMailsMap = new Map<TPortalCustomization, string>([
    ['Default', 'EZRSupport@onecallcm.com'],
    ['GM', 'GM_Diagnostic@onecallcm.com'],
    ['Navigere', 'EZRSupport@navigere.com'],
]);

function HeaderComponent(props: any) {
    const recaptcha = useRecaptcha();

    const { mode, customization } = referralModel.state.use();

    const supportEmail = helpMailsMap.get(customization) || helpMailsMap.get("Default");

    return (
        <div className={props.className}>
            <Box className="box">
                {mode === 'EZAuth' ? (
                    <img src="/OnecallLogoWithText.png" alt="logo" className="logo" style={{ height: 56 }} />
                ) : (
                    <EasyReferralIcon className="logo" />
                )}
                {
                    customization === 'GM'
                    ? <img className='gm-logo' src="/gm-logo.png" alt="gm-logo" />
                    : customization === 'Navigere'
                        ? <img className='navigere-logo' src="/navigere-logo.png" alt="navigere-logo" />
                        : null
                }
                <div className="actions">
                    <Typography variant="body1" className="help">
                        For help: {<a href={`mailto:${supportEmail}`}>{supportEmail}</a>}
                    </Typography>
                    <CtrlButton
                        startIcon={<CheckIcon />}
                        variant="contained"
                        size="small"
                        onClick={(e) => referralModel.handleSubmit(e, recaptcha!.getToken)}
                    >
                        Submit Referral
                    </CtrlButton>
                </div>
            </Box>
        </div>
    );
}

export const Header = styled(HeaderComponent, { name: 'Header' })(({ theme }) => ({
    position: 'sticky',
    top: 0,
    background: theme.app.neutralColor.white,
    borderBottom: `1px solid ${theme.app.neutralColor.borderDisabled}`,
    zIndex: 2000,
    '.box': {
        alignItems: 'center',
        display: 'flex',
        height: 46,
    },
    '.text': {
        marginLeft: theme.spacing(6),
    },
    '.logo': {
        marginLeft: 24,
        '&.MuiSvgIcon-root': {
            width: 110,
            height: 22,
        },
    },
    '.help': {
        color: theme.app.neutralColor.textMain,
        alignSelf: 'center',
    },
    '.actions': {
        display: 'flex',
        columnGap: theme.spacing(6),
        marginLeft: 'auto',
        marginRight: 20,
    },
    '.gm-logo': {
        width: 32,
        height: 32,
        marginLeft: 32
    },
    '.navigere-logo': {
        width: 105,
        height: 36,
        marginLeft: 32
    }
}));
