import { InteractionMethods } from '../data/interactionMethods';
import { ReferralModel } from '../pages/ReferralModel';
import dayjs from 'dayjs';
import {
    Adjuster,
    Attorney,
    CaseManager,
    ClaimPatient,
    Employer,
    Instructions,
    Insurance,
    Order,
    ReferringPhysician,
    Submitter,
} from './types/claim';
import _ from 'lodash';
import { applyMask, phoneMask, zipMask } from '../components/controls/utils';
import { TAddress } from '../common/types';
import {
    Dental,
    Diagnostic,
    Doctor,
    DurableMedicalEquipment,
    HomeHealthComplexCare,
    LanguageTranslation,
    PhysicalTherapy,
    Transportation,
} from './types/services';
import { Attachment, AttachmentsCollection } from './types/attachment';
import { TPortalCustomization } from '../pages/sections/utils';

const formatDateField = (date?: Date | string) => {
    if (!date) {
        return undefined;
    }

    const dateStr = dayjs(date).local().format();
    return dateStr.substring(0, dateStr.indexOf('T'));
};

export const buildOrderPayload = async (referralModel: ReferralModel): Promise<Order> => {
    const state: Order = getReferralModelState(referralModel);
    const customization: TPortalCustomization = referralModel.state.get().customization;

    const payload: Order = {
        submitter: getSubmitter(state.submitter),
        claimPatient: getClaimPatient(state.claimPatient),
        employer: getEmployer(state.employer, customization),
        adjuster: getAdjuster(state.adjuster),
        insurance: getInsurance(state.insurance, customization),
        referringPhysician: getReferringPhysician(state.referringPhysician),
        nurseCaseManager: getNurseCaseManager(state.nurseCaseManager),
        attorney: getAttorney(state.attorney),
        physicalMedicine: getPhysicalMedicine(state.physicalMedicine),
        diagnosticServices: getDiagnosticServices(state.diagnosticServices, customization),
        durableMedicalEquipment: getDurableMedicalEquipment(state.durableMedicalEquipment),
        homeHealthCare: getHomeHealthCare(state.homeHealthCare),
        transportationServices: getTransportationServices(state.transportationServices),
        languageTranslation: getLanguageTranslation(state.languageTranslation),
        dsDentalServices: getDsDentalServices(state.dsDentalServices),
        dsDoctorServices: getDsDoctorServices(state.dsDoctorServices),
        specialInstructions: getSpecialInstructions(state.specialInstructions),
        attachments: await getAttachments(state.attachments),
        clientUrl: `${window.location.origin}${window.location.pathname}`,
        interactionMethod: state.interactionMethod,
        customization: customization,
    };

    return payload;
};

export const applyMasks = (obj: any): any => {
    const phoneFieldNames = ['phone', 'homePhone', 'cellPhone', 'workPhone', 'fax'];

    const zipFieldNames = ['zip'];

    return _.mapValues(obj, (value: any, key: any) => {
        if (_.isObject(value) && value !== null) {
            // Recursively process nested objects
            return applyMasks(value);
        } else if (_.isString(value) && phoneFieldNames.includes(key)) {
            return value ? applyMask(value, phoneMask) : value;
        } else if (_.isString(value) && value.length > 5 && zipFieldNames.includes(key)) {
            return value ? applyMask(value, zipMask) : value;
        }

        return value;
    });
};

function getReferralModelState(referralModel: ReferralModel): Order {
    const globalState = referralModel.state.get();

    const state: any = {
        ...Object.fromEntries(
            Array.from(referralModel.sectionModels, ([key, value]) => [
                key,
                globalState.sections.find((s) => s.systemCode === key)?.visible
                    ? {
                          ...value.state.get(),
                          additionalNotes: Object.fromEntries(value.getAdditionalNotes().entries()),
                      }
                    : null,
            ]),
        ),
        interactionMethod:
            globalState.mode === 'EZAuth' ? InteractionMethods.PhysicianServices : InteractionMethods.EasyReferral,
        clientUrl: `${window.location.origin}${window.location.pathname}`,
    };

    return _.mapKeys(state, (_value, key) => _.camelCase(key)) as Order;
}

function readFileAsByteArray(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event: any) {
            resolve(reader.result);
        };
        reader.readAsDataURL(file);
    });
}

function isEmpty(obj: any): boolean {
    if (_.isObject(obj)) {
        for (let key in obj) {
            if (!isEmpty(_.get(obj, key))) {
                return false;
            }
        }
        return true;
    } else {
        return !obj;
    }
}

function getSubmitter(submitter?: Submitter): Submitter | undefined {
    if (!submitter || isEmpty(submitter)) {
        return undefined;
    }

    return {
        cellPhone: submitter.cellPhone,
        email: submitter.email,
        firstName: submitter.firstName,
        lastName: submitter.lastName,
        companyName: submitter.companyName,
        phone: submitter.phone,
        mainPhone: submitter.mainPhone,
        ext: submitter.ext,
        fax: submitter.fax,
        submitterType: submitter.submitterType,
        otherSubmitterType: submitter.otherSubmitterType,
        address: getAddress(submitter.address),
    };
}

function getAddress(address?: TAddress): TAddress | undefined {
    if (!address || isEmpty(address)) {
        return undefined;
    }

    return {
        address1: address.address1 || address.address1Masked,
        address2: address.address2 || address.address2Masked,
        city: address.city,
        state: address.state,
        zip: address.zip,
    };
}

function getClaimPatient(claimPatient?: ClaimPatient): ClaimPatient | undefined {
    if (!claimPatient || isEmpty(claimPatient)) {
        return undefined;
    }

    return {
        firstName: claimPatient.firstName,
        lastName: claimPatient.lastName,
        dateOfBirth: (claimPatient as any).dateOfBirthMasked
            ? new Date('0001-01-01')
            : new Date(formatDateField(claimPatient.dateOfBirth) || ''),
        dateOfInjury: new Date(formatDateField(claimPatient.dateOfInjury) || ''),
        gender: claimPatient.gender,
        heightFeet: claimPatient.heightFeet,
        heightInches: claimPatient.heightInches,
        weight: claimPatient.weight,
        speaksEnglish: claimPatient.speaksEnglish,
        languagePreference: claimPatient.languagePreference,
        homePhone: claimPatient.homePhone || (claimPatient as any).homePhoneMasked,
        cellPhone: claimPatient.cellPhone || (claimPatient as any).cellPhoneMasked,
        ext: claimPatient.ext,
        alternativePhone: claimPatient.alternativePhone,
        altPhoneDescription: claimPatient.altPhoneDescription,
        workPhone: claimPatient.workPhone,
        email: claimPatient.email,
        address: getAddress(claimPatient.address),
        claimNumber: claimPatient.claimNumber,
        icdCode: claimPatient.icdCode,
        injuryJurisdictionState: claimPatient.injuryJurisdictionState,
        describeInjury: claimPatient.describeInjury,
    };
}

function getEmployer(employer?: Employer, customization?: TPortalCustomization): Employer | undefined {
    if (!employer || isEmpty(employer)) {
        return undefined;
    }

    return {
        company: customization === 'GM' ? 'General Motors' : employer.company,
        phone: employer.phone,
        address: getAddress(employer.address),
    };
}

function getAdjuster(adjuster?: Adjuster): Adjuster | undefined {
    if (!adjuster || isEmpty(adjuster)) {
        return undefined;
    }

    return {
        cellPhone: adjuster.cellPhone,
        email: adjuster.email,
        firstName: adjuster.firstName,
        lastName: adjuster.lastName,
        phone: adjuster.phone,
        ext: adjuster.ext,
        fax: adjuster.fax,
        address: getAddress(adjuster.address),
    };
}

function getInsurance(insurance?: Insurance, customization?: TPortalCustomization): Insurance | undefined {
    if (!insurance || isEmpty(insurance)) {
        return undefined;
    }

    return {
        company: customization === 'GM' ? { label: 'Sedgwick', value: 'Sedgwick' } : insurance.company,
        phone: insurance.phone,
        address: getAddress(insurance.address),
    };
}

function getReferringPhysician(referringPhysician?: ReferringPhysician): ReferringPhysician | undefined {
    if (!referringPhysician || isEmpty(referringPhysician)) {
        return undefined;
    }

    return {
        cellPhone: referringPhysician.cellPhone,
        email: referringPhysician.email,
        firstName: referringPhysician.firstName,
        lastName: referringPhysician.lastName,
        phone: referringPhysician.phone,
        ext: referringPhysician.ext,
        fax: referringPhysician.fax,
        address: getAddress(referringPhysician.address),
        practiceName: referringPhysician.practiceName,
    };
}

function getNurseCaseManager(nurseCaseManager?: CaseManager): CaseManager | undefined {
    if (!nurseCaseManager || isEmpty(nurseCaseManager)) {
        return undefined;
    }

    return {
        cellPhone: nurseCaseManager.cellPhone,
        email: nurseCaseManager.email,
        firstName: nurseCaseManager.firstName,
        lastName: nurseCaseManager.lastName,
        phone: nurseCaseManager.phone,
        ext: nurseCaseManager.ext,
        fax: nurseCaseManager.fax,
        address: getAddress(nurseCaseManager.address),
        company: nurseCaseManager.company,
    };
}

function getAttorney(attorney?: Attorney): Attorney | undefined {
    if (!attorney || isEmpty(attorney)) {
        return undefined;
    }

    return {
        cellPhone: attorney.cellPhone,
        email: attorney.email,
        firstName: attorney.firstName,
        lastName: attorney.lastName,
        phone: attorney.phone,
        ext: attorney.ext,
        fax: attorney.fax,
        address: getAddress(attorney.address),
    };
}

function getPhysicalMedicine(physicalMedicine?: PhysicalTherapy): PhysicalTherapy | undefined {
    if (!physicalMedicine || isEmpty(physicalMedicine)) {
        return undefined;
    }

    return {
        additionalNotes: physicalMedicine.additionalNotes,
        bodyPart: physicalMedicine.bodyPart,
        bodySide: physicalMedicine.bodySide,
        injuryType: physicalMedicine.injuryType,
        isRush: physicalMedicine.isRush,
        otherDescription: physicalMedicine.otherDescription,
        rxCertificationNumber: physicalMedicine.rxCertificationNumber,
        therapyPrescription: physicalMedicine.therapyPrescription,
        therapyType: physicalMedicine.therapyType,
        surgeryDate: new Date(formatDateField(physicalMedicine.surgeryDate) || ''),
        rxCertificationExpirationDate: new Date(formatDateField(physicalMedicine.rxCertificationExpirationDate) || ''),
    };
}

function getDiagnosticServices(diagnosticServices?: Diagnostic, customization?: TPortalCustomization): Diagnostic | undefined {
    if (!diagnosticServices || isEmpty(diagnosticServices)) {
        return undefined;
    }

    return {
        additionalNotes: diagnosticServices.additionalNotes,
        cdsRequested: diagnosticServices.cdsRequested,
        filmsRequested: diagnosticServices.filmsRequested,
        isRush: diagnosticServices.isRush,
        isNavigere: customization === 'Navigere' ? true : undefined,
        physicianFollowUpDate: new Date(formatDateField(diagnosticServices.physicianFollowUpDate) || ''),
        procedures: diagnosticServices.procedures?.map((procedure) => ({
            bodyPart: procedure.bodyPart,
            bodySide: procedure.bodySide,
            otherProcedure: procedure.otherProcedure,
            procedureOption: procedure.procedureOption,
            product: procedure.product,
            ruleOut1: procedure.ruleOut1,
            ruleOut2: procedure.ruleOut2,
        })),
    };
}

function getDurableMedicalEquipment(
    durableMedicalEquipment?: DurableMedicalEquipment,
): DurableMedicalEquipment | undefined {
    if (!durableMedicalEquipment || isEmpty(durableMedicalEquipment)) {
        return undefined;
    }

    return {
        additionalNotes: durableMedicalEquipment.additionalNotes,
        address: getAddress(durableMedicalEquipment.address),
        isRush: durableMedicalEquipment.isRush,
        prescription: durableMedicalEquipment.prescription,
        requestedDeliveryDate: formatDateField(durableMedicalEquipment.requestedDeliveryDate),
        availableForRental: durableMedicalEquipment.availableForRental,
        comments: durableMedicalEquipment.comments,
        contactPerson: durableMedicalEquipment.contactPerson,
        contactPhone: durableMedicalEquipment.contactPhone,
        deliveryAddressType: durableMedicalEquipment.deliveryAddressType,
        products: durableMedicalEquipment.products?.map((product) => ({
            productCategory: product.productCategory,
            selectedProducts: product.selectedProducts,
        })),
        vendorContactName: durableMedicalEquipment.vendorContactName,
        vendorName: durableMedicalEquipment.vendorName,
        vendorPhone: durableMedicalEquipment.vendorPhone,
    };
}

function getHomeHealthCare(homeHealthCare?: HomeHealthComplexCare): HomeHealthComplexCare | undefined {
    if (!homeHealthCare || isEmpty(homeHealthCare)) {
        return undefined;
    }

    return {
        address: getAddress(homeHealthCare.address),
        careAddress: homeHealthCare.careAddress,
        commentsAdditionalDetails: homeHealthCare.commentsAdditionalDetails,
        contactPerson: homeHealthCare.contactPerson,
        homeHealthServices: homeHealthCare.homeHealthServices,
        hospitalFacilityName: homeHealthCare.hospitalFacilityName,
        hospitalPhone: homeHealthCare.hospitalPhone,
        isPrescriptionPhysicianOrders: homeHealthCare.isPrescriptionPhysicianOrders,
        patientInHospital: homeHealthCare.patientInHospital,
        selectedMedicalSuppliesValue: homeHealthCare.selectedMedicalSuppliesValue,
        selectedRespiratorySuppliesValue: homeHealthCare.selectedRespiratorySuppliesValue,
        isRush: homeHealthCare.isRush,
        additionalNotes: homeHealthCare.additionalNotes,
        hospitalDischargeDate: new Date(formatDateField(homeHealthCare.hospitalDischargeDate) || ''),
        requestedStartOfCare: new Date(formatDateField(homeHealthCare.requestedStartOfCare) || ''),
    };
}

function getTransportationServices(transportationServices?: Transportation): Transportation | undefined {
    if (!transportationServices || isEmpty(transportationServices)) {
        return undefined;
    }

    return {
        additionalNotes: transportationServices.additionalNotes,
        isRush: transportationServices.isRush,
        transportationType: transportationServices.transportationType,
        destinationAddresses: transportationServices.destinationAddresses?.map((address) => ({
            ...getAddress(address),
            type: address.type,
            practiceName: address.practiceName,
            locationPhone: address.locationPhone,
        })),
        additionalInstructions: transportationServices.additionalInstructions,
        appointmentTime: transportationServices.appointmentTime,
        appointmentDate: !transportationServices.appointmentTime
            ? new Date(formatDateField(transportationServices.appointmentDate) || '')
            : transportationServices.appointmentDate,
        appointmentDateString: transportationServices.appointmentDateString,
        appointmentTimeString: transportationServices.appointmentTimeString,
        originationAddress: {
            ...getAddress(transportationServices.originationAddress),
            type: transportationServices.originationAddress?.type,
            practiceName: transportationServices.originationAddress?.practiceName,
            locationPhone: transportationServices.originationAddress?.locationPhone,
        },
        stairsSteps: transportationServices.stairsSteps,
        transportationRequired: transportationServices.transportationRequired,
        typeOfAppointment: transportationServices.typeOfAppointment,
        wheelchairOption: transportationServices.wheelchairOption,
    };
}

function getLanguageTranslation(languageTranslation?: LanguageTranslation): LanguageTranslation | undefined {
    if (!languageTranslation || isEmpty(languageTranslation)) {
        return undefined;
    }

    return {
        additionalInstructions: languageTranslation.additionalInstructions,
        address: getAddress(languageTranslation.address),
        appointmentDate: !languageTranslation.appointmentTime
            ? formatDateField(languageTranslation.appointmentDate)
            : languageTranslation.appointmentDate,
        appointmentDateString: languageTranslation.appointmentDateString,
        appointmentTime: languageTranslation.appointmentTime,
        appointmentTimeString: languageTranslation.appointmentTimeString,
        doctorName: languageTranslation.doctorName,
        language: languageTranslation.language,
        languageCertification: languageTranslation.languageCertification,
        phone: languageTranslation.phone,
        appointmentType: languageTranslation.appointmentType,
        isRush: languageTranslation.isRush,
        additionalNotes: languageTranslation.additionalNotes,
    };
}

function getDsDentalServices(dsDentalServices?: Dental): Dental | undefined {
    if (!dsDentalServices || isEmpty(dsDentalServices)) {
        return undefined;
    }

    return {
        additionalNotes: dsDentalServices.additionalNotes,
        dentistFirstName: dsDentalServices.dentistFirstName,
        dentistLastName: dsDentalServices.dentistLastName,
        fax: dsDentalServices.fax,
        howDidTheInjuryOccur: dsDentalServices.howDidTheInjuryOccur,
        isOCwithProvider: dsDentalServices.isOCwithProvider,
        practiceName: dsDentalServices.practiceName,
        previousTreatment: dsDentalServices.previousTreatment,
        referralFor: dsDentalServices.referralFor,
        referralForDescription: dsDentalServices.referralForDescription,
        visitPurpose: dsDentalServices.visitPurpose,
        visitPurposeDescription: dsDentalServices.visitPurposeDescription,
        phone: dsDentalServices.phone,
        isRush: dsDentalServices.isRush,
    };
}

function getDsDoctorServices(dsDoctorServices?: Doctor): Doctor | undefined {
    if (!dsDoctorServices || isEmpty(dsDoctorServices)) {
        return undefined;
    }

    return {
        additionalNotes: dsDoctorServices.additionalNotes,
        isRush: dsDoctorServices.isRush,
        howDidTheInjuryOccur: dsDoctorServices.howDidTheInjuryOccur,
        previousPhysician: dsDoctorServices.previousPhysician,
        providerTypeRequired: dsDoctorServices.providerTypeRequired,
        referralFor: dsDoctorServices.referralFor,
        referralForDescription: dsDoctorServices.referralForDescription,
        referralReason: dsDoctorServices.referralReason,
        referralReasonDescription: dsDoctorServices.referralReasonDescription,
        visitPurpose: dsDoctorServices.visitPurpose,
        visitPurposeDescription: dsDoctorServices.visitPurposeDescription,
    };
}

function getSpecialInstructions(specialInstructions?: Instructions): Instructions | undefined {
    if (!specialInstructions || isEmpty(specialInstructions)) {
        return undefined;
    }

    return {
        serviceCodes: specialInstructions.serviceCodes,
        specialInstructions: specialInstructions.specialInstructions,
    };
}

async function getAttachments(attachments?: AttachmentsCollection): Promise<AttachmentsCollection | undefined> {
    if (!attachments || isEmpty(attachments)) {
        return undefined;
    }

    const orderAttachments: Attachment[] = [];
    for (let attachment of attachments.attachments ?? []) {
        const stateAttachment = attachment as any;
        const document = {
            id: stateAttachment.id.toString(),
            fileName: stateAttachment.name,
            base64Content: await readFileAsByteArray(stateAttachment.file),
        };

        orderAttachments.push({
            ...attachment,
            document,
        });
    }

    return {
        attachments: orderAttachments.map((attachment) => ({
            serviceCodes: attachment.serviceCodes,
            document: attachment.document,
            orderRequestDocumentId: attachment.orderRequestDocumentId,
        })),
    };
}
