import { ForwardedRef, ReactElement, ReactNode, forwardRef } from "react";

interface StopClickPropagationProps {
    stop?: boolean;
    children: ReactNode;
}

export const StopClickPropagation = forwardRef(
    function StopClickPropagationComponent(
        props: StopClickPropagationProps,
        ref?: ForwardedRef<HTMLSpanElement>
    ): ReactElement {
        return props.stop ? (
            <span
                ref={ref}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="button-stop-click-span"
            >
                {props.children}
            </span>
        ) : (
            (props.children as ReactElement)
        );
    }
);
