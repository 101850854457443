import { ViewProps } from '../../../../common/types';
import { Typography, styled } from '@mui/material';
import { CtrlComboSelect } from '../../../../components/controls/CtrlComboSelect';
import { CtrlRadio } from '../../../../components/controls/CtrlRadio';
import { theme } from '../../../../theme';
import { CtrlDateField } from '../../../../components/controls/CtrlDateField';
import { CtrlTextArea } from '../../../../components/controls/CtrlTextArea';
import { CtrlTextField } from '../../../../components/controls/CtrlTextField';
import { CtrlCheckbox } from '../../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../../ReferralModel';
import { valuesManager } from '../../../../services/valuesManager/valuesManager';
import { CtrlIconButton } from '../../../../components/controls/CtrlIconButton';
import { ArrowRightIcon, CloseIcon, PlusIcon } from '../../../../theme/icons';
import { CtrlButton } from '../../../../components/controls/CtrlButton';
import CustomRowsComboSelect from '../../../../components/controls/CustomRowsComboSelect';
import { TransportationAddressMemo } from './TransportationAddress';
import { AddressTypes, TripType, transportationServicesColumns } from './constants';
import { appointmentDateRange } from './TransportationModel';

export interface TransportationProps extends ViewProps<ReferralModel> {}

function TransportationComponent(props: TransportationProps) {
    const { model } = props;
    const transportationModel = model.transportationModel;
    const state = transportationModel.state.use();
    const claimPatientState = model.claimPatientModel.state.use();

    const { handleControlChange, getErrorMessage } = transportationModel;

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CustomRowsComboSelect
                        label="Transportation Required"
                        value={state.transportationRequired}
                        onChange={handleControlChange('transportationRequired')}
                        selectFrom={valuesManager.resourceValues.transportationServices}
                        columns={transportationServicesColumns}
                        listWidth={800}
                        error={getErrorMessage('transportationRequired.value')}
                        required
                    />
                </div>
                <div className="trip-description span-6">
                    <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        Trip Description
                    </Typography>
                    <CtrlRadio
                        options={valuesManager.resourceValues.transportationTypes}
                        value={state.transportationType}
                        name="transportationType"
                        onChange={transportationModel.handleTransportationTypeChange}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect
                        label="Type of Appointment"
                        value={state.typeOfAppointment}
                        onChange={transportationModel.handleTypeOfAppointmentChange}
                        selectFrom={valuesManager.resourceValues.appointmentTypes}
                        error={getErrorMessage('typeOfAppointment.value')}
                        required
                    />
                </div>
                <div className="span-3">
                    <CtrlDateField
                        label="Appointment Date"
                        value={state.appointmentDate}
                        onChange={transportationModel.handleAppointmentDateChange('appointmentDate')}
                        error={getErrorMessage('appointmentDate')}
                        onTextChange={transportationModel.handleAppointmentDateChange('appointmentDateString')}
                        minDate={appointmentDateRange().from}
                        maxDate={appointmentDateRange().to}
                        mode="date"
                        required
                    />
                </div>
                <div className="span-3">
                    <CtrlDateField
                        label="Appointment Time"
                        value={state.appointmentTime}
                        onChange={handleControlChange('appointmentTime')}
                        onTextChange={handleControlChange('appointmentTimeString')}
                        error={getErrorMessage('appointmentTimeString')}
                        disabled={!(state.appointmentDate || state.appointmentDateString)}
                        mode="time"
                    />
                </div>
            </div>

            <div className="typed-address">
                <div className="text-area-label">{'Origination Address'}</div>
                <TransportationAddressMemo
                    address={
                        state.originationAddress.type?.value === AddressTypes.Home
                            ? {
                                  ...claimPatientState.address,
                                  type: state.originationAddress.type,
                                  locationPhone: claimPatientState.homePhone,
                                  locationPhoneMasked: claimPatientState.homePhoneMasked,
                              }
                            : state.originationAddress
                    }
                    model={transportationModel}
                    fieldTemplate="originationAddress"
                    disabled={state.originationAddress?.type?.value === AddressTypes.Home}
                    errors={state.errors}
                />
            </div>
            {state.destinationAddresses.map((destinationAddress, idx) => (
                <div className="typed-address" key={`destination-address-${idx}`}>
                    <div className="typed-address-title text-area-label">
                        {`Destination Address ${
                            state.transportationType === TripType.MultipleStops ? `${idx + 1} ` : ''
                        }`}
                        {state.destinationAddresses.length > 2 && (
                            <CtrlIconButton
                                title="Remove"
                                size="small"
                                onClick={() => transportationModel.removeDestinationAddress(idx)}
                            >
                                <CloseIcon />
                            </CtrlIconButton>
                        )}
                    </div>

                    <TransportationAddressMemo
                        model={transportationModel}
                        fieldTemplate={`destinationAddresses[${idx}]`}
                        address={
                            destinationAddress.type?.value === AddressTypes.Home
                                ? {
                                      ...claimPatientState.address,
                                      type: destinationAddress.type,
                                      locationPhone: claimPatientState.homePhone,
                                      locationPhoneMasked: claimPatientState.homePhoneMasked,
                                  }
                                : destinationAddress
                        }
                        disabled={destinationAddress.type?.value === AddressTypes.Home}
                        errors={state.errors}
                    />
                </div>
            ))}

            {state.transportationType === TripType.MultipleStops && (
                <div>
                    <CtrlButton
                        disabled={state.destinationAddresses.length > 6}
                        endIcon={<PlusIcon />}
                        onClick={transportationModel.addDestinationAddress}
                    >
                        Add Destination Address
                    </CtrlButton>
                </div>
            )}

            <div className="fields-container">
                <div className="span-12">
                    <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        If there are stairs/steps, please provide estimated number of steps
                    </Typography>
                </div>
            </div>
            <div className="fields-container">
                <div className="span-4">
                    <CtrlTextField
                        label="Stairs/Steps"
                        value={state.stairsSteps?.toString()}
                        onChange={(value) => handleControlChange('stairsSteps')(value ? parseInt(value) : null)}
                        maskProps={{
                            mask: '99999',
                        }}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-12">
                    <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        Wheelchair
                    </Typography>
                    <CtrlRadio
                        options={valuesManager.resourceValues.transportationWheelchairOptions}
                        name="wheelchairOption"
                        onChange={transportationModel.handleChange}
                        value={state.wheelchairOption}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-12">
                    <CtrlTextArea
                        label="Additional Instructions"
                        maxCharacters={1000}
                        style={{ minHeight: 84 }}
                        value={state.additionalInstructions}
                        onChange={handleControlChange('additionalInstructions')}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-12">
                    <CtrlCheckbox label="Rush" value={state.isRush} onChange={handleControlChange('isRush')} />
                </div>
            </div>
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection('TransportationServices', transportationModel);
                        model.validateSection('Attachments', model.attachmentsModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const Transportation = styled(TransportationComponent, { name: 'Transportation' })(({ theme }) => ({
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.trip-description': {
        marginTop: -20,
    },
    '.typed-address': {
        width: '65%',
        border: `solid 1px ${theme.app.neutralColor.borderMain}`,
        borderRadius: 12,
        backgroundColor: theme.app.neutralColor.backgroundSuperLight,
        padding: theme.spacing(5, 4),
        margin: theme.spacing(6, 0),
        '& .fields-container': {
            width: '100%',
        },
        '.address-type': {
            marginBottom: theme.spacing(5),
        },
    },
    '.typed-address-title': {
        height: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));
