import { FieldValidator, SectionSystemCode } from '../../../../common/types';
import { requiredIfCondition, validateFaxNumber, validatePhoneNumber } from '../../../../common/validationHelpers';
import { ChangeableModel } from '../../../../model/model';
import { referralModel } from '../../../ReferralModel';
import { IDentalState } from './DentalState';

export const sectionSystemCode: SectionSystemCode = 'dsDentalServices';

export const dentalValidators: FieldValidator[] = [
    { fieldName: 'phone', fieldLabel: 'Phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'fax', fieldLabel: 'Fax', validateFunctions: [validateFaxNumber] },
    {
        fieldName: 'referralForDescription',
        fieldLabel: 'Referral For Description',
        validateFunctions: [
            requiredIfCondition(
                ({ referralModel }) => referralModel.dentalModel.state.get().referralFor?.value === 'Other',
            ),
        ],
    },
    {
        fieldName: 'visitPurposeDescription',
        fieldLabel: 'Visit Purpose Description',
        validateFunctions: [
            requiredIfCondition(
                ({ referralModel }) => referralModel.dentalModel.state.get().visitPurpose?.value === 'DentOther',
            ),
        ],
    },
];

export class DentalModel extends ChangeableModel<IDentalState> {
    getSummaryFields = () => {
        const state = this.state.get();
        return new Map([
            ['Referral For', state.referralFor?.label],
            ['Visit Purpose', state.visitPurpose?.label],
        ]);
    };

    override getAdditionalNotes(withSpecialInstructions: boolean = true) {
        const state = this.state.get();
        let notes: [string, string][] = [];

        const note = [
            referralModel.getGeneralInstructions(),
            this.formatAdditionalNotes(
                [
                    { fieldName: 'referralForDescription', label: 'Description (Referral for)' },
                    { fieldName: 'visitPurposeDescription', label: 'Description (Visit purpose)' },
                    {
                        fieldName: 'isOCwithProvider',
                        label: "I'd like One Call to work with this provider",
                        value: state.isOCwithProvider ? 'Yes' : undefined,
                    },
                ],
                sectionSystemCode,
                withSpecialInstructions,
            ),
        ];

        notes.push(['DentalServices', note.filter(n => n).join(';')]);

        return new Map<string, string>(notes);
    }
}
