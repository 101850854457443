import { SectionSystemCode } from '../../../../common/types';
import { TCustomRowsColum } from '../../../../components/controls/CustomRowsComboSelect';
import { TPortalCustomization } from '../../utils';

export const transportationServicesColumns: TCustomRowsColum[] = [
    { label: 'Transport', key: 'label', span: 4 },
    { label: 'Description', key: 'description', span: 8, withTooltip: 'description' },
];

export enum TripType {
    MultipleStops = 'M',
    RoundTrip = 'R',
  }
  
  export enum AddressTypes {
    Home = 'Home',
    Discharge = 'Discharge',
    Hospital = 'Hospital',
  }

export const appointmentToDestinationMap = new Map<string, string>([
    ['Doctor', 'Doctor'],
    ['IME', 'IME - Medical'],
    ['PhysicalTherapy', 'Physical Therapy'],
    ['Imaging', 'Diagnostics Facility'],
    ['Surgery', 'Hospital'],
    ['Pharmacy', 'Pharmacy'],
    ['Legal', 'Legal Appointment'],
    ['Lab', 'Lab'],
    ['Work', 'Work'],
    ['Discharge', 'Home'],
]);

export const preselectedProductsMap = new Map<TPortalCustomization, SectionSystemCode>([
  ['DX', 'DiagnosticServices'],
  ['DME', 'DurableMedicalEquipment'],
  ['HH', 'HomeHealthCare'],
  ['PHYM', 'PhysicalMedicine']
]);