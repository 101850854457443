import { styled } from "@mui/material";
import {
    DesktopDatePicker,
    DesktopDatePickerProps,
} from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import React, {
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { CalendarIcon, ClockIcon, CloseIcon } from "../../theme/icons";
import { CtrlTextField, CtrlTextFieldProps } from "./CtrlTextField";
import { CtrlIconButton } from "./CtrlIconButton";
import clsx from "clsx";
import { DesktopDateTimePicker, DesktopDateTimePickerProps, DesktopTimePicker, DesktopTimePickerProps } from "@mui/x-date-pickers";

export type TInputMode = 'date' | 'time' | 'dateAndTime'; 

interface DateInputProps extends Omit<CtrlTextFieldProps, 'value' | 'onChange' | 'onClear'> {
    value?: unknown;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    inputFormat?: string;
    onBlurValue?: (value: string) => void;
    mode?: TInputMode;
}

const defaultDateInputFormat = "MM/DD/YYYY";
const defaultDateTimeInputFormat = 'MM/DD/YYYY hh:mm a';
const defaultTimeInputFormat = 'hh:mm a';

const customDateHandler = (s: string, fixYear?: boolean): string => {
    let parts = (s || "").split("/");
    
    let month = parts[0] || "";
    const monthNum = parseInt(month);
    
    if (parts.length === 1) {
        if (month.length === 2 && monthNum > 12) {
            parts = [...parts, month.substring(1)];
            month = `0${month.substring(0, 1)}`;
        }
    }
    
    if (parts.length > 1) {
        if (month.length === 1) {
            month = `0${month}`;
        }
    }
    
    let day: string | undefined = parts[1];
    
    if (parts.length > 2) {
        if (day.length === 1) {
            day = `0${day}`;
        }
    }
    
    let year: string | undefined = parts[2];
    const yearNum = parseInt(year);
    
    if (fixYear && year && year.length === 2) {
        const currYear = new Date().getFullYear();
        if (yearNum > currYear % 100) {
            year = `${Math.trunc(currYear / 100) - 1}${year}`;
        } else {
            year = `${Math.trunc(currYear / 100)}${year}`;
        }
    }

    const res = [month, day, year].filter((s) => s !== undefined).join("/");
    return res;
};

const customDateEventHandler = (e: any) => ({
    ...e,
    target: {
        ...e.target,
        value: customDateHandler(e.target.value),
    },
});

const DateInputComponentRef = forwardRef(function DateInputComponent(
    props: DateInputProps,
    ref?: ForwardedRef<HTMLDivElement>
) {
    const {
        value,
        maskedValue,
        onChange,
        onClear,
        inputProps,
        onBlur: propsOnBlur,
        inputFormat,
        onBlurValue,
        mode,
        ...other
    } = props;
    const { onChange: inputPropsOnChange, ...otherInputProps } = inputProps ?? {};

    const lastKeyRef = useRef<string>('');
    const targetInputFormat =
        mode === 'dateAndTime'
            ? defaultDateTimeInputFormat
            : mode === 'time'
                ? defaultTimeInputFormat
                : defaultDateInputFormat;

    const onChangeProxy = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (inputFormat === targetInputFormat) {
                onChange?.(customDateEventHandler(e));
            } else {
                onChange?.(e);
            }
        },
        [inputFormat, onChange, targetInputFormat],
    );

    const doInputPropsOnChange = useCallback(
        (v: string) => {
            const event = { target: { value: v } };
            inputPropsOnChange?.(customDateEventHandler(event));
        },
        [inputPropsOnChange],
    );

    const onBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            if (inputFormat === targetInputFormat && value && typeof value === 'string') {
                const fixed = customDateHandler(value, true);
                if (fixed !== value) {
                    doInputPropsOnChange(fixed);
                    onBlurValue?.(fixed);
                }
            }

            propsOnBlur?.(e);
        },
        [doInputPropsOnChange, inputFormat, onBlurValue, propsOnBlur, targetInputFormat, value],
    );

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            lastKeyRef.current = e.key;

            if (e.key === 'Backspace' && maskedValue) {
                onClear?.(e as any);
                doInputPropsOnChange('');
            }
        },
        [maskedValue, onClear, doInputPropsOnChange],
    );
    
    return (
        <CtrlTextField
            ref={ref}
            {...other}
            value={value?.toString ? value.toString() : ''}
            onChangeNative={onChangeProxy}
            onBlur={onBlur}
            inputProps={{
                onChange: (e) => {
                    if (inputFormat === targetInputFormat && lastKeyRef.current !== 'Backspace') {
                        inputPropsOnChange?.(customDateEventHandler(e));
                    } else {
                        inputPropsOnChange?.(e);
                    }
                },
                onKeyDown: handleKeyDown,
                ...otherInputProps,
                placeholder: maskedValue || otherInputProps.placeholder,
            }}
            InputProps={{
                ...other.InputProps,
                endAdornment: (
                    <React.Fragment>
                        <CtrlIconButton
                            title="Clear"
                            size="small"
                            onClick={(e) => {
                                onClear?.(e);
                                doInputPropsOnChange('');
                            }}
                            className={clsx('ctrl-date-field-clear-button', {
                                'ctrl-date-field-has-value': value || maskedValue,
                            })}
                            tooltipFollowCursor
                            onFocus={(e: any) => e.target?.previousElementSibling?.focus?.()} // focuses date input area
                        >
                            <CloseIcon />
                        </CtrlIconButton>
                        {other.InputProps?.endAdornment}
                    </React.Fragment>
                ),
            }}
        />
    );
});

const DateInput = styled(DateInputComponentRef, { name: "DateInput" })(
    ({ theme, width, maskedValue }) => ({
        width,
        ".MuiIconButton-root": {
            transform: "translate(-16px, 0)",
            border: "none",
            color: theme.app.neutralColor.iconMain,
            ":hover": {
                color: theme.app.neutralColor.iconHover,
            },
            "&.Mui-disabled": {
                color: theme.app.neutralColor.iconDisabled,
            },
        },
        ".MuiSvgIcon-root": {
            color: theme.app.neutralColor.iconMain,
        },
        "& .MuiInputBase-adornedEnd.MuiOutlinedInput-root": {
            paddingRight: 0,
        },
        ".ctrl-date-field-clear-button": {
            display: "none",
        },
        ":hover": {
            "& :not(.Mui-disabled) .ctrl-date-field-has-value": {
                display: "flex",
                transform: `translate(-${theme.spacing(1)}, 0)`,
            },
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input::placeholder': {
            color: maskedValue ? theme.app.neutralColor.textDark : 'inherit',
            opacity: maskedValue ? '1 !important' : '0.42',
        },
    })
);

export interface CtrlDateFieldProps
    extends Omit<
        (DesktopDateTimePickerProps <Date, Date> & DesktopDatePickerProps <Date, Date> & DesktopTimePickerProps<Date, Date>),
        "renderInput" | "value" | "onChange"
    > {
    width?: number | string;
    value?: Date;
    maskedValue?: Date;
    onChange?: (value?: Date) => void;
    onTextChange?: (value: string) => void;
    required?: boolean;
    error?: string | boolean;
    tabIndex?: number;
    inputProps?: Omit<CtrlTextFieldProps, "value" | "onChange" | "onClear">;
    mode?: TInputMode;
}

const dateToText = (
    dt: Date | undefined | null,
    inputFormat: string,
) => {
    return dt && dayjs(dt).isValid() ? dayjs(dt).format(inputFormat) : "";
};

function CtrlDateFieldComponent(props: CtrlDateFieldProps) {
    const {
        inputFormat = props.mode === 'dateAndTime'
            ? defaultDateTimeInputFormat
            : props.mode === 'time'
                ? defaultTimeInputFormat
                : defaultDateInputFormat,
        className,
        width,
        value,
        maskedValue,
        onChange,
        onTextChange,
        required,
        error,
        tabIndex,
        inputProps,
        mode,
        ...other
    } = props;

    const textFieldRef = useRef<HTMLDivElement>(null);
    const [textValue, setTextValue] = useState("");

    const date = useMemo(
        () => (value && dayjs(value).isValid() ? dayjs(value).toDate() : null),
        [value]
    );

    useEffect(() => {
        const val = dateToText(date, inputFormat);
        setTextValue(val);
        onTextChange?.(val);
    }, [date, inputFormat, onTextChange]);

    const handleChange = useCallback(
        (val: Date | Dayjs | null) => {
            let newVal = val;
            if (dayjs.isDayjs(newVal)) {
                if ((newVal as Dayjs).isValid()) {
                    newVal = (newVal as Dayjs).toDate();

                    if (props.disableFuture && newVal > new Date()) {
                        newVal = new Date();
                    }
                } else {
                    newVal = null;
                }
            }
            onChange?.(newVal ? newVal : undefined);
        },
        [onChange, props.disableFuture]
    );

    const Picker =
        mode === 'dateAndTime' ? DesktopDateTimePicker : mode === 'time' ? DesktopTimePicker : DesktopDatePicker;
    
    return (
        <Picker
            {...other}
            value={date}
            onChange={handleChange}
            hideTabs={mode !== 'dateAndTime'}
            renderInput={(params) => (
                <DateInput
                    {...params}
                    ref={textFieldRef}
                    className={className}
                    width={width}
                    required={required}
                    error={error}
                    tabIndex={tabIndex}
                    onClear={() => {
                        handleChange(null);
                        setTextValue('');
                        onTextChange?.('');
                    }}
                    {...inputProps}
                    value={textValue}
                    maskedValue={maskedValue ? '**/**/****' : undefined}
                    onChange={(e) => {
                        setTextValue(e.target.value);
                        onTextChange?.(e.target.value);
                    }}
                    inputFormat={inputFormat}
                    onBlurValue={(v) => {
                        const djs = dayjs(v);
                        const valid = djs.isValid() && djs.format(inputFormat) === v;
                        handleChange(valid ? djs : null);
                    }}
                    mode={mode}
                />
            )}
            inputFormat={inputFormat}
            components={{
                OpenPickerIcon: mode === 'time' ? ClockIcon : CalendarIcon,
            }}
            PopperProps={{
                placement: 'bottom-start',
            }}
            onClose={() => {
                const input = textFieldRef.current?.querySelector('input');
                input && setTimeout(() => input.focus(), 200);
            }}
        />
    );
}

export const CtrlDateField = styled(CtrlDateFieldComponent, {
    name: "CtrlDateField",
})(({ theme }) => ({})) as typeof CtrlDateFieldComponent;
