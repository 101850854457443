import { styled } from '@mui/system';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { ReferralModel } from '../../ReferralModel';
import { Address } from '../Address';
import { CtrlCheckbox } from '../../../components/controls/CtrlCheckbox';
import { setStateToStorage } from '../../../common/localStorageUtils';
import { ArrowRightIcon } from '../../../theme/icons';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { attorneySubmitterTypes } from './AttorneyModel';
import { isAnyAddressFieldPopulated } from '../../../common/validationHelpers';
import { rememberDataVisible } from '../utils';

export interface AttorneyProps extends ViewProps<ReferralModel> {}
const sectionSystemCode: SectionSystemCode = 'Attorney';

function AttorneyComponent(props: AttorneyProps) {
    const { model } = props;
    const attorneyModel = model.attorneyModel;
    const state = attorneyModel.state.use();
    const { handleControlChange, getErrorMessage } = attorneyModel;

    const { otherSubmitterType } = model.submitterModel.state.use();
    const isSubmitter = attorneySubmitterTypes.includes(otherSubmitterType);
    const anyFieldPopulated = attorneyModel.isAnyFieldPopulated();
    const addressPopulated = isAnyAddressFieldPopulated(state.address);

    const { queryParams } = model.state.use();
    
    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="First Name"
                        value={state.firstName}
                        onChange={handleControlChange('firstName')}
                        error={getErrorMessage('firstName')}
                        maxCharacters={50}
                        required={isSubmitter || anyFieldPopulated}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Last Name"
                        value={state.lastName}
                        onChange={handleControlChange('lastName')}
                        error={getErrorMessage('lastName')}
                        maxCharacters={50}
                        required={isSubmitter || anyFieldPopulated}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Phone"
                        phoneFormat
                        value={state.phone}
                        onChange={handleControlChange('phone')}
                        error={getErrorMessage('phone')}
                        required={isSubmitter}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Ext. "
                        value={state.ext}
                        onChange={handleControlChange('ext')}
                        error={getErrorMessage('ext')}
                        maskProps={{
                            mask: '99999999',
                        }}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Cell Phone"
                        phoneFormat
                        value={state.cellPhone}
                        onChange={handleControlChange('cellPhone')}
                        error={getErrorMessage('cellPhone')}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Email"
                        value={state.email}
                        onChange={handleControlChange('email')}
                        error={getErrorMessage('email')}
                    />
                </div>
            </div>
            <Address
                values={state.address}
                handleChange={attorneyModel.handleControlChange}
                getErrorMessage={attorneyModel.getErrorMessage}
                requiredFields={addressPopulated ? ['address1', 'city', 'state', 'zip'] : []}
            />
            <div className="fields-container no-margin-row">
                <div className="span-12">
                    {rememberDataVisible(queryParams) && (
                        <CtrlCheckbox
                            label="Remember This Section Data"
                            value={state.rememberSectionData}
                            onChange={(value) => {
                                attorneyModel.handleControlChange('rememberSectionData')(value);
                                setStateToStorage(sectionSystemCode, attorneyModel.state.get(), value);
                            }}
                        />
                    )}
                    <CtrlButton
                        endIcon={<ArrowRightIcon />}
                        onClick={() => {
                            const state = attorneyModel.state.get();
                            model.validateSection(sectionSystemCode, attorneyModel);
                            rememberDataVisible(queryParams) && setStateToStorage(sectionSystemCode, state, state.rememberSectionData);
                        }}
                    >
                        Continue
                    </CtrlButton>
                </div>
            </div>
        </div>
    );
}

export const Attorney = styled(AttorneyComponent, { name: 'Attorney' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.no-margin-row': {
        marginTop: '0 !important',
        alignItems: 'center',
    },
}));
