import { TClientApiSettings } from '../common/types';
import { ApiClient } from './apiClient';
import { TGlobalState } from '../model/state';
import { referralModel } from '../pages/ReferralModel';
import _ from 'lodash';
import { Order } from './types/claim';
import { applyMasks } from './payloadBuilder';

export interface SubmittedOrderRequestResponse {
    orderRequestId: string;
    createdDateStringET: string;
    createdDateUTC: Date;
}

export const easyReferralApiClient: TClientApiSettings = { id: 'easyReferralApi' };

export const apiClient = new TGlobalState<ApiClient | undefined>(undefined);

export async function submitReferral(
    token: string,
    orderPayload: any,
    contextDataToken?: string,
): Promise<SubmittedOrderRequestResponse | undefined> {
    let submitUrl = 'api/referral/submit';

    if (contextDataToken) {
        submitUrl += `?contextDataToken=${contextDataToken}`;
    }

    const response = await apiClient
        .get()
        ?.callApi<SubmittedOrderRequestResponse>(submitUrl, 'POST', JSON.stringify(orderPayload), {
            'g-recaptcha-response': token,
            'Content-Type': 'application/json',
        });

    if (response?.error) {
        throw new Error('An error occurred while submitting referral');
    }

    return response?.data;
}

export async function uploadAttachments(): Promise<any> {
    const attachmentsModel = referralModel.attachmentsModel;

    const attachments = attachmentsModel.state.get().attachments.filter((a) => a.file && !a.orderRequestDocumentId);
    if (attachments.length === 0) {
        return;
    }

    const payload = attachmentsModel.getAttachmentsUploadPayload(attachments);
    const response = await apiClient.get()?.callApi('api/attachment', 'POST', payload);

    if (response?.error) {
        throw new Error('An error occurred while uploading attachments');
    }

    attachmentsModel.state.update((s) => {
        s.attachments?.forEach((attachment) => {
            attachment.orderRequestDocumentId = _.get(response?.data, attachment.id);
        });
    });
}

export async function printSummaryPage(order: any, submittedOrderRequest: SubmittedOrderRequestResponse) {
    const response = await apiClient
        .get()
        ?.callApi('api/referral/printSummaryPage', 'POST', JSON.stringify({ order, submittedOrderRequest }), {
            'Content-Type': 'application/json',
        });

    if (response?.error) {
        throw new Error('An error occurred while submitting referral');
    }

    return response;
}

export async function getContextData(token: string, userIP?: string) {
    const response = await apiClient
        .get()
        ?.callApi<Order>(`api/contextData?token=${token}${userIP ? `&userIP=${userIP}` : ''}`, 'GET');

    if (response?.error) {
        throw new Error('An error occurred while loading context data');
    }

    if (response?.data) {
        const mappedContextData = applyMasks(response?.data);
        return mappedContextData;
    }

    return response?.data;
}
