import { ValidatedState } from "../../../common/types";


export interface IMriPrescreeningState extends ValidatedState {
    isClaustrophobic?: string,
    hasPriorBodyAreaSurgeries?: string,
    bodyAreaAndType?: string,
    worksWithMetal?: string,
    hadMetalInEyes?: string,
    hasPacemaker?: string,
    isPregnant?: string
}


export const defaultMriPrescreeningState: IMriPrescreeningState = {
    isClaustrophobic: '',
    hasPriorBodyAreaSurgeries: '',
    bodyAreaAndType: '',
    worksWithMetal: '',
    hadMetalInEyes: '',
    hasPacemaker: '',
    isPregnant: '',
    errors: []
}