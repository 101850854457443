import { Paper, Popper, PopperPlacementType, styled } from "@mui/material";
import { CtrlTextField, CtrlTextFieldProps } from "./CtrlTextField";
import React, { ForwardedRef, ReactElement, useCallback } from "react";
import { CtrlIconButton } from "./CtrlIconButton";
import { ChevronDownIcon, ChevronUpIcon } from "../../theme/icons";

const StyledPoper = styled(Popper, { 
    name: "ComboTemplatePoper",
    shouldForwardProp: (prop) => prop !== "isOnModalDialog",
})<{isOnModalDialog?: boolean}>(
    ({ theme, placement, isOnModalDialog }) => ({
        ".combo-template-paper": {
            backgroundColor: theme.app.neutralColor.backgroundPaper,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            borderRadius: 12,
            transform: `translate(0, ${
                8 * (placement === "top-start" ? -1 : 1)
            }px)`,
            border: `solid 1px ${theme.app.neutralColor.borderMain}`,
            width: 'calc(100% - 2px)',
            shadow: theme.app.neutralColor.shadow1,
            padding: theme.spacing(2, 0),
        },
        ...(isOnModalDialog ? { zIndex: 1301 } : {}),
    })
);

interface CtrlComboTemplateProps extends CtrlTextFieldProps {
    open?: boolean;
    setOpen?: (value: boolean) => void;
    renderControl: () => ReactElement;
    handleKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => boolean;
    poperFocus?: boolean;
    inputText?: string;
    setInputText?: (value: string) => void;
    textFieldRef?: ForwardedRef<HTMLDivElement>;
    isOnModalDialog?: boolean;
}

const CtrlComboTemplateRef = React.forwardRef(
    function CtrlComboTemplateComponent(props: CtrlComboTemplateProps, ref) {
        const {
            open: propsOpen,
            setOpen: propsSetOpen,
            renderControl,
            poperFocus,
            disabled,
            handleKeyDown: propsHandleKeyDown,
            onBlur: propsOnBlur,
            onFocus: propsOnFocus,
            InputProps: propsInputProps,
            inputText,
            setInputText,
            textFieldRef,
            isOnModalDialog,
            ...other
        } = props;

        const [open, setOpen] = React.useState(false);
        const liveRef = React.useRef(false);
        const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
        const listboxRef = React.useRef<HTMLDivElement>(null);
        const inputRef = React.useRef<HTMLInputElement>();
        const clearRef = React.useRef(null);
        const preventBlurTime = React.useRef<Date>();
        const [placement, setPlacement] =
            React.useState<PopperPlacementType>("bottom-start");

        const doSetOpen = propsSetOpen ?? setOpen;
        const isOpen = propsOpen || open;

        const handlePopperUpdate = (data: any) => {
            setPlacement(data.placement);
        };

        React.useEffect(() => {
            liveRef.current = true;
            return () => {
                liveRef.current = false;
            };
        }, []);

        const preventBlur = useCallback(
            () => (preventBlurTime.current = new Date()),
            []
        );

        React.useImperativeHandle(ref, () => ({
            setOpen: doSetOpen,
            preventBlur: preventBlur,
            width: anchorEl && anchorEl.clientWidth,
        }));

        const handleOpen = useCallback(
            () => !isOpen && !disabled && doSetOpen(true),
            [disabled, doSetOpen, isOpen]
        );

        const handleClose = useCallback(
            () => isOpen && doSetOpen(false),
            [doSetOpen, isOpen]
        );

        const toggleOpen = useCallback(
            () => (isOpen ? handleClose() : handleOpen()),
            [handleClose, handleOpen, isOpen]
        );

        const handleKeyDown = useCallback(
            (event: React.KeyboardEvent<HTMLDivElement>) => {
                if (propsHandleKeyDown?.(event)) {
                    return;
                }

                switch (event.key) {
                    case "Escape":
                        if (isOpen) {
                            event.preventDefault();
                            event.stopPropagation();
                            handleClose();
                        }
                        break;
                    case "PageUp":
                    case "PageDown":
                    case "ArrowDown":
                    case "ArrowUp":
                        event.preventDefault();
                        handleOpen();
                        break;
                }

                props.onKeyDown?.(event);
            },
            [handleClose, handleOpen, isOpen, props, propsHandleKeyDown]
        );

        const handleBlur = useCallback(
            (event: React.FocusEvent<HTMLInputElement>) => {
                setTimeout(() => {
                    if (poperFocus) {
                        propsOnBlur?.(event);
                        return;
                    }

                    if (!liveRef.current) {
                        return;
                    }

                    if (listboxRef.current?.matches(":focus-within")) {
                        inputRef.current?.focus();
                        return;
                    }
                    if (preventBlurTime.current) {
                        let priventTime = new Date(preventBlurTime.current);
                        priventTime.setMilliseconds(
                            priventTime.getMilliseconds() + 200
                        );
                        if (priventTime > new Date()) {
                            inputRef.current?.focus();
                            return;
                        }
                    }
                    handleClose();
                }, 0);
            },
            [handleClose, poperFocus, propsOnBlur]
        );

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLInputElement>) => {
                !poperFocus && inputRef.current?.focus();
                if (!(clearRef.current && clearRef.current === e.target)) {
                    handleOpen();
                }
            },
            [handleOpen, poperFocus]
        );

        const handleMouseDown = useCallback(
            (event: React.MouseEvent<HTMLInputElement>) => {
                if (
                    !poperFocus &&
                    (event.target as HTMLElement).tagName !== "INPUT"
                ) {
                    event.preventDefault();
                }
            },
            [poperFocus]
        );

        const handleFocus = useCallback(
            (e: React.FocusEvent<HTMLInputElement>) => {
                if (poperFocus) {
                    propsOnFocus?.(e);
                } else {
                    e.target.select();
                }
            },
            [poperFocus, propsOnFocus]
        );

        const endIcons = (
            <CtrlIconButton
                size="small"
                onClick={toggleOpen}
                disabled={disabled}
                className="combo-template-open-icon"
                tabIndex={-1}
            >
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </CtrlIconButton>
        );

        return (
            <>
                <CtrlTextField
                    ref={textFieldRef}
                    disabled={disabled}
                    inputProps={{
                        onBlur: handleBlur,
                        onFocus: handleFocus,
                        ref: inputRef,
                        autoComplete: "off",
                        spellCheck: false,
                    }}
                    InputProps={{
                        ref: setAnchorEl,
                        ...propsInputProps,
                    }}
                    endIcons={endIcons}
                    onKeyDown={handleKeyDown}
                    onClick={handleClick}
                    onMouseDown={handleMouseDown}
                    value={inputText}
                    onChange={setInputText}
                    {...other}
                />
                <StyledPoper
                    open={isOpen && !disabled}
                    anchorEl={anchorEl ?? undefined}
                    placement={placement}
                    popperOptions={{
                        onFirstUpdate: handlePopperUpdate,
                    }}
                    isOnModalDialog={isOnModalDialog}
                >
                    <Paper
                        ref={listboxRef}
                        className="combo-template-paper"
                        onMouseDown={(event) =>
                            !poperFocus && event.preventDefault()
                        }
                    >
                        {renderControl()}
                    </Paper>
                </StyledPoper>
            </>
        );
    }
);

export const CtrlComboTemplate = styled(CtrlComboTemplateRef, {
    name: "CtrlComboTemplate",
})(({ theme }) => ({}));
