import { DefaultProps, ViewProps } from '../../../../common/types';
import { Box, Typography, styled } from '@mui/material';
import { CtrlComboSelect } from '../../../../components/controls/CtrlComboSelect';
import { CtrlTextArea } from '../../../../components/controls/CtrlTextArea';
import { CtrlCheckbox } from '../../../../components/controls/CtrlCheckbox';
import { CtrlRadio } from '../../../../components/controls/CtrlRadio';
import { theme } from '../../../../theme';
import { CtrlDateField } from '../../../../components/controls/CtrlDateField';
import { CtrlTextField } from '../../../../components/controls/CtrlTextField';
import { CtrlButton } from '../../../../components/controls/CtrlButton';
import { ArrowRightIcon, CloseIcon, PlusIcon } from '../../../../theme/icons';
import { ReferralModel } from '../../../ReferralModel';
import React from 'react';
import { Address } from '../../Address';
import { CtrlComboMultiSelect } from '../../../../components/controls/CtrlComboMultiSelect';
import { CtrlIconButton } from '../../../../components/controls/CtrlIconButton';
import { DurableMedicalEquipmentModel, requestedDeliveryDateRange } from './DurableMedicalEquipmentModel';
import { DurableMedicalEquipmentProduct } from './DurableMedicalEquipmentState';
import { requiredLabel } from '../../../../components/controls/elements/requiredLabel';
import clsx from 'clsx';
import { isAnyAddressFieldPopulated } from '../../../../common/validationHelpers';

interface ProductSectionProps extends DefaultProps {
    model: DurableMedicalEquipmentModel;
    product: DurableMedicalEquipmentProduct;
    products: DurableMedicalEquipmentProduct[];
}

function ProductSectionComponent(props: ProductSectionProps) {
    const { model, product, products } = props;

    return (
        <Box className={clsx('fields-container margin-top', props.className)} key={`dme-product-${product.id}`}>
            <div className="span-4">
                <CtrlComboSelect
                    selectFrom={model.getProductCategoriesForSection(product)}
                    value={product.productCategory}
                    onChange={model.handleProductCategoryChange(product)}
                />
            </div>
            <div className="span-8">
                <CtrlComboMultiSelect
                    onChange={model.handleProductChange(product)}
                    selectFrom={product.products}
                    value={product.selectedProducts}
                    disabled={!product.productCategory || !product.products?.length}
                    error={model.getErrorMessage(`product-${product.id}`)}
                    limitChips={4}
                />
            </div>
            {products.length > 1 ? (
                <CtrlIconButton
                    size="small"
                    className="image-remove-button"
                    onClick={model.removeProduct(product)}
                    title="Remove"
                >
                    <CloseIcon />
                </CtrlIconButton>
            ) : null}
        </Box>
    );
}

const ProductSection = styled(ProductSectionComponent, { name: 'ProductSection' })(({ theme }) => ({
    display: 'flex',
    border: `1px solid ${theme.app.neutralColor.borderMain}`,
    background: theme.app.neutralColor.backgroundSuperLight,
    position: 'relative',
    padding: theme.spacing(0, 4, 4, 4),
    margin: '8px 0px',
    borderColor: theme.app.neutralColor.borderMain,
    borderRadius: 12,
    columnGap: theme.spacing(4),
    '&:hover': {
        borderColor: theme.app.neutralColor.borderHover,
        '.image-remove-button': {
            display: 'flex',
        },
    },
    '.image-remove-button': {
        position: 'absolute',
        top: -8,
        right: -8,
        border: 'solid 1px',
        borderColor: theme.app.neutralColor.borderHover,
        borderRadius: 16,
        display: 'none',
    },
}));

export interface DurableMedicalEquipmentProps extends ViewProps<ReferralModel> {}

function DurableMedicalEquipmentComponent(props: DurableMedicalEquipmentProps) {
    const { model } = props;
    const durableMedicalEquipmentModel = model.durableMedicalEquipmentModel;
    const state = durableMedicalEquipmentModel.state.use();
    const patientState = model.claimPatientModel.state.use();
    const homeAddress = state.deliveryAddressType === 'Home';

    React.useEffect(() => {
        if(!state.productCategories || state.productCategories?.length === 0){
            durableMedicalEquipmentModel.loadProductCategoriesDropdownValues();
        }
    }, [durableMedicalEquipmentModel, state]);

    const addressPopulated = isAnyAddressFieldPopulated(state.deliveryAddressType === 'Home'
        ? model.claimPatientModel.state.get().address
        : state.address);

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="text-area-label span-12">
                    Select Product Category and Product(s) from the list or describe in Comments / Other Products field.
                </div>
            </div>
            <div className="fields-container padding-16 no-margin-top">
                <div className="span-4">Product Category</div>
                <div className="span-8">
                    {requiredLabel(
                        state.products.some((p) => p.productCategory?.value),
                        'Products',
                    )}
                </div>
            </div>
            {state.products.map((product) => {
                return (
                    <ProductSection model={durableMedicalEquipmentModel} product={product} products={state.products} />
                );
            })}
            <div>
                <CtrlButton
                    endIcon={<PlusIcon />}
                    onClick={durableMedicalEquipmentModel.addProduct}
                    disabled={state.products.length >= 5}
                >
                    Add Category
                </CtrlButton>
            </div>
            <div className="fields-container">
                <div className="span-12">
                    <CtrlTextArea
                        label="Comments / Other Products / Product Details"
                        required={!state.products.some((p) => p.productCategory?.label)}
                        maxCharacters={1000}
                        style={{ minHeight: 84 }}
                        value={state.comments}
                        onChange={durableMedicalEquipmentModel.handleControlChange('comments')}
                        error={durableMedicalEquipmentModel.getErrorMessage('comments')}
                    />
                </div>
            </div>
            <div>
                <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                    Some products may be available for rental. Are you interested in rental equipment, if available?
                </Typography>
                <CtrlRadio
                    name="availableForRental"
                    options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                    ]}
                    value={state.availableForRental}
                    onChange={durableMedicalEquipmentModel.handleChange}
                />
            </div>
            <div className="fields-container">
                <div className="span-3">
                    <CtrlCheckbox
                        label="Prescription"
                        value={state.prescription}
                        onChange={durableMedicalEquipmentModel.handleControlChange('prescription')}
                    />
                </div>
                <div className="span-3">
                    <CtrlDateField
                        label="Requested Delivery Date"
                        value={state.requestedDeliveryDate}
                        onChange={durableMedicalEquipmentModel.handleControlChange('requestedDeliveryDate')}
                        onTextChange={durableMedicalEquipmentModel.handleControlChange('requestedDeliveryDateString')}
                        error={durableMedicalEquipmentModel.getErrorMessage('requestedDeliveryDate')}
                        minDate={requestedDeliveryDateRange().from}
                        maxDate={requestedDeliveryDateRange().to}
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Contact Person"
                        value={state.contactPerson}
                        onChange={durableMedicalEquipmentModel.handleControlChange('contactPerson')}
                        maxCharacters={120}
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Contact Phone Number"
                        value={state.contactPhone}
                        onChange={durableMedicalEquipmentModel.handleControlChange('contactPhone')}
                        phoneFormat
                        error={durableMedicalEquipmentModel.getErrorMessage('contactPhone')}
                    />
                </div>
            </div>
            <div className="span-12 delivery-address-section">
                <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                    Delivery Address
                </Typography>
                <CtrlRadio
                    options={[
                        { label: 'Home', value: 'Home' },
                        { label: 'Hospital', value: 'Hospital' },
                        { label: 'Other', value: 'Other' },
                    ]}
                    name="deliveryAddressType"
                    value={state.deliveryAddressType}
                    onChange={durableMedicalEquipmentModel.handleChange}
                />
            </div>
            <Address
                values={homeAddress ? patientState.address : state.address}
                handleChange={durableMedicalEquipmentModel.handleControlChange}
                getErrorMessage={durableMedicalEquipmentModel.getErrorMessage}
                disabled={homeAddress}
                requiredFields={addressPopulated ? ['address1', 'city', 'state', 'zip'] : []}
            />
            <div className="fields-container">
                <div className="span-4">
                    <CtrlTextField
                        label="Vendor Name (if Already Involved)"
                        value={state.vendorName}
                        onChange={durableMedicalEquipmentModel.handleControlChange('vendorName')}
                        maxCharacters={200}
                    />
                </div>
                <div className="span-4">
                    <CtrlTextField
                        label="Vendor Phone"
                        value={state.vendorPhone}
                        onChange={durableMedicalEquipmentModel.handleControlChange('vendorPhone')}
                        phoneFormat
                        error={durableMedicalEquipmentModel.getErrorMessage('vendorPhone')}
                    />
                </div>
                <div className="span-4">
                    <CtrlTextField
                        label="Vendor Contact Name"
                        value={state.vendorContactName}
                        onChange={durableMedicalEquipmentModel.handleControlChange('vendorContactName')}
                        maxCharacters={100}
                    />
                </div>
            </div>
            <div className="fields-container is-rush-row">
                <div className="span-12">
                    <CtrlCheckbox
                        label="Rush"
                        value={state.isRush}
                        onChange={durableMedicalEquipmentModel.handleControlChange('isRush')}
                    />
                </div>
            </div>
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        durableMedicalEquipmentModel.removeEmptyProducts();
                        model.validateSection('DurableMedicalEquipment', durableMedicalEquipmentModel);
                        model.validateSection('Attachments', model.attachmentsModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const DurableMedicalEquipment = styled(DurableMedicalEquipmentComponent, { name: 'DurableMedicalEquipment' })(
    ({ theme }) => ({
        margin: theme.spacing(0, 6),
        padding: theme.spacing(0, 4, 4),
        '.delivery-address-section': {
            columnGap: theme.spacing(4),
            paddingTop: theme.spacing(4),
        },
        '.is-rush-row': {
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
        },
        '.padding-16': {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        '.margin-top': {
            marginTop: `${theme.spacing(2)} !important`,
        },
        '.no-margin-top': {
            marginTop: '0 !important',
        }
    }),
);
