import { Typography } from '@mui/material';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { ReferralModel } from '../../ReferralModel';
import { styled } from '@mui/system';
import { CtrlRadio } from '../../../components/controls/CtrlRadio';
import { theme } from '../../../theme';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../theme/icons';

export interface MriPrescreeningProps extends ViewProps<ReferralModel> {}
const sectionSystemCode: SectionSystemCode = 'PrescreeningQuestions';

function MriPrescreeningComponent(props: MriPrescreeningProps) {
    const { model } = props;
    const prescreeningQuestionsModel = model.prescreeningQuestionsModel;
    const state = prescreeningQuestionsModel.state.use();

    return (
        <div className={props.className}>
            <div className="question-row no-margin-top">
                <Typography variant="h2" color={theme.app.neutralColor.textMain}>
                    Are you claustrophobic?
                </Typography>
                <CtrlRadio
                    name="isClaustrophobic"
                    options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                    ]}
                    value={state.isClaustrophobic}
                    onChange={prescreeningQuestionsModel.handleChange}
                />
            </div>
            <div className="question-row">
                <Typography variant="h2" color={theme.app.neutralColor.textMain}>
                    Have you had any prior surgeries to the body area in which this test is needed?
                </Typography>
                <CtrlRadio
                    name="hasPriorBodyAreaSurgeries"
                    options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                    ]}
                    value={state.hasPriorBodyAreaSurgeries}
                    onChange={(e) => {
                        prescreeningQuestionsModel.handleChange(e);
                        if (e.target.value !== 'Yes') {
                            prescreeningQuestionsModel.handleControlChange('bodyAreaAndType')('');
                        }
                    }}
                />
            </div>
            <div className="sub-question-row">
                <Typography variant="body2" color={theme.app.neutralColor.textMain} className='body-area-text'>
                    Indicate body area and type
                </Typography>
                <CtrlTextField
                    name="bodyAreaAndType"
                    value={state.bodyAreaAndType}
                    onChangeNative={prescreeningQuestionsModel.handleChange}
                    disabled={!(state.hasPriorBodyAreaSurgeries === 'Yes')}
                />
            </div>
            <div className="question-row">
                <Typography variant="h2" color={theme.app.neutralColor.textMain}>
                    Do you work with or around metal, such as welding/grinding?
                </Typography>
                <CtrlRadio
                    name="worksWithMetal"
                    options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                    ]}
                    value={state.worksWithMetal}
                    onChange={(e) => {
                        prescreeningQuestionsModel.handleChange(e);
                        if (e.target.value !== 'Yes') {
                            prescreeningQuestionsModel.state.update((s) => (s.hadMetalInEyes = undefined));
                        }
                    }}
                />
            </div>
            <div className="sub-question-row">
                <Typography variant="body2" color={theme.app.neutralColor.textMain}>
                    Have you ever had a metal in your eyes?
                </Typography>
                <CtrlRadio
                    name="hadMetalInEyes"
                    options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                    ]}
                    value={state.hadMetalInEyes}
                    onChange={prescreeningQuestionsModel.handleChange}
                    disabled={!(state.worksWithMetal === 'Yes')}
                />
            </div>
            <div className="question-row">
                <Typography variant="h2" color={theme.app.neutralColor.textMain}>
                    Do you have a pacemaker?
                </Typography>
                <CtrlRadio
                    name="hasPacemaker"
                    options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                    ]}
                    value={state.hasPacemaker}
                    onChange={prescreeningQuestionsModel.handleChange}
                />
            </div>
            <div className="question-row">
                <Typography variant="h2" color={theme.app.neutralColor.textMain}>
                    Any chance of pregnancy?
                </Typography>
                <CtrlRadio
                    name="isPregnant"
                    options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                    ]}
                    value={state.isPregnant}
                    onChange={prescreeningQuestionsModel.handleChange}
                />
            </div>
            <div className="continue-button-row">
                <CtrlButton endIcon={<ArrowRightIcon/>} onClick={() => { model.validateSection(sectionSystemCode, prescreeningQuestionsModel) }}>
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const MriPrescreening = styled(MriPrescreeningComponent, { name: 'MriPrescreening' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.question-row': {
        display: 'flex',
        margin: theme.spacing(6, 0, 0, 0),
        alignItems: 'center',
        width: '65%',
        justifyContent: 'space-between',
    },
    '.sub-question-row': {
        display: 'flex',
        width: '65%',
        paddingLeft: theme.spacing(6),
        marginTop: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '.question-row h2': {
        marginRight: 10,
    },
    ".no-margin-top": {
        marginTop: 0
    },
    ".body-area-text":{
        width: 300
    }
}));
