import { SectionComponents, SectionSystemCode } from '../common/types';
import { ReferralModel } from './ReferralModel';
import React from 'react';
import {
    Dental,
    Diagnostics,
    Doctor,
    DurableMedicalEquipment,
    HomeHealthComplexCare,
    Language,
    PhysicalTherapy,
    Transportation,
} from './sections/serviceLines/index';
import {
    Adjuster,
    Attachments,
    ClaimPatient,
    Employer,
    Insurance,
    OtherParties,
    SpecialInstructions,
    Submitter,
} from './sections/index';
import { ReferringPhysician } from './sections/referringPhysician/ReferringPhysician';
import { CaseManager } from './sections/caseManager/CaseManager';
import { Attorney } from './sections/attorney/Attorney';
import { SectionSummary } from './sections/SectionSummary';
import { OtherPartiesSummary } from './sections/otherParties/OtherPartiesSummary';
import { SectionIcon } from './sections/SectionIcon';
import { MriPrescreening } from './sections/mriPrescreening/MriPrescreening';

export function ContentSectionsMap(model: ReferralModel) {
    return React.useMemo(
        () =>
            new Map<SectionSystemCode, React.JSX.Element | SectionComponents>([
                [
                    'Submitter',
                    {
                        details: <Submitter model={model} />,
                        summary: <SectionSummary model={model.submitterModel} />,
                        endIcon: (
                            <SectionIcon model={model.submitterModel} onClick={model.errorIconClick('Submitter')} />
                        ),
                    },
                ],
                [
                    'ClaimPatient',
                    {
                        details: <ClaimPatient model={model} />,
                        summary: <SectionSummary model={model.claimPatientModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.claimPatientModel}
                                onClick={model.errorIconClick('ClaimPatient')}
                            />
                        ),
                    },
                ],
                [
                    'Employer',
                    {
                        details: <Employer model={model} />,
                        summary: <SectionSummary model={model.employerModel} />,
                        endIcon: <SectionIcon model={model.employerModel} onClick={model.errorIconClick('Employer')} />,
                    },
                ],
                [
                    'Adjuster',
                    {
                        details: <Adjuster model={model} />,
                        summary: <SectionSummary model={model.adjusterModel} />,
                        endIcon: <SectionIcon model={model.adjusterModel} onClick={model.errorIconClick('Adjuster')} />,
                    },
                ],
                [
                    'Insurance',
                    {
                        details: <Insurance model={model} />,
                        summary: <SectionSummary model={model.insuranceModel} />,
                        endIcon: (
                            <SectionIcon model={model.insuranceModel} onClick={model.errorIconClick('Insurance')} />
                        ),
                    },
                ],
                [
                    'OtherParties',
                    {
                        details: <OtherParties model={model} />,
                        summary: <OtherPartiesSummary model={model} />,
                        endIcon: (
                            <SectionIcon
                                model={model.otherPartiesModel}
                                onClick={model.errorIconClick('OtherParties')}
                            />
                        ),
                    },
                ],
                [
                    'ReferringPhysician',
                    {
                        details: <ReferringPhysician model={model} />,
                        summary: <SectionSummary model={model.referringPhysicianModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.referringPhysicianModel}
                                onClick={model.errorIconClick('ReferringPhysician')}
                            />
                        ),
                    },
                ],
                [
                    'Attorney',
                    {
                        details: <Attorney model={model} />,
                        summary: <SectionSummary model={model.attorneyModel} />,
                        endIcon: <SectionIcon model={model.attorneyModel} onClick={model.errorIconClick('Attorney')} />,
                    },
                ],
                [
                    'NurseCaseManager',
                    {
                        details: <CaseManager model={model} />,
                        summary: <SectionSummary model={model.caseManagerModel} />,
                        endIcon: (
                            <SectionIcon model={model.caseManagerModel} onClick={model.errorIconClick('NurseCaseManager')} />
                        ),
                    },
                ],
                [
                    'DurableMedicalEquipment',
                    {
                        details: <DurableMedicalEquipment model={model} />,
                        summary: <SectionSummary model={model.durableMedicalEquipmentModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.durableMedicalEquipmentModel}
                                onClick={model.errorIconClick('DurableMedicalEquipment')}
                            />
                        ),
                    },
                ],
                [
                    'DiagnosticServices',
                    {
                        details: <Diagnostics model={model} />,
                        summary: <SectionSummary model={model.diagnosticModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.diagnosticModel}
                                onClick={model.errorIconClick('DiagnosticServices')}
                            />
                        ),
                    },
                ],
                [
                    'PhysicalMedicine',
                    {
                        details: <PhysicalTherapy model={model} />,
                        summary: <SectionSummary model={model.physicalTherapyModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.physicalTherapyModel}
                                onClick={model.errorIconClick('PhysicalMedicine')}
                            />
                        ),
                    },
                ],
                [
                    'HomeHealthCare',
                    {
                        details: <HomeHealthComplexCare model={model} />,
                        summary: <SectionSummary model={model.homeHealthComplexCareModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.homeHealthComplexCareModel}
                                onClick={model.errorIconClick('HomeHealthCare')}
                            />
                        ),
                    },
                ],
                [
                    'TransportationServices',
                    {
                        details: <Transportation model={model} />,
                        summary: <SectionSummary model={model.transportationModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.transportationModel}
                                onClick={model.errorIconClick('TransportationServices')}
                            />
                        ),
                    },
                ],
                [
                    'LanguageTranslation',
                    {
                        details: <Language model={model} />,
                        summary: <SectionSummary model={model.languageModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.languageModel}
                                onClick={model.errorIconClick('LanguageTranslation')}
                            />
                        ),
                    },
                ],
                [
                    'dsDoctorServices',
                    {
                        details: <Doctor model={model} />,
                        summary: <SectionSummary model={model.doctorModel} />,
                        endIcon: (
                            <SectionIcon model={model.doctorModel} onClick={model.errorIconClick('dsDoctorServices')} />
                        ),
                    },
                ],
                [
                    'dsDentalServices',
                    {
                        details: <Dental model={model} />,
                        summary: <SectionSummary model={model.dentalModel} />,
                        endIcon: (
                            <SectionIcon model={model.dentalModel} onClick={model.errorIconClick('dsDentalServices')} />
                        ),
                    },
                ],
                [
                    'PrescreeningQuestions',
                    {
                        details: <MriPrescreening model={model} />,
                        summary: <SectionSummary model={model.prescreeningQuestionsModel} />,
                        endIcon: (
                            <SectionIcon model={model.prescreeningQuestionsModel} onClick={model.errorIconClick('PrescreeningQuestions')} />
                        ),
                    },
                ],
                [
                    'Attachments',
                    {
                        details: <Attachments model={model} />,
                        summary: <SectionSummary model={model.attachmentsModel} />,
                        endIcon: (
                            <SectionIcon model={model.attachmentsModel} onClick={model.errorIconClick('Attachments')} />
                        ),
                    },
                ],
                [
                    'SpecialInstructions',
                    {
                        details: <SpecialInstructions model={model} />,
                        summary: <SectionSummary model={model.specialInstructionsModel} />,
                        endIcon: (
                            <SectionIcon
                                model={model.specialInstructionsModel}
                                onClick={model.errorIconClick('SpecialInstructions')}
                            />
                        ),
                    },
                ],
            ]),
        [model],
    );
}
