import _ from 'lodash';
import { KeyValueObject, ValidatedState } from '../../common/types';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const setSubmitterFields = (
    submitterFields: KeyValueObject<any>,
    state: KeyValueObject<any> & ValidatedState,
) => {
    _.forEach(submitterFields, (value, key) => {
        const stateHasFieldValue = _.get(state, key);
        if (!stateHasFieldValue) {
            _.set(state, key, value);

            const errors = state.errors.find((err) => err.fieldName === key);
            if (errors) {
                errors.errors = [];
            }
        }
    });
};

export const fixCollectionFieldsErrorsOrder = (
    fieldName: string,
    fieldLabel: string,
    state: KeyValueObject<any> & ValidatedState,
) => {
    const errors = state.errors.filter((error) => error.fieldName.startsWith(`${fieldName}[`));

    const indexMap = new Map<number, number>();
    errors.forEach((error) => {
        const oldIndex = parseInt(error.fieldName.match(/\d+/)![0]);
        indexMap.set(oldIndex, indexMap.size - 1);
    });

    state.errors = state.errors.map((error) => {
        if (error.fieldName.startsWith(`${fieldName}[`)) {
            const oldIndex = parseInt(error.fieldName.match(/\d+/)![0]);
            const newIndex = indexMap.get(oldIndex)!;

            error.fieldName = `${fieldName}[${newIndex}].${error.fieldName.split('.').splice(1).join('.')}`;
            error.fieldLabel = `${fieldLabel} ${newIndex + 1} - ${error.fieldLabel.split('-').splice(1).join('-')}`;
        }
        return error;
    });
};

export type TPortalMode = 'EZReferral' | 'EZAuth';
export type TPortalCustomization = 'Default' | 'GM' | 'Navigere' | 'DD' | 'DX' | 'DME' | 'HH' | 'PHYM' | 'TL';

export function useUrlCheck() {
  const location = useLocation();

  const ezAuthPaths = [
    'smartforms',
    'ezauth',
    'physicianservices'
  ];

  const customizationPaths: Map<string, TPortalCustomization> = new Map([
    ['gm', 'GM'],
    ['navigere', 'Navigere'],
    ['dd', 'DD'],
    ['dx', 'DX'],
    ['dme', 'DME'],
    ['hh', 'HH'],
    ['phym', 'PHYM'],
    ['tl', 'TL'],
  ]);

  const mode: TPortalMode = ezAuthPaths.some(
      (path) => location.pathname.startsWith(`/${path}`) || window.location.hostname.startsWith(path),
    )
      ? 'EZAuth'
      : 'EZReferral';

    let customization: TPortalCustomization = 'Default';

    for (const [key, value] of customizationPaths) {
        if(location.pathname.startsWith(`/${key}`) || window.location.hostname.startsWith(key)){
            customization = value;
            break;
        }
    }

    const queryParams: Record<string, string> = {};
    for (const [key, value] of new URLSearchParams(location.search).entries()) {
        queryParams[key] = value;
    }

  return { mode, customization, queryParams };
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    // message may not work in all browsers
    event.returnValue = 'Are you sure you want to leave? Your changes may not be saved.';
};

export function useBeforeUnload() {
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
}

export const removeBeforeUnload = () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
};

export function rememberDataVisible(queryParams: Record<string, string>) {
    return !_.has(queryParams, 'token');
}
