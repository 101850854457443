import { SectionSystemCode } from "../../../common/types";
import { validateWarning, requiredForService, validatePhoneNumber, validateZipCode, requiredIfAddressPopulated, validateOptionExists } from "../../../common/validationHelpers";
import { ChangeableModel } from "../../../model/model";
import { valuesManager } from "../../../services/valuesManager/valuesManager";
import { referralModel } from "../../ReferralModel";
import { IEmployerState } from "./EmployerState";

export const requiredCompanyServices: SectionSystemCode[] = ['DiagnosticServices', 'DurableMedicalEquipment', 'HomeHealthCare'];

const getAddress = () => referralModel.employerModel.state.get().address;

export const employerValidators = [
    { fieldName: 'company', fieldLabel: 'Company', validateFunctions: [requiredForService(requiredCompanyServices)] },
    { fieldName: 'phone', fieldLabel: 'Phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'address.address1', fieldLabel: 'Address 1', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [requiredIfAddressPopulated(getAddress)] },
    { fieldName: 'address.state.value', fieldLabel: 'State', validateFunctions: [requiredIfAddressPopulated(getAddress), validateOptionExists(valuesManager.resourceValues.states),] },
    { fieldName: 'address.zip', fieldLabel: 'Zip code', validateFunctions: [validateZipCode, requiredIfAddressPopulated(getAddress)] },
    {
        fieldName: 'company',
        fieldLabel: 'Company',
        validateFunctions: [
            validateWarning(
                ({ value, referralModel }) =>
                    !value &&
                    referralModel.isServiceSelected(['PhysicalMedicine']) &&
                    !referralModel.isServiceSelected(requiredCompanyServices),
            ),
        ],
        isWarning: true,
    },
];

export class EmployerModel extends ChangeableModel<IEmployerState> {
    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([
            ["Company", state.company],
            ["Address", [state.address.address1, state.address.address2, state.address.city, state.address.state?.value, state.address.zip].filter((v) => v).join(' ')],
        ]);
    }
}