import { ValidatedState } from "../../../../common/types"
import { TResourceValue, valuesManager } from "../../../../services/valuesManager/valuesManager";

export interface IDoctorState extends ValidatedState {
    referralReason?: TResourceValue;
    referralReasonDescription?: string;
    referralFor?: TResourceValue;
    referralForDescription?: string;
    visitPurpose?: TResourceValue;
    visitPurposeDescription?: string;
    providerTypeRequired?: string;
    howDidTheInjuryOccur?: string;
    previousPhysician?: string;
    isRush: boolean;
}

export const defaultDoctorState: IDoctorState = {
    referralReason: valuesManager.resourceValues.referralReasonValues[0],
    referralFor: undefined,
    visitPurpose: valuesManager.resourceValues.visitPurposeValues[0],
    isRush: false,
    errors: []
}