import { styled } from '@mui/system';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { ReferralModel } from '../../ReferralModel';
import { CtrlTextField } from '../../../components/controls/CtrlTextField';
import { adjusterSubmitterTypes, requiredNameServices, requiredPhoneServices } from './AdjusterModel';
import { CtrlCheckbox } from '../../../components/controls/CtrlCheckbox';
import { ArrowRightIcon } from '../../../theme/icons';
import { setStateToStorage } from '../../../common/localStorageUtils';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { useEffect } from 'react';
import { rememberDataVisible } from '../utils';

export interface AdjusterProps extends ViewProps<ReferralModel> {}
const sectionSystemCode: SectionSystemCode = 'Adjuster';

function AdjusterComponent(props: AdjusterProps) {
    const { model } = props;
    const adjusterModel = model.adjusterModel;
    const { selectedServices, mode, queryParams } = model.state.use();
    const state = adjusterModel.state.use();
    const { submitterType } = model.submitterModel.state.use();
    const { handleControlChange, getErrorMessage } = adjusterModel;
    const isSubmitter = adjusterSubmitterTypes.includes(submitterType);

    useEffect(() => {
        adjusterModel.clearValidationMessages();
    }, [adjusterModel, isSubmitter, selectedServices]);

    const anyFieldPopulated = adjusterModel.isAnyFieldPopulated();

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="First Name"
                        value={state.firstName}
                        onChange={handleControlChange('firstName')}
                        error={getErrorMessage('firstName')}
                        maxCharacters={50}
                        required={
                            isSubmitter ||
                            (model.isServiceSelected(requiredNameServices) && mode === 'EZReferral') ||
                            anyFieldPopulated
                        }
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Last Name"
                        value={state.lastName}
                        onChange={handleControlChange('lastName')}
                        error={getErrorMessage('lastName')}
                        maxCharacters={50}
                        required={
                            isSubmitter ||
                            (model.isServiceSelected(requiredNameServices) && mode === 'EZReferral') ||
                            anyFieldPopulated
                        }
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-3">
                    <CtrlTextField
                        label="Phone"
                        value={state.phone}
                        onChange={handleControlChange('phone')}
                        error={getErrorMessage('phone')}
                        phoneFormat
                        required={
                            isSubmitter || (model.isServiceSelected(requiredPhoneServices) && mode === 'EZReferral')
                        }
                    />
                </div>
                <div className="span-3">
                    <CtrlTextField
                        label="Ext."
                        value={state.ext}
                        onChange={handleControlChange('ext')}
                        maskProps={{
                            mask: '99999999',
                        }}
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Cell Phone"
                        value={state.cellPhone}
                        onChange={handleControlChange('cellPhone')}
                        error={getErrorMessage('cellPhone')}
                        phoneFormat
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlTextField
                        label="Fax"
                        value={state.fax}
                        onChange={handleControlChange('fax')}
                        error={getErrorMessage('fax')}
                        phoneFormat
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Email"
                        value={state.email}
                        onChange={handleControlChange('email')}
                        error={getErrorMessage('email')}
                        required={isSubmitter}
                    />
                </div>
            </div>
            <div className="continue-button-row">
                {rememberDataVisible(queryParams) && (
                    <CtrlCheckbox
                        label="Remember This Section Data"
                        value={state.rememberSectionData}
                        onChange={(value) => {
                            handleControlChange('rememberSectionData')(value);
                            setStateToStorage(sectionSystemCode, adjusterModel.state.get(), value);
                        }}
                    />
                )}
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        const state = adjusterModel.state.get();
                        model.validateSection(sectionSystemCode, adjusterModel);
                        rememberDataVisible(queryParams) && setStateToStorage(sectionSystemCode, state, state.rememberSectionData);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const Adjuster = styled(AdjusterComponent, { name: 'Adjuster' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
}));