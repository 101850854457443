import { Typography, styled } from "@mui/material";
import { ReactElement, useCallback, useEffect, useRef } from "react";
import { CtrlTextFieldProps } from "./CtrlTextField";
import { CtrlComboTemplate } from "./CtrlComboTemplate";
import { CtrlList, ctrlListItemHeight } from "./CtrlList";
import React from "react";
import {
    defaultGetOptionDisabled,
    defaultOptionGetLabel,
    useFilteredOptions,
    useSelectOptions,
} from "./utils";
import { FixedSizeList } from "react-window";
import { TOptionHint } from "../../common/types";
import { isEmpty } from "lodash";
import clsx from "clsx";

const maxVisibleItems = 10;
const headerHeight  = 25;

const ListContainer = styled("div", { name: "ComboSelectListContainer" })<{
    width?: number | string;
    height?: number | string;
    header?: boolean;
}>(({ theme, width, height, header }) => ({
    width,
    height,
    minHeight: 18 + (header ? headerHeight : 0),
    ul: {
        margin: 0,
    },
    ".ctrl-list-empty-message": {
        color: theme.app.neutralColor.textDefault,
        textAlign: "center",
    },
    ".header-container": {
        padding: theme.spacing(1, 2),
        height: headerHeight
    },
    ".header-container-right-padding": {
        paddingRight: 24
    }
}));

function hoverNextInex<T = any>(
    options: readonly T[],
    step: number,
    getLabel: (value: T, index?: number) => string,
    hovered?: T,
    customRows?: boolean
): number {
    if (!options.length) return -1;
    let hoveredIndex = -1;
    if(customRows){
        hoveredIndex = hovered ? options.findIndex((o: any)=> o.value === getLabel(hovered)) : -1;
    }
    else{
        hoveredIndex = hovered ? options.indexOf(hovered) : -1;
    }

    if (hoveredIndex >= 0) {
        return step > 0
            ? Math.min(options.length - 1, hoveredIndex + step)
            : Math.max(0, hoveredIndex + step);
    } else {
        return step > 0 ? 0 : options.length - 1;
    }
}

export interface CtrlComboSelectProps<T = any>
    extends Omit<CtrlTextFieldProps, "value" | "onChange"> {
    selectFrom?: readonly T[] | (() => T[] | Promise<T[]>);
    getLabel?: (value: T, index?: number) => string;
    getIcon?: (value: T, index?: number) => ReactElement;
    getOptionClass?: (value: T, index?: number) => string;
    getOptionDisabled?: (value: T, index?: number) => boolean;
    getOptionHint?: (value: T, index?: number) => TOptionHint;
    value?: T;
    onChange: (value?: T) => void;
    freeText?: boolean;
    readonly?: boolean;
    header?: string | ReactElement;
    customRows?: boolean;
    getSelected?: (o: T, selected?: T) => boolean;
    getHovered?: (o: T, hovered?: T) => boolean;
    onClose?: () => void;
    searchable?: boolean;
    listWidth?: number | string;
    isOnModalDialog?: boolean;
}

function CtrlComboSelectComponent<T = any>(props: CtrlComboSelectProps<T>) {
    const {
        getLabel: propsGetLabel,
        getIcon,
        getOptionClass,
        getOptionDisabled: propsGetOptionDisabled,
        getOptionHint,
        value,
        onChange,
        freeText,
        readonly,
        disabled,
        onClear: propsOnClear,
        selectFrom,
        header,
        customRows,
        getSelected: getSelectedProps,
        getHovered: getHoveredProps,
        onClose,
        searchable = true,
        listWidth,
        isOnModalDialog,
        ...other
    } = props;

    const [open, setOpen] = React.useState(false);
    const [inputText, setInputText] = React.useState<string>("");
    const [search, setSearch] = React.useState("");
    const textFieldRef = useRef<HTMLDivElement | null>(null);
    const [hovered, setHovered] = React.useState<T | undefined>();
    const listRef = useRef<FixedSizeList | null>(null);

    const getLabel = useCallback(
        (option: T, index?: number) => {
            return propsGetLabel
                ? propsGetLabel(option, index)
                : defaultOptionGetLabel(option);
        },
        [propsGetLabel]
    );

    const getOptionDisabled = useCallback(
        (option: T, index?: number) => {
            return propsGetOptionDisabled
                ? propsGetOptionDisabled(option, index)
                : defaultGetOptionDisabled(option);
        },
        [propsGetOptionDisabled]
    );

    const { options, loading } = useSelectOptions(selectFrom, open);
    const filteredOptions = useFilteredOptions(options, search, getLabel);

    useEffect(() => {
        if (open && value !== undefined && (filteredOptions.includes(value) || getHoveredProps)) {
            setHovered(value);
        } else {
            setHovered(undefined);
        }
    }, [value, filteredOptions, open, getHoveredProps]);

    const doSetInputText = useCallback(
        (val: string) => {
            if (freeText) {
                if (onChange) {
                    if(!customRows || ((value as any)?.value !== val)){
                        onChange(val as T);
                    }
                }
                if (open) {
                    searchable && setSearch(val);
                }
            } else {
                if (open) {
                    setInputText(val);
                    searchable && setSearch(val);
                    if(isEmpty(val)){
                        onChange(undefined);
                    }
                }
            }
        },
        [customRows, freeText, onChange, open, searchable, value]
    );

    React.useEffect(() => {
        if (!freeText) {
            if (!open) {
                setInputText(value ? getLabel(value) : "");
            }
        }
    }, [freeText, getLabel, open, value]);

    React.useEffect(() => {
        if (freeText) {
            if (value && typeof value !== "string") {
                setInputText(getLabel(value));
            }
            else{
                setInputText(value as string);
            }
        }
    }, [freeText, getLabel, value]);

    const doSetOpen = useCallback(
        (val: boolean, clearInput: boolean = true) => {
            setOpen(val);
            if (!freeText) {
                if (!val) {
                    clearInput && setInputText("");
                    setSearch("");
                }
            } else {
                setSearch("");
            }

            if (!val) {
                onClose?.();
            }
        },
        [freeText, onClose]
    );

    const onClear = useCallback(() => {
        if (!disabled) {
            onChange?.(undefined);
            doSetOpen(false);
        }
        propsOnClear?.();
        return true;
    }, [disabled, doSetOpen, onChange, propsOnClear]);

    const onItemClick = useCallback(
        (item: T) => {
            if (!readonly && onChange) {
                if (freeText && !customRows) {
                    doSetInputText(getLabel(item));
                } else {
                    onChange(item);
                }
            }
            doSetOpen(false, false);
        },
        [customRows, doSetInputText, doSetOpen, freeText, getLabel, onChange, readonly]
    );

    const getSelected = useCallback(
        (o: T) => {
            return getSelectedProps ? getSelectedProps(o, value) : value === o;
        },
        [getSelectedProps, value],
    );

    const getHovered = useCallback(
        (o: T) => {
            return getHoveredProps ? getHoveredProps(o, hovered) : hovered !== undefined && hovered === o;
        },
        [getHoveredProps, hovered],
    );

    const onMouseHover = useCallback((o: T) => setHovered(o), []);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            switch (e.key) {
                case "ArrowDown":
                case "PageDown":
                    if (open) {
                        e.stopPropagation();
                        e.preventDefault();

                        const nextIndex = hoverNextInex(
                            filteredOptions,
                            e.key === "PageDown" ? 9 : 1,
                            getLabel,
                            hovered,
                            customRows
                        );
                        setHovered(
                            nextIndex < 0
                                ? undefined
                                : filteredOptions[nextIndex]
                        );
                        if (listRef.current && nextIndex >= 0) {
                            listRef.current.scrollToItem(nextIndex);
                        }
                    }
                    return open;
                case "ArrowUp":
                case "PageUp":
                    if (open) {
                        e.stopPropagation();
                        e.preventDefault();
                        const nextIndex = hoverNextInex(
                            filteredOptions,
                            e.key === "PageUp" ? -9 : -1,
                            getLabel,
                            hovered,
                            customRows
                        );
                        setHovered(
                            nextIndex < 0
                                ? undefined
                                : filteredOptions[nextIndex]
                        );
                        if (listRef.current && nextIndex >= 0) {
                            listRef.current.scrollToItem(nextIndex);
                        }
                    }
                    return open;
                case "Enter":
                    if (
                        open &&
                        hovered !== value &&
                        (!hovered || !getOptionDisabled(hovered))
                    ) {
                        e.stopPropagation();
                        e.preventDefault();
                        onChange(hovered);
                        setOpen(false);
                    }
                    return open;
            }
            return false;
        },
        [customRows, filteredOptions, getLabel, getOptionDisabled, hovered, onChange, open, value]
    );

    useEffect(() => {
        if (value !== undefined && open) {
            setTimeout(() => {
                const valueIndex = filteredOptions.indexOf(value);
                if (valueIndex >= 0) {
                    listRef.current?.scrollToItem(valueIndex);
                }
            }, 0);
        }
    }, [value, open, filteredOptions]);

    const renderDropDown = useCallback(() => {
        const height =
            Math.min(filteredOptions.length, maxVisibleItems) * ctrlListItemHeight + (header ? headerHeight : 0);
        return (
            <ListContainer
                className="list-container"
                width={listWidth || (textFieldRef.current ? textFieldRef.current.clientWidth : 200)}
                height={height}
                header={!!header}
            >
                {header ? (
                    <div
                        className={clsx(
                            options.length > maxVisibleItems ? 'header-container-right-padding' : '',
                            'header-container',
                        )}
                    >
                        {typeof header === 'string' ? <Typography variant="h2">{header}</Typography> : header}
                    </div>
                ) : null}
                <CtrlList
                    ref={listRef}
                    options={filteredOptions}
                    loading={loading}
                    onClick={onItemClick}
                    getSelected={getSelected}
                    getHovered={getHovered}
                    getLabel={propsGetLabel}
                    getIcon={getIcon}
                    getOptionClass={getOptionClass}
                    getOptionDisabled={getOptionDisabled}
                    getOptionHint={getOptionHint}
                    emptyMessage="no results"
                    onMouseHover={onMouseHover}
                    header={!!header}
                    customRows={customRows}
                />
            </ListContainer>
        );
    }, [
        filteredOptions,
        header,
        listWidth,
        options.length,
        loading,
        onItemClick,
        getSelected,
        getHovered,
        propsGetLabel,
        getIcon,
        getOptionClass,
        getOptionDisabled,
        getOptionHint,
        onMouseHover,
        customRows,
    ]);

    return (
        <CtrlComboTemplate
            textFieldRef={textFieldRef}
            renderControl={renderDropDown}
            inputText={inputText}
            setInputText={doSetInputText}
            open={open}
            setOpen={doSetOpen}
            disabled={disabled}
            onClear={onClear}
            handleKeyDown={handleKeyDown}
            isOnModalDialog={isOnModalDialog}
            {...other}
        />
    );
}

export const CtrlComboSelect = styled(CtrlComboSelectComponent, {
    name: "CtrlComboSelect",
})(({ theme }) => ({
    ".list-container": {
        display: "flex",
    }
})) as typeof CtrlComboSelectComponent;
