import { ViewProps } from '../../../../common/types';
import { Typography, styled } from '@mui/material';
import { CtrlComboMultiSelect } from '../../../../components/controls/CtrlComboMultiSelect';
import { CtrlTextField } from '../../../../components/controls/CtrlTextField';
import { CtrlDateField } from '../../../../components/controls/CtrlDateField';
import { CtrlTextArea } from '../../../../components/controls/CtrlTextArea';
import { CtrlCheckbox } from '../../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../../ReferralModel';
import { valuesManager } from '../../../../services/valuesManager/valuesManager';
import { theme } from '../../../../theme';
import { CtrlRadio } from '../../../../components/controls/CtrlRadio';
import { patientRadioButtonValues, careAddressValues } from './HomeHealthComplexCareState';
import { CtrlButton } from '../../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../../theme/icons';
import React from "react";
import { Address } from '../../Address';
import { useCallback } from 'react';
import { hospitalDischargeDateRange, requestedStartOfCareDateRanges } from './HomeHealthComplexCareModel';
import { isAnyAddressFieldPopulated } from '../../../../common/validationHelpers';

export interface HomeHealthComplexCareProps extends ViewProps<ReferralModel> {}

function HomeHealthComplexCareComponent(props: HomeHealthComplexCareProps) {
    const { model } = props;
    const homeHealthComplexCareModel = model.homeHealthComplexCareModel;
    const state = homeHealthComplexCareModel.state.use();
    const claimPatientState = model.claimPatientModel.state.use();
   
    const getFrequencyTypeError = useCallback(
        (fieldName: string) => homeHealthComplexCareModel.getErrorMessage(`${fieldName}`),
        [homeHealthComplexCareModel],
    );

    const hhServiceChecked = state.homeHealthServices?.some(x => x.checked);

    const addressPopulated = isAnyAddressFieldPopulated(state.careAddress === 'Home'
        ? model.claimPatientModel.state.get().address
        : state.address);

    return (
        <div className={props.className}>
        <div className="hh-header">
        <div className="service-row" style={{ paddingTop: 0 }}>
            <div style={{ width: '30%' }}>
                    Home Health Service
            </div>
            <div className="frequency-header">
                Frequency / Duration / Type
                {hhServiceChecked && <span className = 'frequency-required'> *</span>}
            </div>
            </div>
        </div>
        <div className="services-section">
           {state.homeHealthServices?.map((service, index) =>{
            const uniqueKey = `service_${service.label}_${index}`;
            return (
            <div className="service-row" style={{ paddingTop: 0 }}>
                 <React.Fragment key={uniqueKey}>
                <div style={{ width: '30%' }}>
                    <CtrlCheckbox
                        key={service.value}
                        label={service.label}
                        value={service.checked}
                        onChange={() => {homeHealthComplexCareModel.handleHhServiceChange(service)}}
                    />
                </div>
                <div className="frequency">
                    <CtrlTextField
                        value={service.checked ? service.frequencyDurationType : ''}
                        onChange={(value) => {homeHealthComplexCareModel.handleHhfrequencyDurationTypeChange(service, value)}}
                        maxCharacters={256}
                        disabled={!service.checked}
                        required={service.checked}
                        error={getFrequencyTypeError(service.value)}
                    />
                </div>
                </React.Fragment>
            </div>
                    )
            })}
            
        </div>        
        <div className="fields-container">
            <div className="span-6">
                <CtrlComboMultiSelect
                        label ="Medical Supplies"
                        selectFrom={valuesManager.resourceValues.medicalSupplies}
                        onChange={homeHealthComplexCareModel.handleControlChange('selectedMedicalSuppliesValue')}
                        value= {state.selectedMedicalSuppliesValue}
                    />
            </div>
            <div className="span-6">
                <CtrlComboMultiSelect
                        label= "Respiratory Supplies"
                        selectFrom={valuesManager.resourceValues.respiratorySupplies}
                        onChange={homeHealthComplexCareModel.handleControlChange('selectedRespiratorySuppliesValue')}
                        value= {state.selectedRespiratorySuppliesValue}
                    />
            </div>
        </div>
        <div className="fields-container">
            <div className="span-12">
                <CtrlTextArea
                        label="Comments / Additional Details"
                        maxCharacters={1000}
                        style={{ minHeight: 84 }}
                        onChange={homeHealthComplexCareModel.handleControlChange('commentsAdditionalDetails')}
                        value={state.commentsAdditionalDetails}
                        name="commentsAdditionalDetails"
                        required={!state.homeHealthServices?.some(service => service.checked)}
                        error={homeHealthComplexCareModel.getErrorMessage("commentsAdditionalDetails")}
                    />
            </div>
        </div>
        <div className="fields-container">
            <div className="span-6">
                <CtrlCheckbox 
                    label="Prescription/Physician Orders?" 
                    value={state.isPrescriptionPhysicianOrders} 
                    onChange={homeHealthComplexCareModel.handleControlChange('isPrescriptionPhysicianOrders')}
                />
            </div>
            <div className="span-6">
                <CtrlDateField 
                        label="Requested Start of Care"
                        onChange={homeHealthComplexCareModel.handleControlChange('requestedStartOfCare')}
                        value={state.requestedStartOfCare}
                        minDate={requestedStartOfCareDateRanges().from}
                        maxDate={requestedStartOfCareDateRanges().to}
                        onTextChange={homeHealthComplexCareModel.handleControlChange("requestedStartOfCareString")}
                        error={homeHealthComplexCareModel.getErrorMessage('requestedStartOfCare')}
                />
            </div>
        </div>
        <div className="radio-field">
                        <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                            Care Address
                        </Typography>
                <CtrlRadio
                        options={careAddressValues}
                        value={state.careAddress}
                        onChange={homeHealthComplexCareModel.handleChange}
                        name="careAddress"
                />
            </div>
            <Address
                        values={state.careAddress === 'Home' ? claimPatientState.address : state.address}
                        handleChange={homeHealthComplexCareModel.handleControlChange}
                        getErrorMessage={homeHealthComplexCareModel.getErrorMessage}
                        disabled = {state.careAddress === 'Home'}
                        requiredFields={addressPopulated ? ['address1', 'city', 'state', 'zip'] : []}
            />
            <div className= "span-6" style={{ display: 'flex' }}>
                <div className="radio-field">
                        <Typography variant="body1" color={theme.app.neutralColor.textMain}>
                        Patient in the Hospital?
                        </Typography>
                <CtrlRadio
                        options={patientRadioButtonValues}
                        value={state.patientInHospital}
                        onChange={homeHealthComplexCareModel.clearHospitalFields}
                        name="patientInHospital"
                />
                </div>
            <div className= "span-6 hospital-facility">
            <CtrlTextField
                        label="Hospital / Facility Name"
                        value={state.hospitalFacilityName}
                        maxCharacters={256}
                        onChange={homeHealthComplexCareModel.handleControlChange('hospitalFacilityName')}
                        disabled = {state.patientInHospital !== 'Yes'}
                />
            </div>
        </div>
        <div className="fields-container">
            <div className="span-3">
                <CtrlDateField 
                        label="Hospital Discharge Date" 
                        onChange={homeHealthComplexCareModel.handleControlChange('hospitalDischargeDate')}
                        value={state.hospitalDischargeDate}
                        onTextChange={homeHealthComplexCareModel.handleControlChange('hospitalDischargeDateString')}
                        minDate={hospitalDischargeDateRange().from}
                        maxDate={hospitalDischargeDateRange().to}
                        error={homeHealthComplexCareModel.getErrorMessage('hospitalDischargeDate')}
                        disabled = {state.patientInHospital !== 'Yes'}
                />
            </div>
            <div className="span-3">
                <CtrlTextField
                        label="Hospital Phone"
                        value={state.hospitalPhone}
                        onChange={homeHealthComplexCareModel.handleControlChange('hospitalPhone')}
                        phoneFormat
                        disabled = {state.patientInHospital !== 'Yes'}
                        error={homeHealthComplexCareModel.getErrorMessage('hospitalPhone')}
                />
            </div>     
            <div className="span-6">
                <CtrlTextField
                        label="Contact Person"
                        value={state.contactPerson}
                        maxCharacters={120}
                        onChange={homeHealthComplexCareModel.handleControlChange('contactPerson')}
                        disabled = {state.patientInHospital !== 'Yes'}
                />
            </div>        
        </div>
        <div className="hh-is-rush-row">
                <div className="span-12">
                    <CtrlCheckbox
                        label="Rush"
                        value={state.isRush}
                        onChange={homeHealthComplexCareModel.handleControlChange('isRush')}
                    />
                </div>
            </div>
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection('HomeHealthCare', homeHealthComplexCareModel);
                        model.validateSection('Attachments', model.attachmentsModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}


export const HomeHealthComplexCare = styled(HomeHealthComplexCareComponent, { name: 'HomeHealthComplexCare' })(({ theme }) => ({
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.service-padding': {
        paddingBottom: 22,
    },
    '.radio-buttons-section': {
        marginTop: 24,
    },
    '.hh-is-rush-row': {
        marginTop: 7,
        marginBottom: 7,
        paddingTop: 7,
    },
    '.frequency':
    {
        paddingBottom: 10,
        width: '70%'
    },
    '.frequency-header':
    {
        width: '70%'
    },
    '.frequency-required': {
        color: theme.app.statusColor.errorMain, 
    },
    '.services-section': {
        padding: '16px',
        width: '65%',
        margin: '8px 0px',
        border: '1px solid',
        borderColor: theme.app.neutralColor.borderMain,
        borderRadius: 12,
        background: theme.app.neutralColor.backgroundSuperLight,
        columnGap: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    '.hh-header': {
        padding: '0 16px',
        width: '65%',
        margin: '8px 0px',
        columnGap: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    '.service-row': {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    '.radio-field': {
        marginTop: theme.spacing(5),
        width: '33%'
    },
    '.hospital-facility': {
        width: '32%',
        marginTop: 34,
    }
}));
