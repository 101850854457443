import { ChangeEvent } from 'react';
import { FieldValidator, SectionSystemCode, TRange } from '../../../../common/types';
import {
    validateDateField,
    validatePhoneNumber,
    validateRequiredField,
    validateTimeFieldFromString,
    validateZipCode,
} from '../../../../common/validationHelpers';
import { ChangeableModel } from '../../../../model/model';
import { TResourceValue, valuesManager } from '../../../../services/valuesManager/valuesManager';
import { ITransportationState } from './TransportationState';
import { ReferralModel, referralModel } from '../../../ReferralModel';
import { fixCollectionFieldsErrorsOrder } from '../../utils';
import { AddressTypes, TripType, appointmentToDestinationMap } from './constants';
import dayjs from 'dayjs';
import _ from 'lodash';

export const sectionSystemCode: SectionSystemCode = 'TransportationServices';

export const appointmentDateRange = (): TRange<Date> => {
    return {
        from: dayjs(new Date()).startOf('day').toDate(),
        to: dayjs(new Date()).add(1, 'year').endOf('year').toDate(),
    };
};

export const transportationValidators: FieldValidator[] = [
    {
        fieldName: 'transportationRequired.value',
        fieldLabel: 'Transportation Required',
        validateFunctions: [validateRequiredField],
    },
    {
        fieldName: 'typeOfAppointment.value',
        fieldLabel: 'Type of Appointment',
        validateFunctions: [validateRequiredField],
    },
    {
        fieldName: 'appointmentDate',
        fieldLabel: 'Appointment Date',
        validateFunctions: [
            validateRequiredField,
            validateDateField(
                () => referralModel.transportationModel.state.get().appointmentDateString,
                appointmentDateRange,
            ),
        ],
    },
    {
        fieldName: 'appointmentTimeString',
        fieldLabel: 'Appointment Time',
        validateFunctions: [validateTimeFieldFromString],
    },
];

export class TransportationModel extends ChangeableModel<ITransportationState> {
    handleTransportationTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const transportationType = event.target.value;
        const state = this.state.get();
        if (state.transportationType === transportationType) {
            return;
        }
        this.handleChange(event);

        if (transportationType === TripType.MultipleStops && state.destinationAddresses.length < 2) {
            this.addDestinationAddress();
        } else {
            for (let idx = state.destinationAddresses.length - 1; idx > 0; idx--) {
                this.removeDestinationAddress(idx);
            }
        }
    };

    handleTypeOfAppointmentChange = (typeOfAppointment?: TResourceValue) => {
        const currentState = this.state.get();
        const selectedType = typeOfAppointment?.value;

        if (currentState.typeOfAppointment?.value === selectedType) {
            return;
        }

        this.handleControlChange('typeOfAppointment')(typeOfAppointment);
        this.setAddressTypes(selectedType);
    };

    addDestinationAddress = () => {
        this.state.update((s) => s.destinationAddresses.push({}));
    };

    removeDestinationAddress = (index: number) => {
        this.state.update((s) => {
            if (s.destinationAddresses.length > index) {
                s.destinationAddresses.splice(index, 1);
                s.errors = s.errors.filter((error) => !error.fieldName.startsWith(`destinationAddresses[${index}]`));
                fixCollectionFieldsErrorsOrder('destinationAddresses', 'Destination Address', s);
            }
        });
    };

    handleAppointmentDateChange = (fieldName: string) => (value?: any) => {
        this.handleControlChange(fieldName)(value);

        const state = this.state.get();
        if (!value && (state.appointmentTime || state.appointmentTimeString)) {
            this.handleControlChange('appointmentTime')(undefined);
            this.handleControlChange('appointmentTimeString')('');
        }
    };

    validate(referralModel: ReferralModel): void {
        const state = this.state.get();

        const originationAddressValidators = this.getAddressValidators(
            'originationAddress',
            'Origination Address',
            state.originationAddress.type,
        );
        const destinationAddressesValidators = state.destinationAddresses
            .map((address, idx) =>
                this.getAddressValidators(
                    `destinationAddresses[${idx}]`,
                    `Destination Address ${idx + 1}`,
                    address.type,
                ),
            )
            .flat();

        this.validators = [
            ...transportationValidators,
            ...originationAddressValidators,
            ...destinationAddressesValidators,
        ];

        super.validate(referralModel);
    }

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([
            ['Transportation Required', state.transportationRequired?.label],
            ['Type of Appointment', state.typeOfAppointment?.label],
        ]);
    };

    private getAddressValidators = (fieldName: string, label: string, addressType?: TResourceValue) => {
        return getAddressValidators(fieldName, label, addressType);
    };

    private setAddressTypes(selectedType?: string) {
        this.state.update((s) => {
            if (!selectedType) {
                return;
            }

            const isDischarge = selectedType === AddressTypes.Discharge;
            s.originationAddress.type = valuesManager.resourceValues.addressTypes.find((type) =>
                isDischarge ? type.value === AddressTypes.Hospital : type.value === AddressTypes.Home,
            );
            let errors = s.errors.filter((err) => err.fieldName !== 'originationAddress.type.value');

            if (s.transportationType !== TripType.MultipleStops) {
                const targetAddressType = appointmentToDestinationMap.get(selectedType || '');
                s.destinationAddresses = [
                    {
                        ...s.destinationAddresses[0],
                        type: valuesManager.resourceValues.addressTypes.find(
                            (type) => type.value === targetAddressType,
                        ),
                    },
                ];
                errors = errors.filter((err) => err.fieldName !== 'destinationAddresses[0].type.value');
            }

            s.errors = errors;
        });
    }

    override getAdditionalNotes(withSpecialInstructions: boolean = true) {
        const state = this.state.get();

        let notes: [string, string][] = [];

        const note = [
            referralModel.getGeneralInstructions(),
            this.formatAdditionalNotes(
                [{ fieldName: 'additionalInstructions', label: 'Additional instructions' }],
                sectionSystemCode,
                withSpecialInstructions,
            ),
        ];

        notes.push([state.transportationRequired?.value ?? '', note.filter(n => n).join(';')]);

        return new Map<string, string>(notes);
    }
}

const getAddressValidators = (fieldName: string, label: string, addressType?: TResourceValue) => [
    {
        fieldName: `${fieldName}.type.value`,
        fieldLabel: `${label} - Type`,
        validateFunctions: [validateRequiredField],
    },
    {
        fieldName: `${fieldName}.address1`,
        fieldLabel: `${label} - Address 1`,
        validateFunctions: [
            (value: any, referralModel: ReferralModel) => {
                let addressValue = value;

                if (addressType?.value === 'Home') {
                    const patientAddress = referralModel.claimPatientModel.state.get().address;
                    addressValue = patientAddress?.address1 || patientAddress?.address1Masked;
                }

                return validateRequiredField(addressValue);
            },
        ],
    },
    {
        fieldName: `${fieldName}.city`,
        fieldLabel: `${label} - City`,
        validateFunctions: [validateTransportationAddress('city', addressType)],
    },
    {
        fieldName: `${fieldName}.state.value`,
        fieldLabel: `${label} - State`,
        validateFunctions: [validateTransportationAddress('state.value', addressType)],
    },
    {
        fieldName: `${fieldName}.zip`,
        fieldLabel: `${label} - Zip`,
        validateFunctions: [validateTransportationAddress('zip', addressType), validateZipCode],
    },
    {
        fieldName: `${fieldName}.locationPhone`,
        fieldLabel: `${label} - Location Phone`,
        validateFunctions: [validateTransportationLocationPhone(addressType)],
    },
];

const validateTransportationAddress = (fieldName: string, addressType?: TResourceValue) => {
    return (value: string, referralModel: ReferralModel) => {
        return validateRequiredField(addressType?.value === "Home" ? _.get(referralModel.claimPatientModel.state.get().address, fieldName) : value)
    };
}

const validateTransportationLocationPhone = (addressType?: TResourceValue) => {
    return (value: string, referralModel: ReferralModel) => {
        return validatePhoneNumber(addressType?.value === "Home" ? referralModel.claimPatientModel.state.get().homePhone ?? '' : value)
    };
}