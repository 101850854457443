import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { DefaultProps } from '../../common/types';
import { ReactElement } from 'react';

export interface Option {
    value: string;
    label: string;
}

export interface CtrlRadioProps extends DefaultProps {
    options: Option[];
    name?: string;
    value?: string;
    disabled?: boolean;
    children?: React.JSX.Element
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function CtrlRadioComponent(props: CtrlRadioProps) {
    const { className, options, name, value, onChange, disabled } = props;

    return (
        <RadioGroup value={value || ''} onChange={onChange} name={name} row className={className}>
            {options.map((option) => (
                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} disabled={disabled}/>
            ))}
            {(props.children as ReactElement)}
        </RadioGroup>
    );
}

export const CtrlRadio = styled(CtrlRadioComponent, { name: 'CtrlRadio' })(({ theme }) => ({
    ".MuiFormControlLabel-root": {
        marginLeft: "-4px",
    },
    ".MuiRadio-root": {
        padding: "4px",
    }
}));
