import { useCallback, useEffect, useState } from 'react';
import { SectionSystemCode } from '../../../common/types';
import { TResourceValue } from '../../../services/valuesManager/valuesManager';

export const defaultColumns = [
    { label: 'Company', valuePath: 'company.value' },
    { label: 'City', valuePath: 'address.city' },
    { label: 'State', valuePath: 'address.state.value' },
    { label: 'Zip', valuePath: 'address.zip' },
];

export interface ICompany {
    company?: TResourceValue;
    [key: string]: any;
}

export const useCompanies = (sectionSystemCode: SectionSystemCode) => {
    const savedCompaniesKey = `${sectionSystemCode}-SavedCompanies`;
    const [savedCompanies, setSavedCompanies] = useState<ICompany[]>([]);

    useEffect(() => {
        setSavedCompanies(JSON.parse(localStorage.getItem(savedCompaniesKey) ?? '[]'));
    }, [savedCompaniesKey]);

    const saveCompany = useCallback(
        (company?: ICompany) => {
            if (!company?.company?.value) {
                return;
            }

            let updatedCompanies = [...savedCompanies];
            const itemIndex = updatedCompanies.findIndex((c) => c.company?.value === company.company?.value);

            if (itemIndex > -1) {
                updatedCompanies[itemIndex] = company;
            } else {
                updatedCompanies.push(company);
            }

            localStorage.setItem(savedCompaniesKey, JSON.stringify(updatedCompanies));
            setSavedCompanies(updatedCompanies);
        },
        [savedCompanies, savedCompaniesKey],
    );

    return { savedCompanies, saveCompany };
};
