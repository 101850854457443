import { TTypedAddress, ValidatedState } from '../../../../common/types';
import { TResourceValue, valuesManager } from '../../../../services/valuesManager/valuesManager';
import { TripType } from './constants';

export type TransportationAddress = {
    practiceName?: string;
    locationPhone?: string;
    locationPhoneMasked?: string;
} & TTypedAddress<TResourceValue>;

export interface ITransportationState extends ValidatedState {
    transportationRequired?: TResourceValue;
    transportationType?: string;
    typeOfAppointment?: TResourceValue;
    appointmentDate?: Date;
    appointmentDateString?: string;
    appointmentTime?: Date;
    appointmentTimeString?: string;
    stairsSteps?: number;
    wheelchairOption?: string;
    additionalInstructions?: string;
    isRush: boolean;
    originationAddress: TransportationAddress;
    destinationAddresses: TransportationAddress[];
}

export const defaultTransportationState: ITransportationState = {
    transportationRequired: valuesManager.resourceValues.transportationServices[0],
    transportationType: TripType.RoundTrip,
    isRush: false,
    originationAddress: {},
    destinationAddresses: [{}],
    errors: [],
};
