import { styled } from '@mui/material';
import { Header } from './Header';
import { Footer } from './Footer';
import { Content } from './Content';
import { referralModel } from './ReferralModel';

function noDefaultDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    if (e.dataTransfer) {
        e.dataTransfer.dropEffect = "none"
    }
}

function MainLayoutComponent(props: any) {
    return (
        <div className={props.className} onDragOver={noDefaultDrop}>
            <Header />
            <Content model={referralModel}/>
            <Footer model={referralModel}/>
        </div>
    );
}

export const MainLayout = styled(MainLayoutComponent, { name: 'MainLayout' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: "100vh",
    backgroundColor: theme.app.neutralColor.backgroundLight,
}));
