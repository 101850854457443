import { Typography, styled } from '@mui/material';
import { ViewProps } from '../../common/types';
import { ChangeableModel } from '../../model/model';
import clsx from 'clsx';
import React from 'react';

export interface SectionSummaryProps<T extends ChangeableModel<any>> extends ViewProps<T> {}

function SectionSummaryComponent<T extends ChangeableModel<any>>(props: SectionSummaryProps<T>) {
    const { model } = props;
    const fields = model.getSummaryFields();
    model.state.use();

    const getSpan = React.useCallback((): string => {
        switch (fields.size) {
            case 1:
                return 'span-4';
            case 2:
                return 'span-2';

            case 3:
            case 4:
                return 'span-1';

            default:
                return '';
        }
    }, [fields.size]);

    return (
        <div className={props.className}>
            {Array.from(fields).map(([label, value]) => (
                <div className={clsx("cell", getSpan())} key={label}>
                    <Typography variant="h2">{label}</Typography>
                    <Typography variant="body2">{value}</Typography>
                </div>
            ))}
        </div>
    );
}

export const SectionSummary = styled(SectionSummaryComponent, { name: 'SectionSummary' })(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    width: '100%',
    alignSelf: 'flex-start',
    padding: theme.spacing(3),
    '.cell': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        alignItems: 'flex-start',
        padding: theme.spacing(0, 1),
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
    },
}));
