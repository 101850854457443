import { Box, Typography, styled } from '@mui/material';
import { CtrlCheckbox } from '../../components/controls/CtrlCheckbox';
import { DefaultProps, Section, SectionSystemCode } from '../../common/types';
import { InfoIcon, WarningIcon } from '../../theme/icons';
import { helperError } from '../../components/controls/elements/helperError';
import { TPortalCustomization } from './utils';

export interface ServicesProps extends DefaultProps {
    sections: Section[];
    customization: TPortalCustomization;
    error?: string;
    onToggleService: (service: SectionSystemCode) => void;
}

const filterServices = (sections: Section[], customization: TPortalCustomization) => {
    let services = sections.filter((s) => s.service);

    const customizationServicesMap = new Map<TPortalCustomization, SectionSystemCode[]>([
        ['DD', ['dsDentalServices', 'dsDoctorServices']],
        ['TL', ['TransportationServices', 'LanguageTranslation']],
    ]);

    const customizationSpecificServices = customizationServicesMap.get(customization);

    if(customizationSpecificServices){
        services = services.filter(s => customizationSpecificServices.includes(s.systemCode))
    }

    return services;
};

function ServicesComponent(props: ServicesProps) {
    const { sections, error, onToggleService, customization } = props;
    const services = filterServices(sections, customization);

    return (
        <div className={props.className}>
            <Box className="box">
                <div className="section-header">
                    <Typography variant="h2" className="title">
                        Services
                    </Typography>

                    <div className="services">
                        {services.map((service) => (
                            <CtrlCheckbox
                                className="span-3"
                                key={service.systemCode}
                                value={services.find((s) => s === service)?.serviceSelected ?? false}
                                label={service.title}
                                onChange={() => onToggleService(service.systemCode)}
                            />
                        ))}

                        <div className="notes-row span-12">
                            <InfoIcon />
                            <div>Check all that apply</div>
                        </div>

                        {error && <div className="span-12">{helperError(error)}</div>}
                    </div>

                    {error && <WarningIcon className="icon" />}
                </div>
            </Box>
        </div>
    );
}

export const Services = styled(ServicesComponent, { name: 'Services' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 46,
    zIndex: 2000,
    '.box': {
        backgroundColor: theme.app.neutralColor.white,
        padding: theme.spacing(4),
        borderBottom: `solid 1px ${theme.app.neutralColor.borderMain}`,
        shadow: theme.app.neutralColor.shadow1,
        borderRadius: 4,
    },
    '.title': {
        color: theme.app.accentColor.textMain,
        justifyContent: 'flex-start',
    },
    '.services': {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        columnGap: theme.spacing(3),
        rowGap: theme.spacing(2),
        margin: theme.spacing(0, 3),
    },
    '.section-header': {
        minHeight: 'unset',
        height: '100%',
        alignItems: 'flex-start',
    },
    '.notes-row': {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
    },
    '.icon': {
        left: '65%',
        position: 'absolute',
        color: theme.app.statusColor.errorMain,
    },
}));
