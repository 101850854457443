import { styled } from "@mui/material";
import { CtrlTextFieldProps } from "./CtrlTextField";
import { CtrlComboTemplate } from "./CtrlComboTemplate";
import { useCallback, useMemo, useRef, useState } from "react";
import {
    defaultGetOptionDisabled,
    defaultOptionGetLabel,
    useFilteredOptions,
    useSelectOptions,
} from "./utils";
import { ctrlListItemHeight } from "./CtrlList";
import { CtrlListMultiselect } from "./CtrlListMultiselect";
import { CtrlChip } from "./CtrlChip";
import clsx from "clsx";
import { TOptionHint } from "../../common/types";

const maxVisibleItems = 10;

const ListContainer = styled("div", { name: "ComboSelectListContainer" })<{
    width?: number | string;
    height?: number | string;
}>(({ theme, width, height }) => ({
    width,
    height,
    minHeight: 18,
    ul: {
        margin: 0,
    },
    ".ctrl-list-empty-message": {
        color: theme.app.neutralColor.textDefault,
        textAlign: "center",
    },
}));

export interface CtrlComboMultiSelectProps<T = any>
    extends Omit<CtrlTextFieldProps, "value" | "onChange"> {
    selectFrom?: readonly T[] | (() => T[] | Promise<T[]>);
    getLabel?: (value: T, index?: number) => string;
    value?: readonly T[];
    onChange?: (selected: T[]) => void;
    getOptionClass?: (value: T, index?: number) => string;
    getOptionDisabled?: (value: T, index?: number) => boolean;
    getOptionHint?: (value: T, index?: number) => TOptionHint;
    limitChips?: number;
    isOnModalDialog?: boolean;
}

function CtrlComboMultiSelectComponent<T = any>(
    props: CtrlComboMultiSelectProps<T>
) {
    const {
        selectFrom,
        getLabel: propsGetLabel,
        value,
        onChange,
        disabled,
        getOptionClass,
        getOptionDisabled: propsGetOptionDisabled,
        getOptionHint,
        limitChips = 2,
        InputProps,
        className,
        onClear: propsOnClear,
        isOnModalDialog,
        ...other
    } = props;

    const [open, setOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [search, setSearch] = useState("");
    const textFieldRef = useRef<HTMLDivElement | null>(null);

    const getLabel = useCallback(
        (option: T, index?: number) => {
            return propsGetLabel
                ? propsGetLabel(option, index)
                : defaultOptionGetLabel(option);
        },
        [propsGetLabel]
    );

    const getOptionDisabled = useCallback(
        (option: T, index?: number) => {
            return propsGetOptionDisabled
                ? propsGetOptionDisabled(option, index)
                : defaultGetOptionDisabled(option);
        },
        [propsGetOptionDisabled]
    );

    const { options, loading } = useSelectOptions(selectFrom, open);
    const filteredOptions = useFilteredOptions(options, search, getLabel);

    const filteredOptionsRef = useRef<readonly T[]>([]);
    const selectedFirstRef = useRef<readonly T[]>([]);
    const selectedFirst = useMemo(() => {
        if (!open || filteredOptionsRef.current !== filteredOptions) {
            filteredOptionsRef.current = filteredOptions;
            selectedFirstRef.current = [
                ...filteredOptions.filter((o) => value?.includes(o)),
                ...filteredOptions.filter((o) => !value?.includes(o)),
            ];
        }
        return selectedFirstRef.current;
    }, [filteredOptions, open, value]);

    const doSetInputText = useCallback(
        (val: string) => {
            if (open) {
                setInputText(val);
                setSearch(val);
            }
        },
        [open]
    );

    const doSetOpen = useCallback((val: boolean) => {
        setOpen(val);
        setInputText("");
        setSearch("");
    }, []);

    const doSelect = useCallback(
        (items: T[]) => {
            onChange?.(items);
        },
        [onChange]
    );

    const onClear = useCallback(() => {
        if (!disabled) {
            onChange?.([]);
            doSetOpen(false);
        }
        propsOnClear?.();
        return true;
    }, [disabled, doSetOpen, onChange, propsOnClear]);

    const renderDropDown = useCallback(() => {
        const height =
            Math.min(filteredOptions.length, maxVisibleItems) *
            ctrlListItemHeight;
        return (
            <ListContainer
                className="list-container"
                width={
                    textFieldRef.current
                        ? textFieldRef.current.clientWidth
                        : 200
                }
                height={height}
            >
                <CtrlListMultiselect
                    options={selectedFirst}
                    selected={value}
                    setSelected={doSelect}
                    loading={loading}
                    getOptionClass={getOptionClass}
                    getOptionDisabled={getOptionDisabled}
                    getOptionHint={getOptionHint}
                />
            </ListContainer>
        );
    }, [
        doSelect,
        filteredOptions.length,
        getOptionClass,
        getOptionDisabled,
        getOptionHint,
        loading,
        selectedFirst,
        value,
    ]);

    const startAdornment = useMemo(() => {
        if (value && value.length) {
            const nMore = value.length - limitChips;
            const n = Math.min(limitChips, value.length);

            const startAdornment = value.map((val, idx) => {
                return (
                    <CtrlChip
                        key={idx}
                        className="selected-valu-chip"
                        label={getLabel(val)}
                        style={{ maxWidth: `${Math.floor(80 / n)}%` }}
                        onDelete={() =>
                            doSelect(value.filter((r) => r !== val))
                        }
                    />
                );
            });
            if (nMore > 0) {
                startAdornment.length = limitChips;
                startAdornment.push(
                    <span key="moreSel" className="mor-selected">
                        +{nMore}
                    </span>
                );
            }
            return startAdornment;
        }

        return undefined;
    }, [doSelect, getLabel, limitChips, value]);

    return (
        <CtrlComboTemplate
            textFieldRef={textFieldRef}
            renderControl={renderDropDown}
            open={open}
            setOpen={doSetOpen}
            disabled={disabled}
            inputText={inputText}
            setInputText={doSetInputText}
            InputProps={{
                ...InputProps,
                startAdornment,
            }}
            className={clsx(className, { "with-chips": startAdornment })}
            onClear={onClear}
            showClear={Boolean(value?.length)}
            isOnModalDialog={isOnModalDialog}
            {...other}
        />
    );
}

export const CtrlComboMultiSelect = styled(CtrlComboMultiSelectComponent, {
    name: "CtrlComboMultiSelect",
})(({ theme }) => ({
    ".selected-valu-chip": {
        marginRight: theme.spacing(1),
        ".MuiChip-label": {
            color: theme.app.neutralColor.textDark,
        },
    },
    "&.with-chips": {
        ".MuiOutlinedInput-root": {
            paddingLeft: 4,
        },
    },
})) as typeof CtrlComboMultiSelectComponent;
