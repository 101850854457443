import { ReactElement } from "react";
import { ViewProps } from "../../common/types";
import { IModel, TModel } from "../../model/model";

export interface IDialogModel<T = any, R = any> extends IModel<T> {
    close: (result: R | undefined) => void;
    result: Promise<R | undefined>;
    onClose?: (result: R | undefined | null) => void;
}

export class TDialogModel<T = any, R = any>
    extends TModel<T>
    implements IDialogModel<T, R>
{
    close = (result: R | undefined | null) => {
        this.onClose?.(result);
    };
    result: Promise<R | undefined> = Promise.resolve(undefined);
    onClose?: (result: R | undefined | null) => void = undefined;
}

export interface IViewData<M extends TModel<T> = TModel, T = any> {
    viewId: Symbol;
    model: M;
}

export interface IDialogViewData<
    M extends TDialogModel<T> = TDialogModel,
    T = any
> extends IViewData<M, T> {};

export type DefaultView = React.FC<ViewProps<any>>;

interface IViewRegistration<T extends DefaultView = DefaultView> {
    viewId: Symbol;
    Component: T;
}

const views: Map<Symbol, IViewRegistration> = new Map<
    Symbol,
    IViewRegistration
>();

const registerView = <T extends DefaultView = DefaultView>(
    viewId: Symbol,
    View: T
) => {
    views.set(viewId, { viewId, Component: View });
};

const renderView = (viewId: Symbol, props: ViewProps<any>): ReactElement | null => {
    if (!views.has(viewId)) {
        throw new Error(`View "${viewId.toString()}" not registered.`);
    }

    const Component = views.get(viewId)?.Component;
    return Component ? <Component {...props} /> : null;
};

export const Views = {
    registerView,
    renderView,
};