import { CSSObject } from "@mui/material";
import _ from "lodash";

const gridColumnSpan: CSSObject = _.range(12).reduce((acc: CSSObject, i) => {
    acc[`.span-${i + 1}`] = {
        gridColumn: `span ${i + 1}`,
    };
    return acc;
}, {});

const globals: CSSObject = {
    ".grecaptcha-badge": {
        visibility: "hidden",
    },
    "& .flex-item": {
        flex: "1 1 auto",
    },
    "& .flex-center-content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    ...gridColumnSpan,
};

export default globals;
