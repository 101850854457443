import { CSSProperties } from "react";

interface ThemeAppStylesNeutralColor {
    white: CSSProperties["color"];

    textDark: CSSProperties["color"];
    textMain: CSSProperties["color"];
    textDefault: CSSProperties["color"];
    textDisabled: CSSProperties["color"];
    textLight: CSSProperties["color"];

    backgroundPaper: CSSProperties["color"];
    backgroundSuperLight: CSSProperties["color"];
    backgroundLight: CSSProperties["color"];
    backgroundDefault: CSSProperties["color"];
    backgroundHover: CSSProperties["color"];
    backgroundDisabled: CSSProperties["color"];
    backgroundSuperDark: CSSProperties["color"];

    borderMain: CSSProperties["color"];
    borderHover: CSSProperties["color"];
    borderDisabled: CSSProperties["color"];

    iconMain: CSSProperties["color"];
    iconHover: CSSProperties["color"];
    iconDisabled: CSSProperties["color"];

    shadow1: CSSProperties["boxShadow"];
}

interface ThemeAppStylesAccentColor {
    textMain: CSSProperties["color"];
    textHover: CSSProperties["color"];

    backgroundMain: CSSProperties["color"];
    backgroundHover: CSSProperties["color"];
    backgroundLight: CSSProperties["color"];

    borderMain: CSSProperties["color"];

    iconMain: CSSProperties["color"];
    iconHover: CSSProperties["color"];
}

interface ThemeAppStylesStatusColor {
    errorMain: CSSProperties["color"];
    errorLight: CSSProperties["color"];
    errorSuperLight: CSSProperties["color"];
    errorBackgroundMain: CSSProperties["color"];
    errorBackgroundHover: CSSProperties["color"];

    warningMain: CSSProperties["color"];
    warningLight: CSSProperties["color"];
    warningSuperLight: CSSProperties["color"];
}

export interface ThemeAppStyles {
    neutralColor: ThemeAppStylesNeutralColor;
    accentColor: ThemeAppStylesAccentColor;
    statusColor: ThemeAppStylesStatusColor;
}

export const appStyles: ThemeAppStyles = {
    neutralColor: {
        white: "#FFFFFF",

        textDark: "#12091B",
        textMain: "#6C6C7F",
        textDefault: "#9797A6",
        textDisabled: "#BDBDC7",
        textLight: "#FFFFFF",

        backgroundPaper: "#FFFFFF",
        backgroundSuperLight: "#FCFBFE",
        backgroundLight: "#F4F3F6",
        backgroundDefault: "#E9E8ED",
        backgroundHover: "#EEEEF2",
        backgroundDisabled: "#EBE9ED",
        backgroundSuperDark: "#423D4C",

        borderMain: "#DEDDE3",
        borderHover: "#6C6C7F",
        borderDisabled: "#EAE9ED",

        iconMain: "#6C6C7F",
        iconHover: "#12091A",
        iconDisabled: "#BDBDC7",

        shadow1: "0px 4px 16px rgba(31, 20, 42, 0.08)",
    },
    accentColor: {
        textMain: "#8040BF",
        textHover: "#663399",

        backgroundMain: "#8040BF",
        backgroundHover: "#663399",
        backgroundLight: "#EFE7F7",

        borderMain: "#8040BF",

        iconMain: "#8040BF",
        iconHover: "#663399",
    },
    statusColor: {
        errorMain: "#E5201A",
        errorLight: "#FDB0B0",
        errorSuperLight: "#FFD7D7",
        errorBackgroundMain: "#E5201A",
        errorBackgroundHover: "#B71A15",

        warningMain: "#FF7A00",
        warningLight: "#FFC78F",
        warningSuperLight: "#FFEFE0",
    },
};
