import { TAddress, ValidatedState } from "../../../common/types";
import { TResourceValue } from "../../../services/valuesManager/valuesManager";

export interface ICaseManagerState extends ValidatedState {
    firstName?: string;
    lastName?: string;
    phone?: string;
    ext?: string;
    cellPhone?: string;
    email?: string;
    fax?: string;
    company?: TResourceValue;
    address?: TAddress;
    rememberSectionData: boolean;
}

export const defaultCaseManagerState: ICaseManagerState = {
    errors: [],
    rememberSectionData: false,
}