import { ValidatedState } from "../../../common/types"

export interface IOtherPartiesState extends ValidatedState {
    referringPhysicianSelected: boolean,
    caseManagerSelected: boolean,
    attorneySelected: boolean    
}

export const defaultOtherPartiesState: IOtherPartiesState = {
    referringPhysicianSelected: false,
    caseManagerSelected: false,
    attorneySelected: false,
    errors: []
}