import _ from "lodash";
import { SectionSystemCode, TAddress, TRange } from "./types";
import dayjs from "dayjs";
import { ReferralModel, referralModel } from "../pages/ReferralModel";
import { TPortalCustomization, TPortalMode } from "../pages/sections/utils";
import { TResourceValue } from "../services/valuesManager/valuesManager";

export const ERROR_MESSAGE = {
    COMPLETE_THIS_FIELD: 'Complete this field',
    PHONE_MUST_BE_10_DIGITS: 'Phone number must be 10 digits',
    FAX_MUST_BE_10_DIGITS: 'Fax number must be 10 digits',
    ZIP_MUST_BE_5_OR_9_DIGITS: 'Zip code must be 5 or 9 digits',
    EMAIL_MUST_BE_VALID_FORMAT: 'Invalid email format',
    DATE_MUST_BE_VALID_FORMAT: 'Invalid date format',
    TIME_MUST_BE_VALID_FORMAT: 'Invalid time format',
    MISSING_SERVICE: 'At least one service is required',
    INVALID_VALUE: 'Invalid value',
};

export const validateRequiredField = (value?: string) => ((value?.trim ? value.trim() : value)
    ? undefined
    : ERROR_MESSAGE.COMPLETE_THIS_FIELD);

export const removeNonDigitSymbols = (input: string) => !input ? input : input.replace(/[^0-9]/g, '');

export const validatePhoneNumber = (phone: string) => {
    const phoneFormatted = removeNonDigitSymbols(phone);

    return (_.isEmpty(phoneFormatted) || (phoneFormatted.length === 10)
        ? undefined
        : ERROR_MESSAGE.PHONE_MUST_BE_10_DIGITS)
};

export const validateFaxNumber = (fax: string) => {
    const faxFormatted = removeNonDigitSymbols(fax);

    return (_.isEmpty(faxFormatted) || (faxFormatted.length === 10)
        ? undefined
        : ERROR_MESSAGE.FAX_MUST_BE_10_DIGITS)
};

export const validateZipCode = (zip: string | undefined) =>  (!zip || _.isEmpty(zip) || (/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(zip)
? undefined
: ERROR_MESSAGE.ZIP_MUST_BE_5_OR_9_DIGITS);

export const validateEmail = (email: string) => (_.isEmpty(email) || /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(email)
    ? undefined
    : ERROR_MESSAGE.EMAIL_MUST_BE_VALID_FORMAT);

export const validateDateField = (getTextValue: () => string | undefined, getRange?: () => TRange<Date> | undefined, errorMessage?: string) => (value?: Date) => {
    const textValue = getTextValue();
    if (!textValue) {
        return;
    }

    if (!value) {
        return ERROR_MESSAGE.DATE_MUST_BE_VALID_FORMAT;
    }

    const range = getRange?.() || {};
    range.from ??= new Date(1900, 0, 1);
    range.to ??= dayjs(new Date()).add(1, 'year').endOf('year').toDate();

    const dateIsGreater = range.to && value > range.to;
    const dateIsLess = range.from && value < range.from;

    if (dateIsGreater || dateIsLess) {
        return errorMessage ? errorMessage : `Valid date range ${range.from.toLocaleDateString('en-US')} - ${range.to.toLocaleDateString('en-US')}`;
    }
};

export const validateTimeFieldFromString = (value: string) => {
    if (!value) {
        return;
    }

    const format = 'hh:mm a';
    const isValid = dayjs(value, format, true).format(format) === value;

    if (!isValid) {
        return ERROR_MESSAGE.TIME_MUST_BE_VALID_FORMAT;
    }
};

export const requiredForService = (sections: SectionSystemCode[]) => (value: string, referralModel: ReferralModel) => {
    return referralModel.isServiceSelected(sections) ? validateRequiredField(value) : '';
};

export const requiredForModeAndServices = (sections: SectionSystemCode[], mode: TPortalMode) => (value: any, referralModel: ReferralModel) => {
    return referralModel.state.get().mode === mode ? requiredForService(sections)(value, referralModel) : '';
}

type TConditionCallback = ({ value, referralModel }: { value: any; referralModel: ReferralModel }) => boolean;
export const validateWarning = (condition: TConditionCallback) => (value: string, referralModel: ReferralModel) => {
    return condition({ value, referralModel })
        ? 'Providing this information can speed up processing time'
        : undefined;
};

export const isAnyAddressFieldPopulated = (address?: TAddress) => Boolean(address?.address1 || address?.address2 || address?.city || address?.state?.value || address?.zip);

export const requiredIfAddressPopulated = (getAddress: () => TAddress | undefined) => (value: any, referralModel: ReferralModel) => isAnyAddressFieldPopulated(getAddress())
    ? validateRequiredField(value)
    : null;

export const requiredIfCondition = (condition: TConditionCallback) => (value: any, referralModel: ReferralModel) => {
    return condition({ value, referralModel }) ? validateRequiredField(value) : null;
};

export const requiredForCustomization = (customization: TPortalCustomization) => requiredIfCondition(() => referralModel.state.get().customization === customization)

export const validateOptionExists = (options: TResourceValue[]) => (value?: TResourceValue | string) => {
    if (!value) {
        return;
    } 

    if (!options.some(opt => opt.value === value || opt.value === _.get(value, 'value'))) {
        return ERROR_MESSAGE.INVALID_VALUE;
    }
};
