import { styled } from '@mui/system';
import { CtrlCheckbox } from '../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../ReferralModel';
import { SectionSystemCode, ViewProps } from '../../../common/types';
import { CtrlButton } from '../../../components/controls/CtrlButton';
import { ArrowRightIcon } from '../../../theme/icons';


export interface OtherPartiesProps extends ViewProps<ReferralModel> {}
function OtherPartiesComponent(props: OtherPartiesProps) {
    const sectionSystemCode: SectionSystemCode = 'OtherParties';

    const { model } = props;
    const otherPartiesModel = model.otherPartiesModel;
    const state = otherPartiesModel.state.use();
    model.state.use();
    model.submitterModel.state.use();
    
    return (
        <div className={props.className}>
            <div className='parties'>
                <CtrlCheckbox
                    label="There is a Referring Physician on the claim"
                    onChange={(value) => {
                        otherPartiesModel.handleControlChange("referringPhysicianSelected")(value);
                        model.setSectionVisible('ReferringPhysician');
                    }}
                    value={state.referringPhysicianSelected || otherPartiesModel.physicianSelected()}
                    disabled={otherPartiesModel.physicianSelected()}
                />
                <CtrlCheckbox
                    label="There is a Case Manager on the claim"
                    onChange={(value) => {
                        otherPartiesModel.handleControlChange("caseManagerSelected")(value);
                        model.setSectionVisible('NurseCaseManager');
                    }}
                    value={state.caseManagerSelected || otherPartiesModel.caseManagerSelected()}
                    disabled={otherPartiesModel.caseManagerSelected()}
                />
                <CtrlCheckbox
                    label="	There is an Attorney on the claim"
                    onChange={(value) => {
                        otherPartiesModel.handleControlChange("attorneySelected")(value);
                        model.setSectionVisible('Attorney');
                    }}
                    value={state.attorneySelected || otherPartiesModel.attorneySelected()}
                    disabled={otherPartiesModel.attorneySelected()}
                />
            </div>
            <div className="continue-button-row">
                <CtrlButton endIcon={<ArrowRightIcon/>} onClick={() => { model.validateSection(sectionSystemCode, otherPartiesModel) }}>
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const OtherParties = styled(OtherPartiesComponent, { name: 'OtherParties' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    ".parties": {
        display: "flex",
        flexDirection: "column",
    },
    ".parties > :not(:nth-of-type(1))": {
        marginTop: theme.spacing(3),
    }
}));
