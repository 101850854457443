import { validatePhoneNumber, validateZipCode, validateRequiredField, validateWarning, requiredIfAddressPopulated, requiredForModeAndServices, validateOptionExists } from '../../../common/validationHelpers';
import { ChangeableModel } from '../../../model/model';
import { TResourceValue, valuesManager } from '../../../services/valuesManager/valuesManager';
import { ICompany } from '../company/utils';
import { IInsuranceState } from './InsuranceState';
import { ISubmitterState, SubmitterType } from '../submitter/SubmitterState';
import { ReferralModel, referralModel } from '../../ReferralModel';
import { ISubmitterEntity } from '../claimPatient/ClaimPatientModel';
import { setSubmitterFields } from '../utils';
import { SectionSystemCode } from '../../../common/types';

export const adjusterSubmitterTypes: SubmitterType[] = ['AdjusterOffice'];
export const requiredCompanyServices: SectionSystemCode[] = ['PhysicalMedicine', 'DiagnosticServices', 'DurableMedicalEquipment', 'HomeHealthCare', 'LanguageTranslation', 'TransportationServices'];
export const requiredStateServices: SectionSystemCode[] = ['DiagnosticServices', 'DurableMedicalEquipment', 'HomeHealthCare', 'LanguageTranslation', 'TransportationServices'];
export const requiredCityServices: SectionSystemCode[] = ['LanguageTranslation', 'TransportationServices'];

const requiredForSubmitter = (value: any, referralModel: ReferralModel) => {
    return adjusterSubmitterTypes.includes(referralModel.submitterModel.state.get().submitterType)
        ? validateRequiredField(value)
        : null;
};

const requiredIfSectionHasContent = (value: any, referralModel: ReferralModel) => {
    return referralModel.insuranceModel.isAnyFieldPopulated()
        ? validateRequiredField(value)
        : null;
}

export const insuranceValidators = [
    { fieldName: 'phone', fieldLabel: 'Phone', validateFunctions: [validatePhoneNumber] },
    { fieldName: 'address.address1', fieldLabel: 'Address 1', validateFunctions: [requiredIfAddressPopulated(() => referralModel.insuranceModel.state.get().address)] },
    { fieldName: 'address.zip', fieldLabel: 'Zip code', validateFunctions: [validateZipCode, requiredIfAddressPopulated(() => referralModel.insuranceModel.state.get().address)] },
    {
        fieldName: 'company.value',
        fieldLabel: 'Company',
        validateFunctions: [requiredForSubmitter, requiredForModeAndServices(requiredCompanyServices, 'EZReferral'), requiredIfSectionHasContent],
    },
    {
        fieldName: 'address.state.value',
        fieldLabel: 'State',
        validateFunctions: [
            requiredForModeAndServices(requiredStateServices, 'EZReferral'),
            requiredIfAddressPopulated(() => referralModel.insuranceModel.state.get().address),
            validateOptionExists(valuesManager.resourceValues.states)
        ],
    },
    { fieldName: 'address.city', fieldLabel: 'City', validateFunctions: [requiredForModeAndServices(requiredCityServices, 'EZReferral'), requiredIfAddressPopulated(() => referralModel.insuranceModel.state.get().address)] },
    {
        fieldName: 'address',
        fieldLabel: 'Address (Address 1, City, State, Zip)',
        validateFunctions: [
            validateWarning(({ referralModel }) => {
                const address = referralModel.insuranceModel.state.get().address;
                return (
                    !(address?.address1 && (referralModel.isServiceSelected(requiredCityServices) ? true : address?.city) && address?.zip) &&
                    referralModel.isServiceSelected(['DiagnosticServices', 'HomeHealthCare', 'DurableMedicalEquipment'])
                );
            }),
        ],
        isWarning: true,
    },
];

export class InsuranceModel extends ChangeableModel<IInsuranceState> implements ISubmitterEntity {
    copySubmitterFields = (submitter: ISubmitterState) => {
        if (submitter.submitterType === 'AdjusterOffice') {
            const fields = {
                'company.value': submitter.companyName,
                'company.label': submitter.companyName,
            };

            this.state.update((state) => {
                setSubmitterFields(fields, state);
            });
        }
    };

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([
            ['Company', state.company?.value],
            [
                'Address',
                [
                    state.address.address1,
                    state.address.address2,
                    state.address.city,
                    state.address.state?.value,
                    state.address.zip,
                ]
                    .filter((v) => v)
                    .join(' '),
            ],
        ]);
    };

    handleCompanyChange = (company?: TResourceValue | string, savedCompany?: ICompany) => {
        this.state.update((state) => {
            if(typeof company === 'string'){
                state.company = {label: company, value: company, freeText: true};
            }
            else{
                if (savedCompany) {
                    state.address = savedCompany.address;
                    state.phone = savedCompany.phone;
                }
                state.company = { label: company?.label || '', value: company?.value || '' };
            }

            const errors = state.errors.find((err) => err.fieldName === 'company.value');
            if (errors) {
                errors.errors = [];
            }
        });
    };

    isAnyFieldPopulated = () => {
        const state = this.state.get();

        return Boolean(state.company?.value
            || state.phone
            || state.address?.address1
            || state.address?.address2
            || state.address?.city
            || state.address?.state
            || state.address?.zip);
    }
}
