import { FieldValidator } from "../../../common/types";
import { validateEmail, validateFaxNumber, validatePhoneNumber, validateRequiredField } from "../../../common/validationHelpers";
import { ChangeableModel } from "../../../model/model";
import { ReferralModel } from "../../ReferralModel";
import { ISubmitterState } from "./SubmitterState";

const DESCRIBE_SUBMITTER_TYPE_ERROR_MESSAGE = "Describe other submitter type";

const validateOtherSubmitterType = (submitterType?: string) => submitterType
    ? undefined
    : DESCRIBE_SUBMITTER_TYPE_ERROR_MESSAGE;

const validateCompanyName = (value: string, referralModel: ReferralModel) => {
    if (referralModel.submitterModel.state.get().otherSubmitterType === 'UtilizationReview') {
        return validateRequiredField(value);
    }

    return '';
};

export const submitterValidators: FieldValidator[] = [
    {fieldName: "firstName", fieldLabel: "First Name", validateFunctions: [validateRequiredField]},
    {fieldName: "lastName", fieldLabel: "Last Name", validateFunctions: [validateRequiredField]},
    {fieldName: "mainPhone", fieldLabel: "Main Phone", validateFunctions: [validateRequiredField, validatePhoneNumber]},
    {fieldName: "cellPhone", fieldLabel: "Cell Phone", validateFunctions: [validatePhoneNumber]},
    {fieldName: "email", fieldLabel: "Email", validateFunctions: [validateRequiredField, validateEmail]},
    {fieldName: "fax", fieldLabel: "Fax", validateFunctions: [validateFaxNumber]},
    {fieldName: "otherSubmitterType", fieldLabel: "Other Submitter Type", validateFunctions: []},
    {fieldName: "companyName", fieldLabel: "Company Name", validateFunctions: [validateCompanyName]},
];

export class SubmitterModel extends ChangeableModel<ISubmitterState> {
    validate(referralModel: ReferralModel) {
        const submitterTypeValidator = submitterValidators.find(v => v.fieldName === 'otherSubmitterType');
        if(submitterTypeValidator){
            submitterTypeValidator.validateFunctions = [];
            if(this.state.get().submitterType === 'Other'){
                submitterTypeValidator.validateFunctions.push(validateOtherSubmitterType);
            }
        }
        super.validate(referralModel);
    }

    getSummaryFields = () => {
        const state = this.state.get();
        return new Map<string, string | undefined>([
            ["Submitter", [state.firstName, state.lastName].filter((v) => v).join(' ')],
            ["Fax", state.fax],
            ["Phone", [state.mainPhone, state.ext ? `ext.: ${state.ext}` : ''].filter((v) => v).join(' ')],
            ["Email", state.email]
        ]);
    }
}