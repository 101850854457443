import { Button, ButtonProps, styled } from "@mui/material";
import { WithTooltip } from "./WithTooltip";
import { StopClickPropagation } from "./StopClickPropagation";
import { ForwardedRef, forwardRef } from "react";

export interface CtrlButtonProps extends ButtonProps {
    invisible?: boolean;
    titleWidth?: string | number;
}

const CtrlButtonRef = forwardRef(function CtrlButtonComponent(
    props: CtrlButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const { invisible, title, titleWidth, ...other } = props;

    return invisible ? null : (
        <WithTooltip
            title={title}
            titleWidth={titleWidth}
            disabled={props.disabled}
        >
            <StopClickPropagation stop={props.disabled}>
                <Button ref={ref} {...other} />
            </StopClickPropagation>
        </WithTooltip>
    );
});

export const CtrlButton = styled(CtrlButtonRef, { name: "CtrlButton" })(
    ({ theme }) => ({})
);
