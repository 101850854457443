import { ViewProps } from '../../../../common/types';
import { styled } from '@mui/material';
import { CtrlComboSelect } from '../../../../components/controls/CtrlComboSelect';
import { CtrlTextField } from '../../../../components/controls/CtrlTextField';
import { CtrlCheckbox } from '../../../../components/controls/CtrlCheckbox';
import { ReferralModel } from '../../../ReferralModel';
import { valuesManager } from '../../../../services/valuesManager/valuesManager';
import { CtrlButton } from '../../../../components/controls/CtrlButton';
import { ArrowRightIcon, InfoIcon } from '../../../../theme/icons';
import { useEffect } from 'react';

export interface DentalProps extends ViewProps<ReferralModel> {}

const DentistMessage = `Specify below if the patient has seen a dentist related to the injury and treatment provided`;
const NoteMessage = `Note: If you have any medical records, include them in the attachments section or fax to 888-539-0579`;

function DentalComponent(props: DentalProps) {
    const { model } = props;
    const dentalModel = model.dentalModel;
    const state = dentalModel.state.use();
    const dentalReferralForValues = valuesManager.use(s => s.dentalReferralForValues);

    useEffect(() => { valuesManager.loadDentalReferralForValues() }, []);
    useEffect(() => {
        dentalModel.state.update(s => {
            if (!s.referralFor) {
                s.referralFor = dentalReferralForValues?.find(rf => rf.value === 'Evaluation') || dentalReferralForValues?.[0];
            }
        })
    }, [dentalModel.state, dentalReferralForValues])

    return (
        <div className={props.className}>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect
                        label="Referral For"
                        selectFrom={dentalReferralForValues}
                        value={state.referralFor}
                        onChange={(selectedValue) => {
                            const newValue = selectedValue || dentalReferralForValues?.[0];
                            dentalModel.handleControlChange('referralFor')(newValue);
                            dentalModel.clearErrorMessages('referralForDescription');
                        }}
                        hideClear
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Description"
                        value={state.referralForDescription}
                        onChange={dentalModel.handleControlChange('referralForDescription')}
                        error={dentalModel.getErrorMessage('referralForDescription')}
                        required={state.referralFor?.value === "Other"}
                        maxCharacters={200}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-6">
                    <CtrlComboSelect
                        label="Visit Purpose"
                        selectFrom={valuesManager.resourceValues.dentalVisitPurposeValues}
                        value={state.visitPurpose}
                        onChange={(selectedValue) => {
                            const newValue = selectedValue || valuesManager.resourceValues.dentalVisitPurposeValues[0];
                            dentalModel.handleControlChange('visitPurpose')(newValue);
                            dentalModel.clearErrorMessages('visitPurposeDescription');
                        }}
                        hideClear
                    />
                </div>
                <div className="span-6">
                    <CtrlTextField
                        label="Description"
                        value={state.visitPurposeDescription}
                        onChange={dentalModel.handleControlChange('visitPurposeDescription')}
                        error={dentalModel.getErrorMessage('visitPurposeDescription')}
                        required={state.visitPurpose?.value === "DentOther"}
                        maxCharacters={200}
                    />
                </div>
            </div>
            <div className="fields-container">
                <div className="span-12">
                    <CtrlTextField
                        label="How did the Injury Occur?"
                        value={state.howDidTheInjuryOccur}
                        onChange={dentalModel.handleControlChange('howDidTheInjuryOccur')}
                        maxCharacters={255}
                    />
                </div>
            </div>
            <br />
            <br />
            <div>
                <div className="span-12">{DentistMessage}</div>
            </div>
            <div className="dental-info">
                <div className="fields-container">
                    <div className="span-4">
                        <CtrlTextField
                            label="Dentist First Name"
                            value={state.dentistFirstName}
                            onChange={dentalModel.handleControlChange('dentistFirstName')}
                            maxCharacters={50}
                        />
                    </div>
                    <div className="span-4">
                        <CtrlTextField
                            label="Dentist Last Name"
                            value={state.dentistLastName}
                            onChange={dentalModel.handleControlChange('dentistLastName')}
                            maxCharacters={50}
                        />
                    </div>
                    <div className="span-4">
                        <CtrlTextField
                            label="Practice Name"
                            value={state.practiceName}
                            onChange={dentalModel.handleControlChange('practiceName')}
                            maxCharacters={150}
                        />
                    </div>
                </div>
                <div className="fields-container">
                    <div className="span-4">
                        <CtrlTextField
                            label="Phone"
                            value={state.phone}
                            onChange={dentalModel.handleControlChange('phone')}
                            error={dentalModel.getErrorMessage('phone')}
                            phoneFormat
                        />
                    </div>
                    <div className="span-4">
                        <CtrlTextField
                            label="Fax"
                            value={state.fax}
                            onChange={dentalModel.handleControlChange('fax')}
                            error={dentalModel.getErrorMessage('fax')}
                            phoneFormat
                        />
                    </div>
                    <div className="span-4 dental-checkbox">
                        <CtrlCheckbox
                            label="I'd like One Call to work with this provider"
                            value={state.isOCwithProvider}
                            onChange={dentalModel.handleControlChange('isOCwithProvider')}
                        />
                    </div>
                </div>
                <div className="fields-container">
                    <div className="span-12">
                        <CtrlTextField
                            label="Previous Treatment"
                            value={state.previousTreatment}
                            onChange={dentalModel.handleControlChange('previousTreatment')}
                            maxCharacters={4000}
                        />
                    </div>
                </div>
                <div className="hh-is-rush-row">
                    <div className="span-12">
                        <CtrlCheckbox
                            label="Rush"
                            value={state.isRush}
                            onChange={dentalModel.handleControlChange('isRush')}
                        />
                    </div>
                </div>
                <div className="notes-row">
                    <InfoIcon />
                    <div className="span-12">{NoteMessage}</div>
                </div>
                <div className="continue-button-row">
                    <CtrlButton
                        endIcon={<ArrowRightIcon />}
                        onClick={() => {
                            model.validateSection('dsDentalServices', dentalModel);
                            model.validateSection('Attachments', model.attachmentsModel);
                        }}
                    >
                        Continue
                    </CtrlButton>
                </div>
            </div>
        </div>
    );
}

export const Dental = styled(DentalComponent, { name: 'Dental' })(({ theme }) => ({
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.dental-checkbox': {
        position: 'relative',
        marginTop: 7,
    },
    '.hh-is-rush-row': {
        marginTop: 7,
        marginBottom: 20,
        paddingTop: 7,
    },
    '.notes-row': {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        columnGap: theme.spacing(1),
    },
    '.dental-info': {
        position: 'relative',
        marginTop: 12,
    },
}));
